import styled from 'styled-components';

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked {
    & ~ div::after {
      transform: translate(100%, -50%);
    }
  }
`;

export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  font-size: 10px;
  line-height: 16px;

  display: flex;
  justify-content: center;

  & > span {
    color: ${(props) => props.theme.colors.primaryText};
    user-select: none;
  }
`;

export const Switch = styled.div`
  width: 25px;
  height: 14px;
  background-color: ${(props) => props.theme.colors.primaryText};
  border-radius: 22px;
  cursor: pointer;
  margin: 0 8px;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.white};
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translate(0, -50%);
    transition: transform 150ms ease-in-out;
  }
`;
