import styled from 'styled-components';
import Button from '../../Button/Button';
import Typography from '../../Typography/Typography';

export const GasDayContainer = styled.div`
  background-color: #efefef;
  border-radius: ${(props) => props.theme.borderRadius};
  width: 100%;
  margin-bottom: 24px;
  padding: 18px;
`;

export const Container = styled.div`
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: ${(props) => props.theme.borderRadius};
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  margin-bottom: 16px;
`;

export const Picker = styled.div`
  width: 100%;
  justify-content: center;
  display: inline-flex;
  gap: 16px;
  align-items: flex-start;
`;

export const BottomButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const PickerButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
`;

export const Separator = styled.span`
  display: block;
  font-size: 56px;
  margin-top: -20px;
`;

export const SubTitle = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 15px;
`;
