import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { MdAccessTime } from 'react-icons/md';
import * as yup from 'yup';
import { Device, Tag, TagCategory, TagType } from '../../../API';
import errorMessages from '../../../config/errorMessages';
import {
  useUpdateDeviceShadow,
  useRemoveTagFromDevices,
  useAddTagToDevices,
} from '../../../hooks/mutations';
import { useDeviceShadow } from '../../../hooks/queries';
import { DeviceShadowAliases } from '../../../screens/MeterId/types';
import Alert from '../../Alert/Alert';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import Select from '../../Forms/Select/Select';
import Range from '../../Forms/_Range/Range';
import MenuList, { MenuListItem } from '../../MenuList/MenuList';
import Spinner from '../../Spinner/Spinner';
import TimePickerCard, { OnOkBtnClickTimePicker } from '../../TimePickerCard/TimePickerCard';
import Typography from '../../Typography/Typography';
import WithPadding from '../../WithPadding/WithPadding';
import Modal, {
  ModalContext,
  ModalContextI,
  ModalOpeningProps,
  ModalStep,
  ModelButton,
} from '../Modal';
import { DeviceShadowData, DeviceShadowRaw } from './classes/RangeAsset';
import AdemUtils, { AdemModel } from '../../../utils/AdemUtils';
import ATPR from './classes/atPr';
import BPR from './classes/bPr';
import GCO2 from './classes/gCO2';
import GN2 from './classes/gN2';
import GSG from './classes/gsG';
import PRHL from './classes/prHL';
import PRLL from './classes/prLL';
import TEMPHL from './classes/tempHL';
import TEMPLL from './classes/tempLL';
import UFHL from './classes/ufHL';
import UFLL from './classes/ufLL';
import { GasDayContainer } from './style';
import { AssetInfoContainer } from '../style';
import { tagListToTagMap, TagMap, TagsByType } from '../../../utils/TagUtils';
import { useAuth } from '../../../contexts/Auth';

type Props = {
  onTagsChanged: (changed: boolean) => void;
  device: Device;
  predefinedTagCategories: TagCategory[];
  utilityTagCategories: TagCategory[];
  currentPredefinedTags: Tag[];
  currentUtilityTags: Tag[];
};

/**
 * Maps the tag category identifier to the selected tag identifier.
 */
type TagCategorySelectedTag = {
  [categoryId: string]: string;
};

type DeviceShadowDataBrighLyncOnly = Pick<DeviceShadowData, 'wk1' | 'wk2' | 'wk3' | 'wk4' | 'tT'>;

type BrightLyncData = {
  label: string;
  alias: keyof DeviceShadowDataBrighLyncOnly;
  value?: string;
};

const stringTypeKeys = ['tT', 'wk1', 'wk2', 'wk3', 'wk4', 'MID'];
const deviceShadowDataKeys: DeviceShadowAliases[] = [
  'cVD',
  'cVUt',
  'cV',
  'uVD',
  'uVUt',
  'uV',
  'atPr',
  'bPr',
  'tempHL',
  'tempLL',
  'prHL',
  'prLL',
  'uFHL',
  'uFLL',
  'MID',
  'gCO2',
  'gN2',
  'gsG',
  'tT',
  'wk1',
  'wk2',
  'wk3',
  'wk4',
];

const schema = yup.object().shape({
  tempHL: yup
    .number()
    .moreThan(yup.ref('tempLL'), errorMessages.moreThanField('Temperature Low Limit')),
  tempLL: yup
    .number()
    .lessThan(yup.ref('tempHL'), errorMessages.lessThanField('Temperature High Limit')),
  prHL: yup.number().moreThan(yup.ref('prLL'), errorMessages.moreThanField('Pressure Low Limit')),
  prLL: yup.number().lessThan(yup.ref('prHL'), errorMessages.lessThanField('Pressure High Limit')),
  uFHL: yup.number().moreThan(yup.ref('uFLL'), errorMessages.moreThanField('Flow Low Limit')),
  uFLL: yup.number().lessThan(yup.ref('uFHL'), errorMessages.lessThanField('Flow High Limit')),
  MID: yup.string().required(errorMessages.required).max(16, errorMessages.maxCharacters(16)),
});

const AssetConfigurationModal: React.FC<ModalOpeningProps & Props> = ({
  onTagsChanged,
  device,
  predefinedTagCategories,
  utilityTagCategories,
  currentPredefinedTags,
  currentUtilityTags,
  closeModalFunc,
  open,
  ...props
}) => {
  const { refactoredUser } = useAuth();

  const includeTagging = refactoredUser?.hasRole('UtilityAdmin') ?? false;

  const deviceShadow = useDeviceShadow({
    variables: {
      deviceId: device.id,
      region: device.group?.utility.company?.region!,
      prettify: false,
    },
    additionalOptions: {
      enabled: !!device,
    },
  });

  const currentPredefinedTagMap = tagListToTagMap<Tag>(currentPredefinedTags);
  const currentUtilityTagMap = tagListToTagMap<Tag>(currentUtilityTags);

  const updateDeviceShadowMutation = useUpdateDeviceShadow();
  const contextValue = useRef<ModalContextI | null>(null);

  const removeTagFromDevicesMutation = useRemoveTagFromDevices();
  const addTagToDevicesMutation = useAddTagToDevices();

  const [gdstComponents, setGdstComponents] = useState<[number, number, number]>([0, 0, 0]);
  const [gdstMeridian, setGdstMeridian] = useState<'AM' | 'PM'>('AM');

  const [timePickersOn, setTimePickersOn] = useState<string[]>([]);
  const [errorMsg, setErrorMsg] = useState(
    'Configuration may take up to 24 hours to get updated and reflected'
  );

  const [confirmationShown, setConfirmationShown] = useState(false);

  const {
    formState: { errors },
    register,
    setValue,
    getValues,
    setError,
    trigger,
  } = useForm<DeviceShadowData & TagCategorySelectedTag>({
    resolver: yupResolver(schema),
  });

  const data = useMemo(
    () => JSON.parse(deviceShadow.data?.getDeviceShadow || '{}') as DeviceShadowRaw,
    [deviceShadow.data?.getDeviceShadow]
  );

  const valuesFromShadow = data?.state?.reported;
  const ademModel: AdemModel | null = AdemUtils.parseAdemModel(valuesFromShadow?.FWV);

  const volumeData = {
    imperial: {
      data: [
        {
          value: 97,
          label: 0.1,
        },
        {
          value: 3,
          label: 1,
        },
        {
          value: 4,
          label: 10,
        },
        {
          value: 5,
          label: 100,
        },
        {
          value: 6,
          label: 1000,
        },
        {
          value: 14,
          label: 10000,
        },
      ],
      unit: 'FT³',
    },
    metric: {
      data: [
        {
          value: 98,
          label: 0.0001,
        },
        {
          value: 99,
          label: 0.01,
        },
        {
          value: 9,
          label: 0.1,
        },
        {
          value: 10,
          label: 1,
        },
        {
          value: 11,
          label: 10,
        },
        {
          value: 12,
          label: 100,
        },
      ],
      unit: 'M³',
    },
  };

  useEffect(() => {
    if (valuesFromShadow) {
      const entries = Object.entries(valuesFromShadow);

      entries.forEach(([key, value]) => {
        if (!stringTypeKeys.includes(key)) {
          setValue(key as any, value.toString());
        }
      });

      const [gdstHour, gdstMinute, gdstSecond] = valuesFromShadow.GDST.split(' ').map(Number);
      const gdstMeridian = gdstHour < 12 || gdstHour === 24 ? 'AM' : 'PM';
      const gdstHourAdjusted = gdstHour % 12 || 12;

      setGdstComponents([gdstHourAdjusted, gdstMinute, gdstSecond]);
      setGdstMeridian(gdstMeridian);
    }
  }, [valuesFromShadow]);

  const alarmParameters = useMemo(
    () =>
      valuesFromShadow
        ? [
            new TEMPHL({ deviceShadow: data!, register }),
            new TEMPLL({ deviceShadow: data!, register }),
            new PRHL({ deviceShadow: data!, register }),
            new PRLL({ deviceShadow: data!, register }),
            new UFHL({ deviceShadow: data!, register }),
            new UFLL({ deviceShadow: data!, register }),
          ]
        : [],
    [data, register, valuesFromShadow]
  );

  const firstParameters = valuesFromShadow
    ? [new ATPR({ deviceShadow: data!, register }), new BPR({ deviceShadow: data!, register })]
    : [];

  /**
   * Checks to see which tags have been changed by the user.
   *
   * Splits the changed tags into a tuple of two lists:
   * 1. Tags that have been removed from the device
   * 2. Tags that have been added to the device
   *
   * If a tag changes from one tag to another, we need to first
   * remove the old tag and then add the new tag.
   *
   * @param selectedPredefinedTags - Selected tags state once the save button is pressed.
   * @param selectedUtilityTags - Selected tags state once the save button is pressed.
   */
  const getChangedTagsForDevice = (
    selectedPredefinedTags: Tag[],
    selectedUtilityTags: Tag[]
  ): [removeTags: TagsByType, addTags: TagsByType] => {
    const selectedPredefinedTagMap = tagListToTagMap<Tag>(selectedPredefinedTags);
    const selectedUtilityTagMap = tagListToTagMap<Tag>(selectedUtilityTags);

    /**
     * Finds and returns the tags that are in the existing list but not in the comparison map.
     *
     * @param existingTags - The current set of tags.
     * @param comparisonTagsMap - The set of tags to compare against.
     */
    const findChangedTags = (existingTags: Tag[], comparisonTagsMap: TagMap<Tag>) => {
      const changedTags: Tag[] = [];

      existingTags.forEach((tag) => {
        if (!comparisonTagsMap.has(tag.id)) {
          changedTags.push(tag);
        }
      });

      return changedTags;
    };

    /**
     * List of tags that are included in the current tags but have been removed
     * in the selected tags.
     */
    const removeTags: TagsByType = {
      predefined: findChangedTags(currentPredefinedTags, selectedPredefinedTagMap),
      utility: findChangedTags(currentUtilityTags, selectedUtilityTagMap),
    };

    /**
     * List of tags that are included in the selected tags but not originally included
     * in the current tags.
     */
    const addTags: TagsByType = {
      predefined: findChangedTags(selectedPredefinedTags, currentPredefinedTagMap),
      utility: findChangedTags(selectedUtilityTags, currentUtilityTagMap),
    };

    return [removeTags, addTags];
  };

  const submitForm = useCallback(
    async (close: boolean) => {
      const formatTime = (time: string | undefined) => {
        if (!time) return time;

        const [hour, minute, second, meridien] = time.split(/:|\s/);
        let newHour: number = parseInt(hour);
        if (newHour === 12) {
          newHour = 0;
        }

        newHour = meridien === 'PM' ? newHour + 12 : newHour;

        let newHourWithZero = newHour < 10 ? '0' + newHour : newHour;
        return `${newHourWithZero} ${minute} ${second}`;
      };

      const fixTypes = (values: DeviceShadowData) => {
        const fixedTypes = Object.entries(values).reduce((acc, [key, value]) => {
          if (stringTypeKeys.includes(key)) {
            return Object.assign(acc, {
              [key]: value,
            });
          }

          return Object.assign(acc, {
            [key]: Number(value),
          });
        }, {} as DeviceShadowData);

        return fixedTypes;
      };

      const fixCustomerID = (deviceShadowData: DeviceShadowData) => {
        const data = deviceShadowData;

        if (deviceShadowData.MID) {
          const customerID = deviceShadowData.MID;
          const padded = customerID.padStart(16, ' ');
          const onlySixteenLength = padded.slice(0, 16);

          data.MID = onlySixteenLength;
        }

        return data;
      };

      const fixDecimalPoints = (data: DeviceShadowData) => {
        const allParameters = [...alarmParameters, ...firstParameters];

        const entries = Object.entries(data);

        const fixed = entries.map(([key, value]) => {
          const parameter = allParameters.find((parameter) => parameter.deviceShadowAlias === key);

          if (!parameter) return [key, value];

          const fixedValue = parameter.correctValue(Number(value));

          return [key, fixedValue];
        });

        const fixedObject = Object.fromEntries(fixed);

        return fixedObject;
      };

      const formatValues = (values: DeviceShadowData) => {
        const formattedTimes = Object.assign(values, {
          wk1: formatTime(values.wk1),
          wk2: formatTime(values.wk2),
          wk3: formatTime(values.wk3),
          wk4: formatTime(values.wk4),
          tT: formatTime(values.tT),
        });

        const onlyModifiedValues = Object.fromEntries(
          Object.entries(formattedTimes).filter(([key, value]) => {
            return (
              valuesFromShadow[key as keyof DeviceShadowData] != value &&
              deviceShadowDataKeys.includes(key as any)
            );
          })
        ) as DeviceShadowData;

        const typesFixed = fixTypes(onlyModifiedValues);

        const fixMID = fixCustomerID(typesFixed);

        const decimalPointsFixed = fixDecimalPoints(fixMID);

        return decimalPointsFixed;
      };

      const error = await trigger();

      if (!error) return;

      const values = getValues();

      // Update tags

      if (includeTagging) {
        const getSelectedTagForCategory = (tagCategory: TagCategory): Tag | undefined => {
          const tagId: string | undefined = values[tagCategory.id];
          if (tagId === undefined) return undefined;

          const tags = tagCategory.tags ?? [];
          const foundTag = tags.find((tag) => tag.id === tagId);

          if (foundTag) {
            foundTag.category_id = tagCategory.id;
          }

          return foundTag;
        };

        const selectedPredefinedTags: Tag[] = predefinedTagCategories
          .map(getSelectedTagForCategory)
          .reduce<Tag[]>((acc, curr) => (curr === undefined ? acc : [...acc, curr]), []);
        const selectedUtilityTags: Tag[] = utilityTagCategories
          .map(getSelectedTagForCategory)
          .reduce<Tag[]>((acc, curr) => (curr === undefined ? acc : [...acc, curr]), []);

        const [removeTags, addTags] = getChangedTagsForDevice(
          selectedPredefinedTags,
          selectedUtilityTags
        );

        const removeTagsPromises = [];
        if (removeTags.predefined.length > 0) {
          const removePredefinedTagsPromises = removeTags.predefined.map((tag) =>
            removeTagFromDevicesMutation.mutateAsync({
              deviceIds: [device.id],
              tagId: tag.id,
              tagCategoryId: tag.category_id!,
              tagType: TagType.Predefined,
            })
          );
          removeTagsPromises.push(...removePredefinedTagsPromises);
        }
        if (removeTags.utility.length > 0) {
          const removeUtilityTagsPromises = removeTags.utility.map((tag) =>
            removeTagFromDevicesMutation.mutateAsync({
              deviceIds: [device.id],
              tagId: tag.id,
              tagCategoryId: tag.category_id!,
              tagType: TagType.Utility,
            })
          );
          removeTagsPromises.push(...removeUtilityTagsPromises);
        }

        await Promise.all(removeTagsPromises);

        const addTagsPromises = [];
        if (addTags.predefined.length > 0) {
          const addPredefinedTagsPromises = addTags.predefined.map((tag) =>
            addTagToDevicesMutation.mutateAsync({
              deviceIds: [device.id],
              tagId: tag.id,
              tagCategoryId: tag.category_id!,
              tagType: TagType.Predefined,
            })
          );
          addTagsPromises.push(...addPredefinedTagsPromises);
        }
        if (addTags.utility.length > 0) {
          const addUtilityTagsPromises = addTags.utility.map((tag) =>
            addTagToDevicesMutation.mutateAsync({
              deviceIds: [device.id],
              tagId: tag.id,
              tagCategoryId: tag.category_id!,
              tagType: TagType.Utility,
            })
          );
          addTagsPromises.push(...addUtilityTagsPromises);
        }

        await Promise.all(addTagsPromises);

        onTagsChanged(true);
      }

      // Update shadow

      const formattedValues = formatValues(values);

      const ancientShadow = data!;

      const newShadow = { ...ancientShadow };

      newShadow.state.desired = formattedValues as any;

      const stringifiedShadow = JSON.stringify(newShadow);

      try {
        await updateDeviceShadowMutation.mutateAsync({
          deviceId: device.id,
          region: device.group?.utility.company?.region!,
          payload: stringifiedShadow,
        });
      } catch (e) {}

      if (close) closeModalFunc(false);
    },
    [
      closeModalFunc,
      data,
      device,
      getValues,
      updateDeviceShadowMutation,
      valuesFromShadow,
      setError,
    ]
  );

  const onSaveAndContinueBtnClick = useCallback(async () => {
    const hasNoErrors = await trigger();

    if (!hasNoErrors) return;

    contextValue.current?.increaseStep();
  }, [trigger]);

  const onSaveAndCloseBtnClick = useCallback(() => {
    submitForm(true);
  }, [submitForm]);

  const getVolumeMetricData = () => {
    const volume = valuesFromShadow.cVUt;

    if (!volume) return;

    const volumeMetricIds = volumeData.metric.data.map((metric) => metric.value);
    const imperialMetricIds = volumeData.imperial.data.map((imperial) => imperial.value);

    if (volumeMetricIds.includes(volume)) {
      return volumeData.metric;
    }

    if (imperialMetricIds.includes(volume)) {
      return volumeData.imperial;
    }
  };

  const otherParameters = valuesFromShadow
    ? [
        new GCO2({ deviceShadow: data!, register }),
        new GN2({ deviceShadow: data!, register }),
        new GSG({ deviceShadow: data!, register }),
      ]
    : [];

  if (deviceShadow.isIdle) return null;

  const brightLyncData = valuesFromShadow ? getBrightLyncData() : [];

  const onOkBtnClick: OnOkBtnClickTimePicker = ({ date, name }) => {
    const dateHour = date.getHours();

    let meridiem = dateHour >= 12 ? 'PM' : 'AM';

    if (dateHour > 12) {
      date.setHours(dateHour - 12);
    }

    if (dateHour === 0) {
      date.setHours(12);
    }

    const formattedDate = moment(date).format('HH:mm:ss') + ' ' + meridiem;

    setValue(name as keyof DeviceShadowData, formattedDate);
    closeTimePicker(name);
  };

  const closeTimePicker = (name: string) => {
    setTimePickersOn(timePickersOn.filter((timePickerName) => timePickerName !== name));
  };

  const onTimeInputTxtInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    const inputName = e.currentTarget.name;

    const timePickersOnCopy = [...timePickersOn];

    if (timePickersOnCopy.includes(inputName)) {
      closeTimePicker(inputName);
    } else {
      setTimePickersOn([...timePickersOnCopy, inputName]);
    }
  };

  function getBrightLyncData() {
    const formatHour = (str?: string) => {
      if (!str) return;

      const splitted = str.split(' ');
      const [hour, minute, second] = splitted;

      let newHour = Number(hour) > 12 ? Number(hour) - 12 : Number(hour);

      if (newHour === 0) newHour = 12;

      let newHourWithZero = newHour < 10 ? '0' + newHour : newHour;

      let meridien = Number(hour) >= 12 ? 'PM' : 'AM';
      return `${newHourWithZero}:${minute}:${second} ${meridien}`;
    };
    const baseData: BrightLyncData[] = [
      {
        alias: 'tT',
        label: 'Log Report Time',
      },
      {
        alias: 'wk1',
        label: 'Wake Up Time 1',
      },
      {
        alias: 'wk2',
        label: 'Wake Up Time 2',
      },
      {
        alias: 'wk3',
        label: 'Wake Up Time 3',
      },
      {
        alias: 'wk4',
        label: 'Wake Up Time 4',
      },
    ];

    const data = baseData.map(({ alias, label }, index) => {
      const value = formatHour(valuesFromShadow![alias]);

      return {
        alias,
        label,
        value,
      };
    });

    return data;
  }

  const onVolumeIndexFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setConfirmationShown(false);
    if (!confirmationShown) {
      const confirm = window.confirm(
        'Are you sure you want to change the index?\nThis may affect your billing.'
      );

      if (confirm) {
        setTimeout(() => {
          event.target.select();
        }, 0);
        setConfirmationShown(true);
      }
    }
  };

  const getMenuListItems = (value: ModalContextI | null): MenuListItem[] => {
    const items: MenuListItem[] = [
      {
        text: 'AdEM',
        onClick: (e) => {
          e.preventDefault();
          value?.setCurrentStep(1);
        },
        active: value?.currentStep === 1,
      },
      {
        text: 'BrightLync',
        onClick: (e) => {
          e.preventDefault();
          value?.setCurrentStep(2);
        },
        active: value?.currentStep === 2,
      },
    ];

    if (includeTagging) {
      items.push({
        text: 'Tags',
        onClick: (e) => {
          e.preventDefault();
          value?.setCurrentStep(3);
        },
        active: value?.currentStep === 3,
      });
    }

    return items;
  };

  const isLoading =
    updateDeviceShadowMutation.isLoading ||
    addTagToDevicesMutation.isLoading ||
    removeTagFromDevicesMutation.isLoading;

  const stepButtonProps: ModelButton = [
    [
      {
        block: true,
        onClick: onSaveAndCloseBtnClick,
        loading: isLoading,
        disabled: isLoading,
        variant: 'light-primary',
        text: 'Save & Close',
      },
      {
        block: true,
        onClick: onSaveAndContinueBtnClick,
        text: 'Save & Continue',
        loading: isLoading,
        disabled: isLoading,
      },
    ],
  ];

  const lastStepButtonProps: ModelButton = {
    text: 'Save',
    onClick: () => submitForm(true),
    loading: isLoading,
    disabled: isLoading,
  };

  return (
    <Modal
      title="Asset Configuration"
      open={open}
      closeModalFunc={closeModalFunc}
      buttonLoading={updateDeviceShadowMutation.isLoading}
      totalStep={includeTagging ? 3 : 2}
      currentStep={1}
      plusContent={
        <div
          style={{
            width: 175,
            marginRight: 32,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <div>
            <ModalContext.Consumer>
              {(value) => <MenuList items={getMenuListItems(value)} />}
            </ModalContext.Consumer>
          </div>
          <div>
            <AssetInfoContainer>
              <div>
                <h2>Asset Info</h2>
              </div>
              <hr />
              <div>
                <label>Device Type</label>
                <span>AdEM-{ademModel}</span>
              </div>
              <div>
                <label>Meter Size</label>
                <span>{valuesFromShadow?.MtrSz}</span>
              </div>
              <div>
                <label>Pressure Factor Type</label>
                <span>{valuesFromShadow?.prFT ? 'Fixed' : 'Live'}</span>
              </div>
              <div>
                <label>Temperature Factor Type</label>
                <span>{valuesFromShadow?.tempFT ? 'Fixed' : 'Live'}</span>
              </div>
              <div>
                <label>Super-X Factor Type</label>
                <span>{valuesFromShadow?.sxFT ? 'Fixed' : 'Live'}</span>
              </div>
            </AssetInfoContainer>
            <div>
              <Alert variant="danger">
                <AiOutlineInfoCircle size={14} /> {errorMsg}
              </Alert>
            </div>
          </div>
        </div>
      }
    >
      {deviceShadow.isLoading && <Spinner variant="primary" />}
      {!valuesFromShadow && !deviceShadow.isLoading && (
        <Alert variant="danger">No data found</Alert>
      )}
      {valuesFromShadow && !deviceShadow.isLoading && (
        <>
          <ModalContext.Consumer>
            {(value) => {
              contextValue.current = value;
              return (
                <ModalStep
                  step={1}
                  button={stepButtonProps}
                  title="Asset Configuration"
                >
                  {/* <FormGroup>
                    <FormControl
                      id="assetGroup"
                      label="Asset Group"
                      type={'text'}
                    />
                  </FormGroup> */}

                  <WithPadding title="Index Read Parameters">
                    <FormGroup>
                      <Select
                        register={register}
                        name="cVD"
                        defaultValue={valuesFromShadow.cVD}
                        label="Corrected Index Read Length"
                      >
                        {[8, 7, 6, 5].map((num, index) => (
                          <option
                            key={`${num}-${index}`}
                            value={index}
                          >
                            {num} Digits
                          </option>
                        ))}
                      </Select>
                    </FormGroup>
                    <FormGroup>
                      <Select
                        register={register}
                        defaultValue={valuesFromShadow.cVUt}
                        name="cVUt"
                        label="Corrected Index Read Units"
                      >
                        {getVolumeMetricData()?.data!.map(({ value, label }, index) => (
                          <option
                            key={`${label}+${index}`}
                            value={value}
                          >
                            {label} {getVolumeMetricData()?.unit}
                          </option>
                        ))}
                      </Select>
                    </FormGroup>
                    <FormGroup>
                      <FormControl
                        register={register}
                        value={valuesFromShadow.cV}
                        name="cV"
                        label="Corrected Index Read Value"
                        min={0}
                        max={99999999}
                        type={'text'}
                        onFocus={onVolumeIndexFocus}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Select
                        register={register}
                        defaultValue={valuesFromShadow.uVD}
                        name="uVD"
                        label="Uncorrected Index Read Length"
                      >
                        {[8, 7, 6, 5].map((num, index) => (
                          <option
                            key={`${num}_${index}`}
                            value={index}
                          >
                            {num} Digits
                          </option>
                        ))}
                      </Select>
                    </FormGroup>
                    <FormGroup>
                      <Select
                        register={register}
                        name="uVUt"
                        defaultValue={valuesFromShadow.uVUt}
                        label="Uncorrected Index Read Units"
                      >
                        {getVolumeMetricData()?.data!.map(({ value, label }, index) => (
                          <option
                            key={`${label}=${index}`}
                            value={value}
                          >
                            {label} {getVolumeMetricData()?.unit}
                          </option>
                        ))}
                      </Select>
                    </FormGroup>
                    <FormGroup>
                      <FormControl
                        register={register}
                        name="uV"
                        label="Uncorrected Index Read Value"
                        min={0}
                        max={99999999}
                        value={valuesFromShadow.uV || 0}
                        type={'text'}
                        onFocus={onVolumeIndexFocus}
                      />
                    </FormGroup>

                    {firstParameters.map((parameter, index) => (
                      <FormGroup key={`${parameter.deviceShadowAlias}-${index}`}>
                        <Range {...parameter.getRangeProps()} />
                      </FormGroup>
                    ))}
                  </WithPadding>

                  <WithPadding title="Alarm Parameters">
                    {alarmParameters.map((parameter, index) => {
                      return (
                        <FormGroup key={`${parameter.deviceShadowAlias}+${index}`}>
                          <Range
                            {...parameter.getRangeProps()}
                            error={errors[parameter.deviceShadowAlias]?.message}
                          />
                        </FormGroup>
                      );
                    })}
                  </WithPadding>

                  <WithPadding title="Other Parameters">
                    <FormGroup>
                      <FormControl
                        label="Customer ID"
                        register={register}
                        value={valuesFromShadow.MID?.trimStart()}
                        name="MID"
                        type={'text'}
                        supportText="16 character alphanumeric"
                        error={errors.MID?.message}
                      />
                    </FormGroup>
                    {otherParameters.map((parameter, index) => (
                      <FormGroup key={`${parameter.deviceShadowAlias}_${index}`}>
                        <Range {...parameter.getRangeProps()} />
                      </FormGroup>
                    ))}
                  </WithPadding>
                </ModalStep>
              );
            }}
          </ModalContext.Consumer>
          <ModalStep
            step={2}
            title="Asset Configuration"
            button={includeTagging ? stepButtonProps : lastStepButtonProps}
          >
            <GasDayContainer>
              <Typography
                fontWeight={700}
                fontSize={14}
                lineHeight={'24px'}
              >
                Gas Day Start Time
              </Typography>
              <Typography
                fontWeight={400}
                fontSize={14}
                lineHeight={'32px'}
              >
                {`${gdstComponents[0].toString().padStart(2, '0')}:${gdstComponents[1].toString().padStart(2, '0')}:${gdstComponents[2].toString().padStart(2, '0')} ${gdstMeridian}`}{' '}
                Asset Local Time
              </Typography>
            </GasDayContainer>
            {brightLyncData.map(({ label, alias, value }, index) => {
              return (
                <Fragment key={`${label}~${index}`}>
                  <FormGroup>
                    <FormControl
                      inputStyle={{
                        cursor: 'pointer',
                      }}
                      register={register}
                      onClick={onTimeInputTxtInputClick}
                      label={label}
                      name={alias}
                      value={value}
                      rowReverse={true}
                      startIcon={<MdAccessTime />}
                      type="text"
                    />
                  </FormGroup>
                  {timePickersOn.includes(alias) && (
                    <TimePickerCard
                      originalValue={value ?? '09:00:00 AM'}
                      forceMeridian={index === 0}
                      forceMeridianValue={index === 0 ? gdstMeridian : undefined}
                      showMinuteInput={index === 0}
                      minMinute={index === 0 ? 7 : 0}
                      maxMinute={index === 0 ? 15 : 59}
                      minHour={index === 0 ? gdstComponents[0] : 1}
                      maxHour={index === 0 ? gdstComponents[0] : 12}
                      onCancelBtnClick={() => closeTimePicker(alias)}
                      name={alias}
                      onOkBtnClick={onOkBtnClick}
                    />
                  )}
                </Fragment>
              );
            })}
          </ModalStep>
          <ModalStep
            step={3}
            title="Asset Configuration"
            button={lastStepButtonProps}
          >
            <WithPadding title="Predefined Tags">
              {predefinedTagCategories.map(({ id, name, tags }) => {
                return (
                  <FormGroup>
                    <Select
                      register={register}
                      name={id}
                      label={name}
                    >
                      <option
                        key="none"
                        value="none"
                      >
                        None
                      </option>

                      {(tags ?? []).map((tag) => {
                        return (
                          <option
                            key={tag.id}
                            value={tag.id}
                            selected={currentPredefinedTagMap.has(tag.id)}
                          >
                            {tag?.name ?? ''}
                          </option>
                        );
                      })}
                    </Select>
                  </FormGroup>
                );
              })}
            </WithPadding>
            <WithPadding title="Utility Tags">
              {utilityTagCategories.map(({ id, name, tags }) => {
                return (
                  <FormGroup>
                    <Select
                      register={register}
                      name={id}
                      label={name}
                    >
                      <option
                        key="none"
                        value="none"
                      >
                        None
                      </option>

                      {(tags ?? []).map((tag) => {
                        return (
                          <option
                            key={tag.id}
                            value={tag.id}
                            selected={currentUtilityTagMap.has(tag.id)}
                          >
                            {tag?.name ?? ''}
                          </option>
                        );
                      })}
                    </Select>
                  </FormGroup>
                );
              })}
            </WithPadding>
          </ModalStep>
        </>
      )}
    </Modal>
  );
};

export default AssetConfigurationModal;
