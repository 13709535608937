import { Device, Message } from '../API';

export default class AlarmUtils {
  static colors = {
    red: '#F61067',
    yellow: '#F0E100',
    green: '#5FDD9D',
  };

  static getMessageData(message: Message) {
    const { red, yellow, green } = AlarmUtils.colors;

    let color: string = message.status === 'New' ? red : yellow;
    let flashing: boolean = message.urgent;

    if (message.status === 'Resolved') {
      color = green;
      flashing = false;
    }

    return {
      color,
      flashing,
    };
  }

  static getDeviceData(device: Device) {
    const messages = device.messages!;

    if (!messages.length)
      return {
        color: AlarmUtils.colors.green,
        flashing: false,
      };

    const messageData = messages.map((message) => AlarmUtils.getMessageData(message!));
    const messageColors = messageData.map((data) => data.color);

    const colorHierarchy = Object.values(AlarmUtils.colors);

    const deviceColor = colorHierarchy.find((color) => messageColors.includes(color))!;

    const deviceColorData = messageData.find((data) => data.color === deviceColor)!;

    return deviceColorData;
  }
}
