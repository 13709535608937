import styled from 'styled-components';
import Button from '../../components/Button/Button';

export const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ShowingMsg = styled.span`
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
`;

export const FixedButtons = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  display: flex;

  & button {
    border-radius: ${(props) => props.theme.borderRadius};
    padding: 16px 24px;
    font-size: 16px;
    line-height: 32px;
    margin-left: 15px;
  }
`;

export const OptionsWrapper = styled.div`
  display: inline-flex;
  gap: 10px;
`;

export const WantToRemoveMsg = styled.span`
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 64px;
  display: block;
`;

export const UserGroupsText = styled.h3`
  font-size: 16px;
  line-height: 32px;
  margin-bottom: 12px;
  font-weight: lighter;
  color: ${(props) => props.theme.colors.black};
`;

export const DragDropContainer = styled.div`
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px dashed ${(props) => props.theme.colors.gray};
  padding: 56px 16px;
  text-align: center;
  cursor: pointer;
`;

export const DragDropMsg = styled.span`
  line-height: 32px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.gray};
`;

export const Space = styled.div`
  height: 120px;
`;

export const Bedge = styled(Button)`
  padding: 6px 8px !important;
  font-size: 12px;
  line-height: 20px;
  min-height: 32px;
  height: 32px;
  border-radius: ${(props) => props.theme.borderRadius};
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  white-space: break-spaces;
`;

export const BedgeSuffix = styled.div``;
