import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & {
  [P in K]-?: NonNullable<T[P]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** Long type */
  Long: { input: any; output: any };
};

export type AddTagToDevicesResponse = IResponse & {
  __typename?: 'AddTagToDevicesResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type AddUtilityTagCategoriesResponse = IResponse & {
  __typename?: 'AddUtilityTagCategoriesResponse';
  message?: Maybe<Scalars['String']['output']>;
  newUtilityTagCategories?: Maybe<Array<TagCategory>>;
  success: Scalars['Boolean']['output'];
};

export type AddUtilityTagsResponse = IResponse & {
  __typename?: 'AddUtilityTagsResponse';
  message?: Maybe<Scalars['String']['output']>;
  newUtilityTags?: Maybe<Array<Tag>>;
  success: Scalars['Boolean']['output'];
};

export type Alarm = {
  __typename?: 'Alarm';
  lmt?: Maybe<Scalars['Float']['output']>;
  t: Scalars['Long']['output'];
  threshold?: Maybe<Scalars['Float']['output']>;
  type: Scalars['Int']['output'];
  v: Scalars['Float']['output'];
};

export type BatteryLifeResponse = {
  __typename?: 'BatteryLifeResponse';
  communicationCounter?: Maybe<Scalars['Int']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
};

export type CaCertificate = {
  __typename?: 'CaCertificate';
  date: Scalars['String']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CognitoGroup = {
  __typename?: 'CognitoGroup';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type CognitoUser = {
  __typename?: 'CognitoUser';
  deviceId?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  groups?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sub: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type Company = {
  __typename?: 'Company';
  alias?: Maybe<Scalars['String']['output']>;
  correctedFlowUnit: Scalars['String']['output'];
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pressureUnit: Scalars['String']['output'];
  region: Scalars['String']['output'];
  temperatureUnit: Scalars['String']['output'];
  theme?: Maybe<Scalars['String']['output']>;
  voltageUnit: Scalars['String']['output'];
  volumeUnit: Scalars['String']['output'];
};

export type CompanyResponse = {
  __typename?: 'CompanyResponse';
  companies: Array<Maybe<Company>>;
};

export type DeleteUtilityTagCategoryResponse = IResponse & {
  __typename?: 'DeleteUtilityTagCategoryResponse';
  deletedUtilityTagCategoryId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DeleteUtilityTagResponse = IResponse & {
  __typename?: 'DeleteUtilityTagResponse';
  deletedUtilityTagId: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Device = {
  __typename?: 'Device';
  address?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  data?: Maybe<DeviceData>;
  device_group_id: Scalars['String']['output'];
  group?: Maybe<DeviceGroup>;
  id: Scalars['String']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  longitude?: Maybe<Scalars['Float']['output']>;
  messages?: Maybe<Array<Maybe<Message>>>;
  meterId?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  serialNo?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<TagTypes>;
  thresholds?: Maybe<Array<Maybe<Threshold>>>;
  user_device?: Maybe<UserDevice>;
};

export type DeviceData = {
  __typename?: 'DeviceData';
  TmZn: Scalars['Float']['output'];
  ademFirmware?: Maybe<Scalars['String']['output']>;
  bV: Scalars['Float']['output'];
  brightlyncFirmware?: Maybe<Scalars['String']['output']>;
  cFAvg: Scalars['Float']['output'];
  ccbbV?: Maybe<Scalars['Float']['output']>;
  dTm: Scalars['Long']['output'];
  dayCV: Scalars['Float']['output'];
  dayUV: Scalars['Float']['output'];
  fCV: Scalars['Float']['output'];
  fUV: Scalars['Float']['output'];
  ingestionTime: Scalars['Long']['output'];
  maxFC?: Maybe<DeviceDataValue>;
  maxPr?: Maybe<DeviceDataValue>;
  maxTemp?: Maybe<DeviceDataValue>;
  meterSize?: Maybe<Scalars['String']['output']>;
  minFC?: Maybe<DeviceDataValue>;
  minPr?: Maybe<DeviceDataValue>;
  minTemp?: Maybe<DeviceDataValue>;
  pofMF: Scalars['Float']['output'];
  proto: Scalars['Float']['output'];
  qMarg: Scalars['Float']['output'];
};

export type DeviceDataItem = {
  __typename?: 'DeviceDataItem';
  data: Array<Maybe<MeterDataItem>>;
  id: Scalars['String']['output'];
};

export type DeviceDataValue = {
  __typename?: 'DeviceDataValue';
  t: Scalars['Float']['output'];
  v: Scalars['Float']['output'];
};

export type DeviceGroup = {
  __typename?: 'DeviceGroup';
  correctedFlowUnit?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pressureUnit?: Maybe<Scalars['String']['output']>;
  temperatureUnit?: Maybe<Scalars['String']['output']>;
  utility: Utility;
  utility_id: Scalars['String']['output'];
  voltageUnit?: Maybe<Scalars['String']['output']>;
  volumeUnit?: Maybe<Scalars['String']['output']>;
};

export type DeviceList = {
  __typename?: 'DeviceList';
  count: Scalars['Int']['output'];
  rows: Array<Maybe<Device>>;
};

export type DeviceOwner = {
  __typename?: 'DeviceOwner';
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type DeviceWithPredefinedTags = {
  __typename?: 'DeviceWithPredefinedTags';
  deviceId: Scalars['String']['output'];
  predefinedTags?: Maybe<Array<Tag>>;
};

export type DeviceWithUtilityTags = {
  __typename?: 'DeviceWithUtilityTags';
  deviceId: Scalars['String']['output'];
  utilityTags?: Maybe<Array<Tag>>;
};

export type ExportEventsResponse = {
  __typename?: 'ExportEventsResponse';
  link?: Maybe<Scalars['String']['output']>;
};

export type ExportResponse = {
  __typename?: 'ExportResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type Firmware = {
  name: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type FirmwareFile = {
  __typename?: 'FirmwareFile';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type GetAllPredefinedTagCategoriesResponse = IGetAllPredefinedTagCategoriesResponse &
  IResponse & {
    __typename?: 'GetAllPredefinedTagCategoriesResponse';
    message?: Maybe<Scalars['String']['output']>;
    predefinedTagCategories?: Maybe<Array<TagCategory>>;
    success: Scalars['Boolean']['output'];
  };

export type GetAllTagCategoriesResponse = IGetAllPredefinedTagCategoriesResponse &
  IGetAllUtilityTagCategoriesResponse &
  IResponse & {
    __typename?: 'GetAllTagCategoriesResponse';
    message?: Maybe<Scalars['String']['output']>;
    predefinedTagCategories?: Maybe<Array<TagCategory>>;
    success: Scalars['Boolean']['output'];
    utilityTagCategoriesPerUtility?: Maybe<Array<TagCategoryByUtility>>;
  };

export type GetAllUtilityTagCategoriesResponse = IGetAllUtilityTagCategoriesResponse &
  IResponse & {
    __typename?: 'GetAllUtilityTagCategoriesResponse';
    message?: Maybe<Scalars['String']['output']>;
    success: Scalars['Boolean']['output'];
    utilityTagCategoriesPerUtility?: Maybe<Array<TagCategoryByUtility>>;
  };

export type GetPredefinedTagsForDevicesResponse = IGetPredefinedTagsForDevicesResponse &
  IResponse & {
    __typename?: 'GetPredefinedTagsForDevicesResponse';
    devicesWithPredefinedTags: Array<Maybe<DeviceWithPredefinedTags>>;
    message?: Maybe<Scalars['String']['output']>;
    success: Scalars['Boolean']['output'];
  };

export type GetTagsForDevicesResponse = IGetPredefinedTagsForDevicesResponse &
  IGetUtilityTagsForDevicesResponse &
  IResponse & {
    __typename?: 'GetTagsForDevicesResponse';
    devicesWithPredefinedTags: Array<Maybe<DeviceWithPredefinedTags>>;
    devicesWithUtilityTags: Array<Maybe<DeviceWithUtilityTags>>;
    message?: Maybe<Scalars['String']['output']>;
    success: Scalars['Boolean']['output'];
  };

export type GetUtilityTagsForDevicesResponse = IGetUtilityTagsForDevicesResponse &
  IResponse & {
    __typename?: 'GetUtilityTagsForDevicesResponse';
    devicesWithUtilityTags: Array<Maybe<DeviceWithUtilityTags>>;
    message?: Maybe<Scalars['String']['output']>;
    success: Scalars['Boolean']['output'];
  };

export type Group = {
  __typename?: 'Group';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type GroupsReponse = {
  __typename?: 'GroupsReponse';
  groups: Array<Maybe<Group>>;
};

export type IGetAllPredefinedTagCategoriesResponse = {
  predefinedTagCategories?: Maybe<Array<TagCategory>>;
};

export type IGetAllUtilityTagCategoriesResponse = {
  utilityTagCategoriesPerUtility?: Maybe<Array<TagCategoryByUtility>>;
};

export type IGetPredefinedTagsForDevicesResponse = {
  devicesWithPredefinedTags: Array<Maybe<DeviceWithPredefinedTags>>;
};

export type IGetUtilityTagsForDevicesResponse = {
  devicesWithUtilityTags: Array<Maybe<DeviceWithUtilityTags>>;
};

export type IResponse = {
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Logo = {
  __typename?: 'Logo';
  file: S3Object;
  name: Scalars['String']['output'];
};

export type Message = {
  __typename?: 'Message';
  alarms?: Maybe<Array<Maybe<Alarm>>>;
  comment: Scalars['String']['output'];
  dId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  status: Scalars['String']['output'];
  time: Scalars['Long']['output'];
  urgent: Scalars['Boolean']['output'];
};

export type MeterDataItem = {
  __typename?: 'MeterDataItem';
  bV?: Maybe<Scalars['Float']['output']>;
  cF?: Maybe<Scalars['Float']['output']>;
  cV?: Maybe<Scalars['Float']['output']>;
  ccbbV?: Maybe<Scalars['Float']['output']>;
  dayCV?: Maybe<Scalars['Float']['output']>;
  dayUV?: Maybe<Scalars['Float']['output']>;
  deviceId?: Maybe<Scalars['String']['output']>;
  fCV?: Maybe<Scalars['Float']['output']>;
  fUV?: Maybe<Scalars['Float']['output']>;
  maxFC?: Maybe<Scalars['Float']['output']>;
  maxPr?: Maybe<Scalars['Float']['output']>;
  maxTemp?: Maybe<Scalars['Float']['output']>;
  minFC?: Maybe<Scalars['Float']['output']>;
  minPr?: Maybe<Scalars['Float']['output']>;
  minTemp?: Maybe<Scalars['Float']['output']>;
  pofMF?: Maybe<Scalars['Float']['output']>;
  pr?: Maybe<Scalars['Float']['output']>;
  qMarg?: Maybe<Scalars['Float']['output']>;
  temp?: Maybe<Scalars['Float']['output']>;
  time?: Maybe<Scalars['String']['output']>;
  uV?: Maybe<Scalars['Float']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addDevice: Device;
  addTagToDevices: AddTagToDevicesResponse;
  addToGroup?: Maybe<Scalars['Boolean']['output']>;
  addUtilityTagCategories: AddUtilityTagCategoriesResponse;
  addUtilityTags: AddUtilityTagsResponse;
  changeUserDevice: SuccessResponse;
  confirmForgotPassword: SuccessResponsePublic;
  createUser?: Maybe<Scalars['String']['output']>;
  deleteUtilityTag: DeleteUtilityTagResponse;
  deleteUtilityTagCategory: DeleteUtilityTagCategoryResponse;
  deviceJobManager: SuccessResponse;
  disableMfa: SuccessResponse;
  enableMfa: SuccessResponse;
  forgotPassword: SuccessResponsePublic;
  importUsers: SuccessResponse;
  removeDevice: RemoveDeviceReponse;
  removeFromGroup?: Maybe<Scalars['Boolean']['output']>;
  removeTagFromDevices: RemoveTagFromDevicesResponse;
  removeUser: SuccessResponse;
  resetDeviceBatteryCounter: SuccessResponse;
  setThresholds: SuccessResponse;
  updateAlarmComment: SuccessResponse;
  updateAlertComment: SuccessResponse;
  updateAlertStatus: SuccessResponse;
  updateCompany: SuccessResponse;
  updateDeviceGroup: SuccessResponse;
  updateDeviceShadow: SuccessResponse;
  updateUtilityTag: UpdateUtilityTagResponse;
  updateUtilityTagCategory: UpdateUtilityTagCategoryResponse;
  verifyUserEmail: SuccessResponse;
};

export type MutationAddDeviceArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  deviceId: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  meterId: Scalars['String']['input'];
  postalCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export type MutationAddTagToDevicesArgs = {
  deviceIds: Array<InputMaybe<Scalars['String']['input']>>;
  tagCategoryId: Scalars['String']['input'];
  tagId: Scalars['String']['input'];
  tagType: TagType;
};

export type MutationAddToGroupArgs = {
  group: Scalars['String']['input'];
  id: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationAddUtilityTagCategoriesArgs = {
  newUtilityTagCategories: Array<InputMaybe<NewTagCategory>>;
};

export type MutationAddUtilityTagsArgs = {
  newUtilityTags: Array<InputMaybe<NewTag>>;
  utilityTagCategoryId: Scalars['String']['input'];
};

export type MutationChangeUserDeviceArgs = {
  deviceId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type MutationConfirmForgotPasswordArgs = {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationCreateUserArgs = {
  deviceId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  groups: Array<InputMaybe<Scalars['String']['input']>>;
};

export type MutationDeleteUtilityTagArgs = {
  utilityTagCategoryId: Scalars['String']['input'];
  utilityTagId: Scalars['String']['input'];
};

export type MutationDeleteUtilityTagCategoryArgs = {
  utilityTagCategoryId: Scalars['String']['input'];
};

export type MutationDeviceJobManagerArgs = {
  ca?: InputMaybe<Scalars['String']['input']>;
  component?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  deviceIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Firmware>>>;
  operation: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type MutationDisableMfaArgs = {
  challengeAnswer: Scalars['String']['input'];
};

export type MutationEnableMfaArgs = {
  password: Scalars['String']['input'];
};

export type MutationForgotPasswordArgs = {
  username: Scalars['String']['input'];
};

export type MutationImportUsersArgs = {
  file: Scalars['String']['input'];
};

export type MutationRemoveDeviceArgs = {
  deviceId: Scalars['String']['input'];
};

export type MutationRemoveFromGroupArgs = {
  group: Scalars['String']['input'];
  id: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationRemoveTagFromDevicesArgs = {
  deviceIds: Array<InputMaybe<Scalars['String']['input']>>;
  tagCategoryId: Scalars['String']['input'];
  tagId: Scalars['String']['input'];
  tagType: TagType;
};

export type MutationRemoveUserArgs = {
  username: Scalars['String']['input'];
};

export type MutationResetDeviceBatteryCounterArgs = {
  deviceId: Scalars['String']['input'];
  force?: InputMaybe<Scalars['Boolean']['input']>;
};

export type MutationSetThresholdsArgs = {
  deviceIds: Array<InputMaybe<Scalars['String']['input']>>;
  thresholds: Array<InputMaybe<ThresholdValue>>;
};

export type MutationUpdateAlarmCommentArgs = {
  alarmId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
  time: Scalars['Long']['input'];
};

export type MutationUpdateAlertCommentArgs = {
  id: Scalars['String']['input'];
  status: Scalars['String']['input'];
  time: Scalars['Long']['input'];
};

export type MutationUpdateAlertStatusArgs = {
  id: Scalars['String']['input'];
  status: Scalars['String']['input'];
  time: Scalars['Long']['input'];
};

export type MutationUpdateCompanyArgs = {
  correctedFlowUnit?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  logo?: InputMaybe<Scalars['String']['input']>;
  pressureUnit?: InputMaybe<Scalars['String']['input']>;
  temperatureUnit?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  voltageUnit?: InputMaybe<Scalars['String']['input']>;
  volumeUnit?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateDeviceGroupArgs = {
  correctedFlowUnit?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  pressureUnit?: InputMaybe<Scalars['String']['input']>;
  temperatureUnit?: InputMaybe<Scalars['String']['input']>;
  voltageUnit?: InputMaybe<Scalars['String']['input']>;
  volumeUnit?: InputMaybe<Scalars['String']['input']>;
};

export type MutationUpdateDeviceShadowArgs = {
  deviceId: Scalars['String']['input'];
  payload: Scalars['String']['input'];
  region: Scalars['String']['input'];
};

export type MutationUpdateUtilityTagArgs = {
  updatedUtilityTag: UpdatedTag;
  utilityTagCategoryId: Scalars['String']['input'];
};

export type MutationUpdateUtilityTagCategoryArgs = {
  updatedUtilityTagCategory: UpdatedTagCategory;
};

export type MutationVerifyUserEmailArgs = {
  code: Scalars['String']['input'];
};

export type NewTag = {
  name: Scalars['String']['input'];
};

export type NewTagCategory = {
  name: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  authenticateUser: Scalars['String']['output'];
  exportBatteryLifeReport: ExportResponse;
  exportEvents: ExportEventsResponse;
  exportMeterData: ExportResponse;
  getAlarmComment: Scalars['String']['output'];
  getAllPredefinedTagCategories: GetAllPredefinedTagCategoriesResponse;
  getAllTagCategories: GetAllTagCategoriesResponse;
  getAllUtilityTagCategories: GetAllUtilityTagCategoriesResponse;
  getAssociatedSecretCode: SecretCode;
  getBatteryLife: BatteryLifeResponse;
  getCompaniesByGroups: CompanyResponse;
  getDeviceGroups: Array<Maybe<DeviceGroup>>;
  getDeviceShadow: Scalars['String']['output'];
  getFirmware?: Maybe<Array<Maybe<FirmwareFile>>>;
  getMetersetImage?: Maybe<SignedUrlResponse>;
  getPredefinedTagsForDevices: GetPredefinedTagsForDevicesResponse;
  getStatsLogData: Array<Maybe<DeviceDataItem>>;
  getTagsForDevices: GetTagsForDevicesResponse;
  getUtilityTagsForDevices: GetUtilityTagsForDevicesResponse;
  groupList: GroupsReponse;
  groupListForUsers: Array<Maybe<UserGroup>>;
  listCaCertificate: Array<Maybe<CaCertificate>>;
  listCompanies: Array<Maybe<Company>>;
  listDevices: DeviceList;
  listFirmware?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  listFirmwareVersion?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  listUnassignedDevices: Array<Maybe<Scalars['String']['output']>>;
  requestExportAlarmLogs: ExportResponse;
  requestExportMeterHealthReport: ExportResponse;
  userByUsername: CognitoUser;
  userList: UsersReponse;
  verifyTotp: SuccessResponse;
};

export type QueryAuthenticateUserArgs = {
  challengeAnswer?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  newPassword?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  session?: InputMaybe<Scalars['String']['input']>;
};

export type QueryExportEventsArgs = {
  deviceId: Scalars['String']['input'];
  format: Scalars['String']['input'];
  fromDate: Scalars['Long']['input'];
  toDate: Scalars['Long']['input'];
};

export type QueryExportMeterDataArgs = {
  deviceIds: Array<InputMaybe<Scalars['String']['input']>>;
  forceUtc?: InputMaybe<Scalars['Boolean']['input']>;
  format: Scalars['String']['input'];
  frequency: Scalars['String']['input'];
  fromDate: Scalars['String']['input'];
  scaleFlow?: InputMaybe<Scalars['Float']['input']>;
  scaleVolume?: InputMaybe<Scalars['Float']['input']>;
  toDate: Scalars['String']['input'];
};

export type QueryGetAlarmCommentArgs = {
  alarmId: Scalars['String']['input'];
  time: Scalars['Long']['input'];
};

export type QueryGetAssociatedSecretCodeArgs = {
  password: Scalars['String']['input'];
};

export type QueryGetBatteryLifeArgs = {
  deviceId: Scalars['String']['input'];
};

export type QueryGetCompaniesByGroupsArgs = {
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryGetDeviceShadowArgs = {
  deviceId: Scalars['String']['input'];
  prettify?: InputMaybe<Scalars['Boolean']['input']>;
  region: Scalars['String']['input'];
};

export type QueryGetFirmwareArgs = {
  version?: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetMetersetImageArgs = {
  deviceId: Scalars['String']['input'];
};

export type QueryGetPredefinedTagsForDevicesArgs = {
  deviceIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QueryGetStatsLogDataArgs = {
  deviceIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  fromDate: Scalars['String']['input'];
  scaleFlow?: InputMaybe<Scalars['Float']['input']>;
  scaleVolume?: InputMaybe<Scalars['Float']['input']>;
  toDate: Scalars['String']['input'];
};

export type QueryGetTagsForDevicesArgs = {
  deviceIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QueryGetUtilityTagsForDevicesArgs = {
  deviceIds: Array<InputMaybe<Scalars['String']['input']>>;
};

export type QueryGroupListForUsersArgs = {
  users?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type QueryListDevicesArgs = {
  address?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  latitudeHi?: InputMaybe<Scalars['Float']['input']>;
  latitudeLo?: InputMaybe<Scalars['Float']['input']>;
  longitudeHi?: InputMaybe<Scalars['Float']['input']>;
  longitudeLo?: InputMaybe<Scalars['Float']['input']>;
  meterId?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  utility?: InputMaybe<Scalars['String']['input']>;
};

export type QueryListFirmwareVersionArgs = {
  component?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRequestExportAlarmLogsArgs = {
  deviceIds: Array<InputMaybe<Scalars['String']['input']>>;
  format: Scalars['String']['input'];
  fromDate: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
};

export type QueryRequestExportMeterHealthReportArgs = {
  fromDate: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
};

export type QueryUserByUsernameArgs = {
  username: Scalars['String']['input'];
};

export type QueryUserListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type QueryVerifyTotpArgs = {
  challengeAnswer: Scalars['String']['input'];
};

export type RemoveDeviceReponse = {
  __typename?: 'RemoveDeviceReponse';
  id: Scalars['String']['output'];
};

export type RemoveTagFromDevicesResponse = IResponse & {
  __typename?: 'RemoveTagFromDevicesResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type S3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String']['output'];
  key: Scalars['String']['output'];
};

export type SecretCode = {
  __typename?: 'SecretCode';
  secretCode: Scalars['String']['output'];
};

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse';
  message?: Maybe<Scalars['String']['output']>;
  signedUrl: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SuccessResponse = IResponse & {
  __typename?: 'SuccessResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type SuccessResponsePublic = {
  __typename?: 'SuccessResponsePublic';
  success: Scalars['Boolean']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  category_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type TagCategory = {
  __typename?: 'TagCategory';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  tags?: Maybe<Array<Tag>>;
};

export type TagCategoryByUtility = {
  __typename?: 'TagCategoryByUtility';
  id: Scalars['String']['output'];
  utilityTagCategories?: Maybe<Array<TagCategory>>;
};

export enum TagType {
  Predefined = 'predefined',
  Utility = 'utility',
}

export type TagTypes = {
  __typename?: 'TagTypes';
  predefinedTags?: Maybe<Array<Tag>>;
  utilityTags?: Maybe<Array<Tag>>;
};

export type Threshold = {
  __typename?: 'Threshold';
  device_id: Scalars['String']['output'];
  threshold_key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type ThresholdValue = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type UpdateUtilityTagCategoryResponse = IResponse & {
  __typename?: 'UpdateUtilityTagCategoryResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  updatedUtilityTagCategory: TagCategory;
};

export type UpdateUtilityTagResponse = IResponse & {
  __typename?: 'UpdateUtilityTagResponse';
  message?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  updatedUtilityTag: Tag;
};

export type UpdatedTag = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type UpdatedTagCategory = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  groups?: Maybe<Array<Maybe<CognitoGroup>>>;
  id: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type UserDevice = {
  __typename?: 'UserDevice';
  device_id: Scalars['String']['output'];
  user?: Maybe<DeviceOwner>;
  user_id: Scalars['String']['output'];
};

export type UserGroup = {
  __typename?: 'UserGroup';
  groups?: Maybe<Array<Maybe<CognitoGroup>>>;
  id: Scalars['String']['output'];
};

export type UsersReponse = {
  __typename?: 'UsersReponse';
  count?: Maybe<Scalars['Int']['output']>;
  users: Array<Maybe<User>>;
};

export type Utility = {
  __typename?: 'Utility';
  company?: Maybe<Company>;
  company_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  deviceId?: InputMaybe<Scalars['String']['input']>;
  groups: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: string | null;
};

export type ImportUsersMutationVariables = Exact<{
  file: Scalars['String']['input'];
}>;

export type ImportUsersMutation = {
  __typename?: 'Mutation';
  importUsers: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type AddToGroupMutationVariables = Exact<{
  id: Scalars['String']['input'];
  username: Scalars['String']['input'];
  group: Scalars['String']['input'];
}>;

export type AddToGroupMutation = {
  __typename?: 'Mutation';
  addToGroup?: boolean | null;
};

export type RemoveFromGroupMutationVariables = Exact<{
  id: Scalars['String']['input'];
  username: Scalars['String']['input'];
  group: Scalars['String']['input'];
}>;

export type RemoveFromGroupMutation = {
  __typename?: 'Mutation';
  removeFromGroup?: boolean | null;
};

export type AddDeviceMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  groupId: Scalars['String']['input'];
  meterId: Scalars['String']['input'];
  latitude?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddDeviceMutation = {
  __typename?: 'Mutation';
  addDevice: {
    __typename: 'Device';
    id: string;
    device_group_id: string;
    meterId?: string | null;
    serialNo?: string | null;
    latitude?: number | null;
    longitude?: number | null;
    address?: string | null;
    street?: string | null;
    city?: string | null;
    postalCode?: string | null;
    country?: string | null;
    group?: {
      __typename: 'DeviceGroup';
      id: string;
      name: string;
      utility_id: string;
      temperatureUnit?: string | null;
      pressureUnit?: string | null;
      volumeUnit?: string | null;
      voltageUnit?: string | null;
      correctedFlowUnit?: string | null;
      utility: {
        __typename: 'Utility';
        id: string;
        name: string;
        company_id?: string | null;
        company?: {
          __typename: 'Company';
          id: string;
          name: string;
          alias?: string | null;
          theme?: string | null;
          logo?: string | null;
          region: string;
          temperatureUnit: string;
          pressureUnit: string;
          volumeUnit: string;
          voltageUnit: string;
          correctedFlowUnit: string;
        } | null;
      };
    } | null;
    user_device?: {
      __typename: 'UserDevice';
      user_id: string;
      device_id: string;
      user?: {
        __typename: 'DeviceOwner';
        id: string;
        name: string;
        email: string;
      } | null;
    } | null;
    messages?: Array<{
      __typename: 'Message';
      id: string;
      time: any;
      urgent: boolean;
      dId: string;
      status: string;
      comment: string;
      alarms?: Array<{
        __typename: 'Alarm';
        lmt?: number | null;
        threshold?: number | null;
        t: any;
        type: number;
        v: number;
      } | null> | null;
    } | null> | null;
    tags?: {
      __typename: 'TagTypes';
      predefinedTags?: Array<{
        __typename: 'Tag';
        id: string;
        name: string;
        category_id?: string | null;
      }> | null;
      utilityTags?: Array<{
        __typename: 'Tag';
        id: string;
        name: string;
        category_id?: string | null;
      }> | null;
    } | null;
    thresholds?: Array<{
      __typename: 'Threshold';
      device_id: string;
      threshold_key: string;
      value: number;
    } | null> | null;
    data?: {
      __typename: 'DeviceData';
      meterSize?: string | null;
      ademFirmware?: string | null;
      brightlyncFirmware?: string | null;
      ingestionTime: any;
      dTm: any;
      TmZn: number;
      proto: number;
      bV: number;
      ccbbV?: number | null;
      dayCV: number;
      dayUV: number;
      fUV: number;
      fCV: number;
      cFAvg: number;
      pofMF: number;
      qMarg: number;
      maxFC?: { __typename: 'DeviceDataValue'; t: number; v: number } | null;
      maxPr?: { __typename: 'DeviceDataValue'; t: number; v: number } | null;
      maxTemp?: { __typename: 'DeviceDataValue'; t: number; v: number } | null;
      minFC?: { __typename: 'DeviceDataValue'; t: number; v: number } | null;
      minPr?: { __typename: 'DeviceDataValue'; t: number; v: number } | null;
      minTemp?: { __typename: 'DeviceDataValue'; t: number; v: number } | null;
    } | null;
  };
};

export type RemoveDeviceMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
}>;

export type RemoveDeviceMutation = {
  __typename?: 'Mutation';
  removeDevice: { __typename: 'RemoveDeviceReponse'; id: string };
};

export type ChangeUserDeviceMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  deviceId: Scalars['String']['input'];
}>;

export type ChangeUserDeviceMutation = {
  __typename?: 'Mutation';
  changeUserDevice: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type EnableMfaMutationVariables = Exact<{
  password: Scalars['String']['input'];
}>;

export type EnableMfaMutation = {
  __typename?: 'Mutation';
  enableMfa: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type DisableMfaMutationVariables = Exact<{
  challengeAnswer: Scalars['String']['input'];
}>;

export type DisableMfaMutation = {
  __typename?: 'Mutation';
  disableMfa: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['String']['input'];
  theme?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  temperatureUnit?: InputMaybe<Scalars['String']['input']>;
  pressureUnit?: InputMaybe<Scalars['String']['input']>;
  volumeUnit?: InputMaybe<Scalars['String']['input']>;
  voltageUnit?: InputMaybe<Scalars['String']['input']>;
  correctedFlowUnit?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type UpdateDeviceGroupMutationVariables = Exact<{
  id: Scalars['String']['input'];
  temperatureUnit?: InputMaybe<Scalars['String']['input']>;
  pressureUnit?: InputMaybe<Scalars['String']['input']>;
  volumeUnit?: InputMaybe<Scalars['String']['input']>;
  voltageUnit?: InputMaybe<Scalars['String']['input']>;
  correctedFlowUnit?: InputMaybe<Scalars['String']['input']>;
}>;

export type UpdateDeviceGroupMutation = {
  __typename?: 'Mutation';
  updateDeviceGroup: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type UpdateAlertStatusMutationVariables = Exact<{
  id: Scalars['String']['input'];
  time: Scalars['Long']['input'];
  status: Scalars['String']['input'];
}>;

export type UpdateAlertStatusMutation = {
  __typename?: 'Mutation';
  updateAlertStatus: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type UpdateAlertCommentMutationVariables = Exact<{
  id: Scalars['String']['input'];
  time: Scalars['Long']['input'];
  status: Scalars['String']['input'];
}>;

export type UpdateAlertCommentMutation = {
  __typename?: 'Mutation';
  updateAlertComment: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type SetThresholdsMutationVariables = Exact<{
  deviceIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  thresholds: Array<InputMaybe<ThresholdValue>> | InputMaybe<ThresholdValue>;
}>;

export type SetThresholdsMutation = {
  __typename?: 'Mutation';
  setThresholds: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type DeviceJobManagerMutationVariables = Exact<{
  deviceId?: InputMaybe<Scalars['String']['input']>;
  operation: Scalars['String']['input'];
  region?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
  files?: InputMaybe<Array<InputMaybe<Firmware>> | InputMaybe<Firmware>>;
  ca?: InputMaybe<Scalars['String']['input']>;
  component?: InputMaybe<Scalars['String']['input']>;
  deviceIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type DeviceJobManagerMutation = {
  __typename?: 'Mutation';
  deviceJobManager: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type ForgotPasswordMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type ForgotPasswordMutation = {
  __typename?: 'Mutation';
  forgotPassword: { __typename: 'SuccessResponsePublic'; success: boolean };
};

export type ConfirmForgotPasswordMutationVariables = Exact<{
  code: Scalars['String']['input'];
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;

export type ConfirmForgotPasswordMutation = {
  __typename?: 'Mutation';
  confirmForgotPassword: {
    __typename: 'SuccessResponsePublic';
    success: boolean;
  };
};

export type VerifyUserEmailMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type VerifyUserEmailMutation = {
  __typename?: 'Mutation';
  verifyUserEmail: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type RemoveUserMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type RemoveUserMutation = {
  __typename?: 'Mutation';
  removeUser: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type UpdateDeviceShadowMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  region: Scalars['String']['input'];
  payload: Scalars['String']['input'];
}>;

export type UpdateDeviceShadowMutation = {
  __typename?: 'Mutation';
  updateDeviceShadow: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type UpdateAlarmCommentMutationVariables = Exact<{
  alarmId: Scalars['String']['input'];
  time: Scalars['Long']['input'];
  comment: Scalars['String']['input'];
}>;

export type UpdateAlarmCommentMutation = {
  __typename?: 'Mutation';
  updateAlarmComment: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type ResetDeviceBatteryCounterMutationVariables = Exact<{
  deviceId: Scalars['String']['input'];
  force?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type ResetDeviceBatteryCounterMutation = {
  __typename?: 'Mutation';
  resetDeviceBatteryCounter: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type AddUtilityTagCategoriesMutationVariables = Exact<{
  newUtilityTagCategories: Array<InputMaybe<NewTagCategory>> | InputMaybe<NewTagCategory>;
}>;

export type AddUtilityTagCategoriesMutation = {
  __typename?: 'Mutation';
  addUtilityTagCategories: {
    __typename: 'AddUtilityTagCategoriesResponse';
    success: boolean;
    message?: string | null;
    newUtilityTagCategories?: Array<{
      __typename: 'TagCategory';
      id: string;
      name: string;
      tags?: Array<{
        __typename: 'Tag';
        id: string;
        name: string;
        category_id?: string | null;
      }> | null;
    }> | null;
  };
};

export type AddUtilityTagsMutationVariables = Exact<{
  newUtilityTags: Array<InputMaybe<NewTag>> | InputMaybe<NewTag>;
  utilityTagCategoryId: Scalars['String']['input'];
}>;

export type AddUtilityTagsMutation = {
  __typename?: 'Mutation';
  addUtilityTags: {
    __typename: 'AddUtilityTagsResponse';
    success: boolean;
    message?: string | null;
    newUtilityTags?: Array<{
      __typename: 'Tag';
      id: string;
      name: string;
      category_id?: string | null;
    }> | null;
  };
};

export type UpdateUtilityTagCategoryMutationVariables = Exact<{
  updatedUtilityTagCategory: UpdatedTagCategory;
}>;

export type UpdateUtilityTagCategoryMutation = {
  __typename?: 'Mutation';
  updateUtilityTagCategory: {
    __typename: 'UpdateUtilityTagCategoryResponse';
    success: boolean;
    message?: string | null;
    updatedUtilityTagCategory: {
      __typename: 'TagCategory';
      id: string;
      name: string;
      tags?: Array<{
        __typename: 'Tag';
        id: string;
        name: string;
        category_id?: string | null;
      }> | null;
    };
  };
};

export type UpdateUtilityTagMutationVariables = Exact<{
  updatedUtilityTag: UpdatedTag;
  utilityTagCategoryId: Scalars['String']['input'];
}>;

export type UpdateUtilityTagMutation = {
  __typename?: 'Mutation';
  updateUtilityTag: {
    __typename: 'UpdateUtilityTagResponse';
    success: boolean;
    message?: string | null;
    updatedUtilityTag: {
      __typename: 'Tag';
      id: string;
      name: string;
      category_id?: string | null;
    };
  };
};

export type DeleteUtilityTagCategoryMutationVariables = Exact<{
  utilityTagCategoryId: Scalars['String']['input'];
}>;

export type DeleteUtilityTagCategoryMutation = {
  __typename?: 'Mutation';
  deleteUtilityTagCategory: {
    __typename: 'DeleteUtilityTagCategoryResponse';
    success: boolean;
    message?: string | null;
    deletedUtilityTagCategoryId: string;
  };
};

export type DeleteUtilityTagMutationVariables = Exact<{
  utilityTagId: Scalars['String']['input'];
  utilityTagCategoryId: Scalars['String']['input'];
}>;

export type DeleteUtilityTagMutation = {
  __typename?: 'Mutation';
  deleteUtilityTag: {
    __typename: 'DeleteUtilityTagResponse';
    success: boolean;
    message?: string | null;
    deletedUtilityTagId: string;
  };
};

export type AddTagToDevicesMutationVariables = Exact<{
  deviceIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  tagId: Scalars['String']['input'];
  tagCategoryId: Scalars['String']['input'];
  tagType: TagType;
}>;

export type AddTagToDevicesMutation = {
  __typename?: 'Mutation';
  addTagToDevices: {
    __typename: 'AddTagToDevicesResponse';
    success: boolean;
    message?: string | null;
  };
};

export type RemoveTagFromDevicesMutationVariables = Exact<{
  deviceIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  tagId: Scalars['String']['input'];
  tagCategoryId: Scalars['String']['input'];
  tagType: TagType;
}>;

export type RemoveTagFromDevicesMutation = {
  __typename?: 'Mutation';
  removeTagFromDevices: {
    __typename: 'RemoveTagFromDevicesResponse';
    success: boolean;
    message?: string | null;
  };
};

export type AuthenticateUserQueryVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  newPassword?: InputMaybe<Scalars['String']['input']>;
  challengeAnswer?: InputMaybe<Scalars['String']['input']>;
  session?: InputMaybe<Scalars['String']['input']>;
}>;

export type AuthenticateUserQuery = {
  __typename?: 'Query';
  authenticateUser: string;
};

export type GetMetersetImageQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
}>;

export type GetMetersetImageQuery = {
  __typename?: 'Query';
  getMetersetImage?: {
    __typename: 'SignedUrlResponse';
    signedUrl: string;
    success: boolean;
    message?: string | null;
  } | null;
};

export type UserListQueryVariables = Exact<{
  username?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type UserListQuery = {
  __typename?: 'Query';
  userList: {
    __typename: 'UsersReponse';
    count?: number | null;
    users: Array<{
      __typename: 'User';
      id: string;
      username: string;
      groups?: Array<{
        __typename: 'CognitoGroup';
        name: string;
        description?: string | null;
      } | null> | null;
    } | null>;
  };
};

export type GroupListForUsersQueryVariables = Exact<{
  users?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GroupListForUsersQuery = {
  __typename?: 'Query';
  groupListForUsers: Array<{
    __typename: 'UserGroup';
    id: string;
    groups?: Array<{
      __typename: 'CognitoGroup';
      name: string;
      description?: string | null;
    } | null> | null;
  } | null>;
};

export type UserByUsernameQueryVariables = Exact<{
  username: Scalars['String']['input'];
}>;

export type UserByUsernameQuery = {
  __typename?: 'Query';
  userByUsername: {
    __typename: 'CognitoUser';
    sub: string;
    username: string;
    email: string;
    groups?: Array<string | null> | null;
    deviceId?: string | null;
  };
};

export type GroupListQueryVariables = Exact<{ [key: string]: never }>;

export type GroupListQuery = {
  __typename?: 'Query';
  groupList: {
    __typename: 'GroupsReponse';
    groups: Array<{
      __typename: 'Group';
      name: string;
      description?: string | null;
    } | null>;
  };
};

export type GetBatteryLifeQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
}>;

export type GetBatteryLifeQuery = {
  __typename?: 'Query';
  getBatteryLife: {
    __typename: 'BatteryLifeResponse';
    percentage?: number | null;
    communicationCounter?: number | null;
  };
};

export type GetStatsLogDataQueryVariables = Exact<{
  fromDate: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
  deviceIds?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
  frequency?: InputMaybe<Scalars['String']['input']>;
  scaleFlow?: InputMaybe<Scalars['Float']['input']>;
  scaleVolume?: InputMaybe<Scalars['Float']['input']>;
}>;

export type GetStatsLogDataQuery = {
  __typename?: 'Query';
  getStatsLogData: Array<{
    __typename: 'DeviceDataItem';
    id: string;
    data: Array<{
      __typename: 'MeterDataItem';
      deviceId?: string | null;
      time?: string | null;
      dayCV?: number | null;
      dayUV?: number | null;
      minPr?: number | null;
      maxPr?: number | null;
      minFC?: number | null;
      maxFC?: number | null;
      minTemp?: number | null;
      maxTemp?: number | null;
      bV?: number | null;
      ccbbV?: number | null;
      pofMF?: number | null;
      qMarg?: number | null;
      pr?: number | null;
      cF?: number | null;
      cV?: number | null;
      uV?: number | null;
      fCV?: number | null;
      fUV?: number | null;
      temp?: number | null;
    } | null>;
  } | null>;
};

export type RequestExportMeterHealthReportQueryVariables = Exact<{
  fromDate: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
}>;

export type RequestExportMeterHealthReportQuery = {
  __typename?: 'Query';
  requestExportMeterHealthReport: {
    __typename: 'ExportResponse';
    success?: boolean | null;
    message?: string | null;
  };
};

export type RequestExportAlarmLogsQueryVariables = Exact<{
  format: Scalars['String']['input'];
  fromDate: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
  deviceIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type RequestExportAlarmLogsQuery = {
  __typename?: 'Query';
  requestExportAlarmLogs: {
    __typename: 'ExportResponse';
    success?: boolean | null;
    message?: string | null;
  };
};

export type ExportMeterDataQueryVariables = Exact<{
  format: Scalars['String']['input'];
  forceUtc?: InputMaybe<Scalars['Boolean']['input']>;
  fromDate: Scalars['String']['input'];
  toDate: Scalars['String']['input'];
  deviceIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
  frequency: Scalars['String']['input'];
  scaleFlow?: InputMaybe<Scalars['Float']['input']>;
  scaleVolume?: InputMaybe<Scalars['Float']['input']>;
}>;

export type ExportMeterDataQuery = {
  __typename?: 'Query';
  exportMeterData: {
    __typename: 'ExportResponse';
    success?: boolean | null;
    message?: string | null;
  };
};

export type ExportEventsQueryVariables = Exact<{
  format: Scalars['String']['input'];
  fromDate: Scalars['Long']['input'];
  toDate: Scalars['Long']['input'];
  deviceId: Scalars['String']['input'];
}>;

export type ExportEventsQuery = {
  __typename?: 'Query';
  exportEvents: { __typename: 'ExportEventsResponse'; link?: string | null };
};

export type ExportBatteryLifeReportQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ExportBatteryLifeReportQuery = {
  __typename?: 'Query';
  exportBatteryLifeReport: {
    __typename: 'ExportResponse';
    success?: boolean | null;
    message?: string | null;
  };
};

export type GetDeviceGroupsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDeviceGroupsQuery = {
  __typename?: 'Query';
  getDeviceGroups: Array<{
    __typename: 'DeviceGroup';
    id: string;
    name: string;
    utility_id: string;
    temperatureUnit?: string | null;
    pressureUnit?: string | null;
    volumeUnit?: string | null;
    voltageUnit?: string | null;
    correctedFlowUnit?: string | null;
    utility: {
      __typename: 'Utility';
      id: string;
      name: string;
      company_id?: string | null;
      company?: {
        __typename: 'Company';
        id: string;
        name: string;
        alias?: string | null;
        theme?: string | null;
        logo?: string | null;
        region: string;
        temperatureUnit: string;
        pressureUnit: string;
        volumeUnit: string;
        voltageUnit: string;
        correctedFlowUnit: string;
      } | null;
    };
  } | null>;
};

export type ListDevicesQueryVariables = Exact<{
  date?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<Scalars['String']['input']>;
  deviceId?: InputMaybe<Scalars['String']['input']>;
  meterId?: InputMaybe<Scalars['String']['input']>;
  owner?: InputMaybe<Scalars['String']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  utility?: InputMaybe<Scalars['String']['input']>;
  company?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  latitudeLo?: InputMaybe<Scalars['Float']['input']>;
  latitudeHi?: InputMaybe<Scalars['Float']['input']>;
  longitudeLo?: InputMaybe<Scalars['Float']['input']>;
  longitudeHi?: InputMaybe<Scalars['Float']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
}>;

export type ListDevicesQuery = {
  __typename?: 'Query';
  listDevices: {
    __typename: 'DeviceList';
    count: number;
    rows: Array<{
      __typename: 'Device';
      id: string;
      device_group_id: string;
      meterId?: string | null;
      serialNo?: string | null;
      latitude?: number | null;
      longitude?: number | null;
      address?: string | null;
      street?: string | null;
      city?: string | null;
      postalCode?: string | null;
      country?: string | null;
      group?: {
        __typename: 'DeviceGroup';
        id: string;
        name: string;
        utility_id: string;
        temperatureUnit?: string | null;
        pressureUnit?: string | null;
        volumeUnit?: string | null;
        voltageUnit?: string | null;
        correctedFlowUnit?: string | null;
        utility: {
          __typename: 'Utility';
          id: string;
          name: string;
          company_id?: string | null;
          company?: {
            __typename: 'Company';
            id: string;
            name: string;
            alias?: string | null;
            theme?: string | null;
            logo?: string | null;
            region: string;
            temperatureUnit: string;
            pressureUnit: string;
            volumeUnit: string;
            voltageUnit: string;
            correctedFlowUnit: string;
          } | null;
        };
      } | null;
      user_device?: {
        __typename: 'UserDevice';
        user_id: string;
        device_id: string;
        user?: {
          __typename: 'DeviceOwner';
          id: string;
          name: string;
          email: string;
        } | null;
      } | null;
      messages?: Array<{
        __typename: 'Message';
        id: string;
        time: any;
        urgent: boolean;
        dId: string;
        status: string;
        comment: string;
        alarms?: Array<{
          __typename: 'Alarm';
          lmt?: number | null;
          threshold?: number | null;
          t: any;
          type: number;
          v: number;
        } | null> | null;
      } | null> | null;
      tags?: {
        __typename: 'TagTypes';
        predefinedTags?: Array<{
          __typename: 'Tag';
          id: string;
          name: string;
          category_id?: string | null;
        }> | null;
        utilityTags?: Array<{
          __typename: 'Tag';
          id: string;
          name: string;
          category_id?: string | null;
        }> | null;
      } | null;
      thresholds?: Array<{
        __typename: 'Threshold';
        device_id: string;
        threshold_key: string;
        value: number;
      } | null> | null;
      data?: {
        __typename: 'DeviceData';
        meterSize?: string | null;
        ademFirmware?: string | null;
        brightlyncFirmware?: string | null;
        ingestionTime: any;
        dTm: any;
        TmZn: number;
        proto: number;
        bV: number;
        ccbbV?: number | null;
        dayCV: number;
        dayUV: number;
        fUV: number;
        fCV: number;
        cFAvg: number;
        pofMF: number;
        qMarg: number;
        maxFC?: { __typename: 'DeviceDataValue'; t: number; v: number } | null;
        maxPr?: { __typename: 'DeviceDataValue'; t: number; v: number } | null;
        maxTemp?: {
          __typename: 'DeviceDataValue';
          t: number;
          v: number;
        } | null;
        minFC?: { __typename: 'DeviceDataValue'; t: number; v: number } | null;
        minPr?: { __typename: 'DeviceDataValue'; t: number; v: number } | null;
        minTemp?: {
          __typename: 'DeviceDataValue';
          t: number;
          v: number;
        } | null;
      } | null;
    } | null>;
  };
};

export type VerifyTotpQueryVariables = Exact<{
  challengeAnswer: Scalars['String']['input'];
}>;

export type VerifyTotpQuery = {
  __typename?: 'Query';
  verifyTotp: {
    __typename: 'SuccessResponse';
    success: boolean;
    message?: string | null;
  };
};

export type GetAssociatedSecretCodeQueryVariables = Exact<{
  password: Scalars['String']['input'];
}>;

export type GetAssociatedSecretCodeQuery = {
  __typename?: 'Query';
  getAssociatedSecretCode: { __typename: 'SecretCode'; secretCode: string };
};

export type ListUnassignedDevicesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ListUnassignedDevicesQuery = {
  __typename?: 'Query';
  listUnassignedDevices: Array<string | null>;
};

export type GetCompaniesByGroupsQueryVariables = Exact<{
  groups?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>
  >;
}>;

export type GetCompaniesByGroupsQuery = {
  __typename?: 'Query';
  getCompaniesByGroups: {
    __typename: 'CompanyResponse';
    companies: Array<{
      __typename: 'Company';
      id: string;
      name: string;
      alias?: string | null;
      theme?: string | null;
      logo?: string | null;
      region: string;
      temperatureUnit: string;
      pressureUnit: string;
      volumeUnit: string;
      voltageUnit: string;
      correctedFlowUnit: string;
    } | null>;
  };
};

export type ListFirmwareQueryVariables = Exact<{ [key: string]: never }>;

export type ListFirmwareQuery = {
  __typename?: 'Query';
  listFirmware?: Array<string | null> | null;
};

export type ListFirmwareVersionQueryVariables = Exact<{
  component?: InputMaybe<Scalars['String']['input']>;
}>;

export type ListFirmwareVersionQuery = {
  __typename?: 'Query';
  listFirmwareVersion?: Array<string | null> | null;
};

export type GetFirmwareQueryVariables = Exact<{
  version?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetFirmwareQuery = {
  __typename?: 'Query';
  getFirmware?: Array<{
    __typename: 'FirmwareFile';
    name: string;
    url: string;
  } | null> | null;
};

export type ListCaCertificateQueryVariables = Exact<{ [key: string]: never }>;

export type ListCaCertificateQuery = {
  __typename?: 'Query';
  listCaCertificate: Array<{
    __typename: 'CaCertificate';
    key: string;
    date: string;
    name: string;
  } | null>;
};

export type ListCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type ListCompaniesQuery = {
  __typename?: 'Query';
  listCompanies: Array<{
    __typename: 'Company';
    id: string;
    name: string;
    alias?: string | null;
    theme?: string | null;
    logo?: string | null;
    region: string;
    temperatureUnit: string;
    pressureUnit: string;
    volumeUnit: string;
    voltageUnit: string;
    correctedFlowUnit: string;
  } | null>;
};

export type GetDeviceShadowQueryVariables = Exact<{
  deviceId: Scalars['String']['input'];
  region: Scalars['String']['input'];
  prettify?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetDeviceShadowQuery = {
  __typename?: 'Query';
  getDeviceShadow: string;
};

export type GetAlarmCommentQueryVariables = Exact<{
  alarmId: Scalars['String']['input'];
  time: Scalars['Long']['input'];
}>;

export type GetAlarmCommentQuery = {
  __typename?: 'Query';
  getAlarmComment: string;
};

export type GetAllTagCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllTagCategoriesQuery = {
  __typename?: 'Query';
  getAllTagCategories: {
    __typename: 'GetAllTagCategoriesResponse';
    success: boolean;
    message?: string | null;
    predefinedTagCategories?: Array<{
      __typename: 'TagCategory';
      id: string;
      name: string;
      tags?: Array<{
        __typename: 'Tag';
        id: string;
        name: string;
        category_id?: string | null;
      }> | null;
    }> | null;
    utilityTagCategoriesPerUtility?: Array<{
      __typename: 'TagCategoryByUtility';
      id: string;
      utilityTagCategories?: Array<{
        __typename: 'TagCategory';
        id: string;
        name: string;
        tags?: Array<{
          __typename: 'Tag';
          id: string;
          name: string;
          category_id?: string | null;
        }> | null;
      }> | null;
    }> | null;
  };
};

export type GetAllPredefinedTagCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllPredefinedTagCategoriesQuery = {
  __typename?: 'Query';
  getAllPredefinedTagCategories: {
    __typename: 'GetAllPredefinedTagCategoriesResponse';
    success: boolean;
    message?: string | null;
    predefinedTagCategories?: Array<{
      __typename: 'TagCategory';
      id: string;
      name: string;
      tags?: Array<{
        __typename: 'Tag';
        id: string;
        name: string;
        category_id?: string | null;
      }> | null;
    }> | null;
  };
};

export type GetAllUtilityTagCategoriesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllUtilityTagCategoriesQuery = {
  __typename?: 'Query';
  getAllUtilityTagCategories: {
    __typename: 'GetAllUtilityTagCategoriesResponse';
    success: boolean;
    message?: string | null;
    utilityTagCategoriesPerUtility?: Array<{
      __typename: 'TagCategoryByUtility';
      id: string;
      utilityTagCategories?: Array<{
        __typename: 'TagCategory';
        id: string;
        name: string;
        tags?: Array<{
          __typename: 'Tag';
          id: string;
          name: string;
          category_id?: string | null;
        }> | null;
      }> | null;
    }> | null;
  };
};

export type GetPredefinedTagsForDevicesQueryVariables = Exact<{
  deviceIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type GetPredefinedTagsForDevicesQuery = {
  __typename?: 'Query';
  getPredefinedTagsForDevices: {
    __typename: 'GetPredefinedTagsForDevicesResponse';
    success: boolean;
    message?: string | null;
    devicesWithPredefinedTags: Array<{
      __typename: 'DeviceWithPredefinedTags';
      deviceId: string;
      predefinedTags?: Array<{
        __typename: 'Tag';
        id: string;
        name: string;
        category_id?: string | null;
      }> | null;
    } | null>;
  };
};

export type GetUtilityTagsForDevicesQueryVariables = Exact<{
  deviceIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type GetUtilityTagsForDevicesQuery = {
  __typename?: 'Query';
  getUtilityTagsForDevices: {
    __typename: 'GetUtilityTagsForDevicesResponse';
    success: boolean;
    message?: string | null;
    devicesWithUtilityTags: Array<{
      __typename: 'DeviceWithUtilityTags';
      deviceId: string;
      utilityTags?: Array<{
        __typename: 'Tag';
        id: string;
        name: string;
        category_id?: string | null;
      }> | null;
    } | null>;
  };
};

export type GetTagsForDevicesQueryVariables = Exact<{
  deviceIds: Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>;
}>;

export type GetTagsForDevicesQuery = {
  __typename?: 'Query';
  getTagsForDevices: {
    __typename: 'GetTagsForDevicesResponse';
    success: boolean;
    message?: string | null;
    devicesWithPredefinedTags: Array<{
      __typename: 'DeviceWithPredefinedTags';
      deviceId: string;
      predefinedTags?: Array<{
        __typename: 'Tag';
        id: string;
        name: string;
        category_id?: string | null;
      }> | null;
    } | null>;
    devicesWithUtilityTags: Array<{
      __typename: 'DeviceWithUtilityTags';
      deviceId: string;
      utilityTags?: Array<{
        __typename: 'Tag';
        id: string;
        name: string;
        category_id?: string | null;
      }> | null;
    } | null>;
  };
};

export type ResolverTypeWrapper<T> = Promise<T> | T;

export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<
  TResult,
  TKey extends string,
  TParent,
  TContext,
  TArgs,
> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<
  TResult,
  TKey extends string,
  TParent = {},
  TContext = {},
  TArgs = {},
> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (
  obj: T,
  context: TContext,
  info: GraphQLResolveInfo
) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = {
  IGetAllPredefinedTagCategoriesResponse:
    | GetAllPredefinedTagCategoriesResponse
    | GetAllTagCategoriesResponse;
  IGetAllUtilityTagCategoriesResponse:
    | GetAllTagCategoriesResponse
    | GetAllUtilityTagCategoriesResponse;
  IGetPredefinedTagsForDevicesResponse:
    | GetPredefinedTagsForDevicesResponse
    | GetTagsForDevicesResponse;
  IGetUtilityTagsForDevicesResponse: GetTagsForDevicesResponse | GetUtilityTagsForDevicesResponse;
  IResponse:
    | AddTagToDevicesResponse
    | AddUtilityTagCategoriesResponse
    | AddUtilityTagsResponse
    | DeleteUtilityTagCategoryResponse
    | DeleteUtilityTagResponse
    | GetAllPredefinedTagCategoriesResponse
    | GetAllTagCategoriesResponse
    | GetAllUtilityTagCategoriesResponse
    | GetPredefinedTagsForDevicesResponse
    | GetTagsForDevicesResponse
    | GetUtilityTagsForDevicesResponse
    | RemoveTagFromDevicesResponse
    | SuccessResponse
    | UpdateUtilityTagCategoryResponse
    | UpdateUtilityTagResponse;
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AddTagToDevicesResponse: ResolverTypeWrapper<AddTagToDevicesResponse>;
  AddUtilityTagCategoriesResponse: ResolverTypeWrapper<AddUtilityTagCategoriesResponse>;
  AddUtilityTagsResponse: ResolverTypeWrapper<AddUtilityTagsResponse>;
  Alarm: ResolverTypeWrapper<Alarm>;
  BatteryLifeResponse: ResolverTypeWrapper<BatteryLifeResponse>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CaCertificate: ResolverTypeWrapper<CaCertificate>;
  CognitoGroup: ResolverTypeWrapper<CognitoGroup>;
  CognitoUser: ResolverTypeWrapper<CognitoUser>;
  Company: ResolverTypeWrapper<Company>;
  CompanyResponse: ResolverTypeWrapper<CompanyResponse>;
  DeleteUtilityTagCategoryResponse: ResolverTypeWrapper<DeleteUtilityTagCategoryResponse>;
  DeleteUtilityTagResponse: ResolverTypeWrapper<DeleteUtilityTagResponse>;
  Device: ResolverTypeWrapper<Device>;
  DeviceData: ResolverTypeWrapper<DeviceData>;
  DeviceDataItem: ResolverTypeWrapper<DeviceDataItem>;
  DeviceDataValue: ResolverTypeWrapper<DeviceDataValue>;
  DeviceGroup: ResolverTypeWrapper<DeviceGroup>;
  DeviceList: ResolverTypeWrapper<DeviceList>;
  DeviceOwner: ResolverTypeWrapper<DeviceOwner>;
  DeviceWithPredefinedTags: ResolverTypeWrapper<DeviceWithPredefinedTags>;
  DeviceWithUtilityTags: ResolverTypeWrapper<DeviceWithUtilityTags>;
  ExportEventsResponse: ResolverTypeWrapper<ExportEventsResponse>;
  ExportResponse: ResolverTypeWrapper<ExportResponse>;
  Firmware: Firmware;
  FirmwareFile: ResolverTypeWrapper<FirmwareFile>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GetAllPredefinedTagCategoriesResponse: ResolverTypeWrapper<GetAllPredefinedTagCategoriesResponse>;
  GetAllTagCategoriesResponse: ResolverTypeWrapper<GetAllTagCategoriesResponse>;
  GetAllUtilityTagCategoriesResponse: ResolverTypeWrapper<GetAllUtilityTagCategoriesResponse>;
  GetPredefinedTagsForDevicesResponse: ResolverTypeWrapper<GetPredefinedTagsForDevicesResponse>;
  GetTagsForDevicesResponse: ResolverTypeWrapper<GetTagsForDevicesResponse>;
  GetUtilityTagsForDevicesResponse: ResolverTypeWrapper<GetUtilityTagsForDevicesResponse>;
  Group: ResolverTypeWrapper<Group>;
  GroupsReponse: ResolverTypeWrapper<GroupsReponse>;
  IGetAllPredefinedTagCategoriesResponse: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['IGetAllPredefinedTagCategoriesResponse']
  >;
  IGetAllUtilityTagCategoriesResponse: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['IGetAllUtilityTagCategoriesResponse']
  >;
  IGetPredefinedTagsForDevicesResponse: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['IGetPredefinedTagsForDevicesResponse']
  >;
  IGetUtilityTagsForDevicesResponse: ResolverTypeWrapper<
    ResolversInterfaceTypes<ResolversTypes>['IGetUtilityTagsForDevicesResponse']
  >;
  IResponse: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['IResponse']>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Logo: ResolverTypeWrapper<Logo>;
  Long: ResolverTypeWrapper<Scalars['Long']['output']>;
  Message: ResolverTypeWrapper<Message>;
  MeterDataItem: ResolverTypeWrapper<MeterDataItem>;
  Mutation: ResolverTypeWrapper<{}>;
  NewTag: NewTag;
  NewTagCategory: NewTagCategory;
  Query: ResolverTypeWrapper<{}>;
  RemoveDeviceReponse: ResolverTypeWrapper<RemoveDeviceReponse>;
  RemoveTagFromDevicesResponse: ResolverTypeWrapper<RemoveTagFromDevicesResponse>;
  S3Object: ResolverTypeWrapper<S3Object>;
  SecretCode: ResolverTypeWrapper<SecretCode>;
  SignedUrlResponse: ResolverTypeWrapper<SignedUrlResponse>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  SuccessResponse: ResolverTypeWrapper<SuccessResponse>;
  SuccessResponsePublic: ResolverTypeWrapper<SuccessResponsePublic>;
  Tag: ResolverTypeWrapper<Tag>;
  TagCategory: ResolverTypeWrapper<TagCategory>;
  TagCategoryByUtility: ResolverTypeWrapper<TagCategoryByUtility>;
  TagType: TagType;
  TagTypes: ResolverTypeWrapper<TagTypes>;
  Threshold: ResolverTypeWrapper<Threshold>;
  ThresholdValue: ThresholdValue;
  UpdateUtilityTagCategoryResponse: ResolverTypeWrapper<UpdateUtilityTagCategoryResponse>;
  UpdateUtilityTagResponse: ResolverTypeWrapper<UpdateUtilityTagResponse>;
  UpdatedTag: UpdatedTag;
  UpdatedTagCategory: UpdatedTagCategory;
  User: ResolverTypeWrapper<User>;
  UserDevice: ResolverTypeWrapper<UserDevice>;
  UserGroup: ResolverTypeWrapper<UserGroup>;
  UsersReponse: ResolverTypeWrapper<UsersReponse>;
  Utility: ResolverTypeWrapper<Utility>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AddTagToDevicesResponse: AddTagToDevicesResponse;
  AddUtilityTagCategoriesResponse: AddUtilityTagCategoriesResponse;
  AddUtilityTagsResponse: AddUtilityTagsResponse;
  Alarm: Alarm;
  BatteryLifeResponse: BatteryLifeResponse;
  Boolean: Scalars['Boolean']['output'];
  CaCertificate: CaCertificate;
  CognitoGroup: CognitoGroup;
  CognitoUser: CognitoUser;
  Company: Company;
  CompanyResponse: CompanyResponse;
  DeleteUtilityTagCategoryResponse: DeleteUtilityTagCategoryResponse;
  DeleteUtilityTagResponse: DeleteUtilityTagResponse;
  Device: Device;
  DeviceData: DeviceData;
  DeviceDataItem: DeviceDataItem;
  DeviceDataValue: DeviceDataValue;
  DeviceGroup: DeviceGroup;
  DeviceList: DeviceList;
  DeviceOwner: DeviceOwner;
  DeviceWithPredefinedTags: DeviceWithPredefinedTags;
  DeviceWithUtilityTags: DeviceWithUtilityTags;
  ExportEventsResponse: ExportEventsResponse;
  ExportResponse: ExportResponse;
  Firmware: Firmware;
  FirmwareFile: FirmwareFile;
  Float: Scalars['Float']['output'];
  GetAllPredefinedTagCategoriesResponse: GetAllPredefinedTagCategoriesResponse;
  GetAllTagCategoriesResponse: GetAllTagCategoriesResponse;
  GetAllUtilityTagCategoriesResponse: GetAllUtilityTagCategoriesResponse;
  GetPredefinedTagsForDevicesResponse: GetPredefinedTagsForDevicesResponse;
  GetTagsForDevicesResponse: GetTagsForDevicesResponse;
  GetUtilityTagsForDevicesResponse: GetUtilityTagsForDevicesResponse;
  Group: Group;
  GroupsReponse: GroupsReponse;
  IGetAllPredefinedTagCategoriesResponse: ResolversInterfaceTypes<ResolversParentTypes>['IGetAllPredefinedTagCategoriesResponse'];
  IGetAllUtilityTagCategoriesResponse: ResolversInterfaceTypes<ResolversParentTypes>['IGetAllUtilityTagCategoriesResponse'];
  IGetPredefinedTagsForDevicesResponse: ResolversInterfaceTypes<ResolversParentTypes>['IGetPredefinedTagsForDevicesResponse'];
  IGetUtilityTagsForDevicesResponse: ResolversInterfaceTypes<ResolversParentTypes>['IGetUtilityTagsForDevicesResponse'];
  IResponse: ResolversInterfaceTypes<ResolversParentTypes>['IResponse'];
  Int: Scalars['Int']['output'];
  Logo: Logo;
  Long: Scalars['Long']['output'];
  Message: Message;
  MeterDataItem: MeterDataItem;
  Mutation: {};
  NewTag: NewTag;
  NewTagCategory: NewTagCategory;
  Query: {};
  RemoveDeviceReponse: RemoveDeviceReponse;
  RemoveTagFromDevicesResponse: RemoveTagFromDevicesResponse;
  S3Object: S3Object;
  SecretCode: SecretCode;
  SignedUrlResponse: SignedUrlResponse;
  String: Scalars['String']['output'];
  SuccessResponse: SuccessResponse;
  SuccessResponsePublic: SuccessResponsePublic;
  Tag: Tag;
  TagCategory: TagCategory;
  TagCategoryByUtility: TagCategoryByUtility;
  TagTypes: TagTypes;
  Threshold: Threshold;
  ThresholdValue: ThresholdValue;
  UpdateUtilityTagCategoryResponse: UpdateUtilityTagCategoryResponse;
  UpdateUtilityTagResponse: UpdateUtilityTagResponse;
  UpdatedTag: UpdatedTag;
  UpdatedTagCategory: UpdatedTagCategory;
  User: User;
  UserDevice: UserDevice;
  UserGroup: UserGroup;
  UsersReponse: UsersReponse;
  Utility: Utility;
};

export type Aws_Api_KeyDirectiveArgs = {};

export type Aws_Api_KeyDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_Api_KeyDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_AuthDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_AuthDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_AuthDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_Cognito_User_PoolsDirectiveArgs = {
  cognito_groups?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_Cognito_User_PoolsDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_Cognito_User_PoolsDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_IamDirectiveArgs = {};

export type Aws_IamDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_IamDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_LambdaDirectiveArgs = {};

export type Aws_LambdaDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_LambdaDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_OidcDirectiveArgs = {};

export type Aws_OidcDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_OidcDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_PublishDirectiveArgs = {
  subscriptions?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_PublishDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_PublishDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type Aws_SubscribeDirectiveArgs = {
  mutations?: Maybe<Array<Maybe<Scalars['String']['input']>>>;
};

export type Aws_SubscribeDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = Aws_SubscribeDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type DeferDirectiveArgs = {};

export type DeferDirectiveResolver<
  Result,
  Parent,
  ContextType = any,
  Args = DeferDirectiveArgs,
> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type AddTagToDevicesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddTagToDevicesResponse'] = ResolversParentTypes['AddTagToDevicesResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddUtilityTagCategoriesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddUtilityTagCategoriesResponse'] = ResolversParentTypes['AddUtilityTagCategoriesResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newUtilityTagCategories?: Resolver<
    Maybe<Array<ResolversTypes['TagCategory']>>,
    ParentType,
    ContextType
  >;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddUtilityTagsResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['AddUtilityTagsResponse'] = ResolversParentTypes['AddUtilityTagsResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  newUtilityTags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AlarmResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Alarm'] = ResolversParentTypes['Alarm'],
> = {
  lmt?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  t?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  threshold?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  v?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BatteryLifeResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['BatteryLifeResponse'] = ResolversParentTypes['BatteryLifeResponse'],
> = {
  communicationCounter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaCertificateResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CaCertificate'] = ResolversParentTypes['CaCertificate'],
> = {
  date?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CognitoGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CognitoGroup'] = ResolversParentTypes['CognitoGroup'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CognitoUserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['CognitoUser'] = ResolversParentTypes['CognitoUser'],
> = {
  deviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  sub?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company'],
> = {
  alias?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  correctedFlowUnit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pressureUnit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  region?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  temperatureUnit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  theme?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voltageUnit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  volumeUnit?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['CompanyResponse'] = ResolversParentTypes['CompanyResponse'],
> = {
  companies?: Resolver<Array<Maybe<ResolversTypes['Company']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteUtilityTagCategoryResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteUtilityTagCategoryResponse'] = ResolversParentTypes['DeleteUtilityTagCategoryResponse'],
> = {
  deletedUtilityTagCategoryId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeleteUtilityTagResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeleteUtilityTagResponse'] = ResolversParentTypes['DeleteUtilityTagResponse'],
> = {
  deletedUtilityTagId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Device'] = ResolversParentTypes['Device'],
> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['DeviceData']>, ParentType, ContextType>;
  device_group_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  group?: Resolver<Maybe<ResolversTypes['DeviceGroup']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  messages?: Resolver<Maybe<Array<Maybe<ResolversTypes['Message']>>>, ParentType, ContextType>;
  meterId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serialNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<ResolversTypes['TagTypes']>, ParentType, ContextType>;
  thresholds?: Resolver<Maybe<Array<Maybe<ResolversTypes['Threshold']>>>, ParentType, ContextType>;
  user_device?: Resolver<Maybe<ResolversTypes['UserDevice']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceDataResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceData'] = ResolversParentTypes['DeviceData'],
> = {
  TmZn?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ademFirmware?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bV?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  brightlyncFirmware?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cFAvg?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ccbbV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dTm?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  dayCV?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  dayUV?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fCV?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fUV?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ingestionTime?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  maxFC?: Resolver<Maybe<ResolversTypes['DeviceDataValue']>, ParentType, ContextType>;
  maxPr?: Resolver<Maybe<ResolversTypes['DeviceDataValue']>, ParentType, ContextType>;
  maxTemp?: Resolver<Maybe<ResolversTypes['DeviceDataValue']>, ParentType, ContextType>;
  meterSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  minFC?: Resolver<Maybe<ResolversTypes['DeviceDataValue']>, ParentType, ContextType>;
  minPr?: Resolver<Maybe<ResolversTypes['DeviceDataValue']>, ParentType, ContextType>;
  minTemp?: Resolver<Maybe<ResolversTypes['DeviceDataValue']>, ParentType, ContextType>;
  pofMF?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  proto?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  qMarg?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceDataItemResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeviceDataItem'] = ResolversParentTypes['DeviceDataItem'],
> = {
  data?: Resolver<Array<Maybe<ResolversTypes['MeterDataItem']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceDataValueResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeviceDataValue'] = ResolversParentTypes['DeviceDataValue'],
> = {
  t?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  v?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceGroup'] = ResolversParentTypes['DeviceGroup'],
> = {
  correctedFlowUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pressureUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  temperatureUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  utility?: Resolver<ResolversTypes['Utility'], ParentType, ContextType>;
  utility_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  voltageUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  volumeUnit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceListResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceList'] = ResolversParentTypes['DeviceList'],
> = {
  count?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  rows?: Resolver<Array<Maybe<ResolversTypes['Device']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceOwnerResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['DeviceOwner'] = ResolversParentTypes['DeviceOwner'],
> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceWithPredefinedTagsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeviceWithPredefinedTags'] = ResolversParentTypes['DeviceWithPredefinedTags'],
> = {
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  predefinedTags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeviceWithUtilityTagsResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['DeviceWithUtilityTags'] = ResolversParentTypes['DeviceWithUtilityTags'],
> = {
  deviceId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  utilityTags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportEventsResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExportEventsResponse'] = ResolversParentTypes['ExportEventsResponse'],
> = {
  link?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExportResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['ExportResponse'] = ResolversParentTypes['ExportResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FirmwareFileResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['FirmwareFile'] = ResolversParentTypes['FirmwareFile'],
> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllPredefinedTagCategoriesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllPredefinedTagCategoriesResponse'] = ResolversParentTypes['GetAllPredefinedTagCategoriesResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  predefinedTagCategories?: Resolver<
    Maybe<Array<ResolversTypes['TagCategory']>>,
    ParentType,
    ContextType
  >;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllTagCategoriesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllTagCategoriesResponse'] = ResolversParentTypes['GetAllTagCategoriesResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  predefinedTagCategories?: Resolver<
    Maybe<Array<ResolversTypes['TagCategory']>>,
    ParentType,
    ContextType
  >;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  utilityTagCategoriesPerUtility?: Resolver<
    Maybe<Array<ResolversTypes['TagCategoryByUtility']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetAllUtilityTagCategoriesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetAllUtilityTagCategoriesResponse'] = ResolversParentTypes['GetAllUtilityTagCategoriesResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  utilityTagCategoriesPerUtility?: Resolver<
    Maybe<Array<ResolversTypes['TagCategoryByUtility']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetPredefinedTagsForDevicesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetPredefinedTagsForDevicesResponse'] = ResolversParentTypes['GetPredefinedTagsForDevicesResponse'],
> = {
  devicesWithPredefinedTags?: Resolver<
    Array<Maybe<ResolversTypes['DeviceWithPredefinedTags']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetTagsForDevicesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetTagsForDevicesResponse'] = ResolversParentTypes['GetTagsForDevicesResponse'],
> = {
  devicesWithPredefinedTags?: Resolver<
    Array<Maybe<ResolversTypes['DeviceWithPredefinedTags']>>,
    ParentType,
    ContextType
  >;
  devicesWithUtilityTags?: Resolver<
    Array<Maybe<ResolversTypes['DeviceWithUtilityTags']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetUtilityTagsForDevicesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['GetUtilityTagsForDevicesResponse'] = ResolversParentTypes['GetUtilityTagsForDevicesResponse'],
> = {
  devicesWithUtilityTags?: Resolver<
    Array<Maybe<ResolversTypes['DeviceWithUtilityTags']>>,
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group'],
> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupsReponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['GroupsReponse'] = ResolversParentTypes['GroupsReponse'],
> = {
  groups?: Resolver<Array<Maybe<ResolversTypes['Group']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IGetAllPredefinedTagCategoriesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['IGetAllPredefinedTagCategoriesResponse'] = ResolversParentTypes['IGetAllPredefinedTagCategoriesResponse'],
> = {
  __resolveType: TypeResolveFn<
    'GetAllPredefinedTagCategoriesResponse' | 'GetAllTagCategoriesResponse',
    ParentType,
    ContextType
  >;
  predefinedTagCategories?: Resolver<
    Maybe<Array<ResolversTypes['TagCategory']>>,
    ParentType,
    ContextType
  >;
};

export type IGetAllUtilityTagCategoriesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['IGetAllUtilityTagCategoriesResponse'] = ResolversParentTypes['IGetAllUtilityTagCategoriesResponse'],
> = {
  __resolveType: TypeResolveFn<
    'GetAllTagCategoriesResponse' | 'GetAllUtilityTagCategoriesResponse',
    ParentType,
    ContextType
  >;
  utilityTagCategoriesPerUtility?: Resolver<
    Maybe<Array<ResolversTypes['TagCategoryByUtility']>>,
    ParentType,
    ContextType
  >;
};

export type IGetPredefinedTagsForDevicesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['IGetPredefinedTagsForDevicesResponse'] = ResolversParentTypes['IGetPredefinedTagsForDevicesResponse'],
> = {
  __resolveType: TypeResolveFn<
    'GetPredefinedTagsForDevicesResponse' | 'GetTagsForDevicesResponse',
    ParentType,
    ContextType
  >;
  devicesWithPredefinedTags?: Resolver<
    Array<Maybe<ResolversTypes['DeviceWithPredefinedTags']>>,
    ParentType,
    ContextType
  >;
};

export type IGetUtilityTagsForDevicesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['IGetUtilityTagsForDevicesResponse'] = ResolversParentTypes['IGetUtilityTagsForDevicesResponse'],
> = {
  __resolveType: TypeResolveFn<
    'GetTagsForDevicesResponse' | 'GetUtilityTagsForDevicesResponse',
    ParentType,
    ContextType
  >;
  devicesWithUtilityTags?: Resolver<
    Array<Maybe<ResolversTypes['DeviceWithUtilityTags']>>,
    ParentType,
    ContextType
  >;
};

export type IResponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['IResponse'] = ResolversParentTypes['IResponse'],
> = {
  __resolveType: TypeResolveFn<
    | 'AddTagToDevicesResponse'
    | 'AddUtilityTagCategoriesResponse'
    | 'AddUtilityTagsResponse'
    | 'DeleteUtilityTagCategoryResponse'
    | 'DeleteUtilityTagResponse'
    | 'GetAllPredefinedTagCategoriesResponse'
    | 'GetAllTagCategoriesResponse'
    | 'GetAllUtilityTagCategoriesResponse'
    | 'GetPredefinedTagsForDevicesResponse'
    | 'GetTagsForDevicesResponse'
    | 'GetUtilityTagsForDevicesResponse'
    | 'RemoveTagFromDevicesResponse'
    | 'SuccessResponse'
    | 'UpdateUtilityTagCategoryResponse'
    | 'UpdateUtilityTagResponse',
    ParentType,
    ContextType
  >;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
};

export type LogoResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Logo'] = ResolversParentTypes['Logo'],
> = {
  file?: Resolver<ResolversTypes['S3Object'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LongScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Long'], any> {
  name: 'Long';
}

export type MessageResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Message'] = ResolversParentTypes['Message'],
> = {
  alarms?: Resolver<Maybe<Array<Maybe<ResolversTypes['Alarm']>>>, ParentType, ContextType>;
  comment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['Long'], ParentType, ContextType>;
  urgent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MeterDataItemResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['MeterDataItem'] = ResolversParentTypes['MeterDataItem'],
> = {
  bV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cF?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  cV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ccbbV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dayCV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dayUV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  deviceId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fCV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fUV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxFC?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxPr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  maxTemp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minFC?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minPr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minTemp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pofMF?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pr?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  qMarg?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  temp?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uV?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation'],
> = {
  addDevice?: Resolver<
    ResolversTypes['Device'],
    ParentType,
    ContextType,
    RequireFields<MutationAddDeviceArgs, 'deviceId' | 'groupId' | 'meterId'>
  >;
  addTagToDevices?: Resolver<
    ResolversTypes['AddTagToDevicesResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddTagToDevicesArgs, 'deviceIds' | 'tagCategoryId' | 'tagId' | 'tagType'>
  >;
  addToGroup?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationAddToGroupArgs, 'group' | 'id' | 'username'>
  >;
  addUtilityTagCategories?: Resolver<
    ResolversTypes['AddUtilityTagCategoriesResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddUtilityTagCategoriesArgs, 'newUtilityTagCategories'>
  >;
  addUtilityTags?: Resolver<
    ResolversTypes['AddUtilityTagsResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationAddUtilityTagsArgs, 'newUtilityTags' | 'utilityTagCategoryId'>
  >;
  changeUserDevice?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationChangeUserDeviceArgs, 'deviceId' | 'userId'>
  >;
  confirmForgotPassword?: Resolver<
    ResolversTypes['SuccessResponsePublic'],
    ParentType,
    ContextType,
    RequireFields<MutationConfirmForgotPasswordArgs, 'code' | 'password' | 'username'>
  >;
  createUser?: Resolver<
    Maybe<ResolversTypes['String']>,
    ParentType,
    ContextType,
    RequireFields<MutationCreateUserArgs, 'email' | 'groups'>
  >;
  deleteUtilityTag?: Resolver<
    ResolversTypes['DeleteUtilityTagResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUtilityTagArgs, 'utilityTagCategoryId' | 'utilityTagId'>
  >;
  deleteUtilityTagCategory?: Resolver<
    ResolversTypes['DeleteUtilityTagCategoryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeleteUtilityTagCategoryArgs, 'utilityTagCategoryId'>
  >;
  deviceJobManager?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDeviceJobManagerArgs, 'operation'>
  >;
  disableMfa?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationDisableMfaArgs, 'challengeAnswer'>
  >;
  enableMfa?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationEnableMfaArgs, 'password'>
  >;
  forgotPassword?: Resolver<
    ResolversTypes['SuccessResponsePublic'],
    ParentType,
    ContextType,
    RequireFields<MutationForgotPasswordArgs, 'username'>
  >;
  importUsers?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationImportUsersArgs, 'file'>
  >;
  removeDevice?: Resolver<
    ResolversTypes['RemoveDeviceReponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveDeviceArgs, 'deviceId'>
  >;
  removeFromGroup?: Resolver<
    Maybe<ResolversTypes['Boolean']>,
    ParentType,
    ContextType,
    RequireFields<MutationRemoveFromGroupArgs, 'group' | 'id' | 'username'>
  >;
  removeTagFromDevices?: Resolver<
    ResolversTypes['RemoveTagFromDevicesResponse'],
    ParentType,
    ContextType,
    RequireFields<
      MutationRemoveTagFromDevicesArgs,
      'deviceIds' | 'tagCategoryId' | 'tagId' | 'tagType'
    >
  >;
  removeUser?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationRemoveUserArgs, 'username'>
  >;
  resetDeviceBatteryCounter?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationResetDeviceBatteryCounterArgs, 'deviceId'>
  >;
  setThresholds?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationSetThresholdsArgs, 'deviceIds' | 'thresholds'>
  >;
  updateAlarmComment?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAlarmCommentArgs, 'alarmId' | 'comment' | 'time'>
  >;
  updateAlertComment?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAlertCommentArgs, 'id' | 'status' | 'time'>
  >;
  updateAlertStatus?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateAlertStatusArgs, 'id' | 'status' | 'time'>
  >;
  updateCompany?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateCompanyArgs, 'id'>
  >;
  updateDeviceGroup?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDeviceGroupArgs, 'id'>
  >;
  updateDeviceShadow?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateDeviceShadowArgs, 'deviceId' | 'payload' | 'region'>
  >;
  updateUtilityTag?: Resolver<
    ResolversTypes['UpdateUtilityTagResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUtilityTagArgs, 'updatedUtilityTag' | 'utilityTagCategoryId'>
  >;
  updateUtilityTagCategory?: Resolver<
    ResolversTypes['UpdateUtilityTagCategoryResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationUpdateUtilityTagCategoryArgs, 'updatedUtilityTagCategory'>
  >;
  verifyUserEmail?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<MutationVerifyUserEmailArgs, 'code'>
  >;
};

export type QueryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query'],
> = {
  authenticateUser?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryAuthenticateUserArgs, 'email' | 'password'>
  >;
  exportBatteryLifeReport?: Resolver<ResolversTypes['ExportResponse'], ParentType, ContextType>;
  exportEvents?: Resolver<
    ResolversTypes['ExportEventsResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryExportEventsArgs, 'deviceId' | 'format' | 'fromDate' | 'toDate'>
  >;
  exportMeterData?: Resolver<
    ResolversTypes['ExportResponse'],
    ParentType,
    ContextType,
    RequireFields<
      QueryExportMeterDataArgs,
      'deviceIds' | 'format' | 'frequency' | 'fromDate' | 'toDate'
    >
  >;
  getAlarmComment?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAlarmCommentArgs, 'alarmId' | 'time'>
  >;
  getAllPredefinedTagCategories?: Resolver<
    ResolversTypes['GetAllPredefinedTagCategoriesResponse'],
    ParentType,
    ContextType
  >;
  getAllTagCategories?: Resolver<
    ResolversTypes['GetAllTagCategoriesResponse'],
    ParentType,
    ContextType
  >;
  getAllUtilityTagCategories?: Resolver<
    ResolversTypes['GetAllUtilityTagCategoriesResponse'],
    ParentType,
    ContextType
  >;
  getAssociatedSecretCode?: Resolver<
    ResolversTypes['SecretCode'],
    ParentType,
    ContextType,
    RequireFields<QueryGetAssociatedSecretCodeArgs, 'password'>
  >;
  getBatteryLife?: Resolver<
    ResolversTypes['BatteryLifeResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetBatteryLifeArgs, 'deviceId'>
  >;
  getCompaniesByGroups?: Resolver<
    ResolversTypes['CompanyResponse'],
    ParentType,
    ContextType,
    Partial<QueryGetCompaniesByGroupsArgs>
  >;
  getDeviceGroups?: Resolver<Array<Maybe<ResolversTypes['DeviceGroup']>>, ParentType, ContextType>;
  getDeviceShadow?: Resolver<
    ResolversTypes['String'],
    ParentType,
    ContextType,
    RequireFields<QueryGetDeviceShadowArgs, 'deviceId' | 'region'>
  >;
  getFirmware?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['FirmwareFile']>>>,
    ParentType,
    ContextType,
    Partial<QueryGetFirmwareArgs>
  >;
  getMetersetImage?: Resolver<
    Maybe<ResolversTypes['SignedUrlResponse']>,
    ParentType,
    ContextType,
    RequireFields<QueryGetMetersetImageArgs, 'deviceId'>
  >;
  getPredefinedTagsForDevices?: Resolver<
    ResolversTypes['GetPredefinedTagsForDevicesResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetPredefinedTagsForDevicesArgs, 'deviceIds'>
  >;
  getStatsLogData?: Resolver<
    Array<Maybe<ResolversTypes['DeviceDataItem']>>,
    ParentType,
    ContextType,
    RequireFields<QueryGetStatsLogDataArgs, 'fromDate' | 'toDate'>
  >;
  getTagsForDevices?: Resolver<
    ResolversTypes['GetTagsForDevicesResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetTagsForDevicesArgs, 'deviceIds'>
  >;
  getUtilityTagsForDevices?: Resolver<
    ResolversTypes['GetUtilityTagsForDevicesResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryGetUtilityTagsForDevicesArgs, 'deviceIds'>
  >;
  groupList?: Resolver<ResolversTypes['GroupsReponse'], ParentType, ContextType>;
  groupListForUsers?: Resolver<
    Array<Maybe<ResolversTypes['UserGroup']>>,
    ParentType,
    ContextType,
    Partial<QueryGroupListForUsersArgs>
  >;
  listCaCertificate?: Resolver<
    Array<Maybe<ResolversTypes['CaCertificate']>>,
    ParentType,
    ContextType
  >;
  listCompanies?: Resolver<Array<Maybe<ResolversTypes['Company']>>, ParentType, ContextType>;
  listDevices?: Resolver<
    ResolversTypes['DeviceList'],
    ParentType,
    ContextType,
    Partial<QueryListDevicesArgs>
  >;
  listFirmware?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  listFirmwareVersion?: Resolver<
    Maybe<Array<Maybe<ResolversTypes['String']>>>,
    ParentType,
    ContextType,
    Partial<QueryListFirmwareVersionArgs>
  >;
  listUnassignedDevices?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  requestExportAlarmLogs?: Resolver<
    ResolversTypes['ExportResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryRequestExportAlarmLogsArgs, 'deviceIds' | 'format' | 'fromDate' | 'toDate'>
  >;
  requestExportMeterHealthReport?: Resolver<
    ResolversTypes['ExportResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryRequestExportMeterHealthReportArgs, 'fromDate' | 'toDate'>
  >;
  userByUsername?: Resolver<
    ResolversTypes['CognitoUser'],
    ParentType,
    ContextType,
    RequireFields<QueryUserByUsernameArgs, 'username'>
  >;
  userList?: Resolver<
    ResolversTypes['UsersReponse'],
    ParentType,
    ContextType,
    Partial<QueryUserListArgs>
  >;
  verifyTotp?: Resolver<
    ResolversTypes['SuccessResponse'],
    ParentType,
    ContextType,
    RequireFields<QueryVerifyTotpArgs, 'challengeAnswer'>
  >;
};

export type RemoveDeviceReponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveDeviceReponse'] = ResolversParentTypes['RemoveDeviceReponse'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RemoveTagFromDevicesResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['RemoveTagFromDevicesResponse'] = ResolversParentTypes['RemoveTagFromDevicesResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type S3ObjectResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['S3Object'] = ResolversParentTypes['S3Object'],
> = {
  bucket?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SecretCodeResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['SecretCode'] = ResolversParentTypes['SecretCode'],
> = {
  secretCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SignedUrlResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SignedUrlResponse'] = ResolversParentTypes['SignedUrlResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  signedUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuccessResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SuccessResponse'] = ResolversParentTypes['SuccessResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuccessResponsePublicResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['SuccessResponsePublic'] = ResolversParentTypes['SuccessResponsePublic'],
> = {
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Tag'] = ResolversParentTypes['Tag'],
> = {
  category_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagCategoryResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagCategory'] = ResolversParentTypes['TagCategory'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagCategoryByUtilityResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['TagCategoryByUtility'] = ResolversParentTypes['TagCategoryByUtility'],
> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  utilityTagCategories?: Resolver<
    Maybe<Array<ResolversTypes['TagCategory']>>,
    ParentType,
    ContextType
  >;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TagTypesResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['TagTypes'] = ResolversParentTypes['TagTypes'],
> = {
  predefinedTags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  utilityTags?: Resolver<Maybe<Array<ResolversTypes['Tag']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ThresholdResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Threshold'] = ResolversParentTypes['Threshold'],
> = {
  device_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  threshold_key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUtilityTagCategoryResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateUtilityTagCategoryResponse'] = ResolversParentTypes['UpdateUtilityTagCategoryResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedUtilityTagCategory?: Resolver<ResolversTypes['TagCategory'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UpdateUtilityTagResponseResolvers<
  ContextType = any,
  ParentType extends
    ResolversParentTypes['UpdateUtilityTagResponse'] = ResolversParentTypes['UpdateUtilityTagResponse'],
> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedUtilityTag?: Resolver<ResolversTypes['Tag'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User'],
> = {
  groups?: Resolver<Maybe<Array<Maybe<ResolversTypes['CognitoGroup']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserDeviceResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserDevice'] = ResolversParentTypes['UserDevice'],
> = {
  device_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['DeviceOwner']>, ParentType, ContextType>;
  user_id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserGroupResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UserGroup'] = ResolversParentTypes['UserGroup'],
> = {
  groups?: Resolver<Maybe<Array<Maybe<ResolversTypes['CognitoGroup']>>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersReponseResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['UsersReponse'] = ResolversParentTypes['UsersReponse'],
> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  users?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UtilityResolvers<
  ContextType = any,
  ParentType extends ResolversParentTypes['Utility'] = ResolversParentTypes['Utility'],
> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  company_id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AddTagToDevicesResponse?: AddTagToDevicesResponseResolvers<ContextType>;
  AddUtilityTagCategoriesResponse?: AddUtilityTagCategoriesResponseResolvers<ContextType>;
  AddUtilityTagsResponse?: AddUtilityTagsResponseResolvers<ContextType>;
  Alarm?: AlarmResolvers<ContextType>;
  BatteryLifeResponse?: BatteryLifeResponseResolvers<ContextType>;
  CaCertificate?: CaCertificateResolvers<ContextType>;
  CognitoGroup?: CognitoGroupResolvers<ContextType>;
  CognitoUser?: CognitoUserResolvers<ContextType>;
  Company?: CompanyResolvers<ContextType>;
  CompanyResponse?: CompanyResponseResolvers<ContextType>;
  DeleteUtilityTagCategoryResponse?: DeleteUtilityTagCategoryResponseResolvers<ContextType>;
  DeleteUtilityTagResponse?: DeleteUtilityTagResponseResolvers<ContextType>;
  Device?: DeviceResolvers<ContextType>;
  DeviceData?: DeviceDataResolvers<ContextType>;
  DeviceDataItem?: DeviceDataItemResolvers<ContextType>;
  DeviceDataValue?: DeviceDataValueResolvers<ContextType>;
  DeviceGroup?: DeviceGroupResolvers<ContextType>;
  DeviceList?: DeviceListResolvers<ContextType>;
  DeviceOwner?: DeviceOwnerResolvers<ContextType>;
  DeviceWithPredefinedTags?: DeviceWithPredefinedTagsResolvers<ContextType>;
  DeviceWithUtilityTags?: DeviceWithUtilityTagsResolvers<ContextType>;
  ExportEventsResponse?: ExportEventsResponseResolvers<ContextType>;
  ExportResponse?: ExportResponseResolvers<ContextType>;
  FirmwareFile?: FirmwareFileResolvers<ContextType>;
  GetAllPredefinedTagCategoriesResponse?: GetAllPredefinedTagCategoriesResponseResolvers<ContextType>;
  GetAllTagCategoriesResponse?: GetAllTagCategoriesResponseResolvers<ContextType>;
  GetAllUtilityTagCategoriesResponse?: GetAllUtilityTagCategoriesResponseResolvers<ContextType>;
  GetPredefinedTagsForDevicesResponse?: GetPredefinedTagsForDevicesResponseResolvers<ContextType>;
  GetTagsForDevicesResponse?: GetTagsForDevicesResponseResolvers<ContextType>;
  GetUtilityTagsForDevicesResponse?: GetUtilityTagsForDevicesResponseResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  GroupsReponse?: GroupsReponseResolvers<ContextType>;
  IGetAllPredefinedTagCategoriesResponse?: IGetAllPredefinedTagCategoriesResponseResolvers<ContextType>;
  IGetAllUtilityTagCategoriesResponse?: IGetAllUtilityTagCategoriesResponseResolvers<ContextType>;
  IGetPredefinedTagsForDevicesResponse?: IGetPredefinedTagsForDevicesResponseResolvers<ContextType>;
  IGetUtilityTagsForDevicesResponse?: IGetUtilityTagsForDevicesResponseResolvers<ContextType>;
  IResponse?: IResponseResolvers<ContextType>;
  Logo?: LogoResolvers<ContextType>;
  Long?: GraphQLScalarType;
  Message?: MessageResolvers<ContextType>;
  MeterDataItem?: MeterDataItemResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RemoveDeviceReponse?: RemoveDeviceReponseResolvers<ContextType>;
  RemoveTagFromDevicesResponse?: RemoveTagFromDevicesResponseResolvers<ContextType>;
  S3Object?: S3ObjectResolvers<ContextType>;
  SecretCode?: SecretCodeResolvers<ContextType>;
  SignedUrlResponse?: SignedUrlResponseResolvers<ContextType>;
  SuccessResponse?: SuccessResponseResolvers<ContextType>;
  SuccessResponsePublic?: SuccessResponsePublicResolvers<ContextType>;
  Tag?: TagResolvers<ContextType>;
  TagCategory?: TagCategoryResolvers<ContextType>;
  TagCategoryByUtility?: TagCategoryByUtilityResolvers<ContextType>;
  TagTypes?: TagTypesResolvers<ContextType>;
  Threshold?: ThresholdResolvers<ContextType>;
  UpdateUtilityTagCategoryResponse?: UpdateUtilityTagCategoryResponseResolvers<ContextType>;
  UpdateUtilityTagResponse?: UpdateUtilityTagResponseResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserDevice?: UserDeviceResolvers<ContextType>;
  UserGroup?: UserGroupResolvers<ContextType>;
  UsersReponse?: UsersReponseResolvers<ContextType>;
  Utility?: UtilityResolvers<ContextType>;
};

export type DirectiveResolvers<ContextType = any> = {
  aws_api_key?: Aws_Api_KeyDirectiveResolver<any, any, ContextType>;
  aws_auth?: Aws_AuthDirectiveResolver<any, any, ContextType>;
  aws_cognito_user_pools?: Aws_Cognito_User_PoolsDirectiveResolver<any, any, ContextType>;
  aws_iam?: Aws_IamDirectiveResolver<any, any, ContextType>;
  aws_lambda?: Aws_LambdaDirectiveResolver<any, any, ContextType>;
  aws_oidc?: Aws_OidcDirectiveResolver<any, any, ContextType>;
  aws_publish?: Aws_PublishDirectiveResolver<any, any, ContextType>;
  aws_subscribe?: Aws_SubscribeDirectiveResolver<any, any, ContextType>;
  defer?: DeferDirectiveResolver<any, any, ContextType>;
};

export const CreateUserDocument = gql`
  mutation CreateUser($email: String!, $deviceId: String, $groups: [String]!) {
    createUser(email: $email, deviceId: $deviceId, groups: $groups)
  }
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      deviceId: // value for 'deviceId'
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    options
  );
}
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const ImportUsersDocument = gql`
  mutation ImportUsers($file: String!) {
    importUsers(file: $file) {
      success
      message
      __typename
    }
  }
`;
export type ImportUsersMutationFn = Apollo.MutationFunction<
  ImportUsersMutation,
  ImportUsersMutationVariables
>;

/**
 * __useImportUsersMutation__
 *
 * To run a mutation, you first call `useImportUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importUsersMutation, { data, loading, error }] = useImportUsersMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<ImportUsersMutation, ImportUsersMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ImportUsersMutation, ImportUsersMutationVariables>(
    ImportUsersDocument,
    options
  );
}
export type ImportUsersMutationHookResult = ReturnType<typeof useImportUsersMutation>;
export type ImportUsersMutationResult = Apollo.MutationResult<ImportUsersMutation>;
export type ImportUsersMutationOptions = Apollo.BaseMutationOptions<
  ImportUsersMutation,
  ImportUsersMutationVariables
>;
export const AddToGroupDocument = gql`
  mutation AddToGroup($id: String!, $username: String!, $group: String!) {
    addToGroup(id: $id, username: $username, group: $group)
  }
`;
export type AddToGroupMutationFn = Apollo.MutationFunction<
  AddToGroupMutation,
  AddToGroupMutationVariables
>;

/**
 * __useAddToGroupMutation__
 *
 * To run a mutation, you first call `useAddToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToGroupMutation, { data, loading, error }] = useAddToGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAddToGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToGroupMutation, AddToGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToGroupMutation, AddToGroupMutationVariables>(
    AddToGroupDocument,
    options
  );
}
export type AddToGroupMutationHookResult = ReturnType<typeof useAddToGroupMutation>;
export type AddToGroupMutationResult = Apollo.MutationResult<AddToGroupMutation>;
export type AddToGroupMutationOptions = Apollo.BaseMutationOptions<
  AddToGroupMutation,
  AddToGroupMutationVariables
>;
export const RemoveFromGroupDocument = gql`
  mutation RemoveFromGroup($id: String!, $username: String!, $group: String!) {
    removeFromGroup(id: $id, username: $username, group: $group)
  }
`;
export type RemoveFromGroupMutationFn = Apollo.MutationFunction<
  RemoveFromGroupMutation,
  RemoveFromGroupMutationVariables
>;

/**
 * __useRemoveFromGroupMutation__
 *
 * To run a mutation, you first call `useRemoveFromGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromGroupMutation, { data, loading, error }] = useRemoveFromGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      username: // value for 'username'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useRemoveFromGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFromGroupMutation,
    RemoveFromGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveFromGroupMutation, RemoveFromGroupMutationVariables>(
    RemoveFromGroupDocument,
    options
  );
}
export type RemoveFromGroupMutationHookResult = ReturnType<typeof useRemoveFromGroupMutation>;
export type RemoveFromGroupMutationResult = Apollo.MutationResult<RemoveFromGroupMutation>;
export type RemoveFromGroupMutationOptions = Apollo.BaseMutationOptions<
  RemoveFromGroupMutation,
  RemoveFromGroupMutationVariables
>;
export const AddDeviceDocument = gql`
  mutation AddDevice(
    $deviceId: String!
    $groupId: String!
    $meterId: String!
    $latitude: String
    $longitude: String
    $address: String
    $street: String
    $city: String
    $postalCode: String
    $country: String
  ) {
    addDevice(
      deviceId: $deviceId
      groupId: $groupId
      meterId: $meterId
      latitude: $latitude
      longitude: $longitude
      address: $address
      street: $street
      city: $city
      postalCode: $postalCode
      country: $country
    ) {
      id
      device_group_id
      meterId
      serialNo
      group {
        id
        name
        utility_id
        utility {
          id
          name
          company_id
          company {
            id
            name
            alias
            theme
            logo
            region
            temperatureUnit
            pressureUnit
            volumeUnit
            voltageUnit
            correctedFlowUnit
            __typename
          }
          __typename
        }
        temperatureUnit
        pressureUnit
        volumeUnit
        voltageUnit
        correctedFlowUnit
        __typename
      }
      user_device {
        user_id
        device_id
        user {
          id
          name
          email
          __typename
        }
        __typename
      }
      latitude
      longitude
      address
      street
      city
      postalCode
      country
      messages {
        id
        time
        urgent
        dId
        status
        comment
        alarms {
          lmt
          threshold
          t
          type
          v
          __typename
        }
        __typename
      }
      tags {
        predefinedTags {
          id
          name
          category_id
          __typename
        }
        utilityTags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      thresholds {
        device_id
        threshold_key
        value
        __typename
      }
      data {
        meterSize
        ademFirmware
        brightlyncFirmware
        ingestionTime
        dTm
        TmZn
        proto
        bV
        ccbbV
        dayCV
        dayUV
        fUV
        fCV
        cFAvg
        maxFC {
          t
          v
          __typename
        }
        maxPr {
          t
          v
          __typename
        }
        maxTemp {
          t
          v
          __typename
        }
        minFC {
          t
          v
          __typename
        }
        minPr {
          t
          v
          __typename
        }
        minTemp {
          t
          v
          __typename
        }
        pofMF
        qMarg
        __typename
      }
      __typename
    }
  }
`;
export type AddDeviceMutationFn = Apollo.MutationFunction<
  AddDeviceMutation,
  AddDeviceMutationVariables
>;

/**
 * __useAddDeviceMutation__
 *
 * To run a mutation, you first call `useAddDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addDeviceMutation, { data, loading, error }] = useAddDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      groupId: // value for 'groupId'
 *      meterId: // value for 'meterId'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      address: // value for 'address'
 *      street: // value for 'street'
 *      city: // value for 'city'
 *      postalCode: // value for 'postalCode'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useAddDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<AddDeviceMutation, AddDeviceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddDeviceMutation, AddDeviceMutationVariables>(
    AddDeviceDocument,
    options
  );
}
export type AddDeviceMutationHookResult = ReturnType<typeof useAddDeviceMutation>;
export type AddDeviceMutationResult = Apollo.MutationResult<AddDeviceMutation>;
export type AddDeviceMutationOptions = Apollo.BaseMutationOptions<
  AddDeviceMutation,
  AddDeviceMutationVariables
>;
export const RemoveDeviceDocument = gql`
  mutation RemoveDevice($deviceId: String!) {
    removeDevice(deviceId: $deviceId) {
      id
      __typename
    }
  }
`;
export type RemoveDeviceMutationFn = Apollo.MutationFunction<
  RemoveDeviceMutation,
  RemoveDeviceMutationVariables
>;

/**
 * __useRemoveDeviceMutation__
 *
 * To run a mutation, you first call `useRemoveDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDeviceMutation, { data, loading, error }] = useRemoveDeviceMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useRemoveDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveDeviceMutation, RemoveDeviceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveDeviceMutation, RemoveDeviceMutationVariables>(
    RemoveDeviceDocument,
    options
  );
}
export type RemoveDeviceMutationHookResult = ReturnType<typeof useRemoveDeviceMutation>;
export type RemoveDeviceMutationResult = Apollo.MutationResult<RemoveDeviceMutation>;
export type RemoveDeviceMutationOptions = Apollo.BaseMutationOptions<
  RemoveDeviceMutation,
  RemoveDeviceMutationVariables
>;
export const ChangeUserDeviceDocument = gql`
  mutation ChangeUserDevice($userId: String!, $deviceId: String!) {
    changeUserDevice(userId: $userId, deviceId: $deviceId) {
      success
      message
      __typename
    }
  }
`;
export type ChangeUserDeviceMutationFn = Apollo.MutationFunction<
  ChangeUserDeviceMutation,
  ChangeUserDeviceMutationVariables
>;

/**
 * __useChangeUserDeviceMutation__
 *
 * To run a mutation, you first call `useChangeUserDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserDeviceMutation, { data, loading, error }] = useChangeUserDeviceMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useChangeUserDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeUserDeviceMutation,
    ChangeUserDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeUserDeviceMutation, ChangeUserDeviceMutationVariables>(
    ChangeUserDeviceDocument,
    options
  );
}
export type ChangeUserDeviceMutationHookResult = ReturnType<typeof useChangeUserDeviceMutation>;
export type ChangeUserDeviceMutationResult = Apollo.MutationResult<ChangeUserDeviceMutation>;
export type ChangeUserDeviceMutationOptions = Apollo.BaseMutationOptions<
  ChangeUserDeviceMutation,
  ChangeUserDeviceMutationVariables
>;
export const EnableMfaDocument = gql`
  mutation EnableMfa($password: String!) {
    enableMfa(password: $password) {
      success
      message
      __typename
    }
  }
`;
export type EnableMfaMutationFn = Apollo.MutationFunction<
  EnableMfaMutation,
  EnableMfaMutationVariables
>;

/**
 * __useEnableMfaMutation__
 *
 * To run a mutation, you first call `useEnableMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableMfaMutation, { data, loading, error }] = useEnableMfaMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useEnableMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<EnableMfaMutation, EnableMfaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnableMfaMutation, EnableMfaMutationVariables>(
    EnableMfaDocument,
    options
  );
}
export type EnableMfaMutationHookResult = ReturnType<typeof useEnableMfaMutation>;
export type EnableMfaMutationResult = Apollo.MutationResult<EnableMfaMutation>;
export type EnableMfaMutationOptions = Apollo.BaseMutationOptions<
  EnableMfaMutation,
  EnableMfaMutationVariables
>;
export const DisableMfaDocument = gql`
  mutation DisableMfa($challengeAnswer: String!) {
    disableMfa(challengeAnswer: $challengeAnswer) {
      success
      message
      __typename
    }
  }
`;
export type DisableMfaMutationFn = Apollo.MutationFunction<
  DisableMfaMutation,
  DisableMfaMutationVariables
>;

/**
 * __useDisableMfaMutation__
 *
 * To run a mutation, you first call `useDisableMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableMfaMutation, { data, loading, error }] = useDisableMfaMutation({
 *   variables: {
 *      challengeAnswer: // value for 'challengeAnswer'
 *   },
 * });
 */
export function useDisableMfaMutation(
  baseOptions?: Apollo.MutationHookOptions<DisableMfaMutation, DisableMfaMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DisableMfaMutation, DisableMfaMutationVariables>(
    DisableMfaDocument,
    options
  );
}
export type DisableMfaMutationHookResult = ReturnType<typeof useDisableMfaMutation>;
export type DisableMfaMutationResult = Apollo.MutationResult<DisableMfaMutation>;
export type DisableMfaMutationOptions = Apollo.BaseMutationOptions<
  DisableMfaMutation,
  DisableMfaMutationVariables
>;
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany(
    $id: String!
    $theme: String
    $logo: String
    $type: String
    $temperatureUnit: String
    $pressureUnit: String
    $volumeUnit: String
    $voltageUnit: String
    $correctedFlowUnit: String
  ) {
    updateCompany(
      id: $id
      theme: $theme
      logo: $logo
      type: $type
      temperatureUnit: $temperatureUnit
      pressureUnit: $pressureUnit
      volumeUnit: $volumeUnit
      voltageUnit: $voltageUnit
      correctedFlowUnit: $correctedFlowUnit
    ) {
      success
      message
      __typename
    }
  }
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      theme: // value for 'theme'
 *      logo: // value for 'logo'
 *      type: // value for 'type'
 *      temperatureUnit: // value for 'temperatureUnit'
 *      pressureUnit: // value for 'pressureUnit'
 *      volumeUnit: // value for 'volumeUnit'
 *      voltageUnit: // value for 'voltageUnit'
 *      correctedFlowUnit: // value for 'correctedFlowUnit'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
    UpdateCompanyDocument,
    options
  );
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const UpdateDeviceGroupDocument = gql`
  mutation UpdateDeviceGroup(
    $id: String!
    $temperatureUnit: String
    $pressureUnit: String
    $volumeUnit: String
    $voltageUnit: String
    $correctedFlowUnit: String
  ) {
    updateDeviceGroup(
      id: $id
      temperatureUnit: $temperatureUnit
      pressureUnit: $pressureUnit
      volumeUnit: $volumeUnit
      voltageUnit: $voltageUnit
      correctedFlowUnit: $correctedFlowUnit
    ) {
      success
      message
      __typename
    }
  }
`;
export type UpdateDeviceGroupMutationFn = Apollo.MutationFunction<
  UpdateDeviceGroupMutation,
  UpdateDeviceGroupMutationVariables
>;

/**
 * __useUpdateDeviceGroupMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceGroupMutation, { data, loading, error }] = useUpdateDeviceGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      temperatureUnit: // value for 'temperatureUnit'
 *      pressureUnit: // value for 'pressureUnit'
 *      volumeUnit: // value for 'volumeUnit'
 *      voltageUnit: // value for 'voltageUnit'
 *      correctedFlowUnit: // value for 'correctedFlowUnit'
 *   },
 * });
 */
export function useUpdateDeviceGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDeviceGroupMutation,
    UpdateDeviceGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDeviceGroupMutation, UpdateDeviceGroupMutationVariables>(
    UpdateDeviceGroupDocument,
    options
  );
}
export type UpdateDeviceGroupMutationHookResult = ReturnType<typeof useUpdateDeviceGroupMutation>;
export type UpdateDeviceGroupMutationResult = Apollo.MutationResult<UpdateDeviceGroupMutation>;
export type UpdateDeviceGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateDeviceGroupMutation,
  UpdateDeviceGroupMutationVariables
>;
export const UpdateAlertStatusDocument = gql`
  mutation UpdateAlertStatus($id: String!, $time: Long!, $status: String!) {
    updateAlertStatus(id: $id, time: $time, status: $status) {
      success
      message
      __typename
    }
  }
`;
export type UpdateAlertStatusMutationFn = Apollo.MutationFunction<
  UpdateAlertStatusMutation,
  UpdateAlertStatusMutationVariables
>;

/**
 * __useUpdateAlertStatusMutation__
 *
 * To run a mutation, you first call `useUpdateAlertStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertStatusMutation, { data, loading, error }] = useUpdateAlertStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      time: // value for 'time'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAlertStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAlertStatusMutation,
    UpdateAlertStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAlertStatusMutation, UpdateAlertStatusMutationVariables>(
    UpdateAlertStatusDocument,
    options
  );
}
export type UpdateAlertStatusMutationHookResult = ReturnType<typeof useUpdateAlertStatusMutation>;
export type UpdateAlertStatusMutationResult = Apollo.MutationResult<UpdateAlertStatusMutation>;
export type UpdateAlertStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateAlertStatusMutation,
  UpdateAlertStatusMutationVariables
>;
export const UpdateAlertCommentDocument = gql`
  mutation UpdateAlertComment($id: String!, $time: Long!, $status: String!) {
    updateAlertComment(id: $id, time: $time, status: $status) {
      success
      message
      __typename
    }
  }
`;
export type UpdateAlertCommentMutationFn = Apollo.MutationFunction<
  UpdateAlertCommentMutation,
  UpdateAlertCommentMutationVariables
>;

/**
 * __useUpdateAlertCommentMutation__
 *
 * To run a mutation, you first call `useUpdateAlertCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlertCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlertCommentMutation, { data, loading, error }] = useUpdateAlertCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      time: // value for 'time'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateAlertCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAlertCommentMutation,
    UpdateAlertCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAlertCommentMutation, UpdateAlertCommentMutationVariables>(
    UpdateAlertCommentDocument,
    options
  );
}
export type UpdateAlertCommentMutationHookResult = ReturnType<typeof useUpdateAlertCommentMutation>;
export type UpdateAlertCommentMutationResult = Apollo.MutationResult<UpdateAlertCommentMutation>;
export type UpdateAlertCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAlertCommentMutation,
  UpdateAlertCommentMutationVariables
>;
export const SetThresholdsDocument = gql`
  mutation SetThresholds($deviceIds: [String]!, $thresholds: [ThresholdValue]!) {
    setThresholds(deviceIds: $deviceIds, thresholds: $thresholds) {
      success
      message
      __typename
    }
  }
`;
export type SetThresholdsMutationFn = Apollo.MutationFunction<
  SetThresholdsMutation,
  SetThresholdsMutationVariables
>;

/**
 * __useSetThresholdsMutation__
 *
 * To run a mutation, you first call `useSetThresholdsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetThresholdsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setThresholdsMutation, { data, loading, error }] = useSetThresholdsMutation({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *      thresholds: // value for 'thresholds'
 *   },
 * });
 */
export function useSetThresholdsMutation(
  baseOptions?: Apollo.MutationHookOptions<SetThresholdsMutation, SetThresholdsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetThresholdsMutation, SetThresholdsMutationVariables>(
    SetThresholdsDocument,
    options
  );
}
export type SetThresholdsMutationHookResult = ReturnType<typeof useSetThresholdsMutation>;
export type SetThresholdsMutationResult = Apollo.MutationResult<SetThresholdsMutation>;
export type SetThresholdsMutationOptions = Apollo.BaseMutationOptions<
  SetThresholdsMutation,
  SetThresholdsMutationVariables
>;
export const DeviceJobManagerDocument = gql`
  mutation DeviceJobManager(
    $deviceId: String
    $operation: String!
    $region: String
    $version: String
    $files: [Firmware]
    $ca: String
    $component: String
    $deviceIds: [String]
  ) {
    deviceJobManager(
      deviceId: $deviceId
      operation: $operation
      region: $region
      version: $version
      files: $files
      ca: $ca
      component: $component
      deviceIds: $deviceIds
    ) {
      success
      message
      __typename
    }
  }
`;
export type DeviceJobManagerMutationFn = Apollo.MutationFunction<
  DeviceJobManagerMutation,
  DeviceJobManagerMutationVariables
>;

/**
 * __useDeviceJobManagerMutation__
 *
 * To run a mutation, you first call `useDeviceJobManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceJobManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceJobManagerMutation, { data, loading, error }] = useDeviceJobManagerMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      operation: // value for 'operation'
 *      region: // value for 'region'
 *      version: // value for 'version'
 *      files: // value for 'files'
 *      ca: // value for 'ca'
 *      component: // value for 'component'
 *      deviceIds: // value for 'deviceIds'
 *   },
 * });
 */
export function useDeviceJobManagerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeviceJobManagerMutation,
    DeviceJobManagerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeviceJobManagerMutation, DeviceJobManagerMutationVariables>(
    DeviceJobManagerDocument,
    options
  );
}
export type DeviceJobManagerMutationHookResult = ReturnType<typeof useDeviceJobManagerMutation>;
export type DeviceJobManagerMutationResult = Apollo.MutationResult<DeviceJobManagerMutation>;
export type DeviceJobManagerMutationOptions = Apollo.BaseMutationOptions<
  DeviceJobManagerMutation,
  DeviceJobManagerMutationVariables
>;
export const ForgotPasswordDocument = gql`
  mutation ForgotPassword($username: String!) {
    forgotPassword(username: $username) {
      success
      __typename
    }
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(
    ForgotPasswordDocument,
    options
  );
}
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ConfirmForgotPasswordDocument = gql`
  mutation ConfirmForgotPassword($code: String!, $username: String!, $password: String!) {
    confirmForgotPassword(code: $code, username: $username, password: $password) {
      success
      __typename
    }
  }
`;
export type ConfirmForgotPasswordMutationFn = Apollo.MutationFunction<
  ConfirmForgotPasswordMutation,
  ConfirmForgotPasswordMutationVariables
>;

/**
 * __useConfirmForgotPasswordMutation__
 *
 * To run a mutation, you first call `useConfirmForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmForgotPasswordMutation, { data, loading, error }] = useConfirmForgotPasswordMutation({
 *   variables: {
 *      code: // value for 'code'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useConfirmForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmForgotPasswordMutation,
    ConfirmForgotPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ConfirmForgotPasswordMutation, ConfirmForgotPasswordMutationVariables>(
    ConfirmForgotPasswordDocument,
    options
  );
}
export type ConfirmForgotPasswordMutationHookResult = ReturnType<
  typeof useConfirmForgotPasswordMutation
>;
export type ConfirmForgotPasswordMutationResult =
  Apollo.MutationResult<ConfirmForgotPasswordMutation>;
export type ConfirmForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ConfirmForgotPasswordMutation,
  ConfirmForgotPasswordMutationVariables
>;
export const VerifyUserEmailDocument = gql`
  mutation VerifyUserEmail($code: String!) {
    verifyUserEmail(code: $code) {
      success
      message
      __typename
    }
  }
`;
export type VerifyUserEmailMutationFn = Apollo.MutationFunction<
  VerifyUserEmailMutation,
  VerifyUserEmailMutationVariables
>;

/**
 * __useVerifyUserEmailMutation__
 *
 * To run a mutation, you first call `useVerifyUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserEmailMutation, { data, loading, error }] = useVerifyUserEmailMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerifyUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyUserEmailMutation,
    VerifyUserEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<VerifyUserEmailMutation, VerifyUserEmailMutationVariables>(
    VerifyUserEmailDocument,
    options
  );
}
export type VerifyUserEmailMutationHookResult = ReturnType<typeof useVerifyUserEmailMutation>;
export type VerifyUserEmailMutationResult = Apollo.MutationResult<VerifyUserEmailMutation>;
export type VerifyUserEmailMutationOptions = Apollo.BaseMutationOptions<
  VerifyUserEmailMutation,
  VerifyUserEmailMutationVariables
>;
export const RemoveUserDocument = gql`
  mutation RemoveUser($username: String!) {
    removeUser(username: $username) {
      success
      message
      __typename
    }
  }
`;
export type RemoveUserMutationFn = Apollo.MutationFunction<
  RemoveUserMutation,
  RemoveUserMutationVariables
>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useRemoveUserMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(
    RemoveUserDocument,
    options
  );
}
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<
  RemoveUserMutation,
  RemoveUserMutationVariables
>;
export const UpdateDeviceShadowDocument = gql`
  mutation UpdateDeviceShadow($deviceId: String!, $region: String!, $payload: String!) {
    updateDeviceShadow(deviceId: $deviceId, region: $region, payload: $payload) {
      success
      message
      __typename
    }
  }
`;
export type UpdateDeviceShadowMutationFn = Apollo.MutationFunction<
  UpdateDeviceShadowMutation,
  UpdateDeviceShadowMutationVariables
>;

/**
 * __useUpdateDeviceShadowMutation__
 *
 * To run a mutation, you first call `useUpdateDeviceShadowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeviceShadowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeviceShadowMutation, { data, loading, error }] = useUpdateDeviceShadowMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      region: // value for 'region'
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateDeviceShadowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDeviceShadowMutation,
    UpdateDeviceShadowMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateDeviceShadowMutation, UpdateDeviceShadowMutationVariables>(
    UpdateDeviceShadowDocument,
    options
  );
}
export type UpdateDeviceShadowMutationHookResult = ReturnType<typeof useUpdateDeviceShadowMutation>;
export type UpdateDeviceShadowMutationResult = Apollo.MutationResult<UpdateDeviceShadowMutation>;
export type UpdateDeviceShadowMutationOptions = Apollo.BaseMutationOptions<
  UpdateDeviceShadowMutation,
  UpdateDeviceShadowMutationVariables
>;
export const UpdateAlarmCommentDocument = gql`
  mutation UpdateAlarmComment($alarmId: String!, $time: Long!, $comment: String!) {
    updateAlarmComment(alarmId: $alarmId, time: $time, comment: $comment) {
      success
      message
      __typename
    }
  }
`;
export type UpdateAlarmCommentMutationFn = Apollo.MutationFunction<
  UpdateAlarmCommentMutation,
  UpdateAlarmCommentMutationVariables
>;

/**
 * __useUpdateAlarmCommentMutation__
 *
 * To run a mutation, you first call `useUpdateAlarmCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAlarmCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAlarmCommentMutation, { data, loading, error }] = useUpdateAlarmCommentMutation({
 *   variables: {
 *      alarmId: // value for 'alarmId'
 *      time: // value for 'time'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateAlarmCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAlarmCommentMutation,
    UpdateAlarmCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAlarmCommentMutation, UpdateAlarmCommentMutationVariables>(
    UpdateAlarmCommentDocument,
    options
  );
}
export type UpdateAlarmCommentMutationHookResult = ReturnType<typeof useUpdateAlarmCommentMutation>;
export type UpdateAlarmCommentMutationResult = Apollo.MutationResult<UpdateAlarmCommentMutation>;
export type UpdateAlarmCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAlarmCommentMutation,
  UpdateAlarmCommentMutationVariables
>;
export const ResetDeviceBatteryCounterDocument = gql`
  mutation ResetDeviceBatteryCounter($deviceId: String!, $force: Boolean) {
    resetDeviceBatteryCounter(deviceId: $deviceId, force: $force) {
      success
      message
      __typename
    }
  }
`;
export type ResetDeviceBatteryCounterMutationFn = Apollo.MutationFunction<
  ResetDeviceBatteryCounterMutation,
  ResetDeviceBatteryCounterMutationVariables
>;

/**
 * __useResetDeviceBatteryCounterMutation__
 *
 * To run a mutation, you first call `useResetDeviceBatteryCounterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDeviceBatteryCounterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDeviceBatteryCounterMutation, { data, loading, error }] = useResetDeviceBatteryCounterMutation({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useResetDeviceBatteryCounterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetDeviceBatteryCounterMutation,
    ResetDeviceBatteryCounterMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetDeviceBatteryCounterMutation,
    ResetDeviceBatteryCounterMutationVariables
  >(ResetDeviceBatteryCounterDocument, options);
}
export type ResetDeviceBatteryCounterMutationHookResult = ReturnType<
  typeof useResetDeviceBatteryCounterMutation
>;
export type ResetDeviceBatteryCounterMutationResult =
  Apollo.MutationResult<ResetDeviceBatteryCounterMutation>;
export type ResetDeviceBatteryCounterMutationOptions = Apollo.BaseMutationOptions<
  ResetDeviceBatteryCounterMutation,
  ResetDeviceBatteryCounterMutationVariables
>;
export const AddUtilityTagCategoriesDocument = gql`
  mutation AddUtilityTagCategories($newUtilityTagCategories: [NewTagCategory]!) {
    addUtilityTagCategories(newUtilityTagCategories: $newUtilityTagCategories) {
      success
      message
      newUtilityTagCategories {
        id
        name
        tags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export type AddUtilityTagCategoriesMutationFn = Apollo.MutationFunction<
  AddUtilityTagCategoriesMutation,
  AddUtilityTagCategoriesMutationVariables
>;

/**
 * __useAddUtilityTagCategoriesMutation__
 *
 * To run a mutation, you first call `useAddUtilityTagCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUtilityTagCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUtilityTagCategoriesMutation, { data, loading, error }] = useAddUtilityTagCategoriesMutation({
 *   variables: {
 *      newUtilityTagCategories: // value for 'newUtilityTagCategories'
 *   },
 * });
 */
export function useAddUtilityTagCategoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUtilityTagCategoriesMutation,
    AddUtilityTagCategoriesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUtilityTagCategoriesMutation,
    AddUtilityTagCategoriesMutationVariables
  >(AddUtilityTagCategoriesDocument, options);
}
export type AddUtilityTagCategoriesMutationHookResult = ReturnType<
  typeof useAddUtilityTagCategoriesMutation
>;
export type AddUtilityTagCategoriesMutationResult =
  Apollo.MutationResult<AddUtilityTagCategoriesMutation>;
export type AddUtilityTagCategoriesMutationOptions = Apollo.BaseMutationOptions<
  AddUtilityTagCategoriesMutation,
  AddUtilityTagCategoriesMutationVariables
>;
export const AddUtilityTagsDocument = gql`
  mutation AddUtilityTags($newUtilityTags: [NewTag]!, $utilityTagCategoryId: String!) {
    addUtilityTags(newUtilityTags: $newUtilityTags, utilityTagCategoryId: $utilityTagCategoryId) {
      success
      message
      newUtilityTags {
        id
        name
        category_id
        __typename
      }
      __typename
    }
  }
`;
export type AddUtilityTagsMutationFn = Apollo.MutationFunction<
  AddUtilityTagsMutation,
  AddUtilityTagsMutationVariables
>;

/**
 * __useAddUtilityTagsMutation__
 *
 * To run a mutation, you first call `useAddUtilityTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUtilityTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUtilityTagsMutation, { data, loading, error }] = useAddUtilityTagsMutation({
 *   variables: {
 *      newUtilityTags: // value for 'newUtilityTags'
 *      utilityTagCategoryId: // value for 'utilityTagCategoryId'
 *   },
 * });
 */
export function useAddUtilityTagsMutation(
  baseOptions?: Apollo.MutationHookOptions<AddUtilityTagsMutation, AddUtilityTagsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddUtilityTagsMutation, AddUtilityTagsMutationVariables>(
    AddUtilityTagsDocument,
    options
  );
}
export type AddUtilityTagsMutationHookResult = ReturnType<typeof useAddUtilityTagsMutation>;
export type AddUtilityTagsMutationResult = Apollo.MutationResult<AddUtilityTagsMutation>;
export type AddUtilityTagsMutationOptions = Apollo.BaseMutationOptions<
  AddUtilityTagsMutation,
  AddUtilityTagsMutationVariables
>;
export const UpdateUtilityTagCategoryDocument = gql`
  mutation UpdateUtilityTagCategory($updatedUtilityTagCategory: UpdatedTagCategory!) {
    updateUtilityTagCategory(updatedUtilityTagCategory: $updatedUtilityTagCategory) {
      success
      message
      updatedUtilityTagCategory {
        id
        name
        tags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export type UpdateUtilityTagCategoryMutationFn = Apollo.MutationFunction<
  UpdateUtilityTagCategoryMutation,
  UpdateUtilityTagCategoryMutationVariables
>;

/**
 * __useUpdateUtilityTagCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateUtilityTagCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUtilityTagCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUtilityTagCategoryMutation, { data, loading, error }] = useUpdateUtilityTagCategoryMutation({
 *   variables: {
 *      updatedUtilityTagCategory: // value for 'updatedUtilityTagCategory'
 *   },
 * });
 */
export function useUpdateUtilityTagCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUtilityTagCategoryMutation,
    UpdateUtilityTagCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUtilityTagCategoryMutation,
    UpdateUtilityTagCategoryMutationVariables
  >(UpdateUtilityTagCategoryDocument, options);
}
export type UpdateUtilityTagCategoryMutationHookResult = ReturnType<
  typeof useUpdateUtilityTagCategoryMutation
>;
export type UpdateUtilityTagCategoryMutationResult =
  Apollo.MutationResult<UpdateUtilityTagCategoryMutation>;
export type UpdateUtilityTagCategoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateUtilityTagCategoryMutation,
  UpdateUtilityTagCategoryMutationVariables
>;
export const UpdateUtilityTagDocument = gql`
  mutation UpdateUtilityTag($updatedUtilityTag: UpdatedTag!, $utilityTagCategoryId: String!) {
    updateUtilityTag(
      updatedUtilityTag: $updatedUtilityTag
      utilityTagCategoryId: $utilityTagCategoryId
    ) {
      success
      message
      updatedUtilityTag {
        id
        name
        category_id
        __typename
      }
      __typename
    }
  }
`;
export type UpdateUtilityTagMutationFn = Apollo.MutationFunction<
  UpdateUtilityTagMutation,
  UpdateUtilityTagMutationVariables
>;

/**
 * __useUpdateUtilityTagMutation__
 *
 * To run a mutation, you first call `useUpdateUtilityTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUtilityTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUtilityTagMutation, { data, loading, error }] = useUpdateUtilityTagMutation({
 *   variables: {
 *      updatedUtilityTag: // value for 'updatedUtilityTag'
 *      utilityTagCategoryId: // value for 'utilityTagCategoryId'
 *   },
 * });
 */
export function useUpdateUtilityTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUtilityTagMutation,
    UpdateUtilityTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUtilityTagMutation, UpdateUtilityTagMutationVariables>(
    UpdateUtilityTagDocument,
    options
  );
}
export type UpdateUtilityTagMutationHookResult = ReturnType<typeof useUpdateUtilityTagMutation>;
export type UpdateUtilityTagMutationResult = Apollo.MutationResult<UpdateUtilityTagMutation>;
export type UpdateUtilityTagMutationOptions = Apollo.BaseMutationOptions<
  UpdateUtilityTagMutation,
  UpdateUtilityTagMutationVariables
>;
export const DeleteUtilityTagCategoryDocument = gql`
  mutation DeleteUtilityTagCategory($utilityTagCategoryId: String!) {
    deleteUtilityTagCategory(utilityTagCategoryId: $utilityTagCategoryId) {
      success
      message
      deletedUtilityTagCategoryId
      __typename
    }
  }
`;
export type DeleteUtilityTagCategoryMutationFn = Apollo.MutationFunction<
  DeleteUtilityTagCategoryMutation,
  DeleteUtilityTagCategoryMutationVariables
>;

/**
 * __useDeleteUtilityTagCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteUtilityTagCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUtilityTagCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUtilityTagCategoryMutation, { data, loading, error }] = useDeleteUtilityTagCategoryMutation({
 *   variables: {
 *      utilityTagCategoryId: // value for 'utilityTagCategoryId'
 *   },
 * });
 */
export function useDeleteUtilityTagCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUtilityTagCategoryMutation,
    DeleteUtilityTagCategoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUtilityTagCategoryMutation,
    DeleteUtilityTagCategoryMutationVariables
  >(DeleteUtilityTagCategoryDocument, options);
}
export type DeleteUtilityTagCategoryMutationHookResult = ReturnType<
  typeof useDeleteUtilityTagCategoryMutation
>;
export type DeleteUtilityTagCategoryMutationResult =
  Apollo.MutationResult<DeleteUtilityTagCategoryMutation>;
export type DeleteUtilityTagCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteUtilityTagCategoryMutation,
  DeleteUtilityTagCategoryMutationVariables
>;
export const DeleteUtilityTagDocument = gql`
  mutation DeleteUtilityTag($utilityTagId: String!, $utilityTagCategoryId: String!) {
    deleteUtilityTag(utilityTagId: $utilityTagId, utilityTagCategoryId: $utilityTagCategoryId) {
      success
      message
      deletedUtilityTagId
      __typename
    }
  }
`;
export type DeleteUtilityTagMutationFn = Apollo.MutationFunction<
  DeleteUtilityTagMutation,
  DeleteUtilityTagMutationVariables
>;

/**
 * __useDeleteUtilityTagMutation__
 *
 * To run a mutation, you first call `useDeleteUtilityTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUtilityTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUtilityTagMutation, { data, loading, error }] = useDeleteUtilityTagMutation({
 *   variables: {
 *      utilityTagId: // value for 'utilityTagId'
 *      utilityTagCategoryId: // value for 'utilityTagCategoryId'
 *   },
 * });
 */
export function useDeleteUtilityTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUtilityTagMutation,
    DeleteUtilityTagMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUtilityTagMutation, DeleteUtilityTagMutationVariables>(
    DeleteUtilityTagDocument,
    options
  );
}
export type DeleteUtilityTagMutationHookResult = ReturnType<typeof useDeleteUtilityTagMutation>;
export type DeleteUtilityTagMutationResult = Apollo.MutationResult<DeleteUtilityTagMutation>;
export type DeleteUtilityTagMutationOptions = Apollo.BaseMutationOptions<
  DeleteUtilityTagMutation,
  DeleteUtilityTagMutationVariables
>;
export const AddTagToDevicesDocument = gql`
  mutation AddTagToDevices(
    $deviceIds: [String]!
    $tagId: String!
    $tagCategoryId: String!
    $tagType: TagType!
  ) {
    addTagToDevices(
      deviceIds: $deviceIds
      tagId: $tagId
      tagCategoryId: $tagCategoryId
      tagType: $tagType
    ) {
      success
      message
      __typename
    }
  }
`;
export type AddTagToDevicesMutationFn = Apollo.MutationFunction<
  AddTagToDevicesMutation,
  AddTagToDevicesMutationVariables
>;

/**
 * __useAddTagToDevicesMutation__
 *
 * To run a mutation, you first call `useAddTagToDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTagToDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTagToDevicesMutation, { data, loading, error }] = useAddTagToDevicesMutation({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *      tagId: // value for 'tagId'
 *      tagCategoryId: // value for 'tagCategoryId'
 *      tagType: // value for 'tagType'
 *   },
 * });
 */
export function useAddTagToDevicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddTagToDevicesMutation,
    AddTagToDevicesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTagToDevicesMutation, AddTagToDevicesMutationVariables>(
    AddTagToDevicesDocument,
    options
  );
}
export type AddTagToDevicesMutationHookResult = ReturnType<typeof useAddTagToDevicesMutation>;
export type AddTagToDevicesMutationResult = Apollo.MutationResult<AddTagToDevicesMutation>;
export type AddTagToDevicesMutationOptions = Apollo.BaseMutationOptions<
  AddTagToDevicesMutation,
  AddTagToDevicesMutationVariables
>;
export const RemoveTagFromDevicesDocument = gql`
  mutation RemoveTagFromDevices(
    $deviceIds: [String]!
    $tagId: String!
    $tagCategoryId: String!
    $tagType: TagType!
  ) {
    removeTagFromDevices(
      deviceIds: $deviceIds
      tagId: $tagId
      tagCategoryId: $tagCategoryId
      tagType: $tagType
    ) {
      success
      message
      __typename
    }
  }
`;
export type RemoveTagFromDevicesMutationFn = Apollo.MutationFunction<
  RemoveTagFromDevicesMutation,
  RemoveTagFromDevicesMutationVariables
>;

/**
 * __useRemoveTagFromDevicesMutation__
 *
 * To run a mutation, you first call `useRemoveTagFromDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTagFromDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTagFromDevicesMutation, { data, loading, error }] = useRemoveTagFromDevicesMutation({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *      tagId: // value for 'tagId'
 *      tagCategoryId: // value for 'tagCategoryId'
 *      tagType: // value for 'tagType'
 *   },
 * });
 */
export function useRemoveTagFromDevicesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveTagFromDevicesMutation,
    RemoveTagFromDevicesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveTagFromDevicesMutation, RemoveTagFromDevicesMutationVariables>(
    RemoveTagFromDevicesDocument,
    options
  );
}
export type RemoveTagFromDevicesMutationHookResult = ReturnType<
  typeof useRemoveTagFromDevicesMutation
>;
export type RemoveTagFromDevicesMutationResult =
  Apollo.MutationResult<RemoveTagFromDevicesMutation>;
export type RemoveTagFromDevicesMutationOptions = Apollo.BaseMutationOptions<
  RemoveTagFromDevicesMutation,
  RemoveTagFromDevicesMutationVariables
>;
export const AuthenticateUserDocument = gql`
  query AuthenticateUser(
    $email: String!
    $password: String!
    $newPassword: String
    $challengeAnswer: String
    $session: String
  ) {
    authenticateUser(
      email: $email
      password: $password
      newPassword: $newPassword
      challengeAnswer: $challengeAnswer
      session: $session
    )
  }
`;

/**
 * __useAuthenticateUserQuery__
 *
 * To run a query within a React component, call `useAuthenticateUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthenticateUserQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      newPassword: // value for 'newPassword'
 *      challengeAnswer: // value for 'challengeAnswer'
 *      session: // value for 'session'
 *   },
 * });
 */
export function useAuthenticateUserQuery(
  baseOptions: Apollo.QueryHookOptions<AuthenticateUserQuery, AuthenticateUserQueryVariables> &
    ({ variables: AuthenticateUserQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthenticateUserQuery, AuthenticateUserQueryVariables>(
    AuthenticateUserDocument,
    options
  );
}
export function useAuthenticateUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuthenticateUserQuery, AuthenticateUserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthenticateUserQuery, AuthenticateUserQueryVariables>(
    AuthenticateUserDocument,
    options
  );
}
export function useAuthenticateUserSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    AuthenticateUserQuery,
    AuthenticateUserQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AuthenticateUserQuery, AuthenticateUserQueryVariables>(
    AuthenticateUserDocument,
    options
  );
}
export type AuthenticateUserQueryHookResult = ReturnType<typeof useAuthenticateUserQuery>;
export type AuthenticateUserLazyQueryHookResult = ReturnType<typeof useAuthenticateUserLazyQuery>;
export type AuthenticateUserSuspenseQueryHookResult = ReturnType<
  typeof useAuthenticateUserSuspenseQuery
>;
export type AuthenticateUserQueryResult = Apollo.QueryResult<
  AuthenticateUserQuery,
  AuthenticateUserQueryVariables
>;
export const GetMetersetImageDocument = gql`
  query GetMetersetImage($deviceId: String!) {
    getMetersetImage(deviceId: $deviceId) {
      signedUrl
      success
      message
      __typename
    }
  }
`;

/**
 * __useGetMetersetImageQuery__
 *
 * To run a query within a React component, call `useGetMetersetImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMetersetImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMetersetImageQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetMetersetImageQuery(
  baseOptions: Apollo.QueryHookOptions<GetMetersetImageQuery, GetMetersetImageQueryVariables> &
    ({ variables: GetMetersetImageQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMetersetImageQuery, GetMetersetImageQueryVariables>(
    GetMetersetImageDocument,
    options
  );
}
export function useGetMetersetImageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMetersetImageQuery, GetMetersetImageQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMetersetImageQuery, GetMetersetImageQueryVariables>(
    GetMetersetImageDocument,
    options
  );
}
export function useGetMetersetImageSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetMetersetImageQuery,
    GetMetersetImageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMetersetImageQuery, GetMetersetImageQueryVariables>(
    GetMetersetImageDocument,
    options
  );
}
export type GetMetersetImageQueryHookResult = ReturnType<typeof useGetMetersetImageQuery>;
export type GetMetersetImageLazyQueryHookResult = ReturnType<typeof useGetMetersetImageLazyQuery>;
export type GetMetersetImageSuspenseQueryHookResult = ReturnType<
  typeof useGetMetersetImageSuspenseQuery
>;
export type GetMetersetImageQueryResult = Apollo.QueryResult<
  GetMetersetImageQuery,
  GetMetersetImageQueryVariables
>;
export const UserListDocument = gql`
  query UserList($username: String, $limit: Int, $page: Int) {
    userList(username: $username, limit: $limit, page: $page) {
      users {
        id
        username
        groups {
          name
          description
          __typename
        }
        __typename
      }
      count
      __typename
    }
  }
`;

/**
 * __useUserListQuery__
 *
 * To run a query within a React component, call `useUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserListQuery({
 *   variables: {
 *      username: // value for 'username'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUserListQuery(
  baseOptions?: Apollo.QueryHookOptions<UserListQuery, UserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
}
export function useUserListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserListQuery, UserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
}
export function useUserListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserListQuery, UserListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserListQuery, UserListQueryVariables>(UserListDocument, options);
}
export type UserListQueryHookResult = ReturnType<typeof useUserListQuery>;
export type UserListLazyQueryHookResult = ReturnType<typeof useUserListLazyQuery>;
export type UserListSuspenseQueryHookResult = ReturnType<typeof useUserListSuspenseQuery>;
export type UserListQueryResult = Apollo.QueryResult<UserListQuery, UserListQueryVariables>;
export const GroupListForUsersDocument = gql`
  query GroupListForUsers($users: [String]) {
    groupListForUsers(users: $users) {
      id
      groups {
        name
        description
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGroupListForUsersQuery__
 *
 * To run a query within a React component, call `useGroupListForUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupListForUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupListForUsersQuery({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useGroupListForUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupListForUsersQuery, GroupListForUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupListForUsersQuery, GroupListForUsersQueryVariables>(
    GroupListForUsersDocument,
    options
  );
}
export function useGroupListForUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupListForUsersQuery, GroupListForUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupListForUsersQuery, GroupListForUsersQueryVariables>(
    GroupListForUsersDocument,
    options
  );
}
export function useGroupListForUsersSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GroupListForUsersQuery,
    GroupListForUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GroupListForUsersQuery, GroupListForUsersQueryVariables>(
    GroupListForUsersDocument,
    options
  );
}
export type GroupListForUsersQueryHookResult = ReturnType<typeof useGroupListForUsersQuery>;
export type GroupListForUsersLazyQueryHookResult = ReturnType<typeof useGroupListForUsersLazyQuery>;
export type GroupListForUsersSuspenseQueryHookResult = ReturnType<
  typeof useGroupListForUsersSuspenseQuery
>;
export type GroupListForUsersQueryResult = Apollo.QueryResult<
  GroupListForUsersQuery,
  GroupListForUsersQueryVariables
>;
export const UserByUsernameDocument = gql`
  query UserByUsername($username: String!) {
    userByUsername(username: $username) {
      sub
      username
      email
      groups
      deviceId
      __typename
    }
  }
`;

/**
 * __useUserByUsernameQuery__
 *
 * To run a query within a React component, call `useUserByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserByUsernameQuery(
  baseOptions: Apollo.QueryHookOptions<UserByUsernameQuery, UserByUsernameQueryVariables> &
    ({ variables: UserByUsernameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserByUsernameQuery, UserByUsernameQueryVariables>(
    UserByUsernameDocument,
    options
  );
}
export function useUserByUsernameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserByUsernameQuery, UserByUsernameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserByUsernameQuery, UserByUsernameQueryVariables>(
    UserByUsernameDocument,
    options
  );
}
export function useUserByUsernameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<UserByUsernameQuery, UserByUsernameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserByUsernameQuery, UserByUsernameQueryVariables>(
    UserByUsernameDocument,
    options
  );
}
export type UserByUsernameQueryHookResult = ReturnType<typeof useUserByUsernameQuery>;
export type UserByUsernameLazyQueryHookResult = ReturnType<typeof useUserByUsernameLazyQuery>;
export type UserByUsernameSuspenseQueryHookResult = ReturnType<
  typeof useUserByUsernameSuspenseQuery
>;
export type UserByUsernameQueryResult = Apollo.QueryResult<
  UserByUsernameQuery,
  UserByUsernameQueryVariables
>;
export const GroupListDocument = gql`
  query GroupList {
    groupList {
      groups {
        name
        description
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGroupListQuery__
 *
 * To run a query within a React component, call `useGroupListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroupListQuery(
  baseOptions?: Apollo.QueryHookOptions<GroupListQuery, GroupListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GroupListQuery, GroupListQueryVariables>(GroupListDocument, options);
}
export function useGroupListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupListQuery, GroupListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GroupListQuery, GroupListQueryVariables>(GroupListDocument, options);
}
export function useGroupListSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GroupListQuery, GroupListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GroupListQuery, GroupListQueryVariables>(
    GroupListDocument,
    options
  );
}
export type GroupListQueryHookResult = ReturnType<typeof useGroupListQuery>;
export type GroupListLazyQueryHookResult = ReturnType<typeof useGroupListLazyQuery>;
export type GroupListSuspenseQueryHookResult = ReturnType<typeof useGroupListSuspenseQuery>;
export type GroupListQueryResult = Apollo.QueryResult<GroupListQuery, GroupListQueryVariables>;
export const GetBatteryLifeDocument = gql`
  query GetBatteryLife($deviceId: String!) {
    getBatteryLife(deviceId: $deviceId) {
      percentage
      communicationCounter
      __typename
    }
  }
`;

/**
 * __useGetBatteryLifeQuery__
 *
 * To run a query within a React component, call `useGetBatteryLifeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatteryLifeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatteryLifeQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useGetBatteryLifeQuery(
  baseOptions: Apollo.QueryHookOptions<GetBatteryLifeQuery, GetBatteryLifeQueryVariables> &
    ({ variables: GetBatteryLifeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBatteryLifeQuery, GetBatteryLifeQueryVariables>(
    GetBatteryLifeDocument,
    options
  );
}
export function useGetBatteryLifeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBatteryLifeQuery, GetBatteryLifeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBatteryLifeQuery, GetBatteryLifeQueryVariables>(
    GetBatteryLifeDocument,
    options
  );
}
export function useGetBatteryLifeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetBatteryLifeQuery, GetBatteryLifeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBatteryLifeQuery, GetBatteryLifeQueryVariables>(
    GetBatteryLifeDocument,
    options
  );
}
export type GetBatteryLifeQueryHookResult = ReturnType<typeof useGetBatteryLifeQuery>;
export type GetBatteryLifeLazyQueryHookResult = ReturnType<typeof useGetBatteryLifeLazyQuery>;
export type GetBatteryLifeSuspenseQueryHookResult = ReturnType<
  typeof useGetBatteryLifeSuspenseQuery
>;
export type GetBatteryLifeQueryResult = Apollo.QueryResult<
  GetBatteryLifeQuery,
  GetBatteryLifeQueryVariables
>;
export const GetStatsLogDataDocument = gql`
  query GetStatsLogData(
    $fromDate: String!
    $toDate: String!
    $deviceIds: [String]
    $frequency: String
    $scaleFlow: Float
    $scaleVolume: Float
  ) {
    getStatsLogData(
      fromDate: $fromDate
      toDate: $toDate
      deviceIds: $deviceIds
      frequency: $frequency
      scaleFlow: $scaleFlow
      scaleVolume: $scaleVolume
    ) {
      id
      data {
        deviceId
        time
        dayCV
        dayUV
        minPr
        maxPr
        minFC
        maxFC
        minTemp
        maxTemp
        bV
        ccbbV
        pofMF
        qMarg
        pr
        cF
        cV
        uV
        fCV
        fUV
        temp
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetStatsLogDataQuery__
 *
 * To run a query within a React component, call `useGetStatsLogDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatsLogDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatsLogDataQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      deviceIds: // value for 'deviceIds'
 *      frequency: // value for 'frequency'
 *      scaleFlow: // value for 'scaleFlow'
 *      scaleVolume: // value for 'scaleVolume'
 *   },
 * });
 */
export function useGetStatsLogDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetStatsLogDataQuery, GetStatsLogDataQueryVariables> &
    ({ variables: GetStatsLogDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStatsLogDataQuery, GetStatsLogDataQueryVariables>(
    GetStatsLogDataDocument,
    options
  );
}
export function useGetStatsLogDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStatsLogDataQuery, GetStatsLogDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStatsLogDataQuery, GetStatsLogDataQueryVariables>(
    GetStatsLogDataDocument,
    options
  );
}
export function useGetStatsLogDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetStatsLogDataQuery, GetStatsLogDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetStatsLogDataQuery, GetStatsLogDataQueryVariables>(
    GetStatsLogDataDocument,
    options
  );
}
export type GetStatsLogDataQueryHookResult = ReturnType<typeof useGetStatsLogDataQuery>;
export type GetStatsLogDataLazyQueryHookResult = ReturnType<typeof useGetStatsLogDataLazyQuery>;
export type GetStatsLogDataSuspenseQueryHookResult = ReturnType<
  typeof useGetStatsLogDataSuspenseQuery
>;
export type GetStatsLogDataQueryResult = Apollo.QueryResult<
  GetStatsLogDataQuery,
  GetStatsLogDataQueryVariables
>;
export const RequestExportMeterHealthReportDocument = gql`
  query RequestExportMeterHealthReport($fromDate: String!, $toDate: String!) {
    requestExportMeterHealthReport(fromDate: $fromDate, toDate: $toDate) {
      success
      message
      __typename
    }
  }
`;

/**
 * __useRequestExportMeterHealthReportQuery__
 *
 * To run a query within a React component, call `useRequestExportMeterHealthReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestExportMeterHealthReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestExportMeterHealthReportQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useRequestExportMeterHealthReportQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestExportMeterHealthReportQuery,
    RequestExportMeterHealthReportQueryVariables
  > &
    (
      | {
          variables: RequestExportMeterHealthReportQueryVariables;
          skip?: boolean;
        }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RequestExportMeterHealthReportQuery,
    RequestExportMeterHealthReportQueryVariables
  >(RequestExportMeterHealthReportDocument, options);
}
export function useRequestExportMeterHealthReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestExportMeterHealthReportQuery,
    RequestExportMeterHealthReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RequestExportMeterHealthReportQuery,
    RequestExportMeterHealthReportQueryVariables
  >(RequestExportMeterHealthReportDocument, options);
}
export function useRequestExportMeterHealthReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RequestExportMeterHealthReportQuery,
    RequestExportMeterHealthReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    RequestExportMeterHealthReportQuery,
    RequestExportMeterHealthReportQueryVariables
  >(RequestExportMeterHealthReportDocument, options);
}
export type RequestExportMeterHealthReportQueryHookResult = ReturnType<
  typeof useRequestExportMeterHealthReportQuery
>;
export type RequestExportMeterHealthReportLazyQueryHookResult = ReturnType<
  typeof useRequestExportMeterHealthReportLazyQuery
>;
export type RequestExportMeterHealthReportSuspenseQueryHookResult = ReturnType<
  typeof useRequestExportMeterHealthReportSuspenseQuery
>;
export type RequestExportMeterHealthReportQueryResult = Apollo.QueryResult<
  RequestExportMeterHealthReportQuery,
  RequestExportMeterHealthReportQueryVariables
>;
export const RequestExportAlarmLogsDocument = gql`
  query RequestExportAlarmLogs(
    $format: String!
    $fromDate: String!
    $toDate: String!
    $deviceIds: [String]!
  ) {
    requestExportAlarmLogs(
      format: $format
      fromDate: $fromDate
      toDate: $toDate
      deviceIds: $deviceIds
    ) {
      success
      message
      __typename
    }
  }
`;

/**
 * __useRequestExportAlarmLogsQuery__
 *
 * To run a query within a React component, call `useRequestExportAlarmLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestExportAlarmLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestExportAlarmLogsQuery({
 *   variables: {
 *      format: // value for 'format'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      deviceIds: // value for 'deviceIds'
 *   },
 * });
 */
export function useRequestExportAlarmLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RequestExportAlarmLogsQuery,
    RequestExportAlarmLogsQueryVariables
  > &
    ({ variables: RequestExportAlarmLogsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RequestExportAlarmLogsQuery, RequestExportAlarmLogsQueryVariables>(
    RequestExportAlarmLogsDocument,
    options
  );
}
export function useRequestExportAlarmLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RequestExportAlarmLogsQuery,
    RequestExportAlarmLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RequestExportAlarmLogsQuery, RequestExportAlarmLogsQueryVariables>(
    RequestExportAlarmLogsDocument,
    options
  );
}
export function useRequestExportAlarmLogsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    RequestExportAlarmLogsQuery,
    RequestExportAlarmLogsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RequestExportAlarmLogsQuery, RequestExportAlarmLogsQueryVariables>(
    RequestExportAlarmLogsDocument,
    options
  );
}
export type RequestExportAlarmLogsQueryHookResult = ReturnType<
  typeof useRequestExportAlarmLogsQuery
>;
export type RequestExportAlarmLogsLazyQueryHookResult = ReturnType<
  typeof useRequestExportAlarmLogsLazyQuery
>;
export type RequestExportAlarmLogsSuspenseQueryHookResult = ReturnType<
  typeof useRequestExportAlarmLogsSuspenseQuery
>;
export type RequestExportAlarmLogsQueryResult = Apollo.QueryResult<
  RequestExportAlarmLogsQuery,
  RequestExportAlarmLogsQueryVariables
>;
export const ExportMeterDataDocument = gql`
  query ExportMeterData(
    $format: String!
    $forceUtc: Boolean
    $fromDate: String!
    $toDate: String!
    $deviceIds: [String]!
    $frequency: String!
    $scaleFlow: Float
    $scaleVolume: Float
  ) {
    exportMeterData(
      format: $format
      forceUtc: $forceUtc
      fromDate: $fromDate
      toDate: $toDate
      deviceIds: $deviceIds
      frequency: $frequency
      scaleFlow: $scaleFlow
      scaleVolume: $scaleVolume
    ) {
      success
      message
      __typename
    }
  }
`;

/**
 * __useExportMeterDataQuery__
 *
 * To run a query within a React component, call `useExportMeterDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportMeterDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportMeterDataQuery({
 *   variables: {
 *      format: // value for 'format'
 *      forceUtc: // value for 'forceUtc'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      deviceIds: // value for 'deviceIds'
 *      frequency: // value for 'frequency'
 *      scaleFlow: // value for 'scaleFlow'
 *      scaleVolume: // value for 'scaleVolume'
 *   },
 * });
 */
export function useExportMeterDataQuery(
  baseOptions: Apollo.QueryHookOptions<ExportMeterDataQuery, ExportMeterDataQueryVariables> &
    ({ variables: ExportMeterDataQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportMeterDataQuery, ExportMeterDataQueryVariables>(
    ExportMeterDataDocument,
    options
  );
}
export function useExportMeterDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportMeterDataQuery, ExportMeterDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportMeterDataQuery, ExportMeterDataQueryVariables>(
    ExportMeterDataDocument,
    options
  );
}
export function useExportMeterDataSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ExportMeterDataQuery, ExportMeterDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExportMeterDataQuery, ExportMeterDataQueryVariables>(
    ExportMeterDataDocument,
    options
  );
}
export type ExportMeterDataQueryHookResult = ReturnType<typeof useExportMeterDataQuery>;
export type ExportMeterDataLazyQueryHookResult = ReturnType<typeof useExportMeterDataLazyQuery>;
export type ExportMeterDataSuspenseQueryHookResult = ReturnType<
  typeof useExportMeterDataSuspenseQuery
>;
export type ExportMeterDataQueryResult = Apollo.QueryResult<
  ExportMeterDataQuery,
  ExportMeterDataQueryVariables
>;
export const ExportEventsDocument = gql`
  query ExportEvents($format: String!, $fromDate: Long!, $toDate: Long!, $deviceId: String!) {
    exportEvents(format: $format, fromDate: $fromDate, toDate: $toDate, deviceId: $deviceId) {
      link
      __typename
    }
  }
`;

/**
 * __useExportEventsQuery__
 *
 * To run a query within a React component, call `useExportEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportEventsQuery({
 *   variables: {
 *      format: // value for 'format'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      deviceId: // value for 'deviceId'
 *   },
 * });
 */
export function useExportEventsQuery(
  baseOptions: Apollo.QueryHookOptions<ExportEventsQuery, ExportEventsQueryVariables> &
    ({ variables: ExportEventsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportEventsQuery, ExportEventsQueryVariables>(
    ExportEventsDocument,
    options
  );
}
export function useExportEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportEventsQuery, ExportEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportEventsQuery, ExportEventsQueryVariables>(
    ExportEventsDocument,
    options
  );
}
export function useExportEventsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ExportEventsQuery, ExportEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExportEventsQuery, ExportEventsQueryVariables>(
    ExportEventsDocument,
    options
  );
}
export type ExportEventsQueryHookResult = ReturnType<typeof useExportEventsQuery>;
export type ExportEventsLazyQueryHookResult = ReturnType<typeof useExportEventsLazyQuery>;
export type ExportEventsSuspenseQueryHookResult = ReturnType<typeof useExportEventsSuspenseQuery>;
export type ExportEventsQueryResult = Apollo.QueryResult<
  ExportEventsQuery,
  ExportEventsQueryVariables
>;
export const ExportBatteryLifeReportDocument = gql`
  query ExportBatteryLifeReport {
    exportBatteryLifeReport {
      success
      message
      __typename
    }
  }
`;

/**
 * __useExportBatteryLifeReportQuery__
 *
 * To run a query within a React component, call `useExportBatteryLifeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportBatteryLifeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportBatteryLifeReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportBatteryLifeReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ExportBatteryLifeReportQuery,
    ExportBatteryLifeReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportBatteryLifeReportQuery, ExportBatteryLifeReportQueryVariables>(
    ExportBatteryLifeReportDocument,
    options
  );
}
export function useExportBatteryLifeReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportBatteryLifeReportQuery,
    ExportBatteryLifeReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportBatteryLifeReportQuery, ExportBatteryLifeReportQueryVariables>(
    ExportBatteryLifeReportDocument,
    options
  );
}
export function useExportBatteryLifeReportSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ExportBatteryLifeReportQuery,
    ExportBatteryLifeReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    ExportBatteryLifeReportQuery,
    ExportBatteryLifeReportQueryVariables
  >(ExportBatteryLifeReportDocument, options);
}
export type ExportBatteryLifeReportQueryHookResult = ReturnType<
  typeof useExportBatteryLifeReportQuery
>;
export type ExportBatteryLifeReportLazyQueryHookResult = ReturnType<
  typeof useExportBatteryLifeReportLazyQuery
>;
export type ExportBatteryLifeReportSuspenseQueryHookResult = ReturnType<
  typeof useExportBatteryLifeReportSuspenseQuery
>;
export type ExportBatteryLifeReportQueryResult = Apollo.QueryResult<
  ExportBatteryLifeReportQuery,
  ExportBatteryLifeReportQueryVariables
>;
export const GetDeviceGroupsDocument = gql`
  query GetDeviceGroups {
    getDeviceGroups {
      id
      name
      utility_id
      utility {
        id
        name
        company_id
        company {
          id
          name
          alias
          theme
          logo
          region
          temperatureUnit
          pressureUnit
          volumeUnit
          voltageUnit
          correctedFlowUnit
          __typename
        }
        __typename
      }
      temperatureUnit
      pressureUnit
      volumeUnit
      voltageUnit
      correctedFlowUnit
      __typename
    }
  }
`;

/**
 * __useGetDeviceGroupsQuery__
 *
 * To run a query within a React component, call `useGetDeviceGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeviceGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>(
    GetDeviceGroupsDocument,
    options
  );
}
export function useGetDeviceGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>(
    GetDeviceGroupsDocument,
    options
  );
}
export function useGetDeviceGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDeviceGroupsQuery, GetDeviceGroupsQueryVariables>(
    GetDeviceGroupsDocument,
    options
  );
}
export type GetDeviceGroupsQueryHookResult = ReturnType<typeof useGetDeviceGroupsQuery>;
export type GetDeviceGroupsLazyQueryHookResult = ReturnType<typeof useGetDeviceGroupsLazyQuery>;
export type GetDeviceGroupsSuspenseQueryHookResult = ReturnType<
  typeof useGetDeviceGroupsSuspenseQuery
>;
export type GetDeviceGroupsQueryResult = Apollo.QueryResult<
  GetDeviceGroupsQuery,
  GetDeviceGroupsQueryVariables
>;
export const ListDevicesDocument = gql`
  query ListDevices(
    $date: String
    $address: String
    $deviceId: String
    $meterId: String
    $owner: String
    $group: String
    $utility: String
    $company: String
    $tag: String
    $latitudeLo: Float
    $latitudeHi: Float
    $longitudeLo: Float
    $longitudeHi: Float
    $page: Int
    $size: Int
  ) {
    listDevices(
      date: $date
      address: $address
      deviceId: $deviceId
      meterId: $meterId
      owner: $owner
      group: $group
      utility: $utility
      company: $company
      tag: $tag
      latitudeLo: $latitudeLo
      latitudeHi: $latitudeHi
      longitudeLo: $longitudeLo
      longitudeHi: $longitudeHi
      page: $page
      size: $size
    ) {
      rows {
        id
        device_group_id
        meterId
        serialNo
        group {
          id
          name
          utility_id
          utility {
            id
            name
            company_id
            company {
              id
              name
              alias
              theme
              logo
              region
              temperatureUnit
              pressureUnit
              volumeUnit
              voltageUnit
              correctedFlowUnit
              __typename
            }
            __typename
          }
          temperatureUnit
          pressureUnit
          volumeUnit
          voltageUnit
          correctedFlowUnit
          __typename
        }
        user_device {
          user_id
          device_id
          user {
            id
            name
            email
            __typename
          }
          __typename
        }
        latitude
        longitude
        address
        street
        city
        postalCode
        country
        messages {
          id
          time
          urgent
          dId
          status
          comment
          alarms {
            lmt
            threshold
            t
            type
            v
            __typename
          }
          __typename
        }
        tags {
          predefinedTags {
            id
            name
            category_id
            __typename
          }
          utilityTags {
            id
            name
            category_id
            __typename
          }
          __typename
        }
        thresholds {
          device_id
          threshold_key
          value
          __typename
        }
        data {
          meterSize
          ademFirmware
          brightlyncFirmware
          ingestionTime
          dTm
          TmZn
          proto
          bV
          ccbbV
          dayCV
          dayUV
          fUV
          fCV
          cFAvg
          maxFC {
            t
            v
            __typename
          }
          maxPr {
            t
            v
            __typename
          }
          maxTemp {
            t
            v
            __typename
          }
          minFC {
            t
            v
            __typename
          }
          minPr {
            t
            v
            __typename
          }
          minTemp {
            t
            v
            __typename
          }
          pofMF
          qMarg
          __typename
        }
        __typename
      }
      count
      __typename
    }
  }
`;

/**
 * __useListDevicesQuery__
 *
 * To run a query within a React component, call `useListDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDevicesQuery({
 *   variables: {
 *      date: // value for 'date'
 *      address: // value for 'address'
 *      deviceId: // value for 'deviceId'
 *      meterId: // value for 'meterId'
 *      owner: // value for 'owner'
 *      group: // value for 'group'
 *      utility: // value for 'utility'
 *      company: // value for 'company'
 *      tag: // value for 'tag'
 *      latitudeLo: // value for 'latitudeLo'
 *      latitudeHi: // value for 'latitudeHi'
 *      longitudeLo: // value for 'longitudeLo'
 *      longitudeHi: // value for 'longitudeHi'
 *      page: // value for 'page'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useListDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListDevicesQuery, ListDevicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListDevicesQuery, ListDevicesQueryVariables>(ListDevicesDocument, options);
}
export function useListDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListDevicesQuery, ListDevicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListDevicesQuery, ListDevicesQueryVariables>(
    ListDevicesDocument,
    options
  );
}
export function useListDevicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListDevicesQuery, ListDevicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListDevicesQuery, ListDevicesQueryVariables>(
    ListDevicesDocument,
    options
  );
}
export type ListDevicesQueryHookResult = ReturnType<typeof useListDevicesQuery>;
export type ListDevicesLazyQueryHookResult = ReturnType<typeof useListDevicesLazyQuery>;
export type ListDevicesSuspenseQueryHookResult = ReturnType<typeof useListDevicesSuspenseQuery>;
export type ListDevicesQueryResult = Apollo.QueryResult<
  ListDevicesQuery,
  ListDevicesQueryVariables
>;
export const VerifyTotpDocument = gql`
  query VerifyTotp($challengeAnswer: String!) {
    verifyTotp(challengeAnswer: $challengeAnswer) {
      success
      message
      __typename
    }
  }
`;

/**
 * __useVerifyTotpQuery__
 *
 * To run a query within a React component, call `useVerifyTotpQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyTotpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyTotpQuery({
 *   variables: {
 *      challengeAnswer: // value for 'challengeAnswer'
 *   },
 * });
 */
export function useVerifyTotpQuery(
  baseOptions: Apollo.QueryHookOptions<VerifyTotpQuery, VerifyTotpQueryVariables> &
    ({ variables: VerifyTotpQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VerifyTotpQuery, VerifyTotpQueryVariables>(VerifyTotpDocument, options);
}
export function useVerifyTotpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VerifyTotpQuery, VerifyTotpQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VerifyTotpQuery, VerifyTotpQueryVariables>(
    VerifyTotpDocument,
    options
  );
}
export function useVerifyTotpSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<VerifyTotpQuery, VerifyTotpQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<VerifyTotpQuery, VerifyTotpQueryVariables>(
    VerifyTotpDocument,
    options
  );
}
export type VerifyTotpQueryHookResult = ReturnType<typeof useVerifyTotpQuery>;
export type VerifyTotpLazyQueryHookResult = ReturnType<typeof useVerifyTotpLazyQuery>;
export type VerifyTotpSuspenseQueryHookResult = ReturnType<typeof useVerifyTotpSuspenseQuery>;
export type VerifyTotpQueryResult = Apollo.QueryResult<VerifyTotpQuery, VerifyTotpQueryVariables>;
export const GetAssociatedSecretCodeDocument = gql`
  query GetAssociatedSecretCode($password: String!) {
    getAssociatedSecretCode(password: $password) {
      secretCode
      __typename
    }
  }
`;

/**
 * __useGetAssociatedSecretCodeQuery__
 *
 * To run a query within a React component, call `useGetAssociatedSecretCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssociatedSecretCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssociatedSecretCodeQuery({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetAssociatedSecretCodeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAssociatedSecretCodeQuery,
    GetAssociatedSecretCodeQueryVariables
  > &
    ({ variables: GetAssociatedSecretCodeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAssociatedSecretCodeQuery, GetAssociatedSecretCodeQueryVariables>(
    GetAssociatedSecretCodeDocument,
    options
  );
}
export function useGetAssociatedSecretCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAssociatedSecretCodeQuery,
    GetAssociatedSecretCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAssociatedSecretCodeQuery, GetAssociatedSecretCodeQueryVariables>(
    GetAssociatedSecretCodeDocument,
    options
  );
}
export function useGetAssociatedSecretCodeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAssociatedSecretCodeQuery,
    GetAssociatedSecretCodeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAssociatedSecretCodeQuery,
    GetAssociatedSecretCodeQueryVariables
  >(GetAssociatedSecretCodeDocument, options);
}
export type GetAssociatedSecretCodeQueryHookResult = ReturnType<
  typeof useGetAssociatedSecretCodeQuery
>;
export type GetAssociatedSecretCodeLazyQueryHookResult = ReturnType<
  typeof useGetAssociatedSecretCodeLazyQuery
>;
export type GetAssociatedSecretCodeSuspenseQueryHookResult = ReturnType<
  typeof useGetAssociatedSecretCodeSuspenseQuery
>;
export type GetAssociatedSecretCodeQueryResult = Apollo.QueryResult<
  GetAssociatedSecretCodeQuery,
  GetAssociatedSecretCodeQueryVariables
>;
export const ListUnassignedDevicesDocument = gql`
  query ListUnassignedDevices {
    listUnassignedDevices
  }
`;

/**
 * __useListUnassignedDevicesQuery__
 *
 * To run a query within a React component, call `useListUnassignedDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUnassignedDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUnassignedDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUnassignedDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListUnassignedDevicesQuery,
    ListUnassignedDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListUnassignedDevicesQuery, ListUnassignedDevicesQueryVariables>(
    ListUnassignedDevicesDocument,
    options
  );
}
export function useListUnassignedDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListUnassignedDevicesQuery,
    ListUnassignedDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListUnassignedDevicesQuery, ListUnassignedDevicesQueryVariables>(
    ListUnassignedDevicesDocument,
    options
  );
}
export function useListUnassignedDevicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListUnassignedDevicesQuery,
    ListUnassignedDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListUnassignedDevicesQuery, ListUnassignedDevicesQueryVariables>(
    ListUnassignedDevicesDocument,
    options
  );
}
export type ListUnassignedDevicesQueryHookResult = ReturnType<typeof useListUnassignedDevicesQuery>;
export type ListUnassignedDevicesLazyQueryHookResult = ReturnType<
  typeof useListUnassignedDevicesLazyQuery
>;
export type ListUnassignedDevicesSuspenseQueryHookResult = ReturnType<
  typeof useListUnassignedDevicesSuspenseQuery
>;
export type ListUnassignedDevicesQueryResult = Apollo.QueryResult<
  ListUnassignedDevicesQuery,
  ListUnassignedDevicesQueryVariables
>;
export const GetCompaniesByGroupsDocument = gql`
  query GetCompaniesByGroups($groups: [String]) {
    getCompaniesByGroups(groups: $groups) {
      companies {
        id
        name
        alias
        theme
        logo
        region
        temperatureUnit
        pressureUnit
        volumeUnit
        voltageUnit
        correctedFlowUnit
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetCompaniesByGroupsQuery__
 *
 * To run a query within a React component, call `useGetCompaniesByGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesByGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesByGroupsQuery({
 *   variables: {
 *      groups: // value for 'groups'
 *   },
 * });
 */
export function useGetCompaniesByGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompaniesByGroupsQuery,
    GetCompaniesByGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompaniesByGroupsQuery, GetCompaniesByGroupsQueryVariables>(
    GetCompaniesByGroupsDocument,
    options
  );
}
export function useGetCompaniesByGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompaniesByGroupsQuery,
    GetCompaniesByGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompaniesByGroupsQuery, GetCompaniesByGroupsQueryVariables>(
    GetCompaniesByGroupsDocument,
    options
  );
}
export function useGetCompaniesByGroupsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetCompaniesByGroupsQuery,
    GetCompaniesByGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompaniesByGroupsQuery, GetCompaniesByGroupsQueryVariables>(
    GetCompaniesByGroupsDocument,
    options
  );
}
export type GetCompaniesByGroupsQueryHookResult = ReturnType<typeof useGetCompaniesByGroupsQuery>;
export type GetCompaniesByGroupsLazyQueryHookResult = ReturnType<
  typeof useGetCompaniesByGroupsLazyQuery
>;
export type GetCompaniesByGroupsSuspenseQueryHookResult = ReturnType<
  typeof useGetCompaniesByGroupsSuspenseQuery
>;
export type GetCompaniesByGroupsQueryResult = Apollo.QueryResult<
  GetCompaniesByGroupsQuery,
  GetCompaniesByGroupsQueryVariables
>;
export const ListFirmwareDocument = gql`
  query ListFirmware {
    listFirmware
  }
`;

/**
 * __useListFirmwareQuery__
 *
 * To run a query within a React component, call `useListFirmwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFirmwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFirmwareQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFirmwareQuery(
  baseOptions?: Apollo.QueryHookOptions<ListFirmwareQuery, ListFirmwareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListFirmwareQuery, ListFirmwareQueryVariables>(
    ListFirmwareDocument,
    options
  );
}
export function useListFirmwareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListFirmwareQuery, ListFirmwareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListFirmwareQuery, ListFirmwareQueryVariables>(
    ListFirmwareDocument,
    options
  );
}
export function useListFirmwareSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListFirmwareQuery, ListFirmwareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListFirmwareQuery, ListFirmwareQueryVariables>(
    ListFirmwareDocument,
    options
  );
}
export type ListFirmwareQueryHookResult = ReturnType<typeof useListFirmwareQuery>;
export type ListFirmwareLazyQueryHookResult = ReturnType<typeof useListFirmwareLazyQuery>;
export type ListFirmwareSuspenseQueryHookResult = ReturnType<typeof useListFirmwareSuspenseQuery>;
export type ListFirmwareQueryResult = Apollo.QueryResult<
  ListFirmwareQuery,
  ListFirmwareQueryVariables
>;
export const ListFirmwareVersionDocument = gql`
  query ListFirmwareVersion($component: String) {
    listFirmwareVersion(component: $component)
  }
`;

/**
 * __useListFirmwareVersionQuery__
 *
 * To run a query within a React component, call `useListFirmwareVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFirmwareVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFirmwareVersionQuery({
 *   variables: {
 *      component: // value for 'component'
 *   },
 * });
 */
export function useListFirmwareVersionQuery(
  baseOptions?: Apollo.QueryHookOptions<ListFirmwareVersionQuery, ListFirmwareVersionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListFirmwareVersionQuery, ListFirmwareVersionQueryVariables>(
    ListFirmwareVersionDocument,
    options
  );
}
export function useListFirmwareVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListFirmwareVersionQuery,
    ListFirmwareVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListFirmwareVersionQuery, ListFirmwareVersionQueryVariables>(
    ListFirmwareVersionDocument,
    options
  );
}
export function useListFirmwareVersionSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListFirmwareVersionQuery,
    ListFirmwareVersionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListFirmwareVersionQuery, ListFirmwareVersionQueryVariables>(
    ListFirmwareVersionDocument,
    options
  );
}
export type ListFirmwareVersionQueryHookResult = ReturnType<typeof useListFirmwareVersionQuery>;
export type ListFirmwareVersionLazyQueryHookResult = ReturnType<
  typeof useListFirmwareVersionLazyQuery
>;
export type ListFirmwareVersionSuspenseQueryHookResult = ReturnType<
  typeof useListFirmwareVersionSuspenseQuery
>;
export type ListFirmwareVersionQueryResult = Apollo.QueryResult<
  ListFirmwareVersionQuery,
  ListFirmwareVersionQueryVariables
>;
export const GetFirmwareDocument = gql`
  query GetFirmware($version: String) {
    getFirmware(version: $version) {
      name
      url
      __typename
    }
  }
`;

/**
 * __useGetFirmwareQuery__
 *
 * To run a query within a React component, call `useGetFirmwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirmwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirmwareQuery({
 *   variables: {
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetFirmwareQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFirmwareQuery, GetFirmwareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFirmwareQuery, GetFirmwareQueryVariables>(GetFirmwareDocument, options);
}
export function useGetFirmwareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFirmwareQuery, GetFirmwareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFirmwareQuery, GetFirmwareQueryVariables>(
    GetFirmwareDocument,
    options
  );
}
export function useGetFirmwareSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetFirmwareQuery, GetFirmwareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFirmwareQuery, GetFirmwareQueryVariables>(
    GetFirmwareDocument,
    options
  );
}
export type GetFirmwareQueryHookResult = ReturnType<typeof useGetFirmwareQuery>;
export type GetFirmwareLazyQueryHookResult = ReturnType<typeof useGetFirmwareLazyQuery>;
export type GetFirmwareSuspenseQueryHookResult = ReturnType<typeof useGetFirmwareSuspenseQuery>;
export type GetFirmwareQueryResult = Apollo.QueryResult<
  GetFirmwareQuery,
  GetFirmwareQueryVariables
>;
export const ListCaCertificateDocument = gql`
  query ListCaCertificate {
    listCaCertificate {
      key
      date
      name
      __typename
    }
  }
`;

/**
 * __useListCaCertificateQuery__
 *
 * To run a query within a React component, call `useListCaCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCaCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCaCertificateQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCaCertificateQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCaCertificateQuery, ListCaCertificateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListCaCertificateQuery, ListCaCertificateQueryVariables>(
    ListCaCertificateDocument,
    options
  );
}
export function useListCaCertificateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCaCertificateQuery, ListCaCertificateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListCaCertificateQuery, ListCaCertificateQueryVariables>(
    ListCaCertificateDocument,
    options
  );
}
export function useListCaCertificateSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    ListCaCertificateQuery,
    ListCaCertificateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListCaCertificateQuery, ListCaCertificateQueryVariables>(
    ListCaCertificateDocument,
    options
  );
}
export type ListCaCertificateQueryHookResult = ReturnType<typeof useListCaCertificateQuery>;
export type ListCaCertificateLazyQueryHookResult = ReturnType<typeof useListCaCertificateLazyQuery>;
export type ListCaCertificateSuspenseQueryHookResult = ReturnType<
  typeof useListCaCertificateSuspenseQuery
>;
export type ListCaCertificateQueryResult = Apollo.QueryResult<
  ListCaCertificateQuery,
  ListCaCertificateQueryVariables
>;
export const ListCompaniesDocument = gql`
  query ListCompanies {
    listCompanies {
      id
      name
      alias
      theme
      logo
      region
      temperatureUnit
      pressureUnit
      volumeUnit
      voltageUnit
      correctedFlowUnit
      __typename
    }
  }
`;

/**
 * __useListCompaniesQuery__
 *
 * To run a query within a React component, call `useListCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<ListCompaniesQuery, ListCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(
    ListCompaniesDocument,
    options
  );
}
export function useListCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListCompaniesQuery, ListCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(
    ListCompaniesDocument,
    options
  );
}
export function useListCompaniesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<ListCompaniesQuery, ListCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ListCompaniesQuery, ListCompaniesQueryVariables>(
    ListCompaniesDocument,
    options
  );
}
export type ListCompaniesQueryHookResult = ReturnType<typeof useListCompaniesQuery>;
export type ListCompaniesLazyQueryHookResult = ReturnType<typeof useListCompaniesLazyQuery>;
export type ListCompaniesSuspenseQueryHookResult = ReturnType<typeof useListCompaniesSuspenseQuery>;
export type ListCompaniesQueryResult = Apollo.QueryResult<
  ListCompaniesQuery,
  ListCompaniesQueryVariables
>;
export const GetDeviceShadowDocument = gql`
  query GetDeviceShadow($deviceId: String!, $region: String!, $prettify: Boolean) {
    getDeviceShadow(deviceId: $deviceId, region: $region, prettify: $prettify)
  }
`;

/**
 * __useGetDeviceShadowQuery__
 *
 * To run a query within a React component, call `useGetDeviceShadowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeviceShadowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeviceShadowQuery({
 *   variables: {
 *      deviceId: // value for 'deviceId'
 *      region: // value for 'region'
 *      prettify: // value for 'prettify'
 *   },
 * });
 */
export function useGetDeviceShadowQuery(
  baseOptions: Apollo.QueryHookOptions<GetDeviceShadowQuery, GetDeviceShadowQueryVariables> &
    ({ variables: GetDeviceShadowQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>(
    GetDeviceShadowDocument,
    options
  );
}
export function useGetDeviceShadowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>(
    GetDeviceShadowDocument,
    options
  );
}
export function useGetDeviceShadowSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetDeviceShadowQuery, GetDeviceShadowQueryVariables>(
    GetDeviceShadowDocument,
    options
  );
}
export type GetDeviceShadowQueryHookResult = ReturnType<typeof useGetDeviceShadowQuery>;
export type GetDeviceShadowLazyQueryHookResult = ReturnType<typeof useGetDeviceShadowLazyQuery>;
export type GetDeviceShadowSuspenseQueryHookResult = ReturnType<
  typeof useGetDeviceShadowSuspenseQuery
>;
export type GetDeviceShadowQueryResult = Apollo.QueryResult<
  GetDeviceShadowQuery,
  GetDeviceShadowQueryVariables
>;
export const GetAlarmCommentDocument = gql`
  query GetAlarmComment($alarmId: String!, $time: Long!) {
    getAlarmComment(alarmId: $alarmId, time: $time)
  }
`;

/**
 * __useGetAlarmCommentQuery__
 *
 * To run a query within a React component, call `useGetAlarmCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlarmCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlarmCommentQuery({
 *   variables: {
 *      alarmId: // value for 'alarmId'
 *      time: // value for 'time'
 *   },
 * });
 */
export function useGetAlarmCommentQuery(
  baseOptions: Apollo.QueryHookOptions<GetAlarmCommentQuery, GetAlarmCommentQueryVariables> &
    ({ variables: GetAlarmCommentQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAlarmCommentQuery, GetAlarmCommentQueryVariables>(
    GetAlarmCommentDocument,
    options
  );
}
export function useGetAlarmCommentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAlarmCommentQuery, GetAlarmCommentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAlarmCommentQuery, GetAlarmCommentQueryVariables>(
    GetAlarmCommentDocument,
    options
  );
}
export function useGetAlarmCommentSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetAlarmCommentQuery, GetAlarmCommentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAlarmCommentQuery, GetAlarmCommentQueryVariables>(
    GetAlarmCommentDocument,
    options
  );
}
export type GetAlarmCommentQueryHookResult = ReturnType<typeof useGetAlarmCommentQuery>;
export type GetAlarmCommentLazyQueryHookResult = ReturnType<typeof useGetAlarmCommentLazyQuery>;
export type GetAlarmCommentSuspenseQueryHookResult = ReturnType<
  typeof useGetAlarmCommentSuspenseQuery
>;
export type GetAlarmCommentQueryResult = Apollo.QueryResult<
  GetAlarmCommentQuery,
  GetAlarmCommentQueryVariables
>;
export const GetAllTagCategoriesDocument = gql`
  query GetAllTagCategories {
    getAllTagCategories {
      success
      message
      predefinedTagCategories {
        id
        name
        tags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      utilityTagCategoriesPerUtility {
        id
        utilityTagCategories {
          id
          name
          tags {
            id
            name
            category_id
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetAllTagCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllTagCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTagCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTagCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTagCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllTagCategoriesQuery, GetAllTagCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllTagCategoriesQuery, GetAllTagCategoriesQueryVariables>(
    GetAllTagCategoriesDocument,
    options
  );
}
export function useGetAllTagCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTagCategoriesQuery,
    GetAllTagCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllTagCategoriesQuery, GetAllTagCategoriesQueryVariables>(
    GetAllTagCategoriesDocument,
    options
  );
}
export function useGetAllTagCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllTagCategoriesQuery,
    GetAllTagCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAllTagCategoriesQuery, GetAllTagCategoriesQueryVariables>(
    GetAllTagCategoriesDocument,
    options
  );
}
export type GetAllTagCategoriesQueryHookResult = ReturnType<typeof useGetAllTagCategoriesQuery>;
export type GetAllTagCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetAllTagCategoriesLazyQuery
>;
export type GetAllTagCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetAllTagCategoriesSuspenseQuery
>;
export type GetAllTagCategoriesQueryResult = Apollo.QueryResult<
  GetAllTagCategoriesQuery,
  GetAllTagCategoriesQueryVariables
>;
export const GetAllPredefinedTagCategoriesDocument = gql`
  query GetAllPredefinedTagCategories {
    getAllPredefinedTagCategories {
      success
      message
      predefinedTagCategories {
        id
        name
        tags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetAllPredefinedTagCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllPredefinedTagCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPredefinedTagCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPredefinedTagCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPredefinedTagCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllPredefinedTagCategoriesQuery,
    GetAllPredefinedTagCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllPredefinedTagCategoriesQuery,
    GetAllPredefinedTagCategoriesQueryVariables
  >(GetAllPredefinedTagCategoriesDocument, options);
}
export function useGetAllPredefinedTagCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllPredefinedTagCategoriesQuery,
    GetAllPredefinedTagCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllPredefinedTagCategoriesQuery,
    GetAllPredefinedTagCategoriesQueryVariables
  >(GetAllPredefinedTagCategoriesDocument, options);
}
export function useGetAllPredefinedTagCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllPredefinedTagCategoriesQuery,
    GetAllPredefinedTagCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllPredefinedTagCategoriesQuery,
    GetAllPredefinedTagCategoriesQueryVariables
  >(GetAllPredefinedTagCategoriesDocument, options);
}
export type GetAllPredefinedTagCategoriesQueryHookResult = ReturnType<
  typeof useGetAllPredefinedTagCategoriesQuery
>;
export type GetAllPredefinedTagCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetAllPredefinedTagCategoriesLazyQuery
>;
export type GetAllPredefinedTagCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetAllPredefinedTagCategoriesSuspenseQuery
>;
export type GetAllPredefinedTagCategoriesQueryResult = Apollo.QueryResult<
  GetAllPredefinedTagCategoriesQuery,
  GetAllPredefinedTagCategoriesQueryVariables
>;
export const GetAllUtilityTagCategoriesDocument = gql`
  query GetAllUtilityTagCategories {
    getAllUtilityTagCategories {
      success
      message
      utilityTagCategoriesPerUtility {
        id
        utilityTagCategories {
          id
          name
          tags {
            id
            name
            category_id
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetAllUtilityTagCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllUtilityTagCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUtilityTagCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUtilityTagCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUtilityTagCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllUtilityTagCategoriesQuery,
    GetAllUtilityTagCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllUtilityTagCategoriesQuery, GetAllUtilityTagCategoriesQueryVariables>(
    GetAllUtilityTagCategoriesDocument,
    options
  );
}
export function useGetAllUtilityTagCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUtilityTagCategoriesQuery,
    GetAllUtilityTagCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllUtilityTagCategoriesQuery,
    GetAllUtilityTagCategoriesQueryVariables
  >(GetAllUtilityTagCategoriesDocument, options);
}
export function useGetAllUtilityTagCategoriesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAllUtilityTagCategoriesQuery,
    GetAllUtilityTagCategoriesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetAllUtilityTagCategoriesQuery,
    GetAllUtilityTagCategoriesQueryVariables
  >(GetAllUtilityTagCategoriesDocument, options);
}
export type GetAllUtilityTagCategoriesQueryHookResult = ReturnType<
  typeof useGetAllUtilityTagCategoriesQuery
>;
export type GetAllUtilityTagCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetAllUtilityTagCategoriesLazyQuery
>;
export type GetAllUtilityTagCategoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetAllUtilityTagCategoriesSuspenseQuery
>;
export type GetAllUtilityTagCategoriesQueryResult = Apollo.QueryResult<
  GetAllUtilityTagCategoriesQuery,
  GetAllUtilityTagCategoriesQueryVariables
>;
export const GetPredefinedTagsForDevicesDocument = gql`
  query GetPredefinedTagsForDevices($deviceIds: [String]!) {
    getPredefinedTagsForDevices(deviceIds: $deviceIds) {
      success
      message
      devicesWithPredefinedTags {
        deviceId
        predefinedTags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetPredefinedTagsForDevicesQuery__
 *
 * To run a query within a React component, call `useGetPredefinedTagsForDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPredefinedTagsForDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPredefinedTagsForDevicesQuery({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *   },
 * });
 */
export function useGetPredefinedTagsForDevicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPredefinedTagsForDevicesQuery,
    GetPredefinedTagsForDevicesQueryVariables
  > &
    ({ variables: GetPredefinedTagsForDevicesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPredefinedTagsForDevicesQuery,
    GetPredefinedTagsForDevicesQueryVariables
  >(GetPredefinedTagsForDevicesDocument, options);
}
export function useGetPredefinedTagsForDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPredefinedTagsForDevicesQuery,
    GetPredefinedTagsForDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPredefinedTagsForDevicesQuery,
    GetPredefinedTagsForDevicesQueryVariables
  >(GetPredefinedTagsForDevicesDocument, options);
}
export function useGetPredefinedTagsForDevicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetPredefinedTagsForDevicesQuery,
    GetPredefinedTagsForDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetPredefinedTagsForDevicesQuery,
    GetPredefinedTagsForDevicesQueryVariables
  >(GetPredefinedTagsForDevicesDocument, options);
}
export type GetPredefinedTagsForDevicesQueryHookResult = ReturnType<
  typeof useGetPredefinedTagsForDevicesQuery
>;
export type GetPredefinedTagsForDevicesLazyQueryHookResult = ReturnType<
  typeof useGetPredefinedTagsForDevicesLazyQuery
>;
export type GetPredefinedTagsForDevicesSuspenseQueryHookResult = ReturnType<
  typeof useGetPredefinedTagsForDevicesSuspenseQuery
>;
export type GetPredefinedTagsForDevicesQueryResult = Apollo.QueryResult<
  GetPredefinedTagsForDevicesQuery,
  GetPredefinedTagsForDevicesQueryVariables
>;
export const GetUtilityTagsForDevicesDocument = gql`
  query GetUtilityTagsForDevices($deviceIds: [String]!) {
    getUtilityTagsForDevices(deviceIds: $deviceIds) {
      success
      message
      devicesWithUtilityTags {
        deviceId
        utilityTags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetUtilityTagsForDevicesQuery__
 *
 * To run a query within a React component, call `useGetUtilityTagsForDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUtilityTagsForDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUtilityTagsForDevicesQuery({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *   },
 * });
 */
export function useGetUtilityTagsForDevicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUtilityTagsForDevicesQuery,
    GetUtilityTagsForDevicesQueryVariables
  > &
    ({ variables: GetUtilityTagsForDevicesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUtilityTagsForDevicesQuery, GetUtilityTagsForDevicesQueryVariables>(
    GetUtilityTagsForDevicesDocument,
    options
  );
}
export function useGetUtilityTagsForDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUtilityTagsForDevicesQuery,
    GetUtilityTagsForDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUtilityTagsForDevicesQuery, GetUtilityTagsForDevicesQueryVariables>(
    GetUtilityTagsForDevicesDocument,
    options
  );
}
export function useGetUtilityTagsForDevicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetUtilityTagsForDevicesQuery,
    GetUtilityTagsForDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetUtilityTagsForDevicesQuery,
    GetUtilityTagsForDevicesQueryVariables
  >(GetUtilityTagsForDevicesDocument, options);
}
export type GetUtilityTagsForDevicesQueryHookResult = ReturnType<
  typeof useGetUtilityTagsForDevicesQuery
>;
export type GetUtilityTagsForDevicesLazyQueryHookResult = ReturnType<
  typeof useGetUtilityTagsForDevicesLazyQuery
>;
export type GetUtilityTagsForDevicesSuspenseQueryHookResult = ReturnType<
  typeof useGetUtilityTagsForDevicesSuspenseQuery
>;
export type GetUtilityTagsForDevicesQueryResult = Apollo.QueryResult<
  GetUtilityTagsForDevicesQuery,
  GetUtilityTagsForDevicesQueryVariables
>;
export const GetTagsForDevicesDocument = gql`
  query GetTagsForDevices($deviceIds: [String]!) {
    getTagsForDevices(deviceIds: $deviceIds) {
      success
      message
      devicesWithPredefinedTags {
        deviceId
        predefinedTags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      devicesWithUtilityTags {
        deviceId
        utilityTags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

/**
 * __useGetTagsForDevicesQuery__
 *
 * To run a query within a React component, call `useGetTagsForDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTagsForDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTagsForDevicesQuery({
 *   variables: {
 *      deviceIds: // value for 'deviceIds'
 *   },
 * });
 */
export function useGetTagsForDevicesQuery(
  baseOptions: Apollo.QueryHookOptions<GetTagsForDevicesQuery, GetTagsForDevicesQueryVariables> &
    ({ variables: GetTagsForDevicesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTagsForDevicesQuery, GetTagsForDevicesQueryVariables>(
    GetTagsForDevicesDocument,
    options
  );
}
export function useGetTagsForDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTagsForDevicesQuery, GetTagsForDevicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTagsForDevicesQuery, GetTagsForDevicesQueryVariables>(
    GetTagsForDevicesDocument,
    options
  );
}
export function useGetTagsForDevicesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetTagsForDevicesQuery,
    GetTagsForDevicesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetTagsForDevicesQuery, GetTagsForDevicesQueryVariables>(
    GetTagsForDevicesDocument,
    options
  );
}
export type GetTagsForDevicesQueryHookResult = ReturnType<typeof useGetTagsForDevicesQuery>;
export type GetTagsForDevicesLazyQueryHookResult = ReturnType<typeof useGetTagsForDevicesLazyQuery>;
export type GetTagsForDevicesSuspenseQueryHookResult = ReturnType<
  typeof useGetTagsForDevicesSuspenseQuery
>;
export type GetTagsForDevicesQueryResult = Apollo.QueryResult<
  GetTagsForDevicesQuery,
  GetTagsForDevicesQueryVariables
>;
