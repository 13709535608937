import _ from 'lodash';
import { useState } from 'react';
import { useTheme } from 'styled-components';
import { BatteryLifeResponse, Device, DeviceGroup } from '../../API';
import MeterHealthCertificateModal, {
  QMarginData,
  QMarginDataList,
} from '../../components/MeterHealthCertificateModal/MeterHealthCertificateModal';
import { Reported } from '../../modules/DeviceShadow/DeviceShadow';
import { AdemModel } from '../../utils/AdemUtils';
import DateUtils from '../../utils/DateUtils';
import { AsideTitle, Bedge, ContainerAboutAsset } from './style';

type Props = {
  device?: Device | null;
  ademModel: AdemModel | null;
  deviceGroup?: DeviceGroup | null;
  reported: Reported;
  omitPressure: boolean;
  omitTemperature: boolean;
  qMarginDataList: QMarginDataList;
  currQMargin: QMarginData | undefined;
  batteryLifeData?: BatteryLifeResponse | null;
};

export function MeterIdInfo({
  device,
  ademModel,
  deviceGroup,
  reported,
  omitPressure,
  omitTemperature,
  qMarginDataList,
  currQMargin,
  batteryLifeData,
}: Props) {
  const theme = useTheme();
  const [meterHealthCertificateModalOpen, setMeterHealthCertificateModalOpen] = useState(false);

  const temperatureUnit =
    deviceGroup?.temperatureUnit || device?.group?.utility.company?.temperatureUnit;
  const pressureUnit = deviceGroup?.pressureUnit || device?.group?.utility.company?.pressureUnit;
  const volumeUnit = deviceGroup?.volumeUnit || device?.group?.utility.company?.volumeUnit;
  const flowUnit =
    deviceGroup?.correctedFlowUnit || device?.group?.utility.company?.correctedFlowUnit;

  const ipVMapping: string[] = [
    '1CF',
    '5CF',
    '10CF',
    '100CF',
    '1000CF',
    '0.10m3',
    '1.00m3',
    '10.00m3',
    '100.00m3',
    '0.01m3',
  ];

  return (
    <>
      {qMarginDataList && qMarginDataList.length > 0 && (
        <MeterHealthCertificateModal
          open={meterHealthCertificateModalOpen}
          closeModalFunc={setMeterHealthCertificateModalOpen}
          meterSize={device?.data?.meterSize ?? ''}
          address={device?.address ?? ''}
          companyName={device?.group?.utility?.company?.name ?? ''}
          deviceId={device?.id ?? ''}
          serialNo={device?.serialNo ?? ''}
          qMarginDataList={qMarginDataList}
        />
      )}

      <AsideTitle>
        <h3 style={{ color: theme.colors.black }}>About Asset</h3>
      </AsideTitle>

      <ContainerAboutAsset>
        <div>
          <label>Customer ID</label>
          <span>{device?.meterId || 'Not attached'}</span>
        </div>
        <div>
          <label>Device ID</label>
          <span>{device?.id || 'Not attached'}</span>
        </div>
        <div>
          <label>BrightLync ID</label>
          <span>{device?.serialNo || '-'}</span>
        </div>
        <div>
          <label>Device Type</label>
          <span>AdEM-{ademModel}</span>
        </div>
        <div>
          <label>Asset Group</label>
          <span>{device?.group?.name || 'Not attached'}</span>
        </div>
        <div>
          <label>Address</label>
          <span>{device?.address || 'Not attached'}</span>
        </div>
        <div>
          <label>Pressure Factor Type</label>
          <span>{reported?.prFT ? 'Fixed' : 'Live'}</span>
        </div>
        <div>
          <label>Temperature Factor Type</label>
          <span>{reported?.tempFT ? 'Fixed' : 'Live'}</span>
        </div>
        <div>
          <label>Super-X Factor Type</label>
          <span>{reported?.sxFT ? 'Fixed' : 'Live'}</span>
        </div>
        <div>
          <label>Read date/time</label>
          <span>
            {device?.data?.dTm
              ? DateUtils.formatDateWithTmZn(
                  +device.data.dTm * 1000,
                  device.data.TmZn,
                  device.data.proto
                )
              : '-'}
          </span>
        </div>
        <div>
          <label>Ingestion Time</label>
          <span>
            {device?.data?.ingestionTime
              ? DateUtils.formatDateWithTmZn(
                  +device.data.ingestionTime,
                  device.data.TmZn,
                  device.data.proto
                )
              : '-'}
          </span>
        </div>

        <div>
          <label>Full Corrected Index Read</label>
          <span>
            {_.has(device, 'data.fCV')
              ? `${device?.data?.fCV} ${volumeUnit?.replace('3', '³')}`
              : '-'}
          </span>
        </div>
        <div>
          <label>Full Uncorrected Index Read</label>
          <span>
            {_.has(device, 'data.fUV')
              ? `${device?.data?.fUV} ${volumeUnit?.replace('3', '³')}`
              : '-'}
          </span>
        </div>
        <div>
          <label>Average Uncorrected Flow Rate</label>
          <span>
            {_.has(device, 'data.cFAvg')
              ? `${device?.data?.cFAvg} ${flowUnit?.replace('3', '³')}`
              : '-'}
          </span>
        </div>
        <div>
          <label>Pressure Factor</label>
          <span>{reported?.prF ?? '-'}</span>
        </div>
        <div>
          <label>Max. Pressure</label>
          <span>
            {ademModel !== 'T' && !omitPressure && _.has(device, 'data.maxPr.v')
              ? `${device?.data?.maxPr?.v} ${pressureUnit}`
              : '-'}
          </span>
        </div>
        <div>
          <label>Min Flow Corr</label>
          <span>
            {ademModel !== 'T' && _.has(device, 'data.minFC.v')
              ? `${device?.data?.minFC?.v} ${flowUnit?.replace('3', '³')}`
              : '-'}
          </span>
        </div>
        <div>
          <label>Max. Temp</label>
          <span>
            {ademModel !== 'T' && !omitTemperature && _.has(device, 'data.maxTemp.v')
              ? `${device?.data?.maxTemp?.v} ${temperatureUnit}`
              : '-'}
          </span>
        </div>
        <div>
          <label>Remaining Battery Life</label>
          <span>
            {_.has(batteryLifeData, 'percentage') ? `${batteryLifeData?.percentage}%` : '-'}
          </span>
        </div>
        <div>
          <label>Corr. Index Read (Daily)</label>
          <span>
            {_.has(device, 'data.dayCV')
              ? `${device?.data?.dayCV} ${volumeUnit?.replace('3', '³')}`
              : '-'}
          </span>
        </div>
        <div>
          <label>Uncorr. Index Read (Daily)</label>
          <span>
            {_.has(device, 'data.dayUV')
              ? `${device?.data?.dayUV} ${volumeUnit?.replace('3', '³')}`
              : '-'}
          </span>
        </div>
        <div>
          <label>Meter Size</label>
          <span>{device?.data?.meterSize ? `${device?.data?.meterSize} ` : '-'}</span>
        </div>
        {ademModel === 'PTZR' && (
          <div>
            <label>Input Pulse Volume</label>
            <span>{ipVMapping[reported?.ipV ?? -1] ?? '-'}</span>
          </div>
        )}
        {ademModel === 'Tq' && (
          <div>
            <label>Q Margin</label>
            <span>
              {currQMargin?.value === undefined ? '-' : currQMargin.value > 0 ? 'Pass' : 'Check'}

              {qMarginDataList && qMarginDataList.length > 0 && (
                <Bedge
                  variant="light"
                  onClick={() => setMeterHealthCertificateModalOpen(true)}
                >
                  Certificate
                </Bedge>
              )}
            </span>
          </div>
        )}
      </ContainerAboutAsset>
    </>
  );
}
