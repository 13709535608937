import { ChartData, ChartOptions } from 'chart.js';
import { ChangeEvent, memo } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import Switch from '../../components/Forms/Switch/Switch';
import { DeviceDataFormat } from './MeterIdHelpers';
import { Card, ChartGroup, ChartTitle } from './style';

type Props = {
  data: ChartData<'line' | 'bar'>;
  options: ChartOptions<'line' | 'bar'>;
  hasData?: boolean;
  handleToggleView: (e: ChangeEvent<HTMLInputElement>) => void;
  isLine: boolean;
} & Pick<DeviceDataFormat, 'id' | 'title' | 'omit'>;

export const MeterIdChart = memo(
  ({ id, title, omit, data, hasData, options, isLine, handleToggleView }: Props) => {
    if (!hasData || omit) return null;

    return (
      <ChartGroup
        id={id}
        key={id}
      >
        <ChartTitle>{title}</ChartTitle>
        <Card>
          <Switch
            onChange={handleToggleView}
            checked={isLine}
            label="Lines"
            beforeLabel="Bars"
          />

          {isLine && (
            <Line
              options={options}
              data={data as ChartData<'line'>}
            />
          )}
          {!isLine && (
            <Bar
              options={options}
              data={data as ChartData<'bar'>}
            />
          )}
        </Card>
      </ChartGroup>
    );
  }
);
