import styled from 'styled-components';

export const RadioG = styled.div``;

export const LabelSection = styled.label`
  display: block;
  margin-bottom: 18px;
`;

export const Label = styled.label`
  display: block;
  font-size: 1rem;
  line-height: 32px;
  color: ${(props) => props.theme.colors.black};
`;

export const Info = styled.label`
  display: block;
  font-size: 0.75rem;
  line-height: 24px;
  color: ${(props) => props.theme.colors.lightBlack};
`;
