import styled from 'styled-components';
import Button from '../../components/Button/Button';
import breakpoints from '../../styles/breakpoints';

type PillProps = {
  active?: boolean;
};

export const Container = styled.div``;

export const NavPills = styled.div`
  display: flex;
  gap: 10px;

  @media screen and (min-width: ${(props) => breakpoints.md}) {
    margin-left: 30px;
  }
`;

export const EditBtn = styled(Button)`
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 16px 24px;
  font-size: 16px;
  line-height: 32px;
  position: fixed;
  right: 40px;
  bottom: 40px;

  @media screen and (max-width: ${(props) => breakpoints.lg}) {
    padding: 7px 15px;
  }
`;

export const Pill = styled.div<PillProps>`
  color: #666;
  padding: 10px;
  font-weight: ${(props) => (props.active ? 'bold' : 'lighter')};
  border-bottom: ${(props) => (props.active ? '2px solid #000' : '')};
`;

export const Card = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  padding: 20px;
  font-size: 12px;
  margin-bottom: 15px;
`;

export const CardComment = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  padding: 20px;
  font-size: 12px;
  margin-bottom: 15px;
  border: 1px solid #999;
  margin-left: -50px;
  margin-top: 15px;

  textarea {
    border: 0;
    width: 100%;
    height: 100px;

    &:focus {
      border: 0;
      outline: none;
    }
  }
`;

export const Btns = styled.div`
  display: flex;
  gap: 10px;
`;

export const MainTitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
`;

export const AlarmButton = styled(Button)`
  padding: 6px 8px;
  font-size: 12px;
  line-height: 20px;
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const Col = styled.div`
  flex: 1 0 0%;
  color: ${(props) => props.theme.colors.black};
`;

export const Row = styled.div`
  margin-bottom: 5px;

  @media screen and (min-width: ${(props) => breakpoints.lg}) {
    display: flex;
  }
`;

export const RowSpace = styled.div`
  height: 20px;
`;

export const CardAlarm = styled(Card)`
  background-image: url('/svgs/icon-alert.svg');
  background-repeat: no-repeat;
  background-position: 15px 15px;
  padding-left: 70px;

  &:nth-child(2n + 3) {
    @media screen and (min-width: ${(props) => breakpoints.md}) {
      margin-left: 5%;
    }

    @media screen and (min-width: ${(props) => breakpoints.lg}) {
      margin-left: 0;
    }
  }

  & > ${Row}:last-child ${Col}:first-child {
    max-width: 100%;

    & button:first-child {
      margin-right: 10px;
    }
  }

  div${Col}:first-child {
    max-width: 37%;
  }

  @media screen and (min-width: ${(props) => breakpoints.md}) {
    width: 47.5%;
    float: left;
  }

  @media screen and (min-width: ${(props) => breakpoints.lg}) {
    width: 100%;
    float: initial;
  }

  @media screen and (min-width: ${(props) => breakpoints.lg}) {
    width: 100%;
  }

  div.space {
    margin-top: 30px;
  }
`;
