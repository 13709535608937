import { useCallback, useState } from 'react';
import { Device } from '../../../API';

export function useDeviceTableModal() {
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);

  const [installFirmwareModalOpen, setInstallFirmwareModalOpen] = useState(false);
  const handleInstallFirmware = useCallback(async (device: Device) => {
    setSelectedDevice(device);
    setInstallFirmwareModalOpen(true);
  }, []);

  const [pullDataModalOpen, setPullDataModalOpen] = useState(false);
  const handleDevicePull = useCallback(async (device: Device) => {
    setSelectedDevice(device);
    setPullDataModalOpen(true);
  }, []);

  const [exportAlarmModalOpen, setExportAlarmModalOpen] = useState(false);
  const handleAlarmExport = useCallback(async (device: Device) => {
    setSelectedDevice(device);
    setExportAlarmModalOpen(true);
  }, []);

  const [exportDeviceModalOpen, setExportDeviceModalOpen] = useState(false);
  const handleExportDevices = useCallback(async (device: Device) => {
    setSelectedDevice(device);
    setExportDeviceModalOpen(true);
  }, []);

  const [deviceShadowModalOpen, setDeviceShadowModalOpen] = useState(false);
  const handleShadowDevice = useCallback(async (device: Device) => {
    setSelectedDevice(device);
    setDeviceShadowModalOpen(true);
  }, []);

  return {
    selectedDevice,
    installFirmwareModalOpen,
    setInstallFirmwareModalOpen,
    handleInstallFirmware,
    pullDataModalOpen,
    setPullDataModalOpen,
    handleDevicePull,
    exportAlarmModalOpen,
    setExportAlarmModalOpen,
    handleAlarmExport,
    exportDeviceModalOpen,
    setExportDeviceModalOpen,
    handleExportDevices,
    deviceShadowModalOpen,
    setDeviceShadowModalOpen,
    handleShadowDevice,
  };
}
