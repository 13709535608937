import styled from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${(props) => props.theme.inputHeight};
  border-radius: ${(props) => props.theme.borderRadius};
  border: 1px solid ${(props) => props.theme.colors.gray};
  background-color: transparent;

  transition: all 100ms;

  & svg {
    position: absolute;
    right: ${(props) => props.theme.inputHorizontalPadding};
    transform: translate(0, -50%);
    top: 50%;
    color: ${(props) => props.theme.colors.primaryText};
    pointer-events: none;
  }
`;

export const Select = styled.select`
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.black};
  border: none;
  outline: none;
  appearance: none;
  padding: 0 ${(props) => props.theme.inputHorizontalPadding};
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) => props.theme.colors.white};
`;

export const Label = styled.label`
  position: absolute;
  top: 0;
  left: 8px;
  z-index: 10;
  background-color: white;
  transform: translate(0, -50%);
  font-size: 12px;
  color: ${(props) => props.theme.colors.primaryText};
  background-color: ${(props) => props.theme.colors.white};
  padding: 0 5px;
`;
