import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Device } from '../../../API';
import { useSetThresholds } from '../../../hooks/mutations';
import { useDeviceShadow } from '../../../hooks/queries';
import { DeviceShadowRaw } from '../../../modules/DeviceShadow/DeviceShadow';
import AdemUtils, { AdemModel } from '../../../utils/AdemUtils';
import DateUtils from '../../../utils/DateUtils';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import Range from '../../Forms/_Range/Range';
import PRHL from '../AssetConfigurationModal/classes/prHL';
import PRLL from '../AssetConfigurationModal/classes/prLL';
import TEMPHL from '../AssetConfigurationModal/classes/tempHL';
import TEMPLL from '../AssetConfigurationModal/classes/tempLL';
import UFHL from '../AssetConfigurationModal/classes/ufHL';
import UFLL from '../AssetConfigurationModal/classes/ufLL';
import Modal, { ModalOpeningProps } from '../Modal';
import { AssetInfoContainer } from '../style';

type Props = {
  device: Device;
  defaultValues: ThresholdsFormType;
  onThresholdsUpdated: () => void;
};

export type ThresholdsFormType = {
  minPr?: string;
  maxPr?: string;
  minFC?: string;
  maxFC?: string;
  minTemp?: string;
  maxTemp?: string;
  bV?: string;
  ccbbV?: string;
  pofMF?: string;
  bL?: string;
};

const SetThresholdsModal: React.FC<ModalOpeningProps & Props> = ({
  closeModalFunc,
  onThresholdsUpdated,
  open,
  device,
  defaultValues,
}) => {
  const { register, getValues, setValue } = useForm<ThresholdsFormType>();
  const formRef = useRef<HTMLFormElement>(null);
  const { mutateAsync: setThresholds, isLoading: settingThresholds } = useSetThresholds();

  useEffect(() => {
    const entries = Object.entries(defaultValues);
    const formattedEntries = entries.map(([key, value]) => {
      return {
        key,
        value,
      };
    });

    for (const entry of formattedEntries) {
      const key = entry.key as keyof ThresholdsFormType;
      const value = entry.value.toString();

      setValue(key, key === 'bL' ? DateUtils.daysToMonths(value).toString() : value);
    }

    if (formRef.current) {
      const inputSliderContainer =
        formRef.current.querySelector<HTMLDivElement>('.slider-container')!;

      formRef.current!.querySelectorAll('.range-container').forEach((container) => {
        if (!container) return;

        const input = container.querySelector<HTMLInputElement>('input')!;

        const inputWidth = inputSliderContainer.offsetWidth;
        const inputName = input.name;
        const min = parseInt(input.min);
        const max = parseInt(input.max);
        console.log(input.value);
        const inputValue =
          formattedEntries.find((entry) => entry.key === inputName)?.value || input.value;

        const thumbXPosition =
          ((parseInt(inputValue) - min) / (max - min)) * (inputWidth - 12 - 12) + 12;
        console.log('Looping');
        const thumbContainer = container.querySelector<HTMLDivElement>('.thumb-number')!;
        console.log(container);

        if (thumbContainer) {
          thumbContainer.style.left = `${thumbXPosition - 24 / 2}px`;
          thumbContainer.textContent = inputValue;
        }
      });
    }
  }, [defaultValues]);

  const deviceShadow = useDeviceShadow({
    variables: {
      deviceId: device.id,
      region: device.group?.utility.company?.region!,
      prettify: false,
    },
    additionalOptions: {
      enabled: !!device,
    },
  });

  const deviceShadowParsed = JSON.parse(
    deviceShadow.data?.getDeviceShadow || '{}'
  ) as DeviceShadowRaw;

  const reported = deviceShadowParsed?.state?.reported;

  // Ensure that we have a shadow so we can use the data to set the min/max values
  if (!reported) {
    return null;
  }

  const params = {
    deviceShadow: deviceShadowParsed,
    register,
  };

  const MtrSz = reported.MtrSz.trim().replace(/ +/g, '');

  const prLL = new PRLL(params);
  const prHL = new PRHL(params);
  const ufLL = new UFLL(params);
  const ufHL = new UFHL(params);
  const tempLL = new TEMPLL(params);
  const tempHL = new TEMPHL(params);

  /**
   * Limit values are stored as 120% of max.
   * Our thresholds should be 150% of max.
   */
  const from120To150 = (value: number): number => {
    return value * (1.5 / 1.2);
  };

  const minPr = from120To150(prLL.getRangeProps().min);
  const maxPr = from120To150(prHL.getRangeProps().max);
  const minFC = from120To150(ufLL.getRangeProps().min);
  const maxFC = from120To150(ufHL.getRangeProps().max);
  const minTemp = from120To150(tempLL.getRangeProps().min);
  const maxTemp = from120To150(tempHL.getRangeProps().max);

  const ademModel: AdemModel | null = AdemUtils.parseAdemModel(reported?.FWV);

  const onSetThresholdsSubmit = async () => {
    const data = getValues();
    const dataEntries = Object.entries(data);

    type Entry = { key: string; value: string | number };
    const formattedEntries: Entry[] = [];

    dataEntries.forEach(([key, value]) => {
      if (value === undefined) return;

      formattedEntries.push({
        key,
        value: key === 'bL' ? DateUtils.monthsToDays(value) : value,
      });
    });

    await setThresholds({
      deviceIds: [device.id],
      thresholds: formattedEntries as any,
    });

    onThresholdsUpdated();
    closeModalFunc(false);
  };

  return (
    <Modal
      title="Set Thresholds"
      description="Please use the sliders below to set your thresholds."
      onSubmitBtnClick={onSetThresholdsSubmit}
      buttonText="Save Changes"
      buttonLoading={settingThresholds}
      closeModalFunc={closeModalFunc}
      open={open}
      plusContent={
        <div
          style={{
            width: 175,
            marginRight: 32,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            height: '100%',
          }}
        >
          <AssetInfoContainer>
            <div>
              <h2>Asset Info</h2>
            </div>
            <hr />
            <div>
              <label>Device Type</label>
              <span>AdEM-{ademModel}</span>
            </div>
            <div>
              <label>Meter Size</label>
              <span>{reported?.MtrSz}</span>
            </div>
            <div>
              <label>Pressure Factor Type</label>
              <span>{reported?.prFT ? 'Fixed' : 'Live'}</span>
            </div>
            <div>
              <label>Temperature Factor Type</label>
              <span>{reported?.tempFT ? 'Fixed' : 'Live'}</span>
            </div>
            <div>
              <label>Super-X Factor Type</label>
              <span>{reported?.sxFT ? 'Fixed' : 'Live'}</span>
            </div>
          </AssetInfoContainer>
        </div>
      }
    >
      <form ref={formRef}>
        <FormGroup>
          <Range
            id="minPressure"
            label="Minimum Pressure"
            min={minPr}
            value={defaultValues.minPr ? parseFloat(defaultValues.minPr) : minPr}
            max={maxPr}
            step={0.01}
            register={register}
            name="minPr"
            disabled={reported?.prFT ? true : false}
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="maxPressure"
            label="Max Pressure"
            min={minPr}
            value={defaultValues.maxPr ? parseFloat(defaultValues.maxPr) : maxPr}
            max={maxPr}
            step={0.01}
            register={register}
            name="maxPr"
            disabled={reported?.prFT ? true : false}
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="minCorrectedFlow"
            label="Minimum Corrected Flow"
            min={minFC}
            value={defaultValues.minFC ? parseFloat(defaultValues.minFC) : minFC}
            max={maxFC}
            register={register}
            name="minFC"
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="maxCorrectedFlow"
            label="Maximum Corrected Flow"
            min={minFC}
            value={defaultValues.maxFC ? parseFloat(defaultValues.maxFC) : maxFC}
            max={maxFC}
            register={register}
            name="maxFC"
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="minTemperature"
            label="Minimum Temperature"
            min={minTemp}
            value={defaultValues.minTemp ? parseFloat(defaultValues.minTemp) : minTemp}
            max={maxTemp}
            step={0.1}
            register={register}
            name="minTemp"
            disabled={reported?.tempFT ? true : false}
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="MaxTemp"
            label="Maximum Temperature"
            min={minTemp}
            value={defaultValues.maxTemp ? parseFloat(defaultValues.maxTemp) : maxTemp}
            max={maxTemp}
            step={0.1}
            register={register}
            name="maxTemp"
            disabled={reported?.tempFT ? true : false}
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="percentageMaxFlow"
            label="Percentage of Max Flow"
            min={0}
            value={defaultValues.pofMF ? parseFloat(defaultValues.pofMF) : 50}
            max={50}
            register={register}
            name="pofMF"
            disabled={ademModel !== 'Tq'}
          />
        </FormGroup>

        <FormGroup>
          <Range
            id="batteryLifeMonths"
            label="Remaining Months of Battery Life"
            min={0}
            value={defaultValues.bL ? DateUtils.daysToMonths(defaultValues.bL) : 12}
            max={60}
            register={register}
            name="bL"
          />
        </FormGroup>
      </form>
    </Modal>
  );
};

export default SetThresholdsModal;
