import { memo } from 'react';
import { Device, TagCategory } from '../../API';
import { linkTagListToTagCategories } from '../../utils/TagUtils';
import { AsideTitle, ContainerAboutAsset } from './style';

type Props = {
  device?: Device | null;
  predefinedTagCategories: TagCategory[];
  utilityTagCategories: TagCategory[];
};

export const MeterIdTags = memo(
  ({ device, predefinedTagCategories, utilityTagCategories }: Props) => {
    const predefinedTagCategoryCurrentTag = linkTagListToTagCategories(
      device?.tags?.predefinedTags ?? [],
      predefinedTagCategories
    );
    const utilityTagCategoryCurrentTag = linkTagListToTagCategories(
      device?.tags?.utilityTags ?? [],
      utilityTagCategories
    );

    return (
      <>
        <AsideTitle>
          <h3>Tags</h3>
        </AsideTitle>

        <ContainerAboutAsset>
          {predefinedTagCategories.map((tagCategory) => {
            return (
              <div key={tagCategory.id}>
                <label>{tagCategory.name}</label>
                <span>{predefinedTagCategoryCurrentTag.get(tagCategory.id)?.name ?? '-'}</span>
              </div>
            );
          })}

          {utilityTagCategories.map((tagCategory) => {
            return (
              <div key={tagCategory.id}>
                <label>{tagCategory.name}</label>
                <span>{utilityTagCategoryCurrentTag.get(tagCategory.id)?.name ?? '-'}</span>
              </div>
            );
          })}
        </ContainerAboutAsset>
      </>
    );
  }
);
