import React from 'react';
import { UseFormRegister } from 'react-hook-form';

import { ErrorMessage, Input, InputWrapper } from './style';

type FormControlProps = {
  label: string;
  name?: string;
  id: string;
  error?: string;
  register?: UseFormRegister<any>;
  value?: string;
  rows?: number;
};

const TextArea: React.FC<FormControlProps> = ({
  label,
  name,
  id,
  error,
  register,
  value,
  rows,
}) => {
  return (
    <>
      <InputWrapper error={!!error}>
        <Input
          error={!!error}
          placeholder={label}
          id={id}
          defaultValue={value}
          {...register!(name || '')}
          rows={rows}
        />
      </InputWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default TextArea;
