import AdemUtils from '../../../../utils/AdemUtils';
import RangeAsset, {
  ConstructorParams,
  DeviceShadowAliasesAcceptedByRangeAsset,
} from './RangeAsset';
import UFHL from './ufHL';

export default class TEMPHL extends RangeAsset {
  protected label: string = 'Temperature Alarm High Limit';
  public deviceShadowAlias: DeviceShadowAliasesAcceptedByRangeAsset = 'tempHL';

  constructor({ deviceShadow, register }: ConstructorParams) {
    super({ deviceShadow, register });

    this.setMeterInfo();
  }

  setMeterInfo() {
    // TODO : If cVUt is not set for some reason, we should show an error message.
    const cVUt = this.deviceShadow.state.reported.cVUt ?? -1;
    const isImperial = AdemUtils.checkMeasurementSystem(cVUt) === 'imperial';

    this.unitType = isImperial ? 'imperial' : 'metric';
    this.step = 0.1;
    this.meterUnit = 'all';

    if (isImperial) {
      this.min = 68;
      this.max = 161.6;
    } else {
      this.min = 20;
      this.max = 72;
    }
  }
}
