import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import _ from 'lodash';
import moment from 'moment';
import { ChangeEvent, memo, useEffect, useMemo, useRef, useState } from 'react';
import { HiDownload } from 'react-icons/hi';
import { IoMdTrash } from 'react-icons/io';
import { MdOutlineDateRange, MdStackedLineChart } from 'react-icons/md';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Device, DeviceGroup, MeterDataItem } from '../../API';
import Alert from '../../components/Alert/Alert';
import Button from '../../components/Button/Button';
import Checkbox from '../../components/Forms/Checkbox/Checkbox';
import FormControl from '../../components/Forms/FormControl/FormControl';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';
import FormRow from '../../components/Forms/FormRow/FormRow';
import Radio from '../../components/Forms/Radio/Radio';
import Select from '../../components/Forms/Select/Select';
import { QMarginDataList } from '../../components/MeterHealthCertificateModal/MeterHealthCertificateModal';
import ExportAssetModal from '../../components/Modal/ExportAssetModal/ExportAssetModal';
import Modal from '../../components/Modal/Modal';
import Spinner from '../../components/Spinner/Spinner';
import Typography from '../../components/Typography/Typography';
import WithPadding from '../../components/WithPadding/WithPadding';
import errorMessages from '../../config/errorMessages';
import { AdemModel } from '../../utils/AdemUtils';
import {
  chartLineBarOptions,
  getDailyDeviceCompanyData,
  getDeviceDataFormat,
  getHourlyDeviceCompanyData,
} from './MeterIdHelpers';

import { useTheme } from 'styled-components';
import { Frequency } from './MeterId';
import { MeterIdChart } from './MeterIdChart';
import { ContainerButton, RadioGroupInline, SectionFilter } from './style';

type Props = {
  device?: Device | null;
  deviceGroup?: DeviceGroup | null;
  ademModel: AdemModel | null;
  omitPressure: boolean;
  omitTemperature: boolean;
  qMarginDataList: QMarginDataList;
  meterData?: (MeterDataItem | null)[];
  isLoadingMeterData?: boolean;
  volumeUnit?: string;
  flowUnit?: string;
  frequency: Frequency;
  setFrequency: (frequency: Frequency) => void;
  period: Period;
  setPeriod: (period: Period) => void;
  setFromDate: (fromDate: string) => void;
  setToDate: (toDate: string) => void;
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

// eslint-disable-next-line no-unused-vars
enum Period {
  D90,
  CURRENT_MONTH,
  THIS_VS_LAST_MONTH,
  M13,
  CUSTOM,
}

const schema = yup.object({
  comment: yup.string().required(errorMessages.required),
});

const start = new Date();
start.setDate(start.getDate() - 30);

const defaultToDate = new Date();
const defaultFromDate = start;

export const MeterIdChartsSection = memo(
  ({
    device,
    meterData,
    isLoadingMeterData,
    deviceGroup,
    omitPressure,
    omitTemperature,
    ademModel,
    qMarginDataList,
    frequency,
    setFrequency,
    period,
    flowUnit,
    volumeUnit,
    setFromDate,
    setToDate,
    setPeriod,
  }: Props) => {
    const { colors } = useTheme();
    const navigationRef = useRef<HTMLDivElement>(null);

    const { register: periodRegister, handleSubmit } = useForm<any>();
    const {
      getValues: customizeGraphGetValue,
      register: customizeGraphRegister,
      reset: customizeGraphReset,
    } = useForm({
      resolver: yupResolver(schema),
    });

    const [customDateModalOpen, setCustomDateModalOpen] = useState(false);
    const [customizeModalOpen, setCustomizeModalOpen] = useState(false);
    const [exportDeviceModalOpen, setExportDeviceModalOpen] = useState(false);

    const temperatureUnit =
      deviceGroup?.temperatureUnit || device?.group?.utility.company?.temperatureUnit;
    const pressureUnit = deviceGroup?.pressureUnit || device?.group?.utility.company?.pressureUnit;
    const voltageUnit = deviceGroup?.voltageUnit || device?.group?.utility.company?.voltageUnit;

    const dailyDeviceCompanyData = useMemo(
      () => getDailyDeviceCompanyData(omitPressure, omitTemperature),
      [omitPressure, omitTemperature]
    );
    const hourlyDeviceCompanyData = useMemo(
      () => getHourlyDeviceCompanyData(omitPressure, omitTemperature),
      [omitPressure, omitTemperature]
    );

    const deviceDataFormat = useMemo(
      () => getDeviceDataFormat(frequency, omitPressure, ademModel),
      [ademModel, frequency, omitPressure]
    );

    const deviceChartData = useMemo(
      () =>
        deviceDataFormat.map(({ title, datasets, id, scale, omit }) => {
          const hasData = !!meterData?.some((meter) => {
            return !_.isNil((meter as any)[datasets[0].selector]);
          });

          const isQMargin = id === 'qMargin';
          const labels = isQMargin
            ? qMarginDataList.map((data) => data.label)
            : meterData?.map((meter) => meter!.time!);

          const data = {
            labels,
            datasets: datasets.map(({ title, selector }, index) => {
              let data = isQMargin
                ? qMarginDataList.map((data) => data.value)
                : meterData?.map((meter) => (meter as any)[selector]) || [];

              return {
                label: title,
                data,
                borderColor: index === 0 ? '#00A9E0' : '#0C2340',
                backgroundColor: index === 0 ? '#00A9E0' : '#0C2340',
                pointRadius: 0,
                yAxisID: scale,
              };
            }),
          };

          return { data, hasData, title, id, omit };
        }),
      [deviceDataFormat, meterData, qMarginDataList]
    );

    const [customizedGraphNames, setCustomizedGraphNames] = useState<string[][]>([]);

    const customizedGraphsData = useMemo(
      () =>
        customizedGraphNames.map((names, index) => {
          const getRightData = (name: string) => {
            for (const dailyData of dailyDeviceCompanyData) {
              if (name === dailyData.value) return dailyData;
            }
            for (const hourlyData of hourlyDeviceCompanyData) {
              if (name === hourlyData.value) return hourlyData;
            }
          };

          const chartColors = ['#0C2340', '#00A9E0', '#FF9900', colors.danger];

          return {
            title: `Graph custom ${index + 1}`,
            data: {
              labels: meterData?.map((meter) => meter!.time!),
              datasets: names.map((name, index) => {
                const rightData = getRightData(name)!;
                const data = meterData?.map((meter) => (meter! as any)[rightData.value]) || [];

                return {
                  label: rightData.title,
                  data,
                  borderColor: chartColors[index % chartColors.length],
                  backgroundColor: chartColors[index % chartColors.length],
                  pointRadius: 0,
                  yAxisID: rightData.scale,
                };
              }),
            },
          };
        }),
      [
        colors.danger,
        customizedGraphNames,
        dailyDeviceCompanyData,
        hourlyDeviceCompanyData,
        meterData,
      ]
    );

    const [isLine, setIsLine] = useState(true);

    const handleToggleView = (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.currentTarget.checked;

      setIsLine(newValue);
    };

    const options = useMemo(
      () => chartLineBarOptions(volumeUnit, flowUnit, pressureUnit, voltageUnit, temperatureUnit),
      [flowUnit, pressureUnit, temperatureUnit, voltageUnit, volumeUnit]
    );

    const handleUpdateCustomizeBtnClick = async () => {
      const { customize } = customizeGraphGetValue();

      if (!customize) return;

      const newCustomizedGraphs = [...customizedGraphNames];
      newCustomizedGraphs.push([...customize]);

      customizeGraphReset();
      setCustomizedGraphNames(newCustomizedGraphs);
      setCustomizeModalOpen(false);
    };

    const handleCustomDateBtnClick = () => {
      const handleClick = handleSubmit((values) => {
        setFromDate(moment(values.fromDate).format('MM-DD-YYYY'));
        setToDate(moment(values.toDate).format('MM-DD-YYYY'));
      });

      handleClick();

      setCustomDateModalOpen(false);
    };

    const handleExportDevicesBtn = async () => {
      setExportDeviceModalOpen(true);
    };

    useEffect(() => {
      if (period === Period.CUSTOM) {
        setCustomDateModalOpen(true);
      }
    }, [period]);

    useEffect(() => {
      const mainLayoutEl = document.querySelector('#root > div > div');

      const onScroll = () => {
        if (navigationRef.current) {
          const navigationBounds = navigationRef.current.getBoundingClientRect();
          const topToNavigationSize = navigationBounds.top;
          const navigationDiv = navigationRef.current.querySelector('div')!;

          if (topToNavigationSize < 0) {
            navigationDiv.style.position = 'fixed';
          } else {
            navigationDiv.style.position = 'relative';
          }
        }
      };

      mainLayoutEl?.removeEventListener('scroll', onScroll);
      mainLayoutEl?.addEventListener('scroll', onScroll);

      return () => {
        mainLayoutEl?.removeEventListener('scroll', onScroll);
      };
    }, []);

    return (
      <>
        <Modal
          closeModalFunc={setCustomDateModalOpen}
          title="Custom Date"
          description="Change meter data custom date"
          open={customDateModalOpen}
          buttonText="Set Custom Date"
          onSubmitBtnClick={handleCustomDateBtnClick}
        >
          <form>
            <FormGroup>
              <FormRow>
                <FormControl
                  name="fromDate"
                  label="From"
                  type={'date'}
                  id="fromDate"
                  value={moment(
                    new Date(defaultFromDate.getFullYear(), defaultFromDate.getMonth())
                  ).format('YYYY-MM-DD')}
                  register={periodRegister}
                />
                <FormControl
                  register={periodRegister}
                  name="toDate"
                  label="To"
                  value={moment(defaultToDate).format('YYYY-MM-DD')}
                  type={'date'}
                  id="toDate"
                />
              </FormRow>
            </FormGroup>
          </form>
        </Modal>

        <Modal
          closeModalFunc={setCustomizeModalOpen}
          title="Customize Graph"
          description="Please select what data to include in your custom graph."
          open={customizeModalOpen}
          buttonText="Customize graph"
          onSubmitBtnClick={handleUpdateCustomizeBtnClick}
        >
          <form>
            <WithPadding title="Daily Metrics">
              <FormGroup>
                {dailyDeviceCompanyData.map(({ title, value, disabled = false }, index) => {
                  return (
                    <div key={`${value}_${index}`}>
                      <Checkbox
                        register={customizeGraphRegister}
                        label={title}
                        value={value}
                        name="customize[]"
                        disabled={disabled}
                      />
                    </div>
                  );
                })}
              </FormGroup>
            </WithPadding>

            <WithPadding title="Hourly Metrics">
              <FormGroup>
                {hourlyDeviceCompanyData.map(({ title, value, disabled = false }, index) => {
                  return (
                    <div key={`${value}-${index}`}>
                      <Checkbox
                        register={customizeGraphRegister}
                        label={title}
                        value={value}
                        name="customize[]"
                        disabled={disabled}
                      />
                    </div>
                  );
                })}
              </FormGroup>
            </WithPadding>
          </form>
          <div
            style={{
              marginLeft: '8px',
              marginRight: '8px',
              marginBottom: '16px',
            }}
          >
            <Alert variant="info">
              Daily and hourly metrics will be graphed separately. Use the frequency selector to
              display the corresponding data.
            </Alert>
          </div>
        </Modal>

        <ExportAssetModal
          devicesToBeExported={[device]}
          open={exportDeviceModalOpen}
          flowUnit={flowUnit}
          volumeUnit={volumeUnit}
          closeModalFunc={setExportDeviceModalOpen}
        />
        <SectionFilter>
          <div>
            <RadioGroupInline>
              <Radio
                id="Hourly"
                label="Hourly"
                name="frequency"
                value="hourly"
                checked={'hourly' === frequency}
                onChange={(e) => setFrequency(e.currentTarget.value as any)}
              />

              <Radio
                id="Daily"
                label="Daily"
                name="frequency"
                value="daily"
                checked={'daily' === frequency}
                onChange={(e) => setFrequency(e.currentTarget.value as any)}
              />

              {/* <DropdownButton
                  variant="light"
                  renderContent={
                    <>
                      <Button
                        variant="light"
                        onClick={() => setPeriod(Period.CUSTOM)}
                      >
                        Custom
                      </Button>
                      <Button
                        variant="light"
                        onClick={() => setPeriod(Period.THIS_VS_LAST_MONTH)}
                      >
                        Month vs Last
                      </Button>
                      <Button
                        variant="light"
                        onClick={() => setPeriod(Period.D90)}
                      >
                        90 days
                      </Button>
                    </>
                  }
                >
                  {period} days
                </DropdownButton> */}

              <Select
                label=""
                onChange={(e) => setPeriod(parseInt(e.currentTarget.value))}
                name="period"
                register={periodRegister}
              >
                <option value={Period.D90}>90 days</option>
                <option value={Period.THIS_VS_LAST_MONTH}>Month vs Last</option>
                <option value={Period.M13}>13 months</option>
                <option value={Period.CUSTOM}>Custom</option>
              </Select>
            </RadioGroupInline>
          </div>

          <div>
            {period === Period.CUSTOM && (
              <Button
                variant="light-primary"
                onClick={() => {
                  setCustomDateModalOpen(true);
                }}
                startIcon={<MdOutlineDateRange size={14} />}
              >
                Change Date
              </Button>
            )}

            <Button
              variant="light-primary"
              onClick={() => {
                handleExportDevicesBtn();
              }}
              startIcon={<HiDownload size={14} />}
            >
              Download
            </Button>

            <Button
              onClick={() => {
                setCustomizeModalOpen(true);
              }}
              variant="light-primary"
              startIcon={<MdStackedLineChart size={14} />}
            >
              Customize
            </Button>

            {customizedGraphNames.length > 0 && (
              <Button
                onClick={() => {
                  setCustomizedGraphNames([]);
                }}
                variant="danger"
                startIcon={<IoMdTrash size={14} />}
              >
                Reset
              </Button>
            )}
          </div>
        </SectionFilter>

        <Typography
          color={colors.black}
          fontWeight={700}
          fontSize={14}
          lineHeight={'24px'}
        >
          Navigate to:
        </Typography>
        <div
          ref={navigationRef}
          style={{
            height: 60,
          }}
        >
          <ContainerButton>
            {deviceDataFormat.map(({ button, id }) => {
              if (!button || button.omit) return null;

              const { icon: Icon, text } = button;

              return (
                <Button
                  key={id}
                  onClick={function () {
                    document.querySelector('#' + id)?.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }}
                  startIcon={<Icon />}
                  size="sm"
                  variant="light-primary"
                  buttonStyle={{
                    marginRight: 10,
                  }}
                  disabled={button.disabled}
                >
                  {text}
                </Button>
              );
            })}
          </ContainerButton>
        </div>

        {isLoadingMeterData && <Spinner variant="primary" />}

        {!isLoadingMeterData && (
          <>
            {!meterData?.length && <h3>Data not available</h3>}
            {customizedGraphsData.map(({ title, data }, index) => {
              return (
                <MeterIdChart
                  key={`${title}-${index}`}
                  options={options}
                  id={`${title}-${index}`}
                  title={title}
                  hasData={true}
                  data={data}
                  isLine={isLine}
                  handleToggleView={handleToggleView}
                />
              );
            })}
            {deviceChartData.map(({ title, data, id, omit, hasData }) => (
              <MeterIdChart
                key={id}
                options={options}
                id={id}
                title={title}
                hasData={hasData}
                data={data}
                omit={omit}
                isLine={isLine}
                handleToggleView={handleToggleView}
              />
            ))}
          </>
        )}
      </>
    );
  }
);
