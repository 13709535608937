import moment from 'moment';
import { useListDevices, useGetDeviceGroups } from '../../../hooks/queries';
import { useMemo } from 'react';

export const useMeterIdDevice = (id?: string) => {
  const {
    data: devicesData,
    refetch: refetchDevice,
    isLoading: isLoadingDevice,
  } = useListDevices({
    variables: {
      date: moment().format('YYYY-M-D'),
      meterId: '',
      deviceId: id,
    },
  });

  const deviceGroups = useGetDeviceGroups();

  const device = useMemo(() => devicesData?.listDevices.rows[0], [devicesData?.listDevices.rows]);

  const deviceUtilityId = useMemo(() => device?.group?.utility.id, [device?.group?.utility.id]);

  const groups = useMemo(
    () => deviceGroups.data?.getDeviceGroups,
    [deviceGroups.data?.getDeviceGroups]
  );

  const deviceGroup = useMemo(
    () => groups?.find((group) => group?.name === device?.group?.name),
    [device?.group?.name, groups]
  );

  const volumeUnit = useMemo(
    () => deviceGroup?.volumeUnit || device?.group?.utility.company?.volumeUnit,
    [device?.group?.utility.company?.volumeUnit, deviceGroup?.volumeUnit]
  );
  const flowUnit = useMemo(
    () => deviceGroup?.correctedFlowUnit || device?.group?.utility.company?.correctedFlowUnit,
    [device?.group?.utility.company?.correctedFlowUnit, deviceGroup?.correctedFlowUnit]
  );

  return {
    device,
    deviceGroup,
    deviceUtilityId,
    volumeUnit,
    flowUnit,
    refetchDevice,
    isLoadingDevice,
  };
};
