import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CreateUserMutationVariables, Group, User } from '../../../API';
import errorMessages from '../../../config/errorMessages';
import { useAuth } from '../../../contexts/Auth';
import { useAddToGroup, useRemoveFromGroup } from '../../../hooks/mutations';
import { useGroupList, useGroupListForUsers } from '../../../hooks/queries';
import Alert from '../../Alert/Alert';
import Button from '../../Button/Button';
import Checkbox from '../../Forms/Checkbox/Checkbox';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import FormRow from '../../Forms/FormRow/FormRow';
import RadioGroup from '../../Forms/RadioGroup/RadioGroup';
import Select from '../../Forms/Select/Select';
import Modal from '../Modal';

type FormVariables = CreateUserMutationVariables &
  GroupData & { confirmPassword: string; agree: string };

type GroupData = Group & {
  company: string;
  utility: {
    utility: string;
  }[];
  group: {
    group: string;
  }[];
  role: string;
};

type UserType = 'admin' | 'without-utility' | 'with-utility' | 'with-group';

const editUserSchema = yup.object().shape({
  groups: yup.array().min(1, errorMessages.minCharacters(1)),
});

type EditUserModalProps = {
  open: boolean;
  closeModalFunc: (open: boolean) => void;
  userBeingEdited: User | null;
  onUserEdited: () => void;
};

const EditUserModal: React.FC<EditUserModalProps> = ({
  open,
  closeModalFunc,
  userBeingEdited,
  onUserEdited,
}) => {
  const { user } = useAuth();

  const [isAdmin, setIsAdmin] = useState(signedUserIsAdmin());
  const [allUtilityNames, setAllUtilityNames] = useState<string[]>([]);
  const [allGroupNames, setAllGroupNames] = useState<string[]>([]);

  const [loadingUserCreation, setLoadingUserCreation] = useState(false);
  const [editError, setEditError] = useState('');

  const rolesOfUtilities = ['UtilityAdmin', 'UtilityPowerUser'];
  const rolesOfDeviceGroups = ['DeviceGroupAdmin'];

  const { data: useGroupListData } = useGroupList({});

  const allUserGroups = useMemo(
    () => (useGroupListData?.groupList?.groups as GroupData[]) || [],
    [useGroupListData?.groupList?.groups]
  );
  const userGroupsData = useMemo(buildAllUserGroupData, [allUserGroups]);
  const allCompanyNames = useMemo(getAllCompanyNames, [userGroupsData]);
  const allRoleNames = useMemo(getAllRoleNames, [userGroupsData]);

  const {
    register,
    formState: { errors },
    trigger,
    control,
    getValues,
    setError,
    watch,
  } = useForm<FormVariables>({
    resolver: yupResolver(editUserSchema),
  });

  const {
    fields: utilitiesFields,
    remove: removeUtilitiesFields,
    replace: replaceUtilitiesFields,
  } = useFieldArray({
    control,
    name: 'utility' as never,
  });

  const {
    fields: groupsFields,
    remove: removeGroupsFields,
    replace: replaceGroupsFields,
  } = useFieldArray({
    control,
    name: 'group' as never,
  });

  const {
    data: editUserGroupsResponse,
    isLoading: loadingUserGroups,
    refetch: fetchGroupListForEditUser,
  } = useGroupListForUsers({
    variables: {
      users: [userBeingEdited?.username!],
    },
    additionalOptions: {
      enabled: !!userBeingEdited?.username,
    },
  });

  const editUserGroups = useMemo(
    () => editUserGroupsResponse?.groupListForUsers[0]?.groups || [],
    [editUserGroupsResponse?.groupListForUsers]
  );

  const editedGroupsData = useMemo(buildEditedUserGroupData, [editUserGroups]);

  const { mutateAsync: addToGroupMutation } = useAddToGroup();
  const { mutateAsync: removeUserFromGroupMutation } = useRemoveFromGroup();

  function buildGroupData(group: any): GroupData[] {
    return group.map((group: any) => {
      const regexDivided = [...group?.description?.matchAll(/(\w+):\s+([\w-]+)/g)!];

      const dividedToObject = regexDivided.reduce((accumulator: any, currentValue) => {
        const name = currentValue[1];
        const value = currentValue[2];

        accumulator[name] = value;

        return accumulator;
      }, {});

      return {
        ...dividedToObject,
        ...group,
      };
    });
  }

  function buildAllUserGroupData() {
    return buildGroupData(allUserGroups);
  }

  function buildEditedUserGroupData() {
    return buildGroupData(editUserGroups);
  }

  function getAllCompanyNames(): (string | undefined)[] {
    const companies = userGroupsData
      .map((group) => {
        if (!group) return;

        return group!.company;
      })
      .filter((company: any) => !!company)
      .filter((company, index, array) => index === array.indexOf(company!));

    return companies;
  }

  function getAllRoleNames(): (string | undefined)[] {
    const roles = userGroupsData
      .map((group) => {
        if (!group) return;

        return group!.role;
      })
      .filter((role) => !!role)
      .filter((role, index, array) => index === array.indexOf(role!));

    return roles;
  }

  function getGroupsByQuery(query: any) {
    return userGroupsData.filter((group) => {
      const queryEntries = Object.entries(query);

      return queryEntries.every(([key, value]) => {
        return (group as any)[key] === value;
      });
    });
  }

  function signedUserIsAdmin() {
    const allGroups = user['cognito:groups'];

    const isAdmin = allGroups.some((group: any) => {
      const [_, role] = group.split('/');

      return role === 'admin';
    });

    return isAdmin;
  }

  function editedUserIsAdmin() {
    const isAdmin = editUserGroups.some((group) => {
      const [_, role] = group!.name!.split('/');

      return role === 'admin';
    });

    return isAdmin;
  }

  const toggleUserType = (isAdmin: boolean) => setIsAdmin(isAdmin);

  const isButtonDisabled = () => {
    if (isAdmin) {
      return !watch('agree');
    }

    return false;
  };

  const setUtilitiesBasedOnCompanies = () => {
    const company = watch('company');
    const role = watch('role');

    if (!company || !role) return;

    const utilities = getGroupsByQuery({
      company,
      role,
    }).map((company) => company.utility) as any;

    removeUtilitiesFields();
    replaceUtilitiesFields(
      utilities.map((utility: any) => ({
        utility,
      }))
    );
    setAllUtilityNames(utilities);
  };

  const setGroupsBasedOnCompanies = () => {
    const company = watch('company');
    const role = watch('role');

    if (!company || !role) return;

    const groups = getGroupsByQuery({
      company,
      role,
    }).map((company) => company.group);

    removeGroupsFields();
    replaceGroupsFields(
      groups.map((group) => ({
        group,
      }))
    );

    setAllGroupNames(groups as any);
  };

  const roleNeedUtilities = (): boolean => {
    const role = watch('role');

    if (!role) return false;

    return rolesOfUtilities.includes(role!);
  };

  const roleNeedGroups = (): boolean => {
    const role = watch('role');

    if (!role) return false;

    return role === 'DeviceGroupAdmin';
  };

  const getUserType = (): UserType => {
    const role = watch('role');

    if (isAdmin) return 'admin';

    if (rolesOfUtilities.includes(role)) return 'with-utility';
    if (rolesOfDeviceGroups.includes(role)) return 'with-group';

    return 'without-utility';
  };

  const getSuperAdminRole = () => {
    return allUserGroups.find((group) => group!.name === 'romet/admin');
  };

  const handleAdminCreateUser = async (formData: FormVariables) => {
    const superAdminRole = getSuperAdminRole()!.name;

    return await addUserToGroup(superAdminRole);
  };

  const handleWithoutUtilityCreateUser = async (formData: FormVariables) => {
    const { company, role } = formData;

    const groups = getGroupsByQuery({
      company,
      role,
    }).map((group) => group.name);

    const result = await Promise.all(groups.map(async (group) => await addUserToGroup(group)));
    return result.every((data) => data);
  };

  const handleWithUtilityCreateUser = async (formData: FormVariables) => {
    const utilities = formData.utility
      .map((utility) => utility.utility)
      .filter((utility) => typeof utility === 'string');
    const { company, role } = formData;

    const uncombinedGroups = utilities.map((utility) =>
      getGroupsByQuery({
        company,
        role,
        utility,
      })
    );
    const combinedGroups = uncombinedGroups
      .reduce((currentValue, accumulator) => {
        const combined = accumulator.concat(currentValue);

        return combined;
      }, [])
      .map((utility) => utility.name);

    const result = await Promise.all(
      combinedGroups.map(async (group) => await addUserToGroup(group))
    );
    return result.every((data) => data);
  };

  const handleWithGroupCreateUser = async (formData: FormVariables) => {
    const groups = formData.group
      .map((group) => group.group)
      .filter((group) => typeof group === 'string');
    const { company, role } = formData;

    const uncombinedGroups = groups.map((group) =>
      getGroupsByQuery({
        company,
        role,
        group,
      })
    );
    const combinedGroups = uncombinedGroups
      .reduce((currentValue, accumulator) => {
        const combined = accumulator.concat(currentValue);

        return combined;
      }, [])
      .map((group) => group.name);

    const result = await Promise.all(
      combinedGroups.map(async (group) => await addUserToGroup(group))
    );
    return result.every((data) => data);
  };

  const addUserToGroup = async (group: string) => {
    const data = await addToGroupMutation({
      username: userBeingEdited?.username!,
      group,
      id: userBeingEdited?.id!,
    });

    return data?.addToGroup;
  };

  const handleEditUser = async () => {
    setEditError('');

    const removeUserFromGroup = async (group: string) => {
      const data = await removeUserFromGroupMutation({
        username: userBeingEdited?.username!,
        group,
        id: userBeingEdited?.id!,
      });

      return data?.removeFromGroup;
    };

    const hasNoErrors = await trigger();

    console.log(errors);

    if (!hasNoErrors) return;

    const formData = getValues();

    const userType = getUserType();

    setLoadingUserCreation(true);

    const results = await Promise.all(
      editUserGroups.map(async (group) => removeUserFromGroup(group!.name!))
    );
    if (results.some((data) => !data)) {
      setEditError('Could not edit user');
    } else {
      let success = false;

      switch (userType) {
        case 'admin':
          success = !!(await handleAdminCreateUser(formData));
          break;
        case 'without-utility':
          success = await handleWithoutUtilityCreateUser(formData);
          break;
        case 'with-utility':
          success = await handleWithUtilityCreateUser(formData);
          break;
        case 'with-group':
          success = await handleWithGroupCreateUser(formData);
          break;
        default:
          break;
      }

      if (!success) {
        setEditError('Could not edit user');
      } else {
        await fetchGroupListForEditUser();
        onUserEdited();
      }
    }

    setLoadingUserCreation(false);
  };

  useEffect(() => {
    setIsAdmin(editedUserIsAdmin());
  }, [editUserGroups]);

  return (
    <Modal
      open={open}
      closeModalFunc={closeModalFunc}
      title="Edit User"
      description="Please add an email address for the desired user."
      buttonText="Save & Close"
      buttonDisabled={isButtonDisabled() || loadingUserGroups}
      onSubmitBtnClick={handleEditUser}
      buttonLoading={loadingUserCreation || loadingUserGroups}
    >
      <FormGroup>
        <FormGroup>{editError && <Alert variant="danger">{editError}</Alert>}</FormGroup>

        <FormControl
          name="email"
          register={register}
          label={userBeingEdited?.username!}
          id="email"
          type="text"
          error={errors.email?.message}
          disabled
        />
      </FormGroup>
      {signedUserIsAdmin() && (
        <FormGroup>
          <FormRow>
            <Button
              variant={isAdmin ? undefined : 'light'}
              onClick={() => toggleUserType(true)}
              block
            >
              Romet Admin
            </Button>
            <Button
              variant={!isAdmin ? undefined : 'light'}
              onClick={() => toggleUserType(false)}
              block
            >
              Company
            </Button>
          </FormRow>
        </FormGroup>
      )}

      {isAdmin && (
        <Checkbox
          register={register}
          label="I understand that users with a Romet Admin permission have access to the entire platform."
          name="agree"
          error={errors.agree?.message}
        />
      )}
      {!isAdmin && (
        <>
          <FormGroup>
            <Select
              register={register}
              label="Company"
              name="company"
              onChange={() => {
                setUtilitiesBasedOnCompanies();
                setGroupsBasedOnCompanies();
              }}
            >
              {allCompanyNames.map((company, index) => {
                return (
                  <option
                    key={`${company}_${index}`}
                    value={company}
                  >
                    {company}
                  </option>
                );
              })}
            </Select>
          </FormGroup>
          <FormGroup>
            <Select
              register={register}
              label="Role"
              name="role"
              onChange={() => {
                setUtilitiesBasedOnCompanies();
                setGroupsBasedOnCompanies();
              }}
            >
              {allRoleNames.map((role, index) => {
                return (
                  <option
                    key={`${role}_${index}`}
                    value={role}
                  >
                    {role}
                  </option>
                );
              })}
            </Select>
          </FormGroup>

          {roleNeedUtilities() && (
            <FormGroup>
              <RadioGroup label="Select Utility:">
                {utilitiesFields.map((utility, index) => {
                  return (
                    <Checkbox
                      key={utility.id}
                      register={register}
                      label={allUtilityNames[index]}
                      value={allUtilityNames[index]}
                      name={`utility.${index}.utility` as const}
                    />
                  );
                })}
              </RadioGroup>
            </FormGroup>
          )}
        </>
      )}

      {roleNeedGroups() && (
        <FormGroup>
          <RadioGroup label="Select Group:">
            {groupsFields.map((group, index) => {
              return (
                <Checkbox
                  key={group.id}
                  register={register}
                  label={allGroupNames[index]}
                  value={allGroupNames[index]}
                  name={`group.${index}.group` as const}
                />
              );
            })}
          </RadioGroup>
        </FormGroup>
      )}

      {/* <FormGroup>
        <FormControl
          name="password"
          register={register}
          label="Password"
          id="password"
          type="password"
          error={errors.password?.message}
        />
      </FormGroup>
      <FormGroup>
        <FormControl
          name="confirmPassword"
          register={register}
          label="Confirm Password"
          id="confirmPassword"
          type="password"
          error={errors.confirmPassword?.message}
        />
      </FormGroup> */}
    </Modal>
  );
};

export default EditUserModal;
