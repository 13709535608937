import AdemUtils, { AdemModel } from '../../../../utils/AdemUtils';
import RangeAsset, {
  ConstructorParams,
  DeviceShadowAliasesAcceptedByRangeAsset,
  RangeList,
} from './RangeAsset';
let moveDecimal = require('move-decimal-point');

export default class BPR extends RangeAsset {
  private rangeList: RangeList[] = [
    {
      min: 10,
      max: 16,
      type: 'imperial',
    },
    {
      min: 70,
      max: 110,
      type: 'metric',
      meterUnit: 'kpa',
    },
    {
      min: 0.7,
      max: 1.1,
      type: 'metric',
      meterUnit: 'bar',
    },
  ];
  protected label: string = 'Base Gas Pressure';
  deviceShadowAlias: DeviceShadowAliasesAcceptedByRangeAsset = 'bPr';

  constructor({ deviceShadow, register }: ConstructorParams) {
    super({ deviceShadow, register });

    this.setMeterInfo();
  }

  setMeterInfo() {
    const deviceShadowAliasData = (this.getDeviceShadowAliasValue() as number) || 0;

    const chosenFromRangeList = this.rangeList.find((range) => {
      let isFound = deviceShadowAliasData >= range.min && deviceShadowAliasData <= range.max;
      if (!isFound) {
        const min = Math.min(...this.rangeList.map((item) => item.min));
        return min >= range.min && min <= range.max;
      }
      return isFound;
    });

    if (chosenFromRangeList?.type === 'imperial') {
      this.step = 0.01;
    }

    if (chosenFromRangeList?.type === 'metric' && chosenFromRangeList?.meterUnit === 'bar') {
      this.step = 0.00001;
    }

    this.unitType = chosenFromRangeList!.type;
    this.meterUnit =
      chosenFromRangeList?.type === 'imperial' ? 'all' : chosenFromRangeList!.meterUnit;
    this.min = chosenFromRangeList!.min;
    this.max = chosenFromRangeList!.max;
  }

  isConfigurable(): boolean {
    const ademModel: AdemModel | null = AdemUtils.parseAdemModel(
      this.deviceShadow.state.reported.FWV
    );

    const isFixedPTZ = ademModel === 'PTZ' && this.deviceShadow.state.reported.prFT === 1;
    return ademModel !== 'T' && ademModel !== 'UniversalT' && ademModel !== 'Tq' && !isFixedPTZ;
  }

  public _correctValue(value: number): number {
    let corrected = value;

    switch (this.meterUnit) {
      case 'kpa':
        corrected = moveDecimal(corrected, 1);
        //  corrected = corrected * 10;
        break;
      case 'bar':
        corrected = moveDecimal(corrected, 3);

        // corrected = corrected * 1000;
        break;
    }

    return corrected;
  }
}
