import { useMemo } from 'react';
import { TagCategory } from '../../../API';
import { useGetAllTagCategories } from '../../../hooks/queries';

export const useMeterIdTagCategories = (deviceUtilityId: string | undefined) => {
  const tagCategories = useGetAllTagCategories();

  const predefinedTagCategories = useMemo(
    () => (tagCategories.data?.getAllTagCategories.predefinedTagCategories ?? []) as TagCategory[],
    [tagCategories.data?.getAllTagCategories.predefinedTagCategories]
  );

  const utilityListPerUtility = useMemo(
    () => tagCategories.data?.getAllTagCategories.utilityTagCategoriesPerUtility ?? [],
    [tagCategories.data?.getAllTagCategories.utilityTagCategoriesPerUtility]
  );
  const utilityTagCategories = useMemo(
    () =>
      (utilityListPerUtility.find(
        (categoryPerUtility) => categoryPerUtility?.id === deviceUtilityId
      )?.utilityTagCategories ?? []) as TagCategory[],
    [deviceUtilityId, utilityListPerUtility]
  );

  return { predefinedTagCategories, utilityTagCategories };
};
