import { Tag, TagCategory } from '../API';

/**
 * Maps a Tag to an identifier.
 */
export type TagMap<T extends Tag | TagCategory> = Map<string, T>;

/**
 * Object of tag lists for predefined and utility tag types.
 */
export type TagsByType = { predefined: Tag[]; utility: Tag[] };

/**
 * Constructs a `TagMap` out of the passed-in list of `Tag`s or `TagCategorie`s.
 */
export function tagListToTagMap<T extends Tag | TagCategory>(tagList: T[]): TagMap<T> {
  return tagList.reduce<TagMap<T>>((map: TagMap<T>, tag: T) => {
    map.set(tag.id, tag);
    return map;
  }, new Map());
}

/**
 * Links the passed in tag list to their assigned categories.
 * Returns a `TagMap` where the key is category identifier and the value is that tag.
 */
export function linkTagListToTagCategories(
  tagList: Tag[],
  tagCategories: TagCategory[]
): TagMap<Tag> {
  const tagCategoriesTagMap = tagListToTagMap<TagCategory>(tagCategories);

  const tagMap: TagMap<Tag> = new Map();

  tagList.forEach((tag) => {
    if (!tag.category_id) {
      return;
    }

    const tagCategory = tagCategoriesTagMap.get(tag.category_id);
    if (!tagCategory) {
      return;
    }

    tagMap.set(tagCategory.id, tag);
  });

  return tagMap;
}
