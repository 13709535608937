import { Wrapper } from '@googlemaps/react-wrapper';
import { memo, useCallback, useEffect, useRef } from 'react';
import { AiOutlineReload } from 'react-icons/ai';
import { ImMap2 } from 'react-icons/im';

import Button from '../../../components/Button/Button';
import MapComponent from '../../../components/MapComponent/MapComponent';

import { Device } from '../../../API';
import { useDevicesContext, useDevicesDispatchContext } from '../DevicesProvider';
import { MapButtons, MapContainer, MapLeftButtons } from './style';

export type MapStates = {
  fullMap?: boolean;
  showingMap?: boolean;
  onShowingMapChange?: (showingMap: boolean) => void;
  dynamicData?: boolean;
};

type Props = {
  devices: (Device | null)[];
  onChangeFullMap: (fullMap: boolean) => void;
} & MapStates;

export type Coords = {
  latitudeHi?: number;
  longitudeLo?: number;
  latitudeLo?: number;
  longitudeHi?: number;
};

const GOOGLE_MAP_API_KEY = 'AIzaSyBRFCf7Ok6tGtrAbcrCxKdB4iLRlseN8hc';

const DeviceMap = memo(
  ({ fullMap, showingMap, devices, onShowingMapChange, onChangeFullMap }: Props) => {
    const { dynamicMap } = useDevicesContext();
    const { handleDynamicMap, handleCords } = useDevicesDispatchContext();

    const canFetchDevices = useRef(true);
    const mapContainerRef = useRef<HTMLDivElement>(null);

    const calculateAndSetMapContainerHeight = useCallback(() => {
      if (!mapContainerRef.current) return;

      const distanceFromTopToMapContainerTop = mapContainerRef.current.getBoundingClientRect().top;

      const innerHeight = window.innerHeight;

      const contentPadding = window.getComputedStyle(
        document.querySelector('#root > div > div')!,
        null
      ).paddingBottom;

      const paddingToNum = parseFloat(contentPadding.slice(0, contentPadding.indexOf('p')));

      const maxHeight = innerHeight - paddingToNum - distanceFromTopToMapContainerTop!;

      mapContainerRef.current.style.height = `${maxHeight}px`;
    }, []);

    const handleIdle = useCallback(
      (map: google.maps.Map) => {
        if (!dynamicMap) return;

        if (canFetchDevices.current) {
          canFetchDevices.current = false;
          const mapBounds = map.getBounds();
          const northEastCoords = mapBounds?.getNorthEast();
          const southWestCoords = mapBounds?.getSouthWest();

          const coords = {
            latitudeHi: northEastCoords?.lat(),
            longitudeLo: southWestCoords?.lng(),
            latitudeLo: southWestCoords?.lat(),
            longitudeHi: northEastCoords?.lng(),
          };

          handleCords(coords);
        }
      },
      [dynamicMap, handleCords]
    );

    const handleDragStart = useCallback(() => {
      canFetchDevices.current = true;
    }, []);

    const handleZoomChanged = useCallback(() => {
      canFetchDevices.current = true;
    }, []);

    const handleShowMap = () => {
      onShowingMapChange?.(false);
    };

    useEffect(() => {
      window.addEventListener('resize', calculateAndSetMapContainerHeight);

      return () => {
        window.removeEventListener('resize', calculateAndSetMapContainerHeight);
      };
    }, [calculateAndSetMapContainerHeight]);

    useEffect(() => calculateAndSetMapContainerHeight(), [calculateAndSetMapContainerHeight]);

    return (
      <MapContainer
        ref={mapContainerRef}
        fullMap={fullMap}
        showingMap={showingMap}
      >
        <Wrapper apiKey={GOOGLE_MAP_API_KEY}>
          <MapComponent
            onIdle={handleIdle}
            onDragStart={handleDragStart}
            onZoomChanged={handleZoomChanged}
            devices={devices}
            dynamicMap={dynamicMap}
          />
        </Wrapper>
        <MapButtons>
          <MapLeftButtons>
            {onShowingMapChange && (
              <Button
                startIcon={<ImMap2 />}
                variant="light-primary"
                onClick={handleShowMap}
              >
                Hide map
              </Button>
            )}
            <Button
              startIcon={
                <img
                  alt="expand-map"
                  src={fullMap ? 'svgs/retract-arrows.svg' : 'svgs/expand-arrows.svg'}
                />
              }
              variant="light-primary"
              onClick={() => onChangeFullMap(!fullMap)}
            >
              {fullMap ? 'Exit full map' : 'Full map'}
            </Button>
            <Button
              startIcon={<AiOutlineReload />}
              variant="light-primary"
              onClick={() => handleDynamicMap(!dynamicMap)}
            >
              Turn {dynamicMap ? 'Off' : 'On'} Dynamic Map
            </Button>
          </MapLeftButtons>
        </MapButtons>
      </MapContainer>
    );
  }
);

export { DeviceMap };
