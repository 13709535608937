import { memo } from 'react';
import { useGetMetersetImage } from '../../hooks/queries';
import { AsideTitle, ContainerMeterset, MetersetImage } from './style';

type Props = {
  id: string | null;
};

export const MeterIdImage = memo(({ id }: Props) => {
  const { data: useGetMetersetImageData } = useGetMetersetImage({
    variables: {
      deviceId: id!,
    },
  });

  const metersetImage = useGetMetersetImageData?.getMetersetImage?.signedUrl;

  return (
    <>
      {!!metersetImage && (
        <>
          <AsideTitle>
            <h3>Meterset Image</h3>
          </AsideTitle>

          <ContainerMeterset>
            <MetersetImage src={metersetImage} />
          </ContainerMeterset>
        </>
      )}
    </>
  );
});
