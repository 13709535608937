import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import UserSettingsUtils from '../../../utils/UserSettingsUtils';
import { Coords } from '../DeviceMap';

type DevicesContextProps = {
  search: any;
  page: number;
  perPage: number;
  dynamicMap: boolean;
  cords?: Coords | undefined;
};

type DevicesDispatchContextProps = {
  handleSearch: (search: any) => void;
  handlePage: (page: number) => void;
  handlePerPage: (prePage: number) => void;
  handleDynamicMap: (dynamicMap: boolean) => void;
  handleCords: (cords: Coords) => void;
};

const DEFAULT_SEARCH = {};
const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 10;
const DEFAULT_DYNAMIC_MAP = true;
const DEFAULT_CORDS = {};

const DevicesContext = createContext<DevicesContextProps>({
  search: DEFAULT_SEARCH,
  page: DEFAULT_PAGE,
  perPage: DEFAULT_PER_PAGE,
  dynamicMap: DEFAULT_DYNAMIC_MAP,
  cords: DEFAULT_CORDS,
});

const DevicesDispatchContext = createContext<DevicesDispatchContextProps>({
  handleSearch: () => {},
  handlePage: () => {},
  handlePerPage: () => {},
  handleDynamicMap: () => {},
  handleCords: () => {},
});

const useDevicesContext = (): DevicesContextProps => {
  const context = useContext(DevicesContext);

  if (context === undefined) {
    throw new Error('DevicesContext value are undefined');
  }

  return context as DevicesContextProps;
};

const useDevicesDispatchContext = (): DevicesDispatchContextProps => {
  const context = useContext(DevicesDispatchContext);

  if (context === undefined) {
    throw new Error('DevicesDispatchContext value are undefined');
  }

  return context as DevicesDispatchContextProps;
};

function DevicesProvider({ children }: PropsWithChildren) {
  const [search, setSearch] = useState<DevicesContextProps['search']>(DEFAULT_SEARCH);
  const [page, setPage] = useState<DevicesContextProps['page']>(
    UserSettingsUtils.getPages('userConfig').rows || DEFAULT_PAGE
  );
  const [perPage, setPerPage] = useState<DevicesContextProps['perPage']>(
    UserSettingsUtils.getPerPage('userConfig').perPage || DEFAULT_PER_PAGE
  );
  const [dynamicMap, setDynamicMap] =
    useState<DevicesContextProps['dynamicMap']>(DEFAULT_DYNAMIC_MAP);
  const [cords, setCords] = useState<DevicesContextProps['cords']>(DEFAULT_CORDS);

  const deviceContextValue = useMemo<DevicesContextProps>(
    () => ({
      search,
      page,
      perPage,
      dynamicMap,
      cords,
    }),
    [cords, dynamicMap, page, perPage, search]
  );

  const handleSearch = useCallback(
    (search: any) => {
      UserSettingsUtils.saveSearch(search);
      setSearch(search);
    },
    [setSearch]
  );

  const handlePage = useCallback(
    (page: number) => {
      UserSettingsUtils.savePage(page);
      setPage(page);
    },
    [setPage]
  );

  const handlePerPage = useCallback(
    (perPage: number) => {
      UserSettingsUtils.saveAssetsPerPage(perPage);
      setPerPage(perPage);
    },
    [setPerPage]
  );

  const handleDynamicMap = useCallback(
    (dynamicMap: boolean) => {
      setDynamicMap(dynamicMap);
    },
    [setDynamicMap]
  );

  const handleCords = useCallback(
    (cords: Coords) => {
      setCords(cords);
    },
    [setCords]
  );

  const deviceDispatchContextValue = useMemo<DevicesDispatchContextProps>(
    () => ({
      handleSearch,
      handlePage,
      handlePerPage,
      handleDynamicMap,
      handleCords,
    }),
    [handleCords, handleDynamicMap, handlePage, handlePerPage, handleSearch]
  );

  return (
    <DevicesContext.Provider value={deviceContextValue}>
      <DevicesDispatchContext.Provider value={deviceDispatchContextValue}>
        {children}
      </DevicesDispatchContext.Provider>
    </DevicesContext.Provider>
  );
}

export { DevicesProvider, useDevicesContext, useDevicesDispatchContext };
export type { DevicesContextProps, DevicesDispatchContextProps };
