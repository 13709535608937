/*eslint-disable*/

import { memo, useState } from 'react';
import { MdOutlineCloudDownload } from 'react-icons/md';

import Can from '../../../components/Can/Can';
import {
  OptionalTitleContentWrapper,
  Title,
  TitleContainer,
} from '../../../components/MainLayout/style';
import ExportAlarmModal from '../../../components/Modal/ExportAlarmModal/ExportAlarmModal';
import ExportAssetModal from '../../../components/Modal/ExportAssetModal/ExportAssetModal';
import ExportBatteryLifeReportModal from '../../../components/Modal/ExportBatteryLifeReportModal/ExportBatteryLifeReportModal';
import ExportMeterHealthReportModal from '../../../components/Modal/ExportMeterHealthReportModal/ExportMeterHealthReportModal';
import InstallFirmwareModal from '../../../components/Modal/InstallFirmwareModal/InstallFirmwareModal';
import { MainTitleWrapper } from '../../Notifications/style';
import { ModalButton } from '../style';

async function downloadManual() {
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute(
    'href',
    '/pdfs/Energy Array Device Manager - Customer Version.pdf'
  );
  downloadAnchorNode.setAttribute(
    'download',
    'Energy Array Device Manager - Customer Version' + '.pdf'
  );
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export const DeviceHeader = memo(() => {
  const [exportBatteryLifeReportModalOpen, setExportBatteryLifeReportModalOpen] = useState(false);
  const [exportMeterHealthReportModalOpen, setExportMeterHealthReportModalOpen] = useState(false);

  const [exportDeviceModalOpen, setExportDeviceModalOpen] = useState(false);
  const [exportAlarmModalOpen, setExportAlarmModalOpen] = useState(false);
  const [installFirmwareModalOpen, setInstallFirmwareModalOpen] = useState(false);

  const handleExportMeterHealthReportBtn = () => {
    setExportMeterHealthReportModalOpen(true);
  };

  const handleExportBatteryLifeReportBtn = () => {
    setExportBatteryLifeReportModalOpen(true);
  };

  const handleExportDevicesBtn = () => {
    setExportDeviceModalOpen(true);
  };

  const handleExportAlarmsBtn = () => {
    setExportAlarmModalOpen(true);
  };
  const handleInstallFirmwareForDevice = () => {
    setInstallFirmwareModalOpen(true);
  };

  return (
    <>
      {exportMeterHealthReportModalOpen && (
        <ExportMeterHealthReportModal
          open={exportMeterHealthReportModalOpen}
          closeModalFunc={setExportMeterHealthReportModalOpen}
        />
      )}
      {exportBatteryLifeReportModalOpen && (
        <ExportBatteryLifeReportModal
          open={exportBatteryLifeReportModalOpen}
          closeModalFunc={setExportBatteryLifeReportModalOpen}
        />
      )}
      {exportDeviceModalOpen && (
        <ExportAssetModal
          open={exportDeviceModalOpen}
          devicesToBeExported={[]}
          closeModalFunc={setExportDeviceModalOpen}
        />
      )}

      {exportAlarmModalOpen && (
        <ExportAlarmModal
          open={exportAlarmModalOpen}
          devicesToBeExported={[]}
          closeModalFunc={setExportAlarmModalOpen}
        />
      )}

      {installFirmwareModalOpen && (
        <InstallFirmwareModal
          open={installFirmwareModalOpen}
          closeModalFunc={setInstallFirmwareModalOpen}
          devicesToInstallFirmwares={[]}
        />
      )}

      <TitleContainer>
        <MainTitleWrapper>
          <Title>Assets</Title>
        </MainTitleWrapper>
        <OptionalTitleContentWrapper>
          <Can role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}>
            <ModalButton
              startIcon={
                <img
                  src="/svgs/threshold-solid-black.svg"
                  width={14}
                  height={14}
                />
              }
              variant="light-primary"
              onClick={handleExportMeterHealthReportBtn}
            >
              Export meter health report
            </ModalButton>

            <ModalButton
              startIcon={
                <img
                  src="/svgs/battery-three-quarters.svg"
                  width={14}
                  height={14}
                />
              }
              variant="light-primary"
              onClick={handleExportBatteryLifeReportBtn}
            >
              Export battery life report
            </ModalButton>
            <ModalButton
              startIcon={
                <img
                  src="/svgs/compare-arrows.svg"
                  width={14}
                  height={14}
                />
              }
              variant="light-primary"
              onClick={handleExportDevicesBtn}
            >
              Export all data
            </ModalButton>
            <ModalButton
              startIcon={
                <img
                  src="/svgs/compare-arrows.svg"
                  width={14}
                  height={14}
                />
              }
              variant="light-primary"
              onClick={handleExportAlarmsBtn}
            >
              Export all alarms
            </ModalButton>
            <ModalButton
              startIcon={<MdOutlineCloudDownload size={14} />}
              variant="light-primary"
              onClick={handleInstallFirmwareForDevice}
            >
              Install firmware - All
            </ModalButton>
          </Can>

          <ModalButton
            startIcon={
              <img
                src="/svgs/book.svg"
                width={14}
                height={14}
              />
            }
            variant="light-primary"
            onClick={downloadManual}
          >
            Download manual
          </ModalButton>
        </OptionalTitleContentWrapper>
      </TitleContainer>
    </>
  );
});
