import { gql } from '@apollo/client';

export const authenticateUser = gql`
  query AuthenticateUser(
    $email: String!
    $password: String!
    $newPassword: String
    $challengeAnswer: String
    $session: String
  ) {
    authenticateUser(
      email: $email
      password: $password
      newPassword: $newPassword
      challengeAnswer: $challengeAnswer
      session: $session
    )
  }
`;

export const getMetersetImage = gql`
  query GetMetersetImage($deviceId: String!) {
    getMetersetImage(deviceId: $deviceId) {
      signedUrl
      success
      message
      __typename
    }
  }
`;

export const userList = gql`
  query UserList($username: String, $limit: Int, $page: Int) {
    userList(username: $username, limit: $limit, page: $page) {
      users {
        id
        username
        groups {
          name
          description
          __typename
        }
        __typename
      }
      count
      __typename
    }
  }
`;

export const groupListForUsers = gql`
  query GroupListForUsers($users: [String]) {
    groupListForUsers(users: $users) {
      id
      groups {
        name
        description
        __typename
      }
      __typename
    }
  }
`;

export const userByUsername = gql`
  query UserByUsername($username: String!) {
    userByUsername(username: $username) {
      sub
      username
      email
      groups
      deviceId
      __typename
    }
  }
`;

export const groupList = gql`
  query GroupList {
    groupList {
      groups {
        name
        description
        __typename
      }
      __typename
    }
  }
`;

export const getBatteryLife = gql`
  query GetBatteryLife($deviceId: String!) {
    getBatteryLife(deviceId: $deviceId) {
      percentage
      communicationCounter
      __typename
    }
  }
`;

export const getStatsLogData = gql`
  query GetStatsLogData(
    $fromDate: String!
    $toDate: String!
    $deviceIds: [String]
    $frequency: String
    $scaleFlow: Float
    $scaleVolume: Float
  ) {
    getStatsLogData(
      fromDate: $fromDate
      toDate: $toDate
      deviceIds: $deviceIds
      frequency: $frequency
      scaleFlow: $scaleFlow
      scaleVolume: $scaleVolume
    ) {
      id
      data {
        deviceId
        time
        dayCV
        dayUV
        minPr
        maxPr
        minFC
        maxFC
        minTemp
        maxTemp
        bV
        ccbbV
        pofMF
        qMarg
        pr
        cF
        cV
        uV
        fCV
        fUV
        temp
        __typename
      }
      __typename
    }
  }
`;

export const requestExportMeterHealthReport = gql`
  query RequestExportMeterHealthReport($fromDate: String!, $toDate: String!) {
    requestExportMeterHealthReport(fromDate: $fromDate, toDate: $toDate) {
      success
      message
      __typename
    }
  }
`;

export const requestExportAlarmLogs = gql`
  query RequestExportAlarmLogs(
    $format: String!
    $fromDate: String!
    $toDate: String!
    $deviceIds: [String]!
  ) {
    requestExportAlarmLogs(
      format: $format
      fromDate: $fromDate
      toDate: $toDate
      deviceIds: $deviceIds
    ) {
      success
      message
      __typename
    }
  }
`;

export const exportMeterData = gql`
  query ExportMeterData(
    $format: String!
    $forceUtc: Boolean
    $fromDate: String!
    $toDate: String!
    $deviceIds: [String]!
    $frequency: String!
    $scaleFlow: Float
    $scaleVolume: Float
  ) {
    exportMeterData(
      format: $format
      forceUtc: $forceUtc
      fromDate: $fromDate
      toDate: $toDate
      deviceIds: $deviceIds
      frequency: $frequency
      scaleFlow: $scaleFlow
      scaleVolume: $scaleVolume
    ) {
      success
      message
      __typename
    }
  }
`;

export const exportEvents = gql`
  query ExportEvents($format: String!, $fromDate: Long!, $toDate: Long!, $deviceId: String!) {
    exportEvents(format: $format, fromDate: $fromDate, toDate: $toDate, deviceId: $deviceId) {
      link
      __typename
    }
  }
`;

export const exportBatteryLifeReport = gql`
  query ExportBatteryLifeReport {
    exportBatteryLifeReport {
      success
      message
      __typename
    }
  }
`;

export const getDeviceGroups = gql`
  query GetDeviceGroups {
    getDeviceGroups {
      id
      name
      utility_id
      utility {
        id
        name
        company_id
        company {
          id
          name
          alias
          theme
          logo
          region
          temperatureUnit
          pressureUnit
          volumeUnit
          voltageUnit
          correctedFlowUnit
          __typename
        }
        __typename
      }
      temperatureUnit
      pressureUnit
      volumeUnit
      voltageUnit
      correctedFlowUnit
      __typename
    }
  }
`;

export const listDevices = gql`
  query ListDevices(
    $date: String
    $address: String
    $deviceId: String
    $meterId: String
    $owner: String
    $group: String
    $utility: String
    $company: String
    $tag: String
    $latitudeLo: Float
    $latitudeHi: Float
    $longitudeLo: Float
    $longitudeHi: Float
    $page: Int
    $size: Int
  ) {
    listDevices(
      date: $date
      address: $address
      deviceId: $deviceId
      meterId: $meterId
      owner: $owner
      group: $group
      utility: $utility
      company: $company
      tag: $tag
      latitudeLo: $latitudeLo
      latitudeHi: $latitudeHi
      longitudeLo: $longitudeLo
      longitudeHi: $longitudeHi
      page: $page
      size: $size
    ) {
      rows {
        id
        device_group_id
        meterId
        serialNo
        group {
          id
          name
          utility_id
          utility {
            id
            name
            company_id
            company {
              id
              name
              alias
              theme
              logo
              region
              temperatureUnit
              pressureUnit
              volumeUnit
              voltageUnit
              correctedFlowUnit
              __typename
            }
            __typename
          }
          temperatureUnit
          pressureUnit
          volumeUnit
          voltageUnit
          correctedFlowUnit
          __typename
        }
        user_device {
          user_id
          device_id
          user {
            id
            name
            email
            __typename
          }
          __typename
        }
        latitude
        longitude
        address
        street
        city
        postalCode
        country
        messages {
          id
          time
          urgent
          dId
          status
          comment
          alarms {
            lmt
            threshold
            t
            type
            v
            __typename
          }
          __typename
        }
        tags {
          predefinedTags {
            id
            name
            category_id
            __typename
          }
          utilityTags {
            id
            name
            category_id
            __typename
          }
          __typename
        }
        thresholds {
          device_id
          threshold_key
          value
          __typename
        }
        data {
          meterSize
          ademFirmware
          brightlyncFirmware
          ingestionTime
          dTm
          TmZn
          proto
          bV
          ccbbV
          dayCV
          dayUV
          fUV
          fCV
          cFAvg
          maxFC {
            t
            v
            __typename
          }
          maxPr {
            t
            v
            __typename
          }
          maxTemp {
            t
            v
            __typename
          }
          minFC {
            t
            v
            __typename
          }
          minPr {
            t
            v
            __typename
          }
          minTemp {
            t
            v
            __typename
          }
          pofMF
          qMarg
          __typename
        }
        __typename
      }
      count
      __typename
    }
  }
`;

export const verifyTotp = gql`
  query VerifyTotp($challengeAnswer: String!) {
    verifyTotp(challengeAnswer: $challengeAnswer) {
      success
      message
      __typename
    }
  }
`;

export const getAssociatedSecretCode = gql`
  query GetAssociatedSecretCode($password: String!) {
    getAssociatedSecretCode(password: $password) {
      secretCode
      __typename
    }
  }
`;

export const listUnassignedDevices = gql`
  query ListUnassignedDevices {
    listUnassignedDevices
  }
`;

export const getCompaniesByGroups = gql`
  query GetCompaniesByGroups($groups: [String]) {
    getCompaniesByGroups(groups: $groups) {
      companies {
        id
        name
        alias
        theme
        logo
        region
        temperatureUnit
        pressureUnit
        volumeUnit
        voltageUnit
        correctedFlowUnit
        __typename
      }
      __typename
    }
  }
`;

export const listFirmware = gql`
  query ListFirmware {
    listFirmware
  }
`;

export const listFirmwareVersion = gql`
  query ListFirmwareVersion($component: String) {
    listFirmwareVersion(component: $component)
  }
`;

export const getFirmware = gql`
  query GetFirmware($version: String) {
    getFirmware(version: $version) {
      name
      url
      __typename
    }
  }
`;

export const listCaCertificate = gql`
  query ListCaCertificate {
    listCaCertificate {
      key
      date
      name
      __typename
    }
  }
`;

export const listCompanies = gql`
  query ListCompanies {
    listCompanies {
      id
      name
      alias
      theme
      logo
      region
      temperatureUnit
      pressureUnit
      volumeUnit
      voltageUnit
      correctedFlowUnit
      __typename
    }
  }
`;

export const getDeviceShadow = gql`
  query GetDeviceShadow($deviceId: String!, $region: String!, $prettify: Boolean) {
    getDeviceShadow(deviceId: $deviceId, region: $region, prettify: $prettify)
  }
`;

export const getAlarmComment = gql`
  query GetAlarmComment($alarmId: String!, $time: Long!) {
    getAlarmComment(alarmId: $alarmId, time: $time)
  }
`;

export const getAllTagCategories = gql`
  query GetAllTagCategories {
    getAllTagCategories {
      success
      message
      predefinedTagCategories {
        id
        name
        tags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      utilityTagCategoriesPerUtility {
        id
        utilityTagCategories {
          id
          name
          tags {
            id
            name
            category_id
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getAllPredefinedTagCategories = gql`
  query GetAllPredefinedTagCategories {
    getAllPredefinedTagCategories {
      success
      message
      predefinedTagCategories {
        id
        name
        tags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getAllUtilityTagCategories = gql`
  query GetAllUtilityTagCategories {
    getAllUtilityTagCategories {
      success
      message
      utilityTagCategoriesPerUtility {
        id
        utilityTagCategories {
          id
          name
          tags {
            id
            name
            category_id
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getPredefinedTagsForDevices = gql`
  query GetPredefinedTagsForDevices($deviceIds: [String]!) {
    getPredefinedTagsForDevices(deviceIds: $deviceIds) {
      success
      message
      devicesWithPredefinedTags {
        deviceId
        predefinedTags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getUtilityTagsForDevices = gql`
  query GetUtilityTagsForDevices($deviceIds: [String]!) {
    getUtilityTagsForDevices(deviceIds: $deviceIds) {
      success
      message
      devicesWithUtilityTags {
        deviceId
        utilityTags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const getTagsForDevices = gql`
  query GetTagsForDevices($deviceIds: [String]!) {
    getTagsForDevices(deviceIds: $deviceIds) {
      success
      message
      devicesWithPredefinedTags {
        deviceId
        predefinedTags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      devicesWithUtilityTags {
        deviceId
        utilityTags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
