import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Device, ExportMeterDataQueryVariables } from '../../../API';
import errorMessages from '../../../config/errorMessages';
import { useAuth } from '../../../contexts/Auth';
import { exportMeterDataQuery } from '../../../hooks/queries';
import Alert from '../../Alert/Alert';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import FormRow from '../../Forms/FormRow/FormRow';
import Radio from '../../Forms/Radio/Radio';
import RadioGroup from '../../Forms/RadioGroup/RadioGroup';
import Select from '../../Forms/Select/Select';
import Modal, { ModalOpeningProps } from '../Modal';

type Props = {
  devicesToBeExported: Array<Device | null | undefined>;
  flowUnit?: string | undefined;
  volumeUnit?: string | undefined;
};

const exportDataSchema = yup.object({
  format: yup.string().required(errorMessages.required),
  frequency: yup.string().required(errorMessages.required),
  fromDate: yup
    .string()
    .matches(/\d{4}-\d{2}-\d{2}/g, 'Not a valid date')
    .required(errorMessages.required),
  toDate: yup
    .string()
    .matches(/\d{4}-\d{2}-\d{2}/g, 'Not a valid date')
    .required(errorMessages.required),
});

const start = new Date();
start.setDate(start.getDate() - 30);
const defaultToDate = new Date();
const defaultFromDate = start;

const ExportAssetModal: React.FC<ModalOpeningProps & Props> = ({
  open,
  closeModalFunc,
  devicesToBeExported,
  flowUnit,
  volumeUnit,
}) => {
  const [exportingDevice, setExportingDevice] = useState(false);
  const [exportMeterDataAlertMsg, setExportMeterDataAlertMsg] = useState({
    msg: '',
    variant: 'success',
  });

  const { refactoredUser } = useAuth();

  const formats = [
    {
      label: 'CSV',
      value: 'csv',
    },
    {
      label: 'JSON',
      value: 'json',
    },
  ];

  if (refactoredUser?.isAdmin() || refactoredUser?.username.includes('@onegas.com')) {
    formats.push({
      label: 'TXT',
      value: 'cfx',
    });
  }

  const {
    register,
    formState: { errors },
    trigger,
    getValues,
  } = useForm<
    ExportMeterDataQueryVariables & {
      filename: string;
      timezone: 'utc' | 'device-specific';
    }
  >({
    resolver: yupResolver(exportDataSchema),
  });

  const handleExportDevicesSubmit = async () => {
    const hasNoErrors = await trigger();

    if (!hasNoErrors) return;

    const { format, frequency, fromDate, toDate, scaleFlow, scaleVolume, timezone } = getValues();

    setExportingDevice(true);
    try {
      const { data } = await exportMeterDataQuery({
        deviceIds: [...devicesToBeExported.map((device) => device!.id)],
        format,
        frequency,
        forceUtc: timezone === 'utc',
        fromDate: moment(fromDate, 'YYYY-MM-DD').format('MM-DD-YYYY'),
        toDate: moment(toDate, 'YYYY-MM-DD').format('MM-DD-YYYY'),
        scaleFlow: 1 / scaleFlow!,
        scaleVolume: 1 / scaleVolume!,
      });

      const success = data.exportMeterData.success;

      if (success) {
        const msg = data.exportMeterData.message || '';
        setExportMeterDataAlertMsg({
          msg,
          variant: 'success',
        });
      }
    } catch (e) {
      const msg = 'Maximum difference between From Date and To Date should be 31 days';
      setExportMeterDataAlertMsg({
        msg,
        variant: 'danger',
      });
    }

    setExportingDevice(false);
  };

  /**
   * Parses the scale value into a label (if the value is 100 or 1000).
   */
  const parseScale = (value: number, measurementSystem: 'metric' | 'imperial'): string => {
    if (value === 100) {
      return `(CC${measurementSystem === 'metric' ? 'M' : 'F'})`;
    } else if (value === 1000) {
      return `(MC${measurementSystem === 'metric' ? 'M' : 'F'})`;
    }

    return '';
  };

  return (
    <Modal
      title={`Export asset data for ${
        devicesToBeExported.length === 1 ? devicesToBeExported[0]?.id : 'all assets'
      }`}
      description="Export asset data for CSV or JSON"
      open={open}
      closeModalFunc={closeModalFunc}
      buttonText="Export asset(s) data"
      onSubmitBtnClick={handleExportDevicesSubmit}
      buttonLoading={exportingDevice}
    >
      <form>
        <FormGroup>
          <FormRow>
            <FormControl
              name="fromDate"
              label="From"
              type={'date'}
              id="from"
              value={moment(defaultFromDate).format('YYYY-MM-DD')}
              register={register}
              error={errors.fromDate?.message}
            />
            <FormControl
              register={register}
              error={errors.toDate?.message}
              name="toDate"
              label="To"
              value={moment(defaultToDate).format('YYYY-MM-DD')}
              type={'date'}
              id="to"
            />
          </FormRow>
        </FormGroup>
        <FormGroup>
          <Select
            register={register}
            name="scaleFlow"
            defaultValue={1}
            label="Scale Flow Down By"
          >
            {[1, 100, 1000].map((num, index) => (
              <option
                key={index}
                value={num}
              >
                {num} {flowUnit}{' '}
                {parseScale(num, flowUnit?.toLowerCase().includes('ft') ? 'imperial' : 'metric')}
              </option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <Select
            register={register}
            name="scaleVolume"
            defaultValue={1}
            label="Scale Index Read Down By"
          >
            {[1, 100, 1000].map((num, index) => (
              <option
                key={index}
                value={num}
              >
                {num} {volumeUnit}{' '}
                {parseScale(num, volumeUnit?.toLowerCase().includes('ft') ? 'imperial' : 'metric')}
              </option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <RadioGroup label="Format">
            {formats.map(({ label, value }, index) => (
              <Radio
                checked={index === 0}
                register={register}
                key={`${value}_${index}`}
                error={errors.format?.message}
                id={label}
                label={label}
                name="format"
                value={value}
              />
            ))}
          </RadioGroup>
        </FormGroup>
        <FormGroup>
          <RadioGroup label="Interval">
            <Radio
              register={register}
              error={errors.frequency?.message}
              id="daily"
              label="Daily"
              name="frequency"
              value="daily"
              checked
            />
            <Radio
              register={register}
              id="hourly"
              label="Hourly"
              name="frequency"
              value="hourly"
            />
          </RadioGroup>
        </FormGroup>
        <FormGroup>
          <RadioGroup
            label="Timezone"
            info="Export data by either the device's timezone or by UTC"
          >
            <Radio
              register={register}
              error={errors.frequency?.message}
              id="device-specific"
              label="Device-specific"
              name="timezone"
              value="device-specific"
              checked
            />
            <Radio
              register={register}
              id="utc"
              label="UTC"
              name="timezone"
              value="utc"
            />
          </RadioGroup>
        </FormGroup>
      </form>
      {exportMeterDataAlertMsg.msg && (
        <Alert variant={exportMeterDataAlertMsg.variant}>{exportMeterDataAlertMsg.msg}</Alert>
      )}
    </Modal>
  );
};

export default ExportAssetModal;
