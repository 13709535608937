import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { Fragment, memo, useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RiMapPin2Fill } from 'react-icons/ri';
import { QueryObserverResult } from 'react-query';
import * as yup from 'yup';

import { Device, ListDevicesQuery, Message } from '../../API';
import TextArea from '../../components/Forms/TextArea/TextArea';
import InfoBox from '../../components/InfoBox/InfoBox';
import Col from '../../components/Layout/Col/Col';
import Row from '../../components/Layout/Row/Row';
import Modal from '../../components/Modal/Modal';
import Pill from '../../components/Pill/Pill';
import errorMessages from '../../config/errorMessages';
import { useUpdateAlarmComment, useUpdateAlertStatus } from '../../hooks/mutations';
import AlarmUtils from '../../utils/AlarmUtils';
import DateUtils from '../../utils/DateUtils';
import { AlarmButton, AsideTitle, CardAlarm, RowSpace } from './style';

type Props = {
  device?: Device | null;
  refetch: () => Promise<QueryObserverResult<ListDevicesQuery, unknown>>;
};

const TYPE_MAPPING = {
  0: 'Device Inactive',
  1: 'Percentage of Max Flow',
  3: 'Low Battery',
  824: 'Memory Error',
  163: 'Flow High',
  810: 'Flow Low',
  145: 'Pressure High',
  143: 'Pressure Low',
  146: 'Temperature High',
  144: 'Temperature Low',
  99: 'Battery Malfunction',
  106: 'Temperature Malfunction',
  105: 'Pressure Malfunction',
  859: 'Q Low',
  861: 'DP Malfunction',
  8888: 'Low Battery',
};

const schema = yup.object({
  comment: yup.string().required(errorMessages.required),
});

export const MeterIdAlarms = memo(({ device, refetch }: Props) => {
  const updateAlarmComment = useUpdateAlarmComment();
  const updateAlertStatus = useUpdateAlertStatus();

  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [messageToBeUpdated, setMessageToBeUpdated] = useState<Message | null>(null);
  const [commentBeingChanged, setCommentBeingChanged] = useState<number | null>(null);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Message>({
    resolver: yupResolver(schema),
  });

  const handleCommentClick = useCallback(
    (message: Message) => {
      setMessageToBeUpdated(message);
      setValue('comment', message?.comment);
      setCommentModalOpen(true);
    },
    [setValue]
  );

  const handleChangeStatusClick = useCallback(
    async (message: Message, newStatus: string, index: number) => {
      setCommentBeingChanged(index);

      await updateAlertStatus.mutateAsync({
        id: message.id,
        status: newStatus,
        time: message.time,
      });

      await refetch();
      setCommentBeingChanged(null);
    },
    [refetch, updateAlertStatus]
  );

  const handleSubmitButton = useCallback(
    async ({ comment }: Message) => {
      await updateAlarmComment.mutateAsync({
        alarmId: messageToBeUpdated?.id!,
        comment,
        time: messageToBeUpdated?.time!,
      });

      setCommentModalOpen(false);

      await refetch();

      setMessageToBeUpdated(null);
    },
    [messageToBeUpdated?.id, messageToBeUpdated?.time, refetch, updateAlarmComment]
  );

  const handleCloseModal = useCallback(() => {
    setMessageToBeUpdated(null);
    setCommentModalOpen(false);
  }, []);

  const totalAlarms = useMemo(
    () =>
      device?.messages?.reduce((alarmCount, message) => {
        return alarmCount + (message?.alarms?.length || 0);
      }, 0) ?? 0,
    [device?.messages]
  );

  return (
    <>
      {commentModalOpen && (
        <Modal
          closeModalFunc={handleCloseModal}
          title="Comments"
          description="Change alarm comment"
          open={commentModalOpen}
          buttonText="Update Comment"
          buttonLoading={updateAlarmComment.isLoading}
          onSubmitBtnClick={handleSubmit(handleSubmitButton)}
        >
          <form>
            <TextArea
              id="comment"
              label="Comment"
              name="comment"
              register={register}
              error={errors.comment?.message}
              rows={10}
            />
          </form>
        </Modal>
      )}
      <AsideTitle>
        <h3>Alarms</h3>
        <span>
          Showing {totalAlarms} from {totalAlarms} issues
        </span>
      </AsideTitle>

      {device?.messages?.map((message, index) => {
        if (!message) return null;

        return (
          <CardAlarm key={`${message.id}-${index}`}>
            <Pill
              containerStyle={{
                width: 40,
                height: 40,
                position: 'absolute',
                left: 16,
                top: 20,
              }}
              color={AlarmUtils.getMessageData(message).color}
              icon={<RiMapPin2Fill />}
            />
            <InfoBox
              boxStyle={{ padding: 0 }}
              rows={[
                {
                  label: 'Urgent:',
                  value: message?.urgent ? 'Yes' : 'No',
                },
                {
                  label: 'Status:',
                  value: message?.status || '',
                },
              ]}
            />
            <RowSpace />
            {message?.alarms?.map((alarm, index) => {
              const type = (TYPE_MAPPING as any)[alarm?.type!]
                ? (TYPE_MAPPING as any)[alarm?.type!]
                : `Unknown [${alarm?.type}]`;

              let value = alarm?.v ?? '';
              let limit = alarm?.lmt;
              let threshold = alarm?.threshold;
              let ind: 'RISE' | 'CLEAR' | 'NA' = 'CLEAR';

              if (type === TYPE_MAPPING[0]) {
                ind = 'NA';
                value = 'NA';
                limit = null;
              } else if (!_.isNil(limit) && !_.isNil(value)) {
                if ([TYPE_MAPPING[163], TYPE_MAPPING[145], TYPE_MAPPING[146]].includes(type)) {
                  // High Limit Alarm
                  ind = Number(value) > Number(limit) ? 'RISE' : 'CLEAR';
                } else {
                  // Low Limit Alarm
                  ind = Number(value) < Number(limit) ? 'RISE' : 'CLEAR';
                }
              }

              if (type === TYPE_MAPPING[3]) {
                if (value) {
                  value = DateUtils.daysToMonths(value);
                }
                if (!_.isNil(limit)) {
                  limit = DateUtils.daysToMonths(limit);
                }
                if (!_.isNil(threshold)) {
                  threshold = DateUtils.daysToMonths(threshold);
                }
              }

              const rows = [
                {
                  label: 'Alarm Date:',
                  value: new Date(+alarm?.t! * 1000).toLocaleString(),
                },
                {
                  label: 'Type:',
                  value: type,
                },
                {
                  label: 'Value:',
                  value: value,
                },
              ];

              if (!_.isNil(limit)) {
                rows.push({
                  label: 'Limit:',
                  value: limit,
                });
              }
              if (!_.isNil(threshold)) {
                rows.push({
                  label: 'Threshold:',
                  value: threshold,
                });
              }
              if (!_.isNil(ind)) {
                rows.push({
                  label: 'Indicator:',
                  value: ind,
                });
              }

              return (
                <Fragment key={`${alarm?.threshold}-${index}`}>
                  <InfoBox
                    boxStyle={{ padding: 0 }}
                    rows={rows}
                  />
                  <RowSpace />
                </Fragment>
              );
            })}
            {message?.comment && (
              <InfoBox
                boxStyle={{ padding: 0 }}
                rows={[
                  {
                    label: 'Comment:',
                    value: message?.comment.split('\n').map((item, index) => {
                      return <span key={`${item}-${index}`}>{item}</span>;
                    }),
                  },
                ]}
              />
            )}
            <Row>
              <Col>
                <Row>
                  {message!.status === 'New' && (
                    <AlarmButton
                      type="button"
                      shape="round"
                      variant="light"
                      onClick={() => handleChangeStatusClick(message!, 'Acknowledged', index)}
                      loading={commentBeingChanged === index}
                    >
                      Acknowledge
                    </AlarmButton>
                  )}
                  {message!.status === 'Acknowledged' && (
                    <AlarmButton
                      type="button"
                      shape="round"
                      variant="light"
                      onClick={() => handleChangeStatusClick(message!, 'Resolved', index)}
                      loading={commentBeingChanged === index}
                    >
                      Resolved
                    </AlarmButton>
                  )}
                  <AlarmButton
                    type="button"
                    shape="round"
                    variant="light"
                    onClick={() => handleCommentClick(message)}
                    loading={messageToBeUpdated?.id === message.id}
                    disabled={messageToBeUpdated?.id === message.id}
                  >
                    Comment
                  </AlarmButton>
                </Row>
              </Col>
            </Row>
          </CardAlarm>
        );
      })}
    </>
  );
});
