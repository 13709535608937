import React from 'react';
import { useLocation } from 'react-router-dom';

import { ItemLink, ListItem, Nav, NavList } from './style';

export type MenuListItem = {
  to?: string;
  text: string;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  startIcon?: React.ReactNode;
};

type Props = {
  items: MenuListItem[];
};

const MenuList: React.FC<Props> = ({ items }) => {
  const { pathname } = useLocation();

  const onPath = (path?: string): boolean => {
    if (!path) return false;

    const pattern = new RegExp(path + '$');

    return pattern.test(pathname);
  };

  return (
    <Nav>
      <NavList>
        {items.map(({ to, text, active, onClick, startIcon: StartIcon }, index) => (
          <ListItem key={index}>
            <ItemLink
              onClick={onClick}
              active={onPath(to) || active}
              to={to || '/'}
            >
              <>
                {StartIcon}
                {text}
              </>
            </ItemLink>
          </ListItem>
        ))}
      </NavList>
    </Nav>
  );
};

export default MenuList;
