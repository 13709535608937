import React from 'react';
import DataTable, { TableProps, TableStyles } from 'react-data-table-component';
import { FiArrowDownCircle } from 'react-icons/fi';
import { useTheme } from 'styled-components';

const DataT: React.FC<TableProps<any>> = ({ customStyles: customS, ...props }) => {
  const theme = useTheme();

  const customStyles: TableStyles = {
    tableWrapper: {
      style: {
        borderRadius: theme.borderRadius,
        border: `1px solid #efefef`,
        fontSize: '2rem',
        overflow: 'hidden',
        '& .rdt_TableCell > div:first-child': {
          whiteSpace: 'break-spaces',
        },
      },
    },
    table: {
      style: {
        overflow: 'hidden',
        '& input[type="checkbox"]': {
          position: 'relative',
          width: '18px',
          height: '18px',
          appearance: 'none',
          border: `2px solid ${theme.colors.primaryText}`,
          borderRadius: '3px',
          cursor: 'pointer',
        },
        '& input[type="checkbox"]:checked::after': {
          display: 'block',
        },
        '& input[type="checkbox"]:hover, & input[type="checkbox"]:checked': {
          backgroundColor: `${theme.colors.primaryText}`,
        },
        '& input[type="checkbox"]::after': {
          content: "''",
          position: 'absolute',
          display: 'none',
          width: '100%',
          height: '100%',
          maskImage: "url('/svgs/check-solid.svg')",
          maskRepeat: 'no-repeat',
          maskPosition: 'center center',
          maskSize: 'cover',
          backgroundColor: `${theme.colors.white}`,
          zIndex: '5',
        },
      },
    },
    rows: {
      style: {
        '&:nth-child(odd)': {
          backgroundColor: theme.colors.neutral,
        },
        '&:nth-child(even)': {
          backgroundColor: theme.colors.white,
        },
        border: 'none',
      },
    },
    headCells: {
      style: {
        padding: '14px 16px',
        color: theme.colors.black,
        // backgroundColor: theme.colors.neutral,
        overflow: 'hidden',
      },
    },
    cells: {
      style: {
        paddingRight: 0,
        color: theme.colors.black,
      },
    },
    pagination: {
      style: {
        backgroundColor: theme.colors.neutral,
        color: theme.colors.black,
        '& button': {
          fill: theme.colors.black + ' !important',
        },
      },
    },

    ...customS,
  };

  return (
    <DataTable
      sortIcon={<FiArrowDownCircle style={{ margin: '0 10px' }} />}
      {...props}
      customStyles={customStyles}
    />
  );
};

export default DataT;
