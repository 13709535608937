import AdemUtils from '../../../../utils/AdemUtils';
import RangeAsset, {
  ConstructorParams,
  DeviceShadowAliasesAcceptedByRangeAsset,
  MeterSizes,
} from './RangeAsset';

type MetricOrImperialData = {
  [key in MeterSizes]?: {
    min: number;
    max: number;
  };
};

export default class UFLL extends RangeAsset {
  protected label: string = 'Uncorrected Alarm Flow Low Limit';
  public deviceShadowAlias: DeviceShadowAliasesAcceptedByRangeAsset = 'uFLL';

  constructor({ deviceShadow, register }: ConstructorParams) {
    super({ deviceShadow, register });

    this.setMeterInfo();
  }

  setMeterInfo() {
    const imperialData: MetricOrImperialData = {
      RM600: {
        min: 0,
        max: 180,
      },
      RM1000: {
        min: 0,
        max: 300,
      },
      RM1500: {
        min: 0,
        max: 450,
      },
      RM2000: {
        min: 0,
        max: 600,
      },
      RM3000: {
        min: 0,
        max: 900,
      },
      RM5000: {
        min: 0,
        max: 1500,
      },
      RM7000: {
        min: 0,
        max: 2100,
      },
      RM11000: {
        min: 0,
        max: 3300,
      },
      RM16000: {
        min: 0,
        max: 4800,
      },
      RM23000: {
        min: 0,
        max: 6900,
      },
      RM25000: {
        min: 0,
        max: 7500,
      },
      RM38000: {
        min: 0,
        max: 11400,
      },
      RM56000: {
        min: 0,
        max: 16800,
      },
      '1.5MLMMA': {
        min: 0,
        max: 450,
      },
      '3MLMMA': {
        min: 0,
        max: 900,
      },
      '5MLMMA': {
        min: 0,
        max: 1500,
      },
      '7MLMMA': {
        min: 0,
        max: 2100,
      },
      '11MLMMA': {
        min: 0,
        max: 3300,
      },
      '16MLMMA': {
        min: 0,
        max: 4800,
      },
      '23MLMMA': {
        min: 0,
        max: 6900,
      },
      '38MLMMA': {
        min: 0,
        max: 11400,
      },
      '56MLMMA': {
        min: 0,
        max: 16800,
      },
      '102MLMMA': {
        min: 0,
        max: 33600,
      },
      RMT600: {
        min: 0,
        max: 180,
      },
      RMT1000: {
        min: 0,
        max: 300,
      },
      RMT1500: {
        min: 0,
        max: 450,
      },
      RMT2000: {
        min: 0,
        max: 600,
      },
      RMT3000: {
        min: 0,
        max: 900,
      },
      RMT5000: {
        min: 0,
        max: 1500,
      },
      RMT7000: {
        min: 0,
        max: 2100,
      },
      RMT11000: {
        min: 0,
        max: 3300,
      },
      RMT16000: {
        min: 0,
        max: 4800,
      },
      RMT23000: {
        min: 0,
        max: 6900,
      },
      '8C175I': {
        min: 0,
        max: 240,
      },
      '11C175I': {
        min: 0,
        max: 330,
      },
      '15C175I': {
        min: 0,
        max: 450,
      },
      '2M175I': {
        min: 0,
        max: 600,
      },
      '3M175I': {
        min: 0,
        max: 900,
      },
      '5M175I': {
        min: 0,
        max: 1500,
      },
      '7M175I': {
        min: 0,
        max: 2100,
      },
      '11M175I': {
        min: 0,
        max: 3300,
      },
      '16M175I': {
        min: 0,
        max: 4800,
      },
      '23M175I': {
        min: 0,
        max: 6900,
      },
      '23M232I': {
        min: 0,
        max: 6900,
      },
      '38M175I': {
        min: 0,
        max: 11400,
      },
      '1M300I': {
        min: 0,
        max: 300,
      },
      '3M300I': {
        min: 0,
        max: 900,
      },
      '56M175I': {
        min: 0,
        max: 16800,
      },
    };

    const metricData: MetricOrImperialData = {
      G10: {
        min: 0,
        max: 4.8,
      },
      G16: {
        min: 0,
        max: 9,
      },
      G25: {
        min: 0,
        max: 12,
      },
      G40: {
        min: 0,
        max: 16.5,
      },
      G65: {
        min: 0,
        max: 25.5,
      },
      G100: {
        min: 0,
        max: 42,
      },
      G160: {
        min: 0,
        max: 75,
      },
      G250: {
        min: 0,
        max: 135,
      },
      G400: {
        min: 0,
        max: 195,
      },
      'G400-150': {
        min: 0,
        max: 210,
      },
      G650: {
        min: 0,
        max: 330,
      },
      G1000: {
        min: 0,
        max: 480,
      },
      RM16: {
        min: 0,
        max: 4.8,
      },
      RM30: {
        min: 0,
        max: 9,
      },
      RM40: {
        min: 0,
        max: 12,
      },
      RM55: {
        min: 0,
        max: 16.5,
      },
      RM85: {
        min: 0,
        max: 25.5,
      },
      RM140: {
        min: 0,
        max: 42,
      },
      RM200: {
        min: 0,
        max: 60,
      },
      RM300: {
        min: 0,
        max: 90,
      },
      RM450: {
        min: 0,
        max: 135,
      },
      RM650: {
        min: 0,
        max: 195,
      },
      RM700: {
        min: 0,
        max: 210,
      },
      RM1100: {
        min: 0,
        max: 330,
      },
      RM1600: {
        min: 0,
        max: 480,
      },
      '1.5M(40)': {
        min: 0,
        max: 12,
      },
      '3M(85)': {
        min: 0,
        max: 25.5,
      },
      '5M(140)': {
        min: 0,
        max: 42,
      },
      '7M(200)': {
        min: 0,
        max: 600,
      },
      '11M(300)': {
        min: 0,
        max: 900,
      },
      '16M(450)': {
        min: 0,
        max: 1350,
      },
      RMT16: {
        min: 0,
        max: 4.8,
      },
      RMT30: {
        min: 0,
        max: 90,
      },
      RMT40: {
        min: 0,
        max: 120,
      },
      RMT55: {
        min: 0,
        max: 16.5,
      },
      RMT85: {
        min: 0,
        max: 25.5,
      },
      RMT140: {
        min: 0,
        max: 42,
      },
      RMT200: {
        min: 0,
        max: 60,
      },
      RMT300: {
        min: 0,
        max: 90,
      },
      RMT450: {
        min: 0,
        max: 135,
      },
      RMT650: {
        min: 0,
        max: 195,
      },
      '8C175M': {
        min: 0,
        max: 6.9,
      },
      '11C175M': {
        min: 0,
        max: 9.3,
      },
      '15C175M': {
        min: 0,
        max: 12,
      },
      '2M175M': {
        min: 0,
        max: 16.5,
      },
      '3M175M': {
        min: 0,
        max: 25.5,
      },
      '5M175M': {
        min: 0,
        max: 42,
      },
      '7M175M': {
        min: 0,
        max: 60,
      },
      '11M175M': {
        min: 0,
        max: 90,
      },
      '16M175M': {
        min: 0,
        max: 135,
      },
      '23M175M': {
        min: 0,
        max: 195,
      },
      '23M232M': {
        min: 0,
        max: 195,
      },
      '38M175M': {
        min: 0,
        max: 330,
      },
      '1M300M': {
        min: 0,
        max: 9,
      },
      '3M300M': {
        min: 0,
        max: 25.5,
      },
      '56M175M': {
        min: 0,
        max: 480,
      },
    };

    // TODO : If cVUt is not set for some reason, we should show an error message.
    const cVUt = this.deviceShadow.state.reported.cVUt ?? -1;
    const isImperial = AdemUtils.checkMeasurementSystem(cVUt) === 'imperial';
    const meterSize = this.getDeviceDataMeterSize();

    if (isImperial) {
      this.step = 1;
    } else {
      this.step = 0.01;
    }

    const chosenData = isImperial ? imperialData : metricData;
    const { min, max } = chosenData[meterSize] ?? {
      min: isImperial ? 0 : 0.0,
      max: isImperial ? 9999999 : 99999.99,
    };

    this.unitType = isImperial ? 'imperial' : 'metric';
    this.meterUnit = 'all';
    this.min = min;
    this.max = max;
  }

  public _correctValue(value: number): number {
    let corrected = value;

    console.log(this.unitType);

    if (this.unitType === 'metric') {
      const decimalNumbersPattern = /(\.)(\d{1,2})/;
      const valueString = value.toString();

      if (!decimalNumbersPattern.test(valueString)) {
        return Number(valueString + '.55');
      }

      const [_, __, decimalNumbers] = [...valueString.match(decimalNumbersPattern)!];

      if (decimalNumbers.length === 1) {
        return Number(valueString + '5');
      } else {
        return corrected;
      }
    }

    return corrected;
  }
}
