import { useAuth } from '../contexts/Auth';
import { useGroupListForUsers } from './queries';

export function useUserGroups() {
  const { refactoredUser } = useAuth();

  const userGroupsQuery = useGroupListForUsers({
    variables: {
      users: [refactoredUser?.username!],
    },
  });
  const userGroups = userGroupsQuery.data?.groupListForUsers[0]?.groups;

  const buildUserGroupData = () => {
    if (!userGroups) return [];

    return userGroups!.map((group) => {
      const regexDivided = group?.description
        ? [...group?.description?.matchAll(/(\w+):\s+([\w-]+)/g)!]
        : [];

      const dividedToObject = regexDivided.reduce((accumulator: any, currentValue) => {
        const name = currentValue?.[1] || '';
        const value = currentValue?.[2] || '';

        accumulator[name] = value;

        return accumulator;
      }, {});

      return {
        ...dividedToObject,
        ...group,
      };
    });
  };

  const userGroupData = buildUserGroupData();

  return { userGroupData };
}
