import { Group } from '../API';

export type GroupData = Group & {
  company: string;
  utility: {
    utility: string;
  }[];
  group: {
    group: string;
  }[];
  role: string;
};

export default class UserGroupsUtils {
  static buildUserGroupData(allUserGroups: Array<any | null>): GroupData[] {
    return allUserGroups.map((group) => {
      const regexDivided = [...group?.description?.matchAll(/(\w+):\s+([\w-]+)/g)!];

      const dividedToObject = regexDivided.reduce((accumulator: any, currentValue) => {
        const [, name, value] = currentValue;

        accumulator[name] = value;

        return accumulator;
      }, {});

      return {
        ...dividedToObject,
        ...group,
      };
    });
  }

  static createGroupSearcher(buildedUserGroupData: any[]) {
    return (query: any) => {
      return buildedUserGroupData.filter((group) => {
        const queryEntries = Object.entries(query);

        return queryEntries.every(([key, value]) => {
          return (group as any)[key] === value;
        });
      });
    };
  }
}
