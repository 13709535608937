import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { requestExportMeterHealthReportQuery } from '../../../hooks/queries';
import Alert from '../../Alert/Alert';
import Select from '../../Forms/Select/Select';
import Modal, { ModalOpeningProps } from '../Modal';
import { SelectBox } from './style';

type Props = {};

type MonthRange = {
  fromDate: number;
  toDate: number;
};
type MonthRangeMap = {
  [key: string]: MonthRange;
};

const ExportMeterHealthReportModal: React.FC<ModalOpeningProps & Props> = ({
  open,
  closeModalFunc,
}) => {
  const { register } = useForm();

  const [exporting, setExporting] = useState(false);
  const [exportMeterHealthReportAlertMsg, setExportMeterHealthReportAlertMsg] = useState({
    msg: '',
    variant: 'success',
  });

  const [monthRanges, setMonthRanges] = useState<MonthRangeMap>({});
  const [selectedMonthRangeKey, setSelectedMonthRangeKey] = useState<string>();

  const getLast12MonthsUTC = (): MonthRangeMap => {
    const monthRangeMap: MonthRangeMap = {};
    const today = new Date(
      Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
    );

    // Check if today is the last day of the month to include the current month
    const isLastDayOfMonth = (date: Date): boolean => {
      const testDate = new Date(
        Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate() + 1)
      );
      return testDate.getUTCDate() === 1;
    };

    // Adjust starting month based on whether today is the last day of the month
    const startMonth = isLastDayOfMonth(today) ? 0 : 1;

    for (let i = startMonth; i < 12 + startMonth; i++) {
      const startDate = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - i, 1));
      const endDate = new Date(
        Date.UTC(today.getUTCFullYear(), today.getUTCMonth() - i + 1, 0, 23, 59, 59, 999)
      );

      const label = `${startDate.getUTCMonth() + 1 < 10 ? '0' : ''}${startDate.getUTCMonth() + 1}/${startDate.getUTCFullYear()}`;

      monthRangeMap[label] = {
        fromDate: Math.floor(startDate.getTime() / 1000),
        toDate: Math.floor(endDate.getTime() / 1000),
      };
    }

    return monthRangeMap;
  };

  const handleExportMeterHealthReportSubmit = async () => {
    if (selectedMonthRangeKey === undefined) {
      // Realistically, this block shouldn't ever be reached
      // Keeping it here just in case
      setExportMeterHealthReportAlertMsg({
        msg: 'No date selected',
        variant: 'danger',
      });

      return;
    }

    setExporting(true);

    try {
      const selectedMonthRange = monthRanges[selectedMonthRangeKey];

      const { data } = await requestExportMeterHealthReportQuery({
        fromDate: selectedMonthRange.fromDate.toString(),
        toDate: selectedMonthRange.toDate.toString(),
      });

      const success = data.requestExportMeterHealthReport.success;

      if (success) {
        const msg = data.requestExportMeterHealthReport.message || '';
        setExportMeterHealthReportAlertMsg({
          msg,
          variant: 'success',
        });
      }
    } catch (e) {
      const msg = 'Something went wrong. Please try again.';
      setExportMeterHealthReportAlertMsg({
        msg,
        variant: 'danger',
      });
    }

    setExporting(false);
  };

  useEffect(() => {
    const last12MonthsUTC = getLast12MonthsUTC();

    setMonthRanges(last12MonthsUTC);
    setSelectedMonthRangeKey(Object.keys(last12MonthsUTC)[0]);
  }, []);

  return (
    <Modal
      title="Export meter health report for all AdEM-Tq devices"
      description="Export meter health report CSV"
      open={open}
      closeModalFunc={closeModalFunc}
      buttonText="Export meter health report"
      onSubmitBtnClick={handleExportMeterHealthReportSubmit}
      buttonLoading={exporting}
    >
      <SelectBox>
        <Select
          register={register}
          onChange={(e) => setSelectedMonthRangeKey(e.currentTarget.value)}
          name="date"
          defaultValue={selectedMonthRangeKey}
          label="Meter Health Report Date"
        >
          {Object.keys(monthRanges).map((label: string) => {
            return (
              <option
                key={label}
                value={label}
              >
                {label}
              </option>
            );
          })}
        </Select>
      </SelectBox>
      {exportMeterHealthReportAlertMsg.msg && (
        <Alert variant={exportMeterHealthReportAlertMsg.variant}>
          {exportMeterHealthReportAlertMsg.msg}
        </Alert>
      )}
    </Modal>
  );
};

export default ExportMeterHealthReportModal;
