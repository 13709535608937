import React, { ChangeEventHandler, PropsWithChildren, useRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { IoIosArrowDown } from 'react-icons/io';
import theme from '../../../styles/theme';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { Label, Select as SelectInput, SelectWrapper } from './style';

type SelectProps = {
  name?: string;
  label: string;
  disabled?: boolean;
  register?: UseFormRegister<any>;
  error?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  defaultValue?: string | number;
};

const Select: React.FC<PropsWithChildren<SelectProps>> = ({
  children,
  name,
  label,
  disabled = false,
  register,
  error,
  defaultValue,
  onChange,
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const selectWrapper = useRef<HTMLDivElement>(null);

  const onFocusIn = () => {
    if (selectWrapper.current) {
      selectWrapper.current.style.border = `1px solid ${theme.colors.primaryText}`;
    }
  };

  const onFocusOut = () => {
    if (selectWrapper.current) {
      selectWrapper.current.style.border = `1px solid ${theme.colors.gray}`;
    }
  };

  const registerData = register
    ? register(name || '', {
        onBlur: onFocusOut,
        onChange,
      })
    : {
        name,
      };

  return (
    <>
      <SelectWrapper ref={selectWrapper}>
        <SelectInput
          data-testid="select"
          onFocus={onFocusIn}
          disabled={disabled}
          {...registerData}
          defaultValue={defaultValue}
        >
          {children}
        </SelectInput>
        <Label>{label}</Label>
        <IoIosArrowDown />
      </SelectWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default Select;
