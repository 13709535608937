import { gql } from '@apollo/client';

export const createUser = gql`
  mutation CreateUser($email: String!, $deviceId: String, $groups: [String]!) {
    createUser(email: $email, deviceId: $deviceId, groups: $groups)
  }
`;

export const importUsers = gql`
  mutation ImportUsers($file: String!) {
    importUsers(file: $file) {
      success
      message
      __typename
    }
  }
`;

export const addToGroup = gql`
  mutation AddToGroup($id: String!, $username: String!, $group: String!) {
    addToGroup(id: $id, username: $username, group: $group)
  }
`;

export const removeFromGroup = gql`
  mutation RemoveFromGroup($id: String!, $username: String!, $group: String!) {
    removeFromGroup(id: $id, username: $username, group: $group)
  }
`;

export const addDevice = gql`
  mutation AddDevice(
    $deviceId: String!
    $groupId: String!
    $meterId: String!
    $latitude: String
    $longitude: String
    $address: String
    $street: String
    $city: String
    $postalCode: String
    $country: String
  ) {
    addDevice(
      deviceId: $deviceId
      groupId: $groupId
      meterId: $meterId
      latitude: $latitude
      longitude: $longitude
      address: $address
      street: $street
      city: $city
      postalCode: $postalCode
      country: $country
    ) {
      id
      device_group_id
      meterId
      serialNo
      group {
        id
        name
        utility_id
        utility {
          id
          name
          company_id
          company {
            id
            name
            alias
            theme
            logo
            region
            temperatureUnit
            pressureUnit
            volumeUnit
            voltageUnit
            correctedFlowUnit
            __typename
          }
          __typename
        }
        temperatureUnit
        pressureUnit
        volumeUnit
        voltageUnit
        correctedFlowUnit
        __typename
      }
      user_device {
        user_id
        device_id
        user {
          id
          name
          email
          __typename
        }
        __typename
      }
      latitude
      longitude
      address
      street
      city
      postalCode
      country
      messages {
        id
        time
        urgent
        dId
        status
        comment
        alarms {
          lmt
          threshold
          t
          type
          v
          __typename
        }
        __typename
      }
      tags {
        predefinedTags {
          id
          name
          category_id
          __typename
        }
        utilityTags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      thresholds {
        device_id
        threshold_key
        value
        __typename
      }
      data {
        meterSize
        ademFirmware
        brightlyncFirmware
        ingestionTime
        dTm
        TmZn
        proto
        bV
        ccbbV
        dayCV
        dayUV
        fUV
        fCV
        cFAvg
        maxFC {
          t
          v
          __typename
        }
        maxPr {
          t
          v
          __typename
        }
        maxTemp {
          t
          v
          __typename
        }
        minFC {
          t
          v
          __typename
        }
        minPr {
          t
          v
          __typename
        }
        minTemp {
          t
          v
          __typename
        }
        pofMF
        qMarg
        __typename
      }
      __typename
    }
  }
`;

export const removeDevice = gql`
  mutation RemoveDevice($deviceId: String!) {
    removeDevice(deviceId: $deviceId) {
      id
      __typename
    }
  }
`;

export const changeUserDevice = gql`
  mutation ChangeUserDevice($userId: String!, $deviceId: String!) {
    changeUserDevice(userId: $userId, deviceId: $deviceId) {
      success
      message
      __typename
    }
  }
`;

export const enableMfa = gql`
  mutation EnableMfa($password: String!) {
    enableMfa(password: $password) {
      success
      message
      __typename
    }
  }
`;

export const disableMfa = gql`
  mutation DisableMfa($challengeAnswer: String!) {
    disableMfa(challengeAnswer: $challengeAnswer) {
      success
      message
      __typename
    }
  }
`;

export const updateCompany = gql`
  mutation UpdateCompany(
    $id: String!
    $theme: String
    $logo: String
    $type: String
    $temperatureUnit: String
    $pressureUnit: String
    $volumeUnit: String
    $voltageUnit: String
    $correctedFlowUnit: String
  ) {
    updateCompany(
      id: $id
      theme: $theme
      logo: $logo
      type: $type
      temperatureUnit: $temperatureUnit
      pressureUnit: $pressureUnit
      volumeUnit: $volumeUnit
      voltageUnit: $voltageUnit
      correctedFlowUnit: $correctedFlowUnit
    ) {
      success
      message
      __typename
    }
  }
`;

export const updateDeviceGroup = gql`
  mutation UpdateDeviceGroup(
    $id: String!
    $temperatureUnit: String
    $pressureUnit: String
    $volumeUnit: String
    $voltageUnit: String
    $correctedFlowUnit: String
  ) {
    updateDeviceGroup(
      id: $id
      temperatureUnit: $temperatureUnit
      pressureUnit: $pressureUnit
      volumeUnit: $volumeUnit
      voltageUnit: $voltageUnit
      correctedFlowUnit: $correctedFlowUnit
    ) {
      success
      message
      __typename
    }
  }
`;

export const updateAlertStatus = gql`
  mutation UpdateAlertStatus($id: String!, $time: Long!, $status: String!) {
    updateAlertStatus(id: $id, time: $time, status: $status) {
      success
      message
      __typename
    }
  }
`;

export const updateAlertComment = gql`
  mutation UpdateAlertComment($id: String!, $time: Long!, $status: String!) {
    updateAlertComment(id: $id, time: $time, status: $status) {
      success
      message
      __typename
    }
  }
`;

export const setThresholds = gql`
  mutation SetThresholds($deviceIds: [String]!, $thresholds: [ThresholdValue]!) {
    setThresholds(deviceIds: $deviceIds, thresholds: $thresholds) {
      success
      message
      __typename
    }
  }
`;

export const deviceJobManager = gql`
  mutation DeviceJobManager(
    $deviceId: String
    $operation: String!
    $region: String
    $version: String
    $files: [Firmware]
    $ca: String
    $component: String
    $deviceIds: [String]
  ) {
    deviceJobManager(
      deviceId: $deviceId
      operation: $operation
      region: $region
      version: $version
      files: $files
      ca: $ca
      component: $component
      deviceIds: $deviceIds
    ) {
      success
      message
      __typename
    }
  }
`;

export const forgotPassword = gql`
  mutation ForgotPassword($username: String!) {
    forgotPassword(username: $username) {
      success
      __typename
    }
  }
`;

export const confirmForgotPassword = gql`
  mutation ConfirmForgotPassword($code: String!, $username: String!, $password: String!) {
    confirmForgotPassword(code: $code, username: $username, password: $password) {
      success
      __typename
    }
  }
`;

export const verifyUserEmail = gql`
  mutation VerifyUserEmail($code: String!) {
    verifyUserEmail(code: $code) {
      success
      message
      __typename
    }
  }
`;

export const removeUser = gql`
  mutation RemoveUser($username: String!) {
    removeUser(username: $username) {
      success
      message
      __typename
    }
  }
`;

export const updateDeviceShadow = gql`
  mutation UpdateDeviceShadow($deviceId: String!, $region: String!, $payload: String!) {
    updateDeviceShadow(deviceId: $deviceId, region: $region, payload: $payload) {
      success
      message
      __typename
    }
  }
`;

export const updateAlarmComment = gql`
  mutation UpdateAlarmComment($alarmId: String!, $time: Long!, $comment: String!) {
    updateAlarmComment(alarmId: $alarmId, time: $time, comment: $comment) {
      success
      message
      __typename
    }
  }
`;

export const resetDeviceBatteryCounter = gql`
  mutation ResetDeviceBatteryCounter($deviceId: String!, $force: Boolean) {
    resetDeviceBatteryCounter(deviceId: $deviceId, force: $force) {
      success
      message
      __typename
    }
  }
`;

export const addUtilityTagCategories = gql`
  mutation AddUtilityTagCategories($newUtilityTagCategories: [NewTagCategory]!) {
    addUtilityTagCategories(newUtilityTagCategories: $newUtilityTagCategories) {
      success
      message
      newUtilityTagCategories {
        id
        name
        tags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const addUtilityTags = gql`
  mutation AddUtilityTags($newUtilityTags: [NewTag]!, $utilityTagCategoryId: String!) {
    addUtilityTags(newUtilityTags: $newUtilityTags, utilityTagCategoryId: $utilityTagCategoryId) {
      success
      message
      newUtilityTags {
        id
        name
        category_id
        __typename
      }
      __typename
    }
  }
`;

export const updateUtilityTagCategory = gql`
  mutation UpdateUtilityTagCategory($updatedUtilityTagCategory: UpdatedTagCategory!) {
    updateUtilityTagCategory(updatedUtilityTagCategory: $updatedUtilityTagCategory) {
      success
      message
      updatedUtilityTagCategory {
        id
        name
        tags {
          id
          name
          category_id
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const updateUtilityTag = gql`
  mutation UpdateUtilityTag($updatedUtilityTag: UpdatedTag!, $utilityTagCategoryId: String!) {
    updateUtilityTag(
      updatedUtilityTag: $updatedUtilityTag
      utilityTagCategoryId: $utilityTagCategoryId
    ) {
      success
      message
      updatedUtilityTag {
        id
        name
        category_id
        __typename
      }
      __typename
    }
  }
`;

export const deleteUtilityTagCategory = gql`
  mutation DeleteUtilityTagCategory($utilityTagCategoryId: String!) {
    deleteUtilityTagCategory(utilityTagCategoryId: $utilityTagCategoryId) {
      success
      message
      deletedUtilityTagCategoryId
      __typename
    }
  }
`;

export const deleteUtilityTag = gql`
  mutation DeleteUtilityTag($utilityTagId: String!, $utilityTagCategoryId: String!) {
    deleteUtilityTag(utilityTagId: $utilityTagId, utilityTagCategoryId: $utilityTagCategoryId) {
      success
      message
      deletedUtilityTagId
      __typename
    }
  }
`;

export const addTagToDevices = gql`
  mutation AddTagToDevices(
    $deviceIds: [String]!
    $tagId: String!
    $tagCategoryId: String!
    $tagType: TagType!
  ) {
    addTagToDevices(
      deviceIds: $deviceIds
      tagId: $tagId
      tagCategoryId: $tagCategoryId
      tagType: $tagType
    ) {
      success
      message
      __typename
    }
  }
`;

export const removeTagFromDevices = gql`
  mutation RemoveTagFromDevices(
    $deviceIds: [String]!
    $tagId: String!
    $tagCategoryId: String!
    $tagType: TagType!
  ) {
    removeTagFromDevices(
      deviceIds: $deviceIds
      tagId: $tagId
      tagCategoryId: $tagCategoryId
      tagType: $tagType
    ) {
      success
      message
      __typename
    }
  }
`;
