import React, { PropsWithChildren } from 'react';

import { RadioG, Label, LabelSection, Info } from './style';

type RadioGroupProps = {
  label: string;
  info?: string;
};

const RadioGroup: React.FC<PropsWithChildren<RadioGroupProps>> = ({ children, label, info }) => {
  return (
    <RadioG>
      <LabelSection>
        <Label>{label}</Label>
        {info && <Info>{info}</Info>}
      </LabelSection>

      {children}
    </RadioG>
  );
};

export default RadioGroup;
