import { Device } from '../../../API';
import { useDeviceShadow } from '../../../hooks/queries';
import { DeviceShadowRaw } from '../../../modules/DeviceShadow/DeviceShadow';
import AdemUtils, { AdemModel } from '../../../utils/AdemUtils';
import { useMemo } from 'react';

type Props = {
  device?: Device | null;
  id: string | undefined;
};

export const useMeterIdShadow = ({ device, id }: Props) => {
  const assetShadow = useDeviceShadow({
    variables: {
      deviceId: id!,
      prettify: false,
      region: device?.group?.utility.company?.region! || '',
    },
    additionalOptions: {
      enabled: !!device && !!id,
    },
  });
  const deviceShadowParsed = useMemo(
    () => JSON.parse(assetShadow.data?.getDeviceShadow || '{}') as DeviceShadowRaw,
    [assetShadow.data?.getDeviceShadow]
  );

  const reported = useMemo(
    () => deviceShadowParsed?.state?.reported,
    [deviceShadowParsed?.state?.reported]
  );

  const ademModel: AdemModel | null = useMemo(
    () => AdemUtils.parseAdemModel(reported?.FWV),
    [reported?.FWV]
  );

  const omitPressure = useMemo(
    () =>
      ademModel === 'T' ||
      ademModel === 'UniversalT' ||
      ademModel === 'Tq' ||
      (ademModel === 'PTZ' && reported?.prFT === 1),
    [ademModel, reported?.prFT]
  );

  const omitTemperature = useMemo(
    () => ademModel === 'PTZ' && reported?.tempFT === 1,
    [ademModel, reported?.tempFT]
  );

  return { reported, omitPressure, omitTemperature, ademModel };
};
