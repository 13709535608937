import React, { useEffect, useState } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { BtnToTop } from './style';

const ButtonToTop: React.FC = () => {
  const [buttonCanAppear, setButtonCanAppear] = useState(false);

  const handleButtonClick = () => {
    const mainId = '#main';
    document.querySelector(mainId)?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const mainLayoutEl = document.querySelector('#root > div > div');

    const onScroll = () => {
      const mainLayoutScrollY = mainLayoutEl?.scrollTop || 0;

      if (mainLayoutScrollY > 500) {
        setButtonCanAppear(true);
      } else {
        setButtonCanAppear(false);
      }
    };

    mainLayoutEl?.removeEventListener('scroll', onScroll);
    mainLayoutEl?.addEventListener('scroll', onScroll);

    return () => {
      mainLayoutEl?.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <BtnToTop
      show={buttonCanAppear}
      onClick={handleButtonClick}
      shape="circle"
    >
      <AiOutlineArrowUp />
    </BtnToTop>
  );
};

export default ButtonToTop;
