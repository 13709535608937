import { yupResolver } from '@hookform/resolvers/yup';
import isNil from 'lodash/isNil';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Device, DeviceJobManagerMutationVariables } from '../../../API';
import errorMessages from '../../../config/errorMessages';
import { useAuth } from '../../../contexts/Auth';
import { useDeviceJobManager } from '../../../hooks/mutations';
import {
  defaultComponent,
  useGetFirmware,
  useListFirmware,
  useListFirmwareVersion,
} from '../../../hooks/queries';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import Radio from '../../Forms/Radio/Radio';
import RadioGroup from '../../Forms/RadioGroup/RadioGroup';
import Select from '../../Forms/Select/Select';
import Spinner from '../../Spinner/Spinner';
import Modal, { ModalOpeningProps } from '../Modal';

type Props = {
  devicesToInstallFirmwares: Array<Device | null | undefined>;
};

const REGIONS = ['Canada', 'America'] as const;
type RegionType = (typeof REGIONS)[number];

const installFirmwareSchema = yup.object({
  component: yup.string().required(errorMessages.required),
  version: yup.string().required(errorMessages.required),
  file: yup
    .array()
    .min(1, 'A firmware must have at least 1 component selected')
    .typeError('A firmware must have at least 1 component selected'),
});

const InstallFirmwareModal: React.FC<ModalOpeningProps & Props> = ({
  open,
  closeModalFunc,
  devicesToInstallFirmwares,
}) => {
  const { refactoredUser } = useAuth();
  const [installingFirmwares, setInstallingFirmwares] = useState(false);
  const [version, setVersion] = useState<string | null>(null);
  const [regionFilter, setRegionFilter] = useState<RegionType | null>(null);
  const [currentComponent, setCurrentComponent] = useState(defaultComponent);
  const { mutateAsync: deviceJobManager } = useDeviceJobManager();

  const firmwareVersions = useListFirmwareVersion({
    variables: {
      component: currentComponent,
    },
    additionalOptions: {
      enabled: !!open,
    },
  });
  const firmwareComponents = useListFirmware({
    additionalOptions: {
      enabled: !!open,
    },
  });
  const firmwares = useGetFirmware({
    additionalOptions: {
      enabled: !!open,
    },
    variables: {
      version,
    },
  });

  const {
    register,
    getValues,
    trigger,
    formState: { errors: installFirmwareErrors },
    setError: installFirmwareSetError,
  } = useForm<DeviceJobManagerMutationVariables>({
    resolver: yupResolver(installFirmwareSchema),
  });

  const handleInstallFirmwareBtn = async () => {
    const hasNoErrors = await trigger();

    if (!hasNoErrors) return;

    const { files, component } = getValues();

    if (devicesToInstallFirmwares.length === 0) {
      const confirmBox = window.confirm(
        'Do You Want to Install the Selected Firmware to All Devices?'
      );
      if (confirmBox === true) {
        console.log('Installation Confirmed');
      } else {
        console.log('Installation Cancelled');
        return;
      }
    }

    if (isNil(files)) {
      installFirmwareSetError('files', {
        type: 'custom',
        message: 'A firmware must have at least 1 component selected',
      });

      return;
    }

    setInstallingFirmwares(true);
    try {
      await deviceJobManager({
        deviceIds: devicesToInstallFirmwares.map((device) => device?.id!),
        component,
        files: firmwares.data?.getFirmware?.filter((firmware) => {
          (firmware as any).__typename = undefined;
          return (files as any).includes(firmware?.name!);
        }),
        operation: 'install',
      });
    } catch (e) {
      console.error(e);
    }

    closeModalFunc(false);
    setInstallingFirmwares(false);
  };

  const loading = firmwareComponents.isLoading || firmwareVersions.isLoading || firmwares.isLoading;

  return (
    <Modal
      title={'Install firmware'}
      open={open}
      closeModalFunc={closeModalFunc}
      buttonText="Install firmware"
      onSubmitBtnClick={handleInstallFirmwareBtn}
      buttonLoading={installingFirmwares}
    >
      <form>
        {loading && <Spinner variant="primary"></Spinner>}
        {!loading && (
          <>
            <FormGroup>
              <RadioGroup label="Select component">
                {firmwareComponents.data?.listFirmware?.map((component) => {
                  if (!component) return null;

                  return (
                    <Radio
                      key={component!}
                      id={component!}
                      value={component}
                      label={component === 'CCB' ? 'Brightlync' : component}
                      register={register}
                      name="component"
                      disabled={component === 'AdEM'}
                      checked={component === defaultComponent}
                      onChange={(e) => {
                        setCurrentComponent(e.currentTarget.value);
                      }}
                      error={installFirmwareErrors.component?.message}
                    />
                  );
                })}
              </RadioGroup>
            </FormGroup>
            <FormGroup>
              <Select
                register={register}
                label="Select version"
                name="version"
                onChange={(e) => setVersion(e.currentTarget.value)}
                error={installFirmwareErrors.version?.message}
              >
                <option>SELECT VERSION</option>

                {!firmwareVersions?.data?.listFirmwareVersion?.length &&
                  !firmwareVersions.isLoading && (
                    <option selected>NO VERSIONS FOUND FOR THIS COMPONENT</option>
                  )}

                {firmwareVersions.data?.listFirmwareVersion?.map((version, index) => {
                  return (
                    <option
                      key={index}
                      value={version!}
                    >
                      {version}
                    </option>
                  );
                })}
              </Select>
            </FormGroup>
            <FormGroup>
              <Select
                register={register}
                label="Filter by region"
                name="region"
                onChange={(e) => {
                  const region = e.currentTarget.value as RegionType;
                  setRegionFilter(REGIONS.includes(region) ? region : null);
                }}
              >
                <option>FILTER BY REGION</option>

                {REGIONS.map((region, index) => {
                  return (
                    <option
                      key={index}
                      value={region}
                    >
                      {region}
                    </option>
                  );
                })}
              </Select>
            </FormGroup>
            <FormGroup>
              <RadioGroup label="Select firmware">
                {firmwares.data?.getFirmware?.map((firmware, index) => {
                  const isAdmin = refactoredUser?.isAdmin();

                  if (!version) return null;
                  if (!regionFilter && !isAdmin) return null;

                  const provider = firmware?.name.split('/')[2];
                  if (regionFilter === 'Canada' && provider !== 'rogers') return null;
                  if (regionFilter === 'America' && provider !== 'rogers_att') return null;

                  return (
                    <Radio
                      id={firmware?.name!}
                      key={firmware?.name!}
                      label={firmware?.name!}
                      name="files"
                      value={firmware?.name!}
                      register={register}
                      error={(installFirmwareErrors.files as any)?.message}
                    />
                  );
                })}
              </RadioGroup>
            </FormGroup>
          </>
        )}
      </form>
    </Modal>
  );
};

export default InstallFirmwareModal;
