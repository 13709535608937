import { ChangeEventHandler, forwardRef } from 'react';
import { UseFormRegister } from 'react-hook-form';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { Container, InputContainer, Input, Label } from './style';

type RadioProps = {
  label: string;
  id: string;
  register?: UseFormRegister<any>;
  error?: string;
  value?: string;
  name?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  children?: React.ReactNode;
  styles?: React.CSSProperties;
};

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    { label, name, id, register, error, value, checked, onChange, disabled, children, styles },
    ref
  ) => {
    const registerArgs = register
      ? register!(name || '', {
          onChange,
        })
      : { name, onChange };

    return (
      <Container>
        <InputContainer>
          <Input
            style={styles}
            defaultValue={value}
            defaultChecked={checked}
            type="radio"
            id={id}
            disabled={disabled}
            {...registerArgs}
          />
        </InputContainer>
        <Label htmlFor={id}>
          {label}
          {error && (
            <>
              <br />
              <ErrorMessage>{error}</ErrorMessage>
            </>
          )}
        </Label>
        {children}
      </Container>
    );
  }
);

export default Radio;
