import { DocumentNode, OperationVariables } from '@apollo/client';
import { QueryObserverOptions, QueryOptions, useMutation } from 'react-query';
import {
  AddTagToDevicesMutation,
  AddTagToDevicesMutationVariables,
  AddToGroupMutation,
  AddToGroupMutationVariables,
  AddUtilityTagCategoriesMutation,
  AddUtilityTagCategoriesMutationVariables,
  AddUtilityTagsMutation,
  AddUtilityTagsMutationVariables,
  ConfirmForgotPasswordMutation,
  ConfirmForgotPasswordMutationVariables,
  CreateUserMutation,
  CreateUserMutationVariables,
  DeleteUtilityTagCategoryMutation,
  DeleteUtilityTagCategoryMutationVariables,
  DeleteUtilityTagMutation,
  DeleteUtilityTagMutationVariables,
  DeviceJobManagerMutation,
  DeviceJobManagerMutationVariables,
  DisableMfaMutation,
  DisableMfaMutationVariables,
  EnableMfaMutation,
  EnableMfaMutationVariables,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables,
  ImportUsersMutation,
  ImportUsersMutationVariables,
  RemoveFromGroupMutation,
  RemoveFromGroupMutationVariables,
  RemoveTagFromDevicesMutation,
  RemoveTagFromDevicesMutationVariables,
  RemoveUserMutation,
  RemoveUserMutationVariables,
  SetThresholdsMutation,
  SetThresholdsMutationVariables,
  UpdateAlarmCommentMutation,
  UpdateAlarmCommentMutationVariables,
  UpdateAlertStatusMutation,
  UpdateAlertStatusMutationVariables,
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables,
  UpdateDeviceGroupMutation,
  UpdateDeviceGroupMutationVariables,
  UpdateDeviceShadowMutation,
  UpdateDeviceShadowMutationVariables,
  UpdateUtilityTagMutation,
  UpdateUtilityTagMutationVariables,
} from '../API';
import { apiClient } from '../api-client';
import { useAuth } from '../contexts/Auth';
import {
  addTagToDevices,
  addUtilityTagCategories,
  addUtilityTags,
  deleteUtilityTag,
  deleteUtilityTagCategory,
  removeTagFromDevices,
  updateAlarmComment,
  updateAlertStatus,
  updateCompany,
  updateDeviceShadow,
  updateUtilityTag,
  createUser,
  setThresholds,
  removeFromGroup,
  addToGroup,
  confirmForgotPassword,
  removeUser,
  importUsers,
  forgotPassword,
  updateDeviceGroup,
  deviceJobManager,
  disableMfa,
  enableMfa,
} from '../graphql/mutations';

type CreateCustomMutationParams = {
  mutation: DocumentNode;
};

type CustomMutationParams<TQuery, TVariables = any> = {
  variables?: TVariables;
  additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
};

type BaseMutationParams<TQuery, TVariables = any> = {
  mutation: DocumentNode;
  variables: TVariables;
  additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
  additionalKeys?: any[];
};

function useBaseMutation<TQuery, TVariables extends OperationVariables>({
  mutation,
}: BaseMutationParams<TQuery, TVariables>) {
  const { logout } = useAuth();

  return useMutation(mutateFunc);

  async function mutateFunc(variables: TVariables) {
    try {
      const { data } = await apiClient.mutate<TVariables, TQuery>(mutation, {
        ...variables,
      });

      return data;
    } catch (e: unknown) {
      throw apiClient.errorHandle(e, logout);
    }
  }
}

export const useUpdateDeviceShadow = createCustomMutation<
  UpdateDeviceShadowMutation,
  UpdateDeviceShadowMutationVariables
>({
  mutation: updateDeviceShadow,
});

export const useUpdateCompany = createCustomMutation<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>({
  mutation: updateCompany,
});

export const useRemoveTagFromDevices = createCustomMutation<
  RemoveTagFromDevicesMutation,
  RemoveTagFromDevicesMutationVariables
>({
  mutation: removeTagFromDevices,
});

export const useAddTagToDevices = createCustomMutation<
  AddTagToDevicesMutation,
  AddTagToDevicesMutationVariables
>({
  mutation: addTagToDevices,
});

export const useDeleteUtilityTag = createCustomMutation<
  DeleteUtilityTagMutation,
  DeleteUtilityTagMutationVariables
>({
  mutation: deleteUtilityTag,
});

export const useUpdateUtilityTag = createCustomMutation<
  UpdateUtilityTagMutation,
  UpdateUtilityTagMutationVariables
>({
  mutation: updateUtilityTag,
});

export const useAddUtilityTags = createCustomMutation<
  AddUtilityTagsMutation,
  AddUtilityTagsMutationVariables
>({
  mutation: addUtilityTags,
});

export const useAddUtilityTagCategories = createCustomMutation<
  AddUtilityTagCategoriesMutation,
  AddUtilityTagCategoriesMutationVariables
>({
  mutation: addUtilityTagCategories,
});

export const useDeleteUtilityTagCategory = createCustomMutation<
  DeleteUtilityTagCategoryMutation,
  DeleteUtilityTagCategoryMutationVariables
>({
  mutation: deleteUtilityTagCategory,
});

export const useUpdateAlarmComment = createCustomMutation<
  UpdateAlarmCommentMutation,
  UpdateAlarmCommentMutationVariables
>({ mutation: updateAlarmComment });

export const useUpdateAlertStatus = createCustomMutation<
  UpdateAlertStatusMutation,
  UpdateAlertStatusMutationVariables
>({ mutation: updateAlertStatus });

export const useCreateUserMutation = createCustomMutation<
  CreateUserMutation,
  CreateUserMutationVariables
>({ mutation: createUser });

export const useSetThresholds = createCustomMutation<
  SetThresholdsMutation,
  SetThresholdsMutationVariables
>({ mutation: setThresholds });

export const useRemoveFromGroup = createCustomMutation<
  RemoveFromGroupMutation,
  RemoveFromGroupMutationVariables
>({ mutation: removeFromGroup });

export const useAddToGroup = createCustomMutation<AddToGroupMutation, AddToGroupMutationVariables>({
  mutation: addToGroup,
});

export const useConfirmForgotPass = createCustomMutation<
  ConfirmForgotPasswordMutation,
  ConfirmForgotPasswordMutationVariables
>({ mutation: confirmForgotPassword });

export const useRemoveUser = createCustomMutation<RemoveUserMutation, RemoveUserMutationVariables>({
  mutation: removeUser,
});

export const useImportUsers = createCustomMutation<
  ImportUsersMutation,
  ImportUsersMutationVariables
>({ mutation: importUsers });

export const useForgotPassword = createCustomMutation<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>({ mutation: forgotPassword });

export const useUpdateDeviceGroup = createCustomMutation<
  UpdateDeviceGroupMutation,
  UpdateDeviceGroupMutationVariables
>({ mutation: updateDeviceGroup });

export const useDeviceJobManager = createCustomMutation<
  DeviceJobManagerMutation,
  DeviceJobManagerMutationVariables
>({ mutation: deviceJobManager });

export const useDisableMfa = createCustomMutation<DisableMfaMutation, DisableMfaMutationVariables>({
  mutation: disableMfa,
});

export const useEnableMfa = createCustomMutation<EnableMfaMutation, EnableMfaMutationVariables>({
  mutation: enableMfa,
});

function createCustomMutation<TMutation, TVariables extends OperationVariables>({
  mutation,
}: CreateCustomMutationParams) {
  return ({ variables, additionalOptions }: CustomMutationParams<TMutation, TVariables> = {}) =>
    useBaseMutation<TMutation, TVariables>({
      mutation,
      variables: { ...variables! },
      additionalOptions,
    });
}
