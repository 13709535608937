import moment from 'moment';
import { QMarginDataList } from '../../../components/MeterHealthCertificateModal/MeterHealthCertificateModal';
import { useMeterData } from '../../../hooks/queries';
import { parseQMarginData } from '../MeterIdHelpers';
import { Frequency } from '../MeterId';
import { useMemo } from 'react';

type Props = {
  id?: string;
  frequency: Frequency;
  fromDate: string;
  toDate: string;
};

export const useMeterIdMeterData = ({ id, frequency, fromDate, toDate }: Props) => {
  const { data, isLoading: isLoadingMeterData } = useMeterData({
    variables: {
      deviceIds: id ? [id] : undefined,
      frequency,
      fromDate,
      toDate,
    },
    additionalOptions: {
      enabled: !!fromDate || !!toDate,
    },
  });

  const meterData = useMemo(() => data?.getStatsLogData[0]?.data, [data?.getStatsLogData]);
  const currDate = useMemo(() => moment(new Date()).format('MM/YYYY'), []);

  const qMarginDataList: QMarginDataList = useMemo(() => parseQMarginData(meterData), [meterData]);

  const currQMargin = useMemo(
    () => qMarginDataList.find(({ label }) => label === currDate),
    [currDate, qMarginDataList]
  );

  return { meterData, qMarginDataList, currQMargin, isLoadingMeterData };
};
