import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Aside, Container, Section } from './style';

import moment from 'moment';
import ButtonToTop from '../../components/ButtonToTop/ButtonToTop';
import LoadingModal from '../../components/LoadingModal/LoadingModal';
import { MeterIdAlarms } from './MeterIdAlarms';
import { MeterIdChartsSection } from './MeterIdChartsSection';
import { MeterIdHeader } from './MeterIdHeader';
import { MeterIdImage } from './MeterIdImage';
import { MeterIdInfo } from './MeterIdInfo';
import { MeterIdTags } from './MeterIdTags';
import {
  useMeterIdBatteryLife,
  useMeterIdDevice,
  useMeterIdMeterData,
  useMeterIdShadow,
  useMeterIdTagCategories,
} from './hooks';

export type Frequency = 'daily' | 'hourly';
const defaultFrequency: Frequency = 'daily';

// eslint-disable-next-line no-unused-vars
enum Period {
  D90,
  CURRENT_MONTH,
  THIS_VS_LAST_MONTH,
  M13,
  CUSTOM,
}

const MeterId: React.FC = () => {
  const { id } = useParams();

  const [frequency, setFrequency] = useState<Frequency>(defaultFrequency);
  const [period, setPeriod] = useState(Period.D90);

  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');

  const getFromDate = () => {
    let start = new Date();

    switch (period) {
      case Period.D90:
        start.setDate(start.getDate() - 90);
        break;
      case Period.THIS_VS_LAST_MONTH:
        start = new Date(start.getFullYear(), start.getMonth() - 1, 0);
        break;
      case Period.M13:
        start = new Date(start.getFullYear(), start.getMonth() - 12, 1);
        break;
      case Period.CUSTOM:
        return fromDate;
      default:
        break;
    }

    return moment(start).format('MM-DD-YYYY');
  };

  const getToDate = () => {
    let end = new Date();
    if (period === Period.CUSTOM) {
      return toDate;
    }

    return moment(end).format('MM-DD-YYYY');
  };

  const { meterData, qMarginDataList, currQMargin, isLoadingMeterData } = useMeterIdMeterData({
    id,
    frequency,
    fromDate,
    toDate,
  });

  const {
    device,
    deviceGroup,
    deviceUtilityId,
    volumeUnit,
    flowUnit,
    refetchDevice,
    isLoadingDevice,
  } = useMeterIdDevice(id);

  const { predefinedTagCategories, utilityTagCategories } =
    useMeterIdTagCategories(deviceUtilityId);

  const { ademModel, reported, omitPressure, omitTemperature } = useMeterIdShadow({ device, id });

  const { batteryLife, refetchBatteryLife } = useMeterIdBatteryLife(id);

  useEffect(() => {
    refetchBatteryLife();

    setFromDate(getFromDate());
    setToDate(getToDate());
  }, [frequency, period, refetchBatteryLife]);

  if (isLoadingDevice) return <LoadingModal enabled />;

  return (
    <>
      <ButtonToTop />

      <MeterIdHeader
        device={device}
        refetch={refetchDevice}
        predefinedTagCategories={predefinedTagCategories}
        utilityTagCategories={utilityTagCategories}
        isDevicesLoading={isLoadingDevice}
        flowUnit={flowUnit}
        volumeUnit={volumeUnit}
      />

      <Container>
        <Section>
          <MeterIdChartsSection
            device={device}
            deviceGroup={deviceGroup}
            ademModel={ademModel}
            omitPressure={omitPressure}
            omitTemperature={omitTemperature}
            qMarginDataList={qMarginDataList}
            meterData={meterData}
            isLoadingMeterData={isLoadingMeterData}
            volumeUnit={volumeUnit}
            flowUnit={flowUnit}
            frequency={frequency}
            setFrequency={setFrequency}
            period={period}
            setPeriod={setPeriod}
            setFromDate={setFromDate}
            setToDate={setToDate}
          />
        </Section>

        <Aside>
          <MeterIdInfo
            device={device}
            ademModel={ademModel}
            deviceGroup={deviceGroup}
            reported={reported}
            omitPressure={omitPressure}
            omitTemperature={omitTemperature}
            qMarginDataList={qMarginDataList}
            currQMargin={currQMargin}
            batteryLifeData={batteryLife}
          />

          <MeterIdTags
            device={device}
            predefinedTagCategories={predefinedTagCategories}
            utilityTagCategories={utilityTagCategories}
          />
          <MeterIdImage id={id!} />
          <MeterIdAlarms
            device={device}
            refetch={refetchDevice}
          />
        </Aside>
      </Container>
    </>
  );
};

export default MeterId;
