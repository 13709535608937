import { PropsWithChildren } from 'react';
import { useAuth } from '../../contexts/Auth';
import { Navigate } from 'react-router-dom';

const UnprotectedRoute: React.FC<PropsWithChildren> = ({ children }) => {
  const { signed, loading } = useAuth();

  if (loading) return null;

  if (!signed) {
    return <>{children}</>;
  } else {
    return (
      <Navigate
        to={'/assets'}
        replace
      />
    );
  }
};

export default UnprotectedRoute;
