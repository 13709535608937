import AdemUtils from '../../../../utils/AdemUtils';
import RangeAsset, {
  ConstructorParams,
  DeviceShadowAliasesAcceptedByRangeAsset,
} from './RangeAsset';
import UFHL from './ufHL';

export default class TEMPLL extends RangeAsset {
  protected label: string = 'Temperature Alarm Low Limit';
  public deviceShadowAlias: DeviceShadowAliasesAcceptedByRangeAsset = 'tempLL';

  constructor({ deviceShadow, register }: ConstructorParams) {
    super({ deviceShadow, register });

    this.setMeterInfo();
  }

  setMeterInfo() {
    // TODO : If cVUt is not set for some reason, we should show an error message.
    const cVUt = this.deviceShadow.state.reported.cVUt ?? -1;
    const isImperial = AdemUtils.checkMeasurementSystem(cVUt) === 'imperial';

    this.unitType = isImperial ? 'imperial' : 'metric';
    this.step = 0.1;
    this.meterUnit = 'all';

    if (isImperial) {
      this.min = -40;
      this.max = 50;
    } else {
      this.min = -40;
      this.max = 10;
    }
  }
}
