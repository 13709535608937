import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Company } from '../../../API';
import { useAuth } from '../../../contexts/Auth';
import { useGroupList } from '../../../hooks/queries';
import UserGroupsUtils from '../../../utils/UserGroupsUtils';
import UserSettingsUtils from '../../../utils/UserSettingsUtils';
import Button from '../../Button/Button';
import Can from '../../Can/Can';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import Select from '../../Forms/Select/Select';
import Spinner from '../../Spinner/Spinner';
import Modal from '../Modal';

type AdvancedSearchModalProps = {
  open: boolean;
  closeModalFunc: (open: boolean) => void;
  onSearch: (searchData: any) => void;
  loadingCompanies: boolean;
  companies: (Company | null)[];
};

const defaultValues = {
  meterId: '',
  deviceId: '',
  address: '',
  company: '',
  utility: '',
  group: '',
};

const AdvancedSearchModal: React.FC<AdvancedSearchModalProps> = ({
  open,
  closeModalFunc,
  onSearch,
  loadingCompanies,
  companies,
}) => {
  const userGroups = useGroupList({
    additionalOptions: {
      enabled: open,
    },
  });
  const userGroupsData = UserGroupsUtils.buildUserGroupData(
    userGroups.data?.groupList.groups || []
  );

  const utilities = userGroupsData
    .filter((group) => group.utility)
    .filter(
      (value, index, self) =>
        self.findIndex((val) => {
          return val.utility === value.utility;
        }) === index
    );

  const searchSaved = UserSettingsUtils.getItem('search');

  const { register, trigger, getValues, setValue, reset } = useForm({
    defaultValues: {
      ...(searchSaved || defaultValues),
    },
  });

  const { isAdmin } = useAuth();

  const onSubmit = async () => {
    const hasNoErrors = await trigger();

    if (!hasNoErrors) return;

    const formData = getValues();

    onSearch(formData);
  };

  const clear = () => {
    reset({
      ...defaultValues,
    });
  };

  useEffect(() => {
    if (!loadingCompanies && companies.length === 1) {
      const value = companies[0]?.alias as never;

      setValue('company', value!);
    }
  }, [loadingCompanies, companies, setValue]);

  useEffect(() => {
    if (!loadingCompanies && utilities.length === 1) {
      const value = utilities[0] as never;

      setValue('utilities', [value!]);
    }
  }, [loadingCompanies, setValue, utilities]);

  return (
    <Modal
      open={open}
      closeModalFunc={closeModalFunc}
      title="Advanced Search"
      description=""
      bottomContent={
        <>
          <FormGroup>
            <Button
              block
              variant="light"
              onClick={clear}
            >
              Clear
            </Button>
          </FormGroup>
          <Button
            block
            onClick={onSubmit}
          >
            Search
          </Button>
        </>
      }
    >
      {loadingCompanies || (userGroups.isLoading && <Spinner variant="primary" />)}
      {!loadingCompanies && !userGroups.isLoading && (
        <>
          <FormGroup>
            <FormControl
              register={register}
              id="meterId"
              name="meterId"
              label="Customer ID"
              type={'text'}
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              register={register}
              id="deviceId"
              name="deviceId"
              label="Device ID"
              type={'text'}
            />
          </FormGroup>

          <FormGroup>
            <FormControl
              register={register}
              id="address"
              name="address"
              label="Address"
              type={'text'}
            />
          </FormGroup>
          {(companies.length > 1 || isAdmin()) && (
            <FormGroup>
              <Select
                label={'Company'}
                register={register}
                name="company"
              >
                <option value={''}>No one selected</option>
                {companies.map((company, index) => (
                  <option
                    key={`${company?.id!}_${index}`}
                    value={company!.name!}
                  >
                    {company!.name}
                  </option>
                ))}
              </Select>
            </FormGroup>
          )}
          <Can role={['admin', 'CompanyAdmin', 'UtilityAdmin', 'UtilityPowerUser']}>
            {utilities.length > 1 && (
              <FormGroup>
                <FormControl
                  register={register}
                  id="utility"
                  name="utility"
                  label="Utility"
                  type={'text'}
                />
              </FormGroup>
            )}
          </Can>
          <FormGroup>
            <FormControl
              register={register}
              id="group"
              name="group"
              label="Group"
              type={'text'}
            />
          </FormGroup>
        </>
      )}
    </Modal>
  );
};

export default AdvancedSearchModal;
