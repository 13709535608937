import React from 'react';
import Button from '../Button/Button';
import {
  BottomContentContainer,
  BoxTitle,
  Container,
  Content,
  Header,
  InfoItem,
  Label,
  MultipleValue,
  Value,
  Wrapper,
} from './style';

export type InfoBoxRow = {
  label: string;
  value: string | string[] | any[];
};

type Props = {
  rows: InfoBoxRow[];
  title?: string;
  buttonText?: string;
  containerStyle?: React.CSSProperties;
  boxStyle?: React.CSSProperties;
  onBtnClick?: () => void;
  bottomContent?: React.ReactNode;
};

const InfoBox: React.FC<Props> = ({
  title,
  rows,
  buttonText,
  containerStyle,
  onBtnClick,
  bottomContent,
  boxStyle,
}) => {
  const showValues = (values: string[]) => {
    return (
      <MultipleValue>
        {values.map((value, index) => (
          <Value key={`${value}_${index}`}>{value}</Value>
        ))}
      </MultipleValue>
    );
  };
  return (
    <Wrapper style={containerStyle}>
      <Container style={boxStyle}>
        <Header>
          <BoxTitle>{title}</BoxTitle>
          {buttonText && (
            <Button
              onClick={onBtnClick}
              variant="light"
              buttonStyle={{
                padding: '10px 16px',
              }}
            >
              {buttonText}
            </Button>
          )}
        </Header>
        <Content>
          {rows.map((row, index) => {
            return (
              <InfoItem key={`${row.label}_${index}`}>
                <Label>{row.label}</Label>
                {typeof row.value !== 'object' ? <Value>{row.value}</Value> : showValues(row.value)}
              </InfoItem>
            );
          })}
        </Content>
        {bottomContent && <BottomContentContainer>{bottomContent}</BottomContentContainer>}
      </Container>
    </Wrapper>
  );
};

export default InfoBox;
