import { useMemo } from 'react';
import { useAuth } from '../contexts/Auth';
import { useListCompanies } from './queries';
import { useUserGroups } from './useUserGroups';

export function useUserCompanies() {
  const { refactoredUser } = useAuth();
  const { userGroupData } = useUserGroups();

  const { data, isLoading } = useListCompanies();
  const allCompanies = useMemo(() => data?.listCompanies || [], [data?.listCompanies]);

  const getUserCompanies = () => {
    if (!allCompanies) return [];

    if (refactoredUser?.isAdmin()) return allCompanies;

    const userCompanies = allCompanies.filter((company) => {
      const { alias } = company!;

      const userHasThisCompany = userGroupData.some((group) => group.company === alias);

      return userHasThisCompany;
    });

    return userCompanies;
  };

  const userCompanies = getUserCompanies();

  return { userCompanies, companiesLoading: isLoading };
}
