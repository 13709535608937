import React, { useEffect } from 'react';
import { useDeviceJobManager } from '../../../hooks/mutations';
import Spinner from '../../Spinner/Spinner';
import Modal, { ModalOpeningProps } from '../Modal';

type Props = {
  deviceBeingPulled: string | null;
};

const PullDataModal: React.FC<ModalOpeningProps & Props> = ({
  open,
  closeModalFunc,
  deviceBeingPulled,
}) => {
  const { mutateAsync: deviceJobManager, isLoading } = useDeviceJobManager();

  const pullDeviceData = async (deviceId: string) => {
    await deviceJobManager({
      operation: 'pullData',
      deviceId,
    });
  };

  useEffect(() => {
    if (open) pullDeviceData(deviceBeingPulled!);
  }, [open]);

  return (
    <Modal
      closeModalFunc={closeModalFunc}
      open={open}
      title="Pull Data"
      description={`Pulling data for ${deviceBeingPulled}`}
    >
      {isLoading ? <Spinner variant="primary" /> : <p>Job was created successfully</p>}
    </Modal>
  );
};

export default PullDataModal;
