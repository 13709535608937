import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { CreateUserMutation, CreateUserMutationVariables, Device } from '../../../API';
import errorMessages from '../../../config/errorMessages';
import { useAuth } from '../../../contexts/Auth';
import { useCreateUserMutation } from '../../../hooks/mutations';
import { useGroupList, useListDevices } from '../../../hooks/queries';
import UserGroupsUtils, { GroupData } from '../../../utils/UserGroupsUtils';
import Alert from '../../Alert/Alert';
import Button from '../../Button/Button';
import Checkbox from '../../Forms/Checkbox/Checkbox';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import FormRow from '../../Forms/FormRow/FormRow';
import RadioGroup from '../../Forms/RadioGroup/RadioGroup';
import Select from '../../Forms/Select/Select';
import Modal from '../Modal';

type FormVariables = CreateUserMutationVariables &
  GroupData & { confirmPassword: string; agree: string };

type UserType = 'admin' | 'without-utility' | 'with-utility' | 'with-group';

const createUserSchema = yup.object().shape({
  email: yup.string().required(errorMessages.required).email(errorMessages.email),
  groups: yup.array().min(1, errorMessages.minCharacters(1)),
});

type CreateUserModalProps = {
  open: boolean;
  closeModalFunc: (open: boolean) => void;
  onCreateUser?: () => void;
};

const CreateUserModal: React.FC<CreateUserModalProps> = ({
  open,
  closeModalFunc,
  onCreateUser,
}) => {
  const { user } = useAuth();

  const [isAdmin, setIsAdmin] = useState(signedUserIsAdmin());
  const [allUtilityNames, setAllUtilityNames] = useState<string[]>([]);
  const [allGroupNames, setAllGroupNames] = useState<string[]>([]);
  const [loadingUserCreation, setLoadingUserCreation] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');

  const rolesOfUtilities = ['UtilityAdmin', 'UtilityPowerUser'];

  const {
    register,
    formState: { errors },
    trigger,
    control,
    getValues,
    setError,
    reset,
    watch,
  } = useForm<FormVariables>({
    resolver: yupResolver(createUserSchema),
  });

  const perPage = 100;

  const [page, setPage] = useState(1);
  const { data: devices, isLoading: devicesLoading } = useListDevices({
    variables: {
      date: moment().format('YYYY-M-D'),
      page,
      size: perPage,
      company: signedUserIsAdmin() ? watch('company') : null,
    },
  });
  const deviceRows = useMemo(() => devices?.listDevices.rows || [], [devices?.listDevices.rows]);
  const deviceCount = useMemo(() => devices?.listDevices.count || 0, [devices?.listDevices.count]);

  const { mutateAsync: createUserMutation } = useCreateUserMutation();

  const columns: TableColumn<Device | null>[] = [
    {
      name: 'Device Id',
      selector: (row) => (row ? row.id : ''),
      sortable: false,
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const [selectedDevice, setSelectedDevice] = useState<Device | null>();
  const handleSelectedRowsChange = (selected: any) => {
    setSelectedDevice(selected.selectedRows[0]);
  };

  const {
    fields: deviceFields,
    remove: removeDeviceFields,
    replace: replaceDeviceFields,
  } = useFieldArray({
    control,
    name: 'device' as never,
  });

  const {
    fields: utilitiesFields,
    remove: removeUtilitiesFields,
    replace: replaceUtilitiesFields,
  } = useFieldArray({
    control,
    name: 'utility' as never,
  });

  const {
    fields: groupsFields,
    remove: removeGroupsFields,
    replace: replaceGroupsFields,
  } = useFieldArray({
    control,
    name: 'group' as never,
  });

  const { data: useGroupListData } = useGroupList();
  const allUserGroups = useMemo(
    () => (useGroupListData?.groupList?.groups as GroupData[]) || [],
    [useGroupListData?.groupList?.groups]
  );
  const userGroupsData = useMemo(
    () => UserGroupsUtils.buildUserGroupData(allUserGroups),
    [allUserGroups]
  );

  const getGroupsByQuery = UserGroupsUtils.createGroupSearcher(userGroupsData);

  const allCompanyNames = useMemo(getAllCompanyNames, [userGroupsData]);
  const allRoleNames = useMemo(getAllRoleNames, [userGroupsData]);

  function getAllCompanyNames(): (string | undefined)[] {
    const companies = userGroupsData
      .map((group) => {
        if (!group) return;

        return group!.company;
      })
      .filter((company) => !!company)
      .filter((company, index, array) => index === array.indexOf(company!));

    return companies;
  }

  function getAllRoleNames(): (string | undefined)[] {
    const roles = userGroupsData
      .map((group) => {
        if (!group) return;

        return group!.role;
      })
      .filter((role) => !!role)
      .filter((role, index, array) => index === array.indexOf(role!));

    return roles;
  }

  function signedUserIsAdmin() {
    const allGroups = user['cognito:groups'];

    const isAdmin = allGroups.some((group: any) => {
      const [_, role] = group.split('/');

      return role === 'admin';
    });

    return isAdmin;
  }

  const toggleUserType = (isAdmin: boolean) => setIsAdmin(isAdmin);

  const isButtonDisabled = () => {
    if (isAdmin) {
      return !watch('agree');
    }

    return false;
  };

  const setUtilitiesBasedOnCompanies = () => {
    const company = watch('company');
    const role = watch('role');

    if (!company || !role) return;

    const utilities = getGroupsByQuery({
      company,
      role,
    }).map((company) => company.utility);

    removeUtilitiesFields();
    replaceUtilitiesFields(
      utilities.map((utility) => ({
        utility,
      }))
    );

    setAllUtilityNames(utilities);
  };

  const setGroupsBasedOnCompanies = () => {
    const company = watch('company');
    const role = watch('role');

    if (!company || !role) return;

    const groups = getGroupsByQuery({
      company,
      role,
    }).map((company) => company.group);

    removeGroupsFields();
    replaceGroupsFields(
      groups.map((group) => ({
        group,
      }))
    );

    setAllGroupNames(groups);
  };

  const roleNeedUtilities = (): boolean => {
    const role = watch('role');

    if (!role) return false;

    return rolesOfUtilities.includes(role!);
  };

  const roleNeedDevice = (): boolean => {
    const role = watch('role');

    if (!role) return false;

    return role === 'DeviceViewer';
  };

  const roleNeedGroups = (): boolean => {
    const role = watch('role');

    if (!role) return false;

    return role === 'DeviceGroupAdmin';
  };

  const getUserType = (): UserType => {
    const role = watch('role');

    if (isAdmin) return 'admin';

    if (role === 'DeviceGroupAdmin') return 'with-group';

    if (rolesOfUtilities.includes(role)) return 'with-utility';
    if (!rolesOfUtilities.includes(role)) return 'without-utility';

    return 'without-utility';
  };

  const getSuperAdminRole = () => {
    return allUserGroups.find((group) => group!.name === 'romet/admin');
  };

  const handleAdminCreateUser = async (formData: FormVariables) => {
    const superAdminRole = getSuperAdminRole()!.name;
    const data = await createUserMutation({
      ...formData,
      deviceId: selectedDevice?.id,
      groups: [superAdminRole as any],
    });

    return data;
  };

  const handleWithoutUtilityCreateUser = async (formData: FormVariables) => {
    const { company, role } = formData;

    const groups = getGroupsByQuery({
      company,
      role,
    }).map((group) => group.name);

    const data = await createUserMutation({
      ...formData,
      groups,
    });

    return data;
  };

  const handleWithUtilityCreateUser = async (formData: FormVariables) => {
    const utilities = formData.utility
      .map((utility) => utility.utility)
      .filter((utility) => typeof utility === 'string');
    const { company, role } = formData;

    const uncombinedGroups = utilities.map((utility) =>
      getGroupsByQuery({
        company,
        role,
        utility,
      })
    );
    const combinedGroups = uncombinedGroups
      .reduce((currentValue, accumulator) => {
        const combined = accumulator.concat(currentValue);

        return combined;
      }, [])
      .map((utility) => utility.name);

    const data = await createUserMutation({
      ...formData,
      groups: combinedGroups,
    });

    return data;
  };

  const handleWithGroupCreateUser = async (formData: FormVariables) => {
    const groups = formData.group
      .map((group) => group.group)
      .filter((group) => typeof group === 'string');
    const { company, role } = formData;

    const uncombinedGroups = groups.map((group) =>
      getGroupsByQuery({
        company,
        role,
        group,
      })
    );
    const combinedGroups = uncombinedGroups
      .reduce((currentValue, accumulator) => {
        const combined = accumulator.concat(currentValue);

        return combined;
      }, [])
      .map((group) => group.name);

    const data = await createUserMutation({
      ...formData,
      groups: combinedGroups,
    });

    return data;
  };

  const handleCreateUser = async () => {
    setErrorMsg('');

    const hasNoErrors = await trigger();

    if (!hasNoErrors) return;

    const formData = { ...getValues() };
    const userType = getUserType();

    setLoadingUserCreation(true);

    let data: CreateUserMutation | null | undefined;

    switch (userType) {
      case 'admin':
        data = await handleAdminCreateUser(formData);
        break;
      case 'without-utility':
        data = await handleWithoutUtilityCreateUser(formData);
        break;
      case 'with-utility':
        data = await handleWithUtilityCreateUser(formData);
        break;
      case 'with-group':
        data = await handleWithGroupCreateUser(formData);
        break;
      default:
        break;
    }

    const parsedData = JSON.parse(data?.createUser!);

    const error = parsedData.statusCode === 400;

    if (error) {
      const message = parsedData.message;

      setErrorMsg(message);
    } else {
      setSuccessMsg('User created successfully');
      if (onCreateUser) onCreateUser();
      reset({
        email: '',
        company: allCompanyNames[0],
        role: allRoleNames[0],
      });
    }

    setLoadingUserCreation(false);
  };

  return (
    <Modal
      open={open}
      closeModalFunc={closeModalFunc}
      title="Create User"
      description="Please add an email address for the desired user."
      buttonText="Create User"
      buttonDisabled={isButtonDisabled()}
      onSubmitBtnClick={handleCreateUser}
      buttonLoading={loadingUserCreation}
    >
      <FormGroup>
        <FormControl
          name="email"
          register={register}
          label="Email Address"
          id="email"
          type="text"
          error={errors.email?.message}
          onChange={() => {
            setErrorMsg('');
            setSuccessMsg('');
          }}
        />
      </FormGroup>
      {signedUserIsAdmin() && (
        <FormGroup>
          <FormRow>
            <Button
              variant={isAdmin ? undefined : 'light'}
              onClick={() => toggleUserType(true)}
              block
            >
              Romet Admin
            </Button>
            <Button
              variant={!isAdmin ? undefined : 'light'}
              onClick={() => toggleUserType(false)}
              block
            >
              Company
            </Button>
          </FormRow>
        </FormGroup>
      )}

      {isAdmin && (
        <Checkbox
          register={register}
          label="I understand that users with a Romet Admin permission have access to the entire platform."
          name="agree"
          error={errors.agree?.message}
        />
      )}
      {!isAdmin && (
        <>
          <FormGroup>
            <Select
              register={register}
              label="Company"
              name="company"
              onChange={() => {
                setUtilitiesBasedOnCompanies();
                setGroupsBasedOnCompanies();
              }}
            >
              {allCompanyNames.map((company, index) => {
                return (
                  <option
                    key={index}
                    value={company}
                  >
                    {company}
                  </option>
                );
              })}
            </Select>
          </FormGroup>
          <FormGroup>
            <Select
              register={register}
              label="Role"
              name="role"
              onChange={() => {
                setUtilitiesBasedOnCompanies();
                setGroupsBasedOnCompanies();
              }}
            >
              {allRoleNames.map((role, index) => {
                return (
                  <option
                    key={index}
                    value={role}
                  >
                    {role}
                  </option>
                );
              })}
            </Select>
          </FormGroup>

          {
            roleNeedDevice() && (
              <DataTable
                selectableRows
                pagination
                paginationServer
                noTableHead
                selectableRowsSingle
                onChangePage={handlePageChange}
                progressPending={devicesLoading}
                columns={columns}
                data={deviceRows}
                paginationPerPage={perPage}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onSelectedRowsChange={handleSelectedRowsChange}
                paginationTotalRows={deviceCount}
              />
            )
            // <FormGroup>
            //   <RadioGroup label="Select Device:">

            //     {deviceRows.map((device, index) => {
            //       if (device === null) {
            //         return;
            //       }

            //       return (
            //         <>
            //           <Checkbox
            //             key={device.id}
            //             register={register}
            //             label={device.id}
            //             value={device.id}
            //             name={`device.${index}.device` as const} />
            //         </>
            //       )
            //     })}

            //   </RadioGroup>

            // </FormGroup>
          }

          {roleNeedUtilities() && (
            <FormGroup>
              <RadioGroup label="Select Utility:">
                {utilitiesFields.map((utility, index) => {
                  return (
                    <Checkbox
                      key={utility.id}
                      register={register}
                      label={allUtilityNames[index]}
                      value={allUtilityNames[index]}
                      name={`utility.${index}.utility` as const}
                    />
                  );
                })}
              </RadioGroup>
            </FormGroup>
          )}

          {roleNeedGroups() && (
            <FormGroup>
              <RadioGroup label="Select Group:">
                {groupsFields.map((group, index) => {
                  return (
                    <Checkbox
                      key={group.id}
                      register={register}
                      label={allGroupNames[index]}
                      value={allGroupNames[index]}
                      name={`group.${index}.group` as const}
                    />
                  );
                })}
              </RadioGroup>
            </FormGroup>
          )}
        </>
      )}

      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
      {successMsg && <Alert variant="success">{successMsg}</Alert>}
    </Modal>
  );
};

export default CreateUserModal;
