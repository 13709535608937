import { useGetBatteryLife } from '../../../hooks/queries';

export const useMeterIdBatteryLife = (id?: string) => {
  const { data, refetch: refetchBatteryLife } = useGetBatteryLife({
    variables: { deviceId: id },
  });
  const batteryLife = data?.getBatteryLife || null;

  return { batteryLife, refetchBatteryLife };
};
