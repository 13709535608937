import React, { useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import DataTable from '../../components/DataTable/DataTable';

import { AiOutlinePlusCircle } from 'react-icons/ai';
import { CgTrashEmpty } from 'react-icons/cg';
import { RiDownloadLine, RiPencilLine } from 'react-icons/ri';

import {
  Bedge,
  BedgeSuffix,
  DragDropContainer,
  DragDropMsg,
  FixedButtons,
  OptionsWrapper,
  SearchInputWrapper,
  ShowingMsg,
  Space,
  WantToRemoveMsg,
} from './style';

import { useDropzone } from 'react-dropzone';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ImportUsersMutationVariables, User } from '../../API';
import Alert from '../../components/Alert/Alert';
import Button from '../../components/Button/Button';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import FormControl from '../../components/Forms/FormControl/FormControl';
import FormGroup from '../../components/Forms/FormGroup/FormGroup';
import FormRow from '../../components/Forms/FormRow/FormRow';
import CreateUserModal from '../../components/Modal/CreateUserModal/CreateUserModal';
import EditUserModal from '../../components/Modal/EditUserModal/EditUserModal';
import Modal from '../../components/Modal/Modal';
import SearchInput from '../../components/SearchInput/SearchInput';
import Spinner from '../../components/Spinner/Spinner';
import errorMessages from '../../config/errorMessages';
import { useImportUsers, useRemoveUser } from '../../hooks/mutations';
import { useGroupListForUsers, useUserList } from '../../hooks/queries';

const createUserSchema = yup.object().shape({
  email: yup.string().required(errorMessages.required).email(errorMessages.email),
  password: yup
    .string()
    .required(errorMessages.required)
    .min(8, errorMessages.minCharacters(8))
    .matches(/[a-z]/g, 'Password must have lowercase characters')
    .matches(/[!@#$%^&*(),.?":{}|<>]/g, 'Password must have symbol characters')
    .matches(/[A-Z]/g, 'Password must have uppercase characters'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], "Passwords don't match")
    .required(errorMessages.required),
  groups: yup.array().min(1, errorMessages.minCharacters(1)),
});

const Users: React.FC = () => {
  const { mutateAsync: removeUserMutation } = useRemoveUser();
  const { mutateAsync: importUsersMutation } = useImportUsers();

  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [username, setUsername] = useState('');

  const {
    data: userListData,
    isLoading: userListLoading,
    refetch: refetchUserList,
  } = useUserList({
    variables: {
      username,
      limit: perPage,
      page,
    },
    additionalOptions: {
      cacheTime: 0,
    },
  });

  const userCount = useMemo(() => userListData?.userList.count, [userListData?.userList.count]);
  const userList = useMemo(
    () => userListData?.userList.users || [],
    [userListData?.userList.users]
  );

  const {
    data: userGroups,
    isLoading: userGroupsLoading,
    refetch: refetchUserGroups,
  } = useGroupListForUsers({
    variables: {
      users: userList?.map((user) => user?.username!),
    },
    additionalOptions: {
      enabled: !!userList?.length,
      cacheTime: 0,
    },
  });

  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false);
  const [userToBeRemoved, setUserToBeRemoved] = useState<User | null>(null);
  const [removingUser, setRemovingUser] = useState(false);
  const [removeUserMsg, setRemoveUserMsg] = useState('');
  const [removeUserErrorMsg, setRemoveUserErrorMsg] = useState('');

  const [createUserModalOpen, setCreateUserModalOpen] = useState(false);

  const [importUserModalOpen, setImportUserModalOpen] = useState(false);
  const [dropError, setDropError] = useState<string | undefined>();
  const [importUserData, setImportUserData] = useState<ImportUsersMutationVariables>();
  const [loadingImportingUsers, setLoadingImportingUsers] = useState(false);

  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [userBeingEdited, setUserBeingEdited] = useState<User | null>(null);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'text/csv': ['.csv'],
    },
    onDrop: (files) => {
      setDropError(undefined);
      const fileReader = new FileReader();
      const file = files[0];

      fileReader.onload = () => {
        const binaryStr = fileReader.result;
        setImportUserData({ file: binaryStr!.toString() });
      };

      if (!file) return;

      fileReader.readAsDataURL(file);
    },
    onDropRejected: (file) => {
      setDropError('Incorrect file');
    },
  });
  const file = acceptedFiles[0];

  const {
    handleSubmit: removeUserSubmitHandler,
    register: removeUserRegister,
    setValue: setRemoveUserValue,
  } = useForm<User>();

  const columns: TableColumn<User>[] = [
    {
      name: 'Username',
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: 'Groups',
      selector: (row) => {
        if (userGroupsLoading) return <Spinner variant="primary" />;

        for (const userGroup of userGroups?.groupListForUsers || []) {
          if (userGroup && userGroup.id === row?.username)
            return userGroup.groups?.map((group) => {
              const getSuffix = ({ group, utility, company }: any) => {
                if (group) {
                  return group;
                } else if (utility) {
                  return utility;
                } else if (company) {
                  return company;
                }

                return null;
              };

              const regexDivided = [...group?.description?.matchAll(/(\w+):\s+([\w-]+)/g)!];

              const dividedToObject = regexDivided.reduce((accumulator: any, currentValue) => {
                const name = currentValue[1];
                const value = currentValue[2];

                accumulator[name] = value;

                return accumulator;
              }, {});

              const role = dividedToObject.role || 'RometAdmin';
              const suffix = getSuffix(dividedToObject);

              const formattedRole = role.replaceAll(/([A-Z])/g, ' $1');

              return (
                <Bedge
                  size="sm"
                  key={`${row.id}_${suffix}`}
                  variant="light-primary"
                >
                  <strong>
                    {formattedRole}
                    {suffix ? ':' : ''}
                  </strong>{' '}
                  {suffix && <BedgeSuffix>&nbsp;{suffix}</BedgeSuffix>}
                </Bedge>
              );
            }) as any;
        }

        return '';
      },
      sortable: true,
      style: {
        padding: '24px 0',
        '& > div': {
          display: 'inline-flex',
          flexWrap: 'wrap',
          gap: '10px',
        },
      },
      compact: true,
    },
    {
      name: '',
      selector: (row) =>
        (
          <OptionsWrapper>
            <Button
              onClick={() => {
                setEditUserModalOpen(true);
                setUserBeingEdited(row);
              }}
              variant="light"
              shape="round"
            >
              <RiPencilLine />
            </Button>
            <Button
              onClick={() => setUserToBeRemoved(row)}
              variant="light"
              shape="round"
            >
              <CgTrashEmpty />
            </Button>
          </OptionsWrapper>
        ) as any,
      style: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
          width: '47.5px',
          height: '32px',
          minHeight: '32px',
        },
      },
    },
  ];

  const refetchUsers = async () => {
    refetchUserList();
    refetchUserGroups();
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (perPage: number, page: number) => {
    setPage(page);
    setPerPage(perPage);
  };

  const handleCreateUserBtnClick = async () => {
    setCreateUserModalOpen(true);
  };

  const onUserRemoveSubmit: SubmitHandler<User> = async ({ username }) => {
    setRemovingUser(true);
    setRemoveUserErrorMsg('');
    setRemoveUserMsg('');

    const data = await removeUserMutation({
      username,
    });

    if (!data?.removeUser.success) {
      setRemoveUserErrorMsg(data?.removeUser.message || '');
    } else {
      setUserToBeRemoved(null);
      setRemoveUserMsg(data?.removeUser.message || '');
      refetchUsers();
    }

    setRemovingUser(false);
  };

  const onUserImportSubmit = async () => {
    if (!importUserData) {
      setDropError('No file selected');
      return;
    }
    setLoadingImportingUsers(true);
    try {
      await importUsersMutation({ ...importUserData! });

      setImportUserModalOpen(false);
      setDropError(undefined);
      refetchUsers();
    } catch (e) {
      setDropError('Malformatted CSV');
    }
    setLoadingImportingUsers(false);
  };

  const onUserEdited = () => {
    refetchUsers();
    setEditUserModalOpen(false);
  };

  useEffect(() => {
    if (userToBeRemoved) {
      setRemoveUserValue('username', userToBeRemoved.username);
      setRemoveUserModalOpen(true);
    }
  }, [userToBeRemoved]);

  useEffect(() => {
    if (!editUserModalOpen) {
      setUserBeingEdited(null);
      refetchUsers();
    }
  }, [editUserModalOpen]);

  useEffect(() => {
    if (!removeUserModalOpen) {
      setUserToBeRemoved(null);
    }
  }, [removeUserModalOpen]);

  return (
    <>
      <Modal
        title="Remove user?"
        description="This action can't be undone."
        open={removeUserModalOpen}
        closeModalFunc={setRemoveUserModalOpen}
      >
        <form onSubmit={removeUserSubmitHandler(onUserRemoveSubmit)}>
          <WantToRemoveMsg>
            Do you want remove user {userToBeRemoved?.username || '[REMOVED]'}?
          </WantToRemoveMsg>
          <input
            type="hidden"
            {...removeUserRegister('username')}
          />
          <FormControl
            label={userToBeRemoved?.username || '[REMOVED]'}
            id="deviceId"
            disabled
          />
          <FormGroup>
            <FormGroup>
              {removeUserErrorMsg && <Alert variant="danger">{removeUserErrorMsg}</Alert>}
              {removeUserMsg && !userToBeRemoved?.username && <Alert>{removeUserMsg}</Alert>}
            </FormGroup>
            <FormRow>
              {!!userToBeRemoved?.username && (
                <Button
                  block
                  variant="danger"
                  loading={removingUser}
                  type="submit"
                >
                  Remove User
                </Button>
              )}

              <Button
                block
                variant="light-primary"
                onClick={(e) => {
                  e.preventDefault();
                  setRemoveUserModalOpen(false);
                }}
              >
                {userToBeRemoved?.username ? 'Keep User' : 'Close'}
              </Button>
            </FormRow>
          </FormGroup>
        </form>
      </Modal>

      <CreateUserModal
        open={createUserModalOpen}
        closeModalFunc={setCreateUserModalOpen}
      />

      <EditUserModal
        open={editUserModalOpen}
        closeModalFunc={setEditUserModalOpen}
        userBeingEdited={userBeingEdited}
        onUserEdited={onUserEdited}
      />

      <Modal
        title="Import Users"
        description="CSV should have following: name, password, and groups. Values in groups should be separated by ',' symbol."
        open={importUserModalOpen}
        closeModalFunc={setImportUserModalOpen}
        buttonText="Import users"
        buttonLoading={loadingImportingUsers}
        onSubmitBtnClick={onUserImportSubmit}
      >
        <DragDropContainer {...getRootProps()}>
          <input {...getInputProps()} />

          <DragDropMsg>
            {file ? file.name : 'Drop CSV file here or click to select files'}
          </DragDropMsg>
        </DragDropContainer>
        {dropError && <ErrorMessage>{dropError}</ErrorMessage>}
      </Modal>

      {/* <TitleContainer>
        <MainTitleWrapper>
          <Title>Users</Title>
        </MainTitleWrapper>
      </TitleContainer> */}

      <SearchInputWrapper>
        <SearchInput
          onChange={(e) => setUsername(e.currentTarget.value)}
          placeholder="Search by username"
        />
        <ShowingMsg>Showing {userList.length.toString().replace('.', ',')} results</ShowingMsg>
      </SearchInputWrapper>

      <DataTable
        columns={columns}
        data={userList}
        progressPending={userListLoading}
        pagination
        paginationServer
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        paginationTotalRows={userCount || 0}
      />
      <Space />

      <FixedButtons>
        <Button
          startIcon={<RiDownloadLine size={13} />}
          shape="round"
          variant="light"
          type="button"
          onClick={() => setImportUserModalOpen(true)}
        >
          Import Users
        </Button>
        <Button
          startIcon={<AiOutlinePlusCircle size={13} />}
          shape="round"
          onClick={() => handleCreateUserBtnClick()}
        >
          Create user
        </Button>
      </FixedButtons>
    </>
  );
};

export default Users;
