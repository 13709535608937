import moment from 'moment';
import { IconType } from 'react-icons';
import {
  MdOutlineBatteryChargingFull,
  MdOutlineSpeed,
  MdPhotoSizeSelectSmall,
  MdThermostat,
  MdWaves,
} from 'react-icons/md';
import { TbHeartbeat } from 'react-icons/tb';

import { ChartOptions } from 'chart.js';
import _ from 'lodash';
import { MeterDataItem } from '../../API';
import {
  QMarginData,
  QMarginDataList,
} from '../../components/MeterHealthCertificateModal/MeterHealthCertificateModal';

type DeviceCompanyData = {
  title: string;
  value: string;
  selector: string;
  scale: string;
  disabled?: boolean;
};

export type DeviceDataFormat = {
  title: string;
  datasets: {
    title: string;
    selector: string;
  }[];
  id: string;
  scale?: string;
  button?: {
    icon: IconType;
    text: string;
    omit?: boolean;
    disabled?: boolean;
  };
  omit?: boolean;
};

export function chartLineBarOptions(
  volumeUnit: string | undefined,
  flowUnit: string | undefined,
  pressureUnit: string | undefined,
  voltageUnit: string | undefined,
  temperatureUnit: string | undefined
): ChartOptions<'line' | 'bar'> {
  return {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date/Time',
        },
      },
      y: {
        type: 'linear',
        display: 'auto',
        position: 'left',
      },
      volumeUnit: {
        type: 'linear',
        display: 'auto',
        position: 'left',
        title: {
          display: true,
          text: volumeUnit?.replace('3', '³'),
        },
      },
      flowUnit: {
        type: 'linear',
        display: 'auto',
        position: 'left',
        title: {
          display: true,
          text: flowUnit?.replace('3', '³'),
        },
      },
      pressureUnit: {
        type: 'linear',
        display: 'auto',
        position: 'left',
        title: {
          display: true,
          text: pressureUnit,
        },
      },
      voltageUnit: {
        type: 'linear',
        display: 'auto',
        position: 'left',
        title: {
          display: true,
          text: voltageUnit,
        },
      },
      temperatureUnit: {
        type: 'linear',
        display: 'auto',
        position: 'left',
        title: {
          display: true,
          text: temperatureUnit,
        },
      },
    },
  };
}

export function getDailyDeviceCompanyData(
  omitPressure: boolean,
  omitTemperature: boolean
): DeviceCompanyData[] {
  return [
    {
      title: 'Remaining Battery Life',
      value: 'percentage',
      selector: 'percentage',
      scale: 'percentage',
      disabled: true,
    },
    {
      title: 'Max. Pressure',
      value: 'maxPr',
      selector: 'maxPr.v',
      scale: 'pressureUnit',
      disabled: omitPressure,
    },
    {
      title: 'Min. Pressure',
      value: 'minPr',
      selector: 'minPr.v',
      scale: 'pressureUnit',
      disabled: omitPressure,
    },
    {
      title: 'Max. Temp',
      value: 'maxTemp',
      selector: 'maxTemp.v',
      scale: 'temperatureUnit',
      disabled: omitTemperature,
    },
    {
      title: 'Min. Temp',
      value: 'minTemp',
      selector: 'minTemp.v',
      scale: 'temperatureUnit',
      disabled: omitTemperature,
    },
    {
      title: 'Max Flow Corr',
      value: 'maxFC',
      selector: 'maxFC.v',
      scale: 'flowUnit',
    },
    {
      title: 'Min Flow Corr',
      value: 'minFC',
      selector: 'minFC.v',
      scale: 'flowUnit',
    },
    {
      title: 'Corr. Index Read',
      value: 'dayCV',
      selector: 'dayCV',
      scale: 'volumeUnit',
    },
    {
      title: 'Uncorr. Index Read',
      value: 'dayUV',
      selector: 'dayUV',
      scale: 'volumeUnit',
    },
  ];
}

export function getDeviceDataFormat(
  frequency: string,
  omitPressure: boolean,
  ademModel: string | null
): DeviceDataFormat[] {
  return [
    {
      title: 'Full Volume',
      datasets: [
        {
          title: 'Full Corrected Volume',
          selector: 'fCV',
        },
        {
          title: 'Full Uncorrected Volume',
          selector: 'fUV',
        },
      ],
      button: {
        icon: MdPhotoSizeSelectSmall,
        text: 'Full Volume',
        omit: frequency === 'daily',
      },
      id: 'volume',
      scale: 'volumeUnit',
    },
    {
      title: 'Corrected/Uncorrected Volume',
      datasets: [
        {
          title: 'Corrected Volume',
          selector: frequency === 'daily' ? 'dayCV' : 'cV',
        },
        {
          title: 'Uncorrected Volume',
          selector: frequency === 'daily' ? 'dayUV' : 'uV',
        },
      ],
      button: {
        icon: MdPhotoSizeSelectSmall,
        text: 'Volume',
      },
      id: 'volumeMinMax',
      scale: 'volumeUnit',
    },
    {
      title: 'Pressure',
      button: {
        icon: MdOutlineSpeed,
        text: 'Pressure',
        omit: frequency !== 'hourly' || omitPressure,
      },
      datasets: [
        {
          title: 'Pressure',
          selector: 'pr',
        },
      ],
      id: 'pressure',
      scale: 'pressureUnit',
      omit: omitPressure,
    },
    {
      title: 'Pressure MIN/MAX',
      button: {
        icon: MdOutlineSpeed,
        text: 'Pressure',
        omit: frequency !== 'daily' || omitPressure,
      },
      datasets: [
        {
          title: 'Min Pressure',
          selector: 'minPr',
        },
        {
          title: 'Max Pressure',
          selector: 'maxPr',
        },
      ],
      id: 'pressureMinMax',
      scale: 'pressureUnit',
      omit: omitPressure,
    },
    {
      title: 'Temperature',
      datasets: [
        {
          title: 'Temperature',
          selector: 'temp',
        },
      ],
      button: {
        icon: MdThermostat,
        text: 'Temperature',
        omit: frequency !== 'hourly',
      },
      id: 'temperature',
      scale: 'temperatureUnit',
    },
    {
      title: 'Temperature MIN/MAX',
      datasets: [
        {
          title: 'Min Temperature',
          selector: 'minTemp',
        },
        {
          title: 'Max Temperature',
          selector: 'maxTemp',
        },
      ],
      button: {
        icon: MdThermostat,
        text: 'Temperature',
        omit: ademModel === 'T' || frequency !== 'daily',
      },
      omit: ademModel === 'T',
      id: 'temperatureMinMax',
      scale: 'temperatureUnit',
    },
    {
      title: 'Flow Correction MIN/MAX',
      button: {
        icon: MdWaves,
        text: 'Flow',
        disabled: frequency !== 'daily',
        omit: ademModel === 'T',
      },
      datasets: [
        {
          title: 'Min Flow',
          selector: 'minFC',
        },
        {
          title: 'Max Flow',
          selector: 'maxFC',
        },
      ],
      omit: ademModel === 'T',
      id: 'flowCorrectionMinMax',
      scale: 'flowUnit',
    },
    {
      title: 'Remaining Battery Life',
      datasets: [
        {
          title: 'Remaining Battery Life',
          selector: 'percentage',
        },
      ],
      button: {
        icon: MdOutlineBatteryChargingFull,
        text: 'Battery Health',
        disabled: true,
      },
      id: 'batteryVoltage',
      scale: 'voltageUnit',
    },
    {
      title: 'Q Margin',
      datasets: [
        {
          title: 'Q Margin',
          selector: 'qMarg',
        },
      ],
      button: {
        icon: TbHeartbeat,
        text: 'Meter Health',
        omit: ademModel !== 'Tq',
      },
      id: 'qMargin',
      omit: ademModel !== 'Tq',
    },
  ];
}

export function getHourlyDeviceCompanyData(
  omitPressure: boolean,
  omitTemperature: boolean
): DeviceCompanyData[] {
  return [
    {
      title: 'Pressure',
      value: 'pr',
      selector: 'pr',
      scale: 'pressureUnit',
      disabled: omitPressure,
    },
    {
      title: 'Temperature',
      value: 'temp',
      selector: 'temp',
      scale: 'temperatureUnit',
      disabled: omitTemperature,
    },
    {
      title: 'Corr. Index Read',
      value: 'cV',
      selector: 'cV',
      scale: 'volumeUnit',
    },
    {
      title: 'Uncorr. Index Read',
      value: 'uV',
      selector: 'uV',
      scale: 'volumeUnit',
    },
    {
      title: 'Full Corr. Index Read',
      value: 'fCV',
      selector: 'fCV',
      scale: 'volumeUnit',
    },
    {
      title: 'Full Uncorr. Index Read',
      value: 'fUV',
      selector: 'fUV',
      scale: 'volumeUnit',
    },
  ];
}

/**
 * Get the average of the Q Margin data for each month.
 */
export function parseQMarginData(data?: (MeterDataItem | null)[]): QMarginDataList {
  if (!data) {
    return [];
  }

  let qMarginDataList: QMarginDataList = [];

  // Split the data by month
  type QMarginBucket = { [month: string]: { value: number; length: number } };
  const buckets = data?.reduce((prev: QMarginBucket, curr) => {
    const date = moment(new Date(curr!.time!)).format('MM/YYYY');

    const qMarg = curr?.qMarg;
    if (!_.isNil(qMarg)) {
      if (!(date in prev)) {
        prev[date] = { value: 0, length: 0 };
      }

      prev[date].value += qMarg;
      prev[date].length += 1;
    }

    return prev;
  }, {});

  // Remove current month since data will not be fully populated until the next month
  const date = new Date();
  delete buckets[moment(date).format('MM/YYYY')];

  // Find the average of each month
  qMarginDataList = Object.entries(buckets).reduce((prev: QMarginDataList, curr) => {
    const [date, bucket] = curr;

    const qMarginData: QMarginData = {
      label: date,
      value: bucket.value / bucket.length,
    };

    prev.push(qMarginData);
    return prev;
  }, []);

  return qMarginDataList;
}
