import { DefaultTheme } from 'styled-components';

export function calculateAndSetTableHeight() {
  const tableBody: HTMLTableSectionElement | null = document.querySelector('.rdt_TableBody');

  if (!tableBody) return;

  const windowInnerHeight = window.innerHeight;
  const tableFromTop = tableBody.getBoundingClientRect().top;

  const tableNavHeight = document.querySelector('.rdt_Pagination')?.getBoundingClientRect().height;

  const maxHeight = windowInnerHeight - tableFromTop - tableNavHeight!;

  tableBody.style.height = `${maxHeight}px`;
}

export function tableStyles(theme: DefaultTheme) {
  return {
    rows: {
      style: {
        minHeight: '80px',
        flex: '1',
        maxHeight: 100,
        '&:nth-child(odd)': {
          backgroundColor: theme.colors.neutral,
        },
        '&:nth-child(even)': {
          backgroundColor: theme.colors.white,
        },
        border: 'none',
      },
    },
    table: {
      style: {
        '& div:first-child': {
          overflow: 'visible !important',
        },
        '& .rdt_TableBody': {
          overflow: 'auto',
        },
      },
    },
  };
}
