import { BsThreeDots } from 'react-icons/bs';

import { memo } from 'react';
import { Device } from '../../../API';
import Can from '../../../components/Can/Can';
import { DropdownItem, TableDropdown } from '../style';
import { DropdownBtn } from './style';

type Props = {
  row: Device;
  index: number;
  onInstallFirmware: (device: Device) => void;
  onDevicePull: (device: Device) => void;
  onDeviceExport: (device: Device) => void;
  onAlarmExport: (device: Device) => void;
  onShadowDevice: (device: Device) => void;
};

export const DeviceTableActions = memo(
  ({
    row,
    index,
    onInstallFirmware,
    onDevicePull,
    onAlarmExport,
    onDeviceExport,
    onShadowDevice,
  }: Props) => {
    const handleOnInstallFirmware = () => onInstallFirmware(row);
    const handleOnDevicePull = () => onDevicePull(row);
    const handleOnAlarmExport = () => onAlarmExport(row);
    const handleOnDeviceExport = () => onDeviceExport(row);
    const handleOnShadowDevice = () => onShadowDevice(row);

    return (
      <Can
        key={row.id}
        role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}
      >
        <DropdownBtn
          dropdownPosition={index! > 4 ? 'top' : 'bottom'}
          renderContent={
            <TableDropdown>
              <Can role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}>
                <DropdownItem onClick={handleOnInstallFirmware}>Install Firmware</DropdownItem>
              </Can>
              <Can role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}>
                <DropdownItem onClick={handleOnDevicePull}>Pull Data</DropdownItem>
              </Can>
              <Can role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}>
                <DropdownItem onClick={handleOnAlarmExport}>Export Alarm Data</DropdownItem>
              </Can>
              <Can role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}>
                <DropdownItem onClick={handleOnDeviceExport}>Export Asset Data</DropdownItem>
              </Can>
              <Can role={'admin'}>
                <DropdownItem onClick={handleOnShadowDevice}>Asset Shadow</DropdownItem>
              </Can>
            </TableDropdown>
          }
          shape="circle"
          variant="light"
          dropIcon={false}
        >
          <BsThreeDots />
        </DropdownBtn>
      </Can>
    );
  }
);
