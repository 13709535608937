import moment from 'moment';
import { useCallback, useState } from 'react';

import NavTabs from '../../components/NavTabs/NavTabs';
import { useListDevices } from '../../hooks/queries';
import { DeviceHeader } from './DeviceHeader';
import { DeviceMap } from './DeviceMap';
import { DeviceTable } from './DeviceTable';
import { useDevicesContext } from './DevicesProvider';
import { GeneralContainer } from './style';

function Devices() {
  const { page, perPage, search, dynamicMap, cords } = useDevicesContext();
  const [fullMap, setFullMap] = useState(false);
  const [onlyTable, setOnlyTable] = useState(true);
  const [showingMap, setShowingMap] = useState(true);

  const devices = useListDevices({
    variables: {
      date: moment().format('YYYY-M-D'),
      ...search,
      page,
      size: perPage,
      ...(!dynamicMap ? undefined : cords),
    },
    additionalOptions: {
      refetchOnMount: 'always',
    },
  });

  const changeFullMap = useCallback((full: boolean) => {
    setShowingMap(true);
    setFullMap(full);
  }, []);

  const changeShowingMap = useCallback((showMap: boolean) => {
    setShowingMap(showMap);
    if (!showMap) {
      setFullMap(false);
    }
  }, []);

  return (
    <>
      <DeviceHeader />
      <NavTabs
        items={[
          {
            text: 'Inventory',
            to: '#',
            onClick: () => setOnlyTable(true),
            active: onlyTable,
          },
          {
            text: 'Map',
            to: '#',
            onClick: () => setOnlyTable(false),
            active: !onlyTable,
          },
        ]}
      />
      <GeneralContainer
        fullMap={fullMap}
        showingMap={showingMap}
      >
        {!onlyTable && (
          <DeviceMap
            fullMap={fullMap}
            showingMap={showingMap}
            onChangeFullMap={changeFullMap}
            devices={devices.data?.listDevices.rows || []}
            onShowingMapChange={changeShowingMap}
          />
        )}

        <DeviceTable
          fullMap={fullMap}
          showingMap={showingMap}
          devices={devices}
          onShowingMapChange={changeShowingMap}
        />
      </GeneralContainer>
    </>
  );
}

export { Devices };
