import React, { memo, useEffect, useMemo, useState } from 'react';
import { Selector, TableColumn } from 'react-data-table-component';
import { ImMap2 } from 'react-icons/im';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CSSObject, useTheme } from 'styled-components';

import { useCallback } from 'react';
import { Device, ListDevicesQuery } from '../../../API';
import Button from '../../../components/Button/Button';
import DataT from '../../../components/DataTable/DataTable';
import UserSettingsUtils from '../../../utils/UserSettingsUtils';
import { MapStates } from '../DeviceMap/DeviceMap';
import { useDevicesContext, useDevicesDispatchContext } from '../DevicesProvider';
import { DataContainer } from '../style';
import { DeviceTableActions } from './DeviceTableActions';
import { initPossibleColumns } from './DeviceTableColumns';
import { DeviceTableFilter } from './DeviceTableFilter';
import { calculateAndSetTableHeight, tableStyles } from './DeviceTableHelper';
import { DeviceTableModals } from './DeviceTableModals';
import { TableButtons, TableWrapper } from './style';
import { useDeviceTableModal } from './useDeviceTableModals';

export type PossibleColumnsProps = {
  id: string;
  name: string;
  isVisible: boolean;
  style?: CSSObject;
  selector?: Selector<Device>;
  cell?: (
    row: Device,
    rowIndex: number,
    column: TableColumn<Device>,
    id: string | number
  ) => React.ReactNode;
  center?: boolean;
};

type Props = {
  devices: UseQueryResult<ListDevicesQuery, unknown>;
} & MapStates;

const DeviceTable: React.FC<Props> = memo(
  ({ devices, fullMap, showingMap, onShowingMapChange }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    const { page, perPage } = useDevicesContext();
    const { handlePage, handlePerPage } = useDevicesDispatchContext();
    const [possibleColumns, setPossibleColumns] =
      useState<PossibleColumnsProps[]>(initPossibleColumns);

    const {
      selectedDevice,
      installFirmwareModalOpen,
      setInstallFirmwareModalOpen,
      handleInstallFirmware,
      pullDataModalOpen,
      setPullDataModalOpen,
      handleDevicePull,
      exportAlarmModalOpen,
      setExportAlarmModalOpen,
      handleAlarmExport,
      exportDeviceModalOpen,
      setExportDeviceModalOpen,
      handleExportDevices,
      deviceShadowModalOpen,
      setDeviceShadowModalOpen,
      handleShadowDevice,
    } = useDeviceTableModal();

    const columns: TableColumn<Device>[] = useMemo(
      () => [
        ...possibleColumns.map(({ name, selector, center, style, isVisible, cell }) => {
          return {
            name,
            selector,
            cell,
            center,
            style,
            omit: !isVisible,
          };
        }),
        {
          name: '',
          reorder: true,
          cell: (row, index) => {
            return (
              <DeviceTableActions
                row={row}
                index={index}
                onInstallFirmware={handleInstallFirmware}
                onDevicePull={handleDevicePull}
                onDeviceExport={handleExportDevices}
                onAlarmExport={handleAlarmExport}
                onShadowDevice={handleShadowDevice}
              />
            );
          },
          center: true,
          compact: true,
          style: {
            '& button': {
              padding: '14px',
              fontSize: '12px',
              minWidth: '32px',
              minHeight: '32px',
            },
          },
        },
      ],
      [
        possibleColumns,
        handleInstallFirmware,
        handleDevicePull,
        handleExportDevices,
        handleAlarmExport,
        handleShadowDevice,
      ]
    );

    const handleRowClicked = useCallback(
      (device: Device) => {
        navigate('/assets/' + device.id);
      },
      [navigate]
    );

    const deviceRows = useMemo(
      () => devices?.data?.listDevices.rows || [],
      [devices?.data?.listDevices.rows]
    );

    const handleShowMap = () => {
      onShowingMapChange?.(true);
    };

    useEffect(() => {
      async function loadColumns() {
        const { columns: storedColumns } = await UserSettingsUtils.getColumns('userConfig');
        const parsedColumns = possibleColumns.map((item) => {
          const { id } = item;

          const findColumnStored = storedColumns?.some((stored: string) => stored === id);

          if (findColumnStored) {
            return {
              ...item,
              isVisible: true,
            };
          }

          return item;
        });
        setPossibleColumns(parsedColumns);
      }

      loadColumns();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      calculateAndSetTableHeight();
    }, [deviceRows]);

    useEffect(() => {
      window.addEventListener('resize', calculateAndSetTableHeight);

      const userSettingsColumns = UserSettingsUtils.getItem('columns') || [];

      setPossibleColumns((columns) =>
        columns.map((column) => ({
          ...column,
          visible: userSettingsColumns.includes(column.id),
        }))
      );
      return () => {
        window.removeEventListener('resize', calculateAndSetTableHeight);
      };
    }, []);

    return (
      <DataContainer
        fullMap={fullMap}
        showingMap={showingMap}
      >
        <DeviceTableModals
          selectedDevice={selectedDevice}
          installFirmwareModalOpen={installFirmwareModalOpen}
          setInstallFirmwareModalOpen={setInstallFirmwareModalOpen}
          pullDataModalOpen={pullDataModalOpen}
          setPullDataModalOpen={setPullDataModalOpen}
          exportAlarmModalOpen={exportAlarmModalOpen}
          setExportAlarmModalOpen={setExportAlarmModalOpen}
          exportDeviceModalOpen={exportDeviceModalOpen}
          setExportDeviceModalOpen={setExportDeviceModalOpen}
          deviceShadowModalOpen={deviceShadowModalOpen}
          setDeviceShadowModalOpen={setDeviceShadowModalOpen}
        />

        <DeviceTableFilter
          possibleColumns={possibleColumns}
          setPossibleColumns={setPossibleColumns}
        />
        <TableWrapper className="table-wrapper">
          {perPage && (
            <DataT
              paginationDefaultPage={page}
              onRowClicked={handleRowClicked}
              pointerOnHover={true}
              columns={columns}
              data={deviceRows}
              progressPending={devices.isLoading}
              pagination
              paginationServer
              onChangePage={handlePage}
              onChangeRowsPerPage={handlePerPage}
              paginationTotalRows={devices.data?.listDevices.count}
              paginationPerPage={perPage}
              customStyles={tableStyles(theme)}
              defaultSortFieldId="alarms"
              defaultSortAsc={false}
            />
          )}

          <TableButtons>
            {!showingMap && (
              <Button
                variant="light-primary"
                startIcon={<ImMap2 />}
                size="sm"
                onClick={handleShowMap}
              >
                Show Map
              </Button>
            )}
          </TableButtons>
        </TableWrapper>
      </DataContainer>
    );
  }
);

export { DeviceTable };
