import { Alarm, Device } from '../../API';

export const mapStyles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

const northAmericaLatLong = {
  lat: 47.116386,
  lng: -101.299591,
};

const center: google.maps.LatLngLiteral = {
  ...northAmericaLatLong,
};

export const mapOptions = {
  center,
  zoom: 4,
  styles: mapStyles,
  disableDefaultUI: true,
};

const markerSvgIconPath =
  'M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z';

export const infoWindowContent = (device: Device, alarms: (Alarm | null)[] | undefined) => `
<div style="width:250px; height:150px; padding: 5px">
  Customer ID: <strong>${device.meterId}</strong> <br>
  Address: <strong>${device.address}</strong> <br>
  User: <strong>${device.user_device?.user?.email || 'Not Attached'}</strong> <br>
  Alarms: <strong>${alarms?.length}</strong> <br><br>
  <a class="btn" href="assets/${device.id}">View</a>
</div>
`;

export const getDeviceAlarms = (device: Device) =>
  device?.messages?.reduce(
    (acc, message) => {
      if (message?.alarms && Array.isArray(message.alarms)) {
        acc.push(...message.alarms.filter((alarm) => alarm !== null));
      }
      return acc;
    },
    [] as (Alarm | null)[]
  );

export const markerIcon = (color: string, strokeColor: string) => ({
  path: markerSvgIconPath,
  fillColor: color,
  fillOpacity: 1,
  scale: 2,
  strokeWeight: 3,
  strokeColor,
  anchor: new google.maps.Point(15, 30),
});
