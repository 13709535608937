// Function to query devices from the API
// Use React Query
// https://react-query.tanstack.com/

import { QueryObserverOptions, QueryOptions, useQuery } from 'react-query';
import {
  GetCompaniesByGroupsQuery,
  GetCompaniesByGroupsQueryVariables,
  GetDeviceGroupsQuery,
  GetFirmwareQuery,
  GetFirmwareQueryVariables,
  GroupListForUsersQuery,
  GroupListForUsersQueryVariables,
  ListDevicesQuery,
  GetMetersetImageQuery,
  GetMetersetImageQueryVariables,
  ListDevicesQueryVariables,
  ListFirmwareQuery,
  ListFirmwareVersionQuery,
  ListFirmwareVersionQueryVariables,
  UserListQuery,
  UserListQueryVariables,
  GetAllTagCategoriesQuery,
  GetAllUtilityTagCategoriesQuery,
  GetBatteryLifeQuery,
  GetAssociatedSecretCodeQueryVariables,
  GetAssociatedSecretCodeQuery,
  VerifyTotpQuery,
  VerifyTotpQueryVariables,
  RequestExportAlarmLogsQuery,
  RequestExportAlarmLogsQueryVariables,
  RequestExportMeterHealthReportQuery,
  RequestExportMeterHealthReportQueryVariables,
  ExportMeterDataQuery,
  ExportMeterDataQueryVariables,
  ExportBatteryLifeReportQuery,
  ExportBatteryLifeReportQueryVariables,
} from '../API';
import { useAuth } from '../contexts/Auth';
import {
  exportMeterData,
  getAllTagCategories,
  getAllUtilityTagCategories,
  getAssociatedSecretCode,
  getBatteryLife,
  listFirmware,
  requestExportAlarmLogs,
  requestExportMeterHealthReport,
  verifyTotp,
} from '../graphql/queries';
import {
  getCompaniesByGroups,
  getDeviceGroups,
  getFirmware,
  groupListForUsers,
  listDevices,
  getMetersetImage,
  listFirmwareVersion,
  userList,
} from '../graphql/queries';
import { apiClient } from '../api-client';

import {
  GetDeviceShadowQuery,
  GetDeviceShadowQueryVariables,
  GetStatsLogDataQuery,
  GetStatsLogDataQueryVariables,
  GroupListQuery,
  ListCompaniesQuery,
} from '../API';
import { listCompanies } from '../graphql/queries';
import { getDeviceShadow, getStatsLogData, groupList } from '../graphql/queries';
import { DocumentNode, OperationVariables } from '@apollo/client';

export const defaultComponent = 'CCB';

type BaseQueryParams<TQuery, TVariables extends OperationVariables = {}> = {
  query: DocumentNode;
  variables: TVariables;
  key: string;
  additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
  additionalKeys?: any[];
};

type CustomQueryParams<TQuery, TVariables extends OperationVariables = {}> = {
  variables?: TVariables;
  additionalOptions?: QueryOptions<TQuery> & QueryObserverOptions<TQuery>;
};

function useBaseQuery<TQuery, TVariables extends OperationVariables = {}>({
  query,
  variables,
  key,
  additionalOptions,
  additionalKeys,
}: BaseQueryParams<TQuery, TVariables>) {
  const { logout } = useAuth();
  return useQuery<TQuery>({
    queryKey: [key, variables, ...(additionalKeys ?? [])],
    queryFn: fetchFunc,
    retry: false,
    staleTime: 1000 * 60,
    ...additionalOptions,
  });

  async function fetchFunc() {
    try {
      const { data } = await apiClient.query<TVariables, TQuery>(query, {
        ...variables,
      });

      return data;
    } catch (e: unknown) {
      throw apiClient.errorHandle(e, logout);
    }
  }
}

export const useListDevices = createCustomQuery<ListDevicesQuery, ListDevicesQueryVariables>({
  query: listDevices,
  key: 'listDevices',
});

export const useGetMetersetImage = createCustomQuery<
  GetMetersetImageQuery,
  GetMetersetImageQueryVariables
>({
  query: getMetersetImage,
  key: 'getMetersetImage',
});

export const useGroupListForUsers = createCustomQuery<
  GroupListForUsersQuery,
  GroupListForUsersQueryVariables
>({
  query: groupListForUsers,
  key: 'groupListForUsers',
});

export const useListFirmware = createCustomQuery<ListFirmwareQuery>({
  query: listFirmware,
  key: 'listFirmware',
});

export const useListFirmwareVersion = createCustomQuery<
  ListFirmwareVersionQuery,
  ListFirmwareVersionQueryVariables
>({
  query: listFirmwareVersion,
  key: 'listFirmwareVersion',
});

export const useGetFirmware = createCustomQuery<GetFirmwareQuery, GetFirmwareQueryVariables>({
  query: getFirmware,
  key: 'getFirmware',
});

export const useGroupList = createCustomQuery<GroupListQuery>({
  query: groupList,
  key: 'groupList',
});

export const useListCompanies = createCustomQuery<ListCompaniesQuery>({
  query: listCompanies,
  key: 'listCompanies',
});

export const useDeviceShadow = createCustomQuery<
  GetDeviceShadowQuery,
  GetDeviceShadowQueryVariables
>({
  query: getDeviceShadow,
  key: 'getDeviceShadow',
});

export const useMeterData = createCustomQuery<GetStatsLogDataQuery, GetStatsLogDataQueryVariables>({
  query: getStatsLogData,
  key: 'getStatsLogData',
});

export const useUserList = createCustomQuery<UserListQuery, UserListQueryVariables>({
  query: userList,
  key: 'userList',
});

export const useGetDeviceGroups = createCustomQuery<GetDeviceGroupsQuery>({
  query: getDeviceGroups,
  key: 'getDeviceGroups',
});

export const useGetCompaniesByGroups = createCustomQuery<
  GetCompaniesByGroupsQuery,
  GetCompaniesByGroupsQueryVariables
>({
  query: getCompaniesByGroups,
  key: 'getCompaniesByGroups',
});

export const useGetAllUtilityTagCategories = createCustomQuery<GetAllUtilityTagCategoriesQuery>({
  query: getAllUtilityTagCategories,
  key: 'getAllUtilityTagCategories',
});

export const useGetAllTagCategories = createCustomQuery<GetAllTagCategoriesQuery>({
  query: getAllTagCategories,
  key: 'getAllTagCategories',
});

export const useGetBatteryLife = createCustomQuery<GetBatteryLifeQuery>({
  query: getBatteryLife,
  key: 'getBatteryLife',
});

export const useGetSecretCode = createCustomQuery<GetAssociatedSecretCodeQuery>({
  query: getAssociatedSecretCode,
  key: 'getAssociatedSecretCode',
});

export const getSecretCode = async (variables: GetAssociatedSecretCodeQueryVariables) => {
  return apiClient.query<GetAssociatedSecretCodeQueryVariables, GetAssociatedSecretCodeQuery>(
    getAssociatedSecretCode,
    variables
  );
};

export const verifyTotpQuery = (variables: VerifyTotpQueryVariables) => {
  return apiClient.query<VerifyTotpQueryVariables, VerifyTotpQuery>(verifyTotp, variables);
};

export const requestExportAlarmLogsQuery = (variables: RequestExportAlarmLogsQueryVariables) => {
  return apiClient.query<RequestExportAlarmLogsQueryVariables, RequestExportAlarmLogsQuery>(
    requestExportAlarmLogs,
    variables
  );
};

export const requestExportMeterHealthReportQuery = (
  variables: RequestExportMeterHealthReportQueryVariables
) => {
  return apiClient.query<
    RequestExportMeterHealthReportQueryVariables,
    RequestExportMeterHealthReportQuery
  >(requestExportMeterHealthReport, variables);
};

export const exportMeterDataQuery = (variables: ExportMeterDataQueryVariables) => {
  return apiClient.query<ExportMeterDataQueryVariables, ExportMeterDataQuery>(
    exportMeterData,
    variables
  );
};

export const exportBatteryLifeReportQuery = (variables: ExportBatteryLifeReportQueryVariables) => {
  return apiClient.query<ExportBatteryLifeReportQueryVariables, ExportBatteryLifeReportQuery>(
    exportMeterData,
    variables
  );
};

type CreateCustomQueryParams = {
  query: DocumentNode;
  key: string;
};

function createCustomQuery<TQuery, TVariables extends OperationVariables = {}>({
  query,
  key,
}: CreateCustomQueryParams) {
  return ({ variables, additionalOptions }: CustomQueryParams<TQuery, TVariables> = {}) =>
    useBaseQuery<TQuery, TVariables>({
      query,
      variables: { ...variables! },
      key,
      additionalOptions,
    });
}
