import { BiPlusCircle } from 'react-icons/bi';

import { memo, useCallback, useMemo, useState } from 'react';
import AssetsSearch from '../../../components/AssetsSearch/AssetsSearch';
import Button from '../../../components/Button/Button';
import AdvancedSearchModal from '../../../components/Modal/AdvancedSearchModal/AdvancedSearchModal';
import EditColumnsModal from '../../../components/Modal/EditColumnsModal/EditColumnsModal';
import { useAuth } from '../../../contexts/Auth';
import { useUserCompanies } from '../../../hooks';
import UserSettingsUtils from '../../../utils/UserSettingsUtils';
import { useDevicesContext, useDevicesDispatchContext } from '../DevicesProvider';
import { DataOptions } from '../style';
import { PossibleColumnsProps } from './DeviceTable';

type Props = {
  possibleColumns: PossibleColumnsProps[];
  setPossibleColumns: React.Dispatch<React.SetStateAction<PossibleColumnsProps[]>>;
};

export const DeviceTableFilter = memo(({ possibleColumns, setPossibleColumns }: Props) => {
  const { refactoredUser } = useAuth();
  const { dynamicMap } = useDevicesContext();
  const { handleSearch, handleDynamicMap, handlePage } = useDevicesDispatchContext();

  const { userCompanies, companiesLoading } = useUserCompanies();

  const [advancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false);
  const [editColumnsModalOpen, setEditColumnsModalOpen] = useState(false);

  const onSearch = useCallback(
    (searchData: any) => {
      if (dynamicMap) handleDynamicMap(false);
      UserSettingsUtils.saveSearch({ ...searchData });
      handlePage(1);
      handleSearch({ ...searchData });

      setAdvancedSearchModalOpen(false);
    },
    [dynamicMap, handleDynamicMap, handlePage, handleSearch]
  );

  const onTextSearch = useCallback(
    (searchData: any) => {
      if (dynamicMap) handleDynamicMap(false);
      UserSettingsUtils.saveSearch(searchData);
      handlePage(1);
      handleSearch({ ...searchData });
    },
    [dynamicMap, handleDynamicMap, handlePage, handleSearch]
  );

  const dropdownDataSearch = useMemo(() => {
    return [
      {
        label: 'Customer ID',
        name: 'meterId',
      },
      {
        label: 'Device ID',
        name: 'deviceId',
      },
      {
        label: 'Tag',
        name: 'tag',
      },
      {
        label: 'Device Address',
        name: 'address',
      },
      {
        label: 'Company',
        name: 'company',
        omit: refactoredUser?.isAdmin() ? false : userCompanies.length <= 1,
      },
      {
        label: 'Utility',
        name: 'utility',
        omit: refactoredUser?.hasRole([
          'admin',
          'CompanyAdmin',
          'UtilityAdmin',
          'UtilityPowerUser',
        ]),
      },
      {
        label: 'Group',
        name: 'group',
      },
    ];
  }, [refactoredUser, userCompanies.length]);

  const handleAdvancedClick = () => setAdvancedSearchModalOpen(true);
  const handleEditColumnsClick = () => {
    setEditColumnsModalOpen(true);
  };

  return (
    <>
      {advancedSearchModalOpen && (
        <AdvancedSearchModal
          open={advancedSearchModalOpen}
          closeModalFunc={setAdvancedSearchModalOpen}
          onSearch={onSearch}
          companies={userCompanies}
          loadingCompanies={companiesLoading}
        />
      )}
      {editColumnsModalOpen && (
        <EditColumnsModal
          open={editColumnsModalOpen}
          closeModalFunc={setEditColumnsModalOpen}
          possibleColumns={possibleColumns}
          setPossibleColumns={setPossibleColumns}
        />
      )}
      <DataOptions>
        <>
          <AssetsSearch
            dropdownData={dropdownDataSearch}
            onSearch={onTextSearch}
          />
          <Button
            variant="light-primary"
            startIcon={<BiPlusCircle size={14} />}
            onClick={handleAdvancedClick}
          >
            Advanced Search
          </Button>
          <Button
            onClick={handleEditColumnsClick}
            variant="light-primary"
          >
            Edit columns
          </Button>
        </>
      </DataOptions>
    </>
  );
});
