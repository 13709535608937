import { Dispatch, SetStateAction, memo } from 'react';

import { Device } from '../../../API';
import DeviceShadowModal from '../../../components/Modal/DeviceShadowModal/DeviceShadowModal';
import ExportAlarmModal from '../../../components/Modal/ExportAlarmModal/ExportAlarmModal';
import ExportAssetModal from '../../../components/Modal/ExportAssetModal/ExportAssetModal';
import InstallFirmwareModal from '../../../components/Modal/InstallFirmwareModal/InstallFirmwareModal';
import PullDataModal from '../../../components/Modal/PullDataModal/PullDataModal';

type Props = {
  selectedDevice: Device | null;
  installFirmwareModalOpen: boolean;
  setInstallFirmwareModalOpen: Dispatch<SetStateAction<boolean>>;
  pullDataModalOpen: boolean;
  setPullDataModalOpen: Dispatch<SetStateAction<boolean>>;
  exportAlarmModalOpen: boolean;
  setExportAlarmModalOpen: Dispatch<SetStateAction<boolean>>;
  exportDeviceModalOpen: boolean;
  setExportDeviceModalOpen: Dispatch<SetStateAction<boolean>>;
  deviceShadowModalOpen: boolean;
  setDeviceShadowModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const DeviceTableModals = memo(
  ({
    selectedDevice,
    installFirmwareModalOpen,
    setInstallFirmwareModalOpen,
    pullDataModalOpen,
    setPullDataModalOpen,
    exportAlarmModalOpen,
    setExportAlarmModalOpen,
    exportDeviceModalOpen,
    setExportDeviceModalOpen,
    deviceShadowModalOpen,
    setDeviceShadowModalOpen,
  }: Props) => {
    if (!selectedDevice) {
      return null;
    }

    return (
      <>
        {installFirmwareModalOpen && (
          <InstallFirmwareModal
            open={installFirmwareModalOpen}
            devicesToInstallFirmwares={[selectedDevice]}
            closeModalFunc={setInstallFirmwareModalOpen}
          />
        )}
        {pullDataModalOpen && selectedDevice && (
          <PullDataModal
            open={pullDataModalOpen}
            closeModalFunc={setPullDataModalOpen}
            deviceBeingPulled={selectedDevice.id}
          />
        )}
        {exportAlarmModalOpen && (
          <ExportAlarmModal
            open={exportAlarmModalOpen}
            devicesToBeExported={[selectedDevice]}
            closeModalFunc={setExportAlarmModalOpen}
          />
        )}
        {exportDeviceModalOpen && (
          <ExportAssetModal
            open={exportDeviceModalOpen}
            devicesToBeExported={[selectedDevice]}
            closeModalFunc={setExportDeviceModalOpen}
          />
        )}
        {deviceShadowModalOpen && (
          <DeviceShadowModal
            open={deviceShadowModalOpen}
            deviceBeingShadowed={selectedDevice}
            closeModalFunc={setDeviceShadowModalOpen}
          />
        )}
      </>
    );
  }
);
