import styled, { keyframes } from 'styled-components';
import Button from '../Button/Button';

export type ButtonStyleProps = {
  show: boolean;
};

const btnToTopAnimationTransitionBottomToUp = keyframes`

    from {
        bottom: -100px;
        opacity: 0;
        transform: scale(0.5);
    }

    to {
        opacity: 1;
        bottom: 3rem;
        transform: scale(1);

    }
`;

const btnToTopAnimationTransitionUpToBottom = keyframes`

    from {
        bottom: 3rem;
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0;
        bottom: -100px;
        transform: scale(0.5);
    }
`;

export const BtnToTop = styled(Button)<ButtonStyleProps>`
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 9999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  animation: ${({ show }) =>
      show ? btnToTopAnimationTransitionBottomToUp : btnToTopAnimationTransitionUpToBottom}
    0.5s ease-in-out;
  animation-fill-mode: forwards;
`;
