import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import UserSettingsUtils from '../../utils/UserSettingsUtils';
import SearchInput from '../SearchInput/SearchInput';

import { Container, Dropdown, DropdownLink, LinkText } from './style';

type DropdownData = {
  label: string;
  name: string;
  omit?: boolean;
};

type AssetsSearchProps = {
  onSearch: (searchData: any) => void;
  dropdownData: DropdownData[];
};

const AssetsSearch: React.FC<AssetsSearchProps> = ({ onSearch, dropdownData }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const canCallOnSearch = useRef(false);
  const [keyword, setKeyword] = useState(
    Object.keys(UserSettingsUtils.getItem('search') || {})[0] || dropdownData[0].name
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const getInputValue = () => {
    const inputValue = inputRef.current!.value;

    return inputValue;
  };

  const setInputValue = (value: any) => {
    inputRef.current!.value = value;
  };

  const changeKeyword = (keyword: string) => {
    setKeyword(keyword);
    setShowDropdown(false);

    inputRef.current!.focus();
  };

  const getSearchData = () => {
    const inputValue = getInputValue();

    const searchData = {
      [keyword]: inputValue,
    };

    return searchData;
  };

  const onChange = () => {
    setShowDropdown(false);

    const searchData = getSearchData();

    onSearch(searchData);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const path = e?.composedPath();

      if (!path) return;

      const clickedInDropdown = path.includes(containerRef.current!);

      if (!clickedInDropdown) {
        setShowDropdown(false);
      }
    };

    setInputValue(Object.values(UserSettingsUtils.getItem('search') || {})[0] || '');

    document.body.addEventListener('click', handleClickOutside);

    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const getCurrentLabel = () => dropdownData.find((data) => keyword === data.name);

  const handleOnDropdownClick = () => setShowDropdown((isShow) => !isShow);

  useEffect(() => {
    if (canCallOnSearch.current) {
      onSearch(getSearchData());
    }

    canCallOnSearch.current = true;
  }, [keyword]);

  return (
    <Container ref={containerRef}>
      <SearchInput
        ref={inputRef}
        dropdownIcon
        onDropdownClick={handleOnDropdownClick}
        onChange={onChange}
        placeholder={`Search for ${getCurrentLabel()?.label}`}
      />
      {showDropdown && (
        <Dropdown>
          {dropdownData.map(({ label, name, omit }, index) => {
            if (omit) return null;

            return (
              <DropdownLink
                selected={name === keyword}
                onClick={() => changeKeyword(name)}
                key={`${name}-${index}`}
              >
                <LinkText>{label}</LinkText>
                <AiOutlineSearch />
              </DropdownLink>
            );
          })}
        </Dropdown>
      )}
    </Container>
  );
};

export default AssetsSearch;
