import _ from 'lodash';
import { Device } from '../../../API';
import DateUtils from '../../../utils/DateUtils';
import { Badge, WrapperBadge } from '../style';
import { PossibleColumnsProps } from './DeviceTable';

const DeviceIDSelector = (row: Device) => row.id || '';
const SerialNoSelector = (row: Device) => row.serialNo || '';
const MeterIDSelector = (row: Device) => row.meterId || '';

const OwnerSelector = (row: Device) => row.user_device?.user?.email || 'Not attached';

const GroupSelector = (row: Device) => row.group?.name || 'Not attached';

const UtilitySelector = (row: Device) => row.group?.utility.name || 'Not attached';

const CompanySelector = (row: Device) => row.group?.utility.company?.name || 'Not attached';

const AddressSelector = (row: Device) => row.address || '-';

const DtmSelector = (row: Device) => {
  const dTm = row?.data?.dTm;

  return dTm
    ? DateUtils.formatDateWithTmZn(parseInt(dTm) * 1000, row?.data?.TmZn, row?.data?.proto)
    : '-';
};

const IngestionTimeSelector = (row: Device) =>
  _.has(row, 'data.ingestionTime')
    ? DateUtils.formatDateWithTmZn(
        parseInt(row!.data!.ingestionTime),
        row?.data?.TmZn,
        row?.data?.proto
      )
    : '-';

const FcvSelector = (row: Device) =>
  _.has(row, 'data.fCV') ? `${row.data?.fCV} ${row.group?.utility.company?.volumeUnit}` : '-';

const BatteryLifeSelector = (row: Device) => {
  const rowBatteryLife = row as Device & { batteryLife: string | number };
  if (!rowBatteryLife?.batteryLife) return '-';

  return `${rowBatteryLife?.batteryLife}%`;
};

const AlarmSelector = (row: Device) => {
  const alarms = row?.messages?.reduce((current, message) => {
    const messageAlarmsCount = message?.alarms?.length || 0;

    return current + messageAlarmsCount;
  }, 0);

  return alarms?.toString() || '-';
};

const TagCell = (row: Device) => {
  return (
    <WrapperBadge>
      {[...(row?.tags?.predefinedTags ?? []), ...(row?.tags?.utilityTags ?? [])].map(
        (tag, index) => (
          <Badge
            key={`${tag.name} ${index}`}
            size="sm"
            variant="light-primary"
          >
            {tag?.name}
          </Badge>
        )
      )}
    </WrapperBadge>
  );
};

export const initPossibleColumns: PossibleColumnsProps[] = [
  {
    id: 'deviceId',
    name: 'Device ID',
    selector: DeviceIDSelector,
    style: {
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    isVisible: false,
  },
  {
    id: 'serialNo',
    name: 'BrightLync ID',
    selector: SerialNoSelector,
    style: {
      fontWeight: 'bold',
      cursor: 'pointer',
    },
    isVisible: false,
  },
  {
    id: 'meterId',
    name: 'Customer ID',
    isVisible: true,
    selector: MeterIDSelector,
    style: {
      fontWeight: 'bold',
      cursor: 'pointer',
    },
  },
  {
    id: 'owner',
    name: 'Asset Owner',
    selector: OwnerSelector,
    isVisible: false,
  },
  {
    id: 'group',
    name: 'Asset Group',
    selector: GroupSelector,
    isVisible: false,
  },
  {
    id: 'utility',
    name: 'Utility',
    selector: UtilitySelector,
    isVisible: false,
  },
  {
    id: 'company',
    name: 'Company',
    selector: CompanySelector,
    isVisible: false,
  },
  {
    id: 'address',
    name: 'Address',
    isVisible: true,
    selector: AddressSelector,
    style: {
      cursor: 'pointer',
      width: 500,
    },
  },
  {
    id: 'dtm',
    name: 'Read date/time',
    selector: DtmSelector,
    isVisible: false,
  },
  {
    id: 'ingestionTime',
    name: 'Ingestion Time',
    selector: IngestionTimeSelector,
    isVisible: false,
  },
  {
    id: 'fcv',
    name: 'Full Corrected Index Read',
    selector: FcvSelector,
    isVisible: false,
  },
  {
    id: 'fuv',
    name: 'Full Uncorrected Index Read',
    selector: (row) =>
      _.has(row, 'data.fUV') ? `${row.data?.fUV} ${row.group?.utility.company?.volumeUnit}` : '-',
    isVisible: false,
  },
  {
    id: 'cfavg',
    name: 'Average Uncorrected Flow Rate',
    selector: (row) =>
      _.has(row, 'data.cFAvg')
        ? `${row.data?.cFAvg} ${row.group?.utility.company?.correctedFlowUnit}`
        : '-',
    isVisible: false,
  },
  {
    id: 'maxpr',
    name: 'Max. Pressure',
    selector: (row) =>
      _.has(row, 'data.maxPr.v')
        ? `${row.data?.maxPr?.v} ${row.group?.utility.company?.pressureUnit}`
        : '-',
    isVisible: false,
  },
  {
    id: 'minfc',
    name: 'Min. Flow Corr.',
    selector: (row) =>
      _.has(row, 'data.minFC.v')
        ? `${row.data?.minFC?.v} ${row.group?.utility.company?.volumeUnit}`
        : '-',
    isVisible: false,
  },
  {
    id: 'maxtemp',
    name: 'Max. Temp.',
    selector: (row) =>
      _.has(row, 'data.maxTemp.v')
        ? `${row.data?.maxTemp?.v} ${row.group?.utility.company?.temperatureUnit}`
        : '-',
    isVisible: false,
  },
  {
    id: 'bv',
    name: 'Battery Remaining Life',
    selector: BatteryLifeSelector,
    isVisible: false,
  },
  {
    id: 'daycv',
    name: 'Corr. Index Read (Daily)',
    selector: (row) =>
      _.has(row, 'data.dayCV')
        ? `${row.data?.dayCV} ${row.group?.utility.company?.volumeUnit}`
        : '-',
    isVisible: false,
  },
  {
    id: 'dayuv',
    name: 'Uncorr. Index Read (Daily)',
    selector: (row) =>
      _.has(row, 'data.dayUV')
        ? `${row.data?.dayUV} ${row.group?.utility.company?.volumeUnit}`
        : '-',
    isVisible: false,
  },
  {
    id: 'alarms',
    name: 'Alarms',
    isVisible: true,
    selector: AlarmSelector,
  },
  {
    id: 'tags',
    name: 'Tags',
    isVisible: true,
    cell: TagCell,
  },
];
