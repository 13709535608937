import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { PossibleColumnsProps } from '../../../screens/Devices/DeviceTable/DeviceTable';
import UserSettingsUtils from '../../../utils/UserSettingsUtils';
import Checkbox from '../../Forms/Checkbox/Checkbox';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import RadioGroup from '../../Forms/RadioGroup/RadioGroup';
import Modal, { ModalOpeningProps } from '../Modal';

type Props = {
  possibleColumns: PossibleColumnsProps[];
  setPossibleColumns: React.Dispatch<React.SetStateAction<PossibleColumnsProps[]>>;
};

const EditColumnsModal: React.FC<ModalOpeningProps & Props> = ({
  open,
  closeModalFunc,
  possibleColumns,
  setPossibleColumns,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const { register, getValues } = useForm<{ columns: string[] }>();

  const toggleColumn = () => {
    const values: any = getValues().columns;

    setPossibleColumns((columns) =>
      columns.map((column) => ({
        ...column,
        isVisible: values.includes(column.id),
      }))
    );

    UserSettingsUtils.saveColumns(values);

    closeModalFunc(false);
  };

  const toggleSelectAll = (e: any) => {
    if (formRef.current) {
      const isChecked = e.currentTarget.checked;
      const selects = formRef.current.querySelectorAll<HTMLInputElement>(
        'input:not([value="selectall"])'
      );

      selects.forEach((select) => {
        select.checked = isChecked;
      });
    }
  };

  return (
    <Modal
      closeModalFunc={closeModalFunc}
      open={open}
      title="Edit Columns"
      description="Select the column metrics required"
      buttonText="Change Columns"
      onSubmitBtnClick={toggleColumn}
    >
      <form ref={formRef}>
        <FormGroup>
          <RadioGroup label="Select component">
            <div>
              <Checkbox
                label="Select All"
                name="columns[]"
                value={'selectall'}
                onClick={toggleSelectAll}
                register={register}
                checked={false}
              />
            </div>
            {possibleColumns.map(({ id, name, isVisible }) => {
              return (
                <div key={id}>
                  <Checkbox
                    label={name}
                    value={id}
                    name="columns[]"
                    register={register}
                    checked={isVisible}
                  />
                </div>
              );
            })}

            {/* {(userCompanies.length > 1 || userIsAdmin) && <div>
                            <Checkbox
                                value={'company'}
                                label="Company"
                                name="columns[]"
                                register={changeColumnsRegister}
                                checked={visibleColumns.includes('company')}
                            />
        </div>} */}
          </RadioGroup>
        </FormGroup>
      </form>
    </Modal>
  );
};

export default EditColumnsModal;
