import React, { useState } from 'react';
import { Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import Modal from '../Modal/Modal';
import Select from '../Forms/Select/Select';
import { useForm } from 'react-hook-form';
import { SelectBox } from './style';

export type QMarginData = { label: string; value: number };
export type QMarginDataList = QMarginData[];

type Props = {
  open: boolean;
  closeModalFunc: (open: boolean) => void;
  meterSize: number | string;
  deviceId: string;
  serialNo: string;
  companyName: string;
  address: string;
  qMarginDataList: QMarginDataList;
};

const MeterHealthCertificateModal: React.FC<Props> = ({
  open,
  closeModalFunc,
  meterSize,
  deviceId,
  serialNo,
  companyName,
  address,
  qMarginDataList,
}) => {
  const defaultValue = qMarginDataList[qMarginDataList.length - 1].value.toString();

  const [selectedQMarginValue, setSelectedQMarginValue] = useState<string>(defaultValue);

  const { register } = useForm();

  return (
    <Modal
      title="Meter Health Certificate"
      open={open}
      closeModalFunc={closeModalFunc}
    >
      <SelectBox>
        <Select
          register={register}
          onChange={(e) => setSelectedQMarginValue(e.currentTarget.value)}
          name="date"
          defaultValue={selectedQMarginValue}
          label="Meter Health Date"
        >
          {qMarginDataList.map((qMarginData: QMarginData) => {
            return (
              <option
                key={qMarginData.label}
                value={qMarginData.value.toString()}
              >
                {qMarginData.label}
              </option>
            );
          })}
        </Select>
      </SelectBox>

      <PDFViewer style={styles.pdfViewer}>
        <Document>
          <Page size="A4">
            <View style={styles.header}>
              <View style={styles.headerSection}>
                <Image
                  style={styles.logo}
                  src="/images/romet-logo-white.png"
                />
              </View>
              <View style={styles.headerSection}>
                <Text style={styles.headerText}>Romet Limited</Text>
                <Text style={styles.headerText}>5030 Timberlea Blvd.</Text>
                <Text style={styles.headerText}>Mississauga, ON, CAN</Text>
                <Text style={styles.headerText}>L4W 2S5</Text>
              </View>
            </View>

            <View style={styles.mainContent}>
              <Text style={styles.title}>Rotary Meter Health Acceptance Certificate</Text>

              <View style={styles.table}>
                <View style={styles.tableHeader}>
                  <Text style={styles.tableHeaderTitle}>Meter Information</Text>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.parameter}>
                    <Text style={styles.cellValue}>Meter Size</Text>
                  </View>
                  <View style={styles.value}>
                    <Text style={styles.cellValue}>{meterSize}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.parameter}>
                    <Text style={styles.cellValue}>Device ID</Text>
                  </View>
                  <View style={styles.value}>
                    <Text style={styles.cellValue}>{deviceId}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.parameter}>
                    <Text style={styles.cellValue}>BrightLync ID</Text>
                  </View>
                  <View style={styles.value}>
                    <Text style={styles.cellValue}>{serialNo}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.parameter}>
                    <Text style={styles.cellValue}>Company Name</Text>
                  </View>
                  <View style={styles.value}>
                    <Text style={styles.cellValue}>{companyName}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.parameter}>
                    <Text style={styles.cellValue}>Address</Text>
                  </View>
                  <View style={styles.value}>
                    <Text style={styles.cellValue}>{address}</Text>
                  </View>
                </View>
              </View>

              <View style={styles.table}>
                <View style={styles.tableHeader}>
                  <Text style={styles.tableHeaderTitle}>Calculation Result</Text>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.parameter}>
                    <Text style={styles.cellValue}>Q min allowable</Text>
                  </View>
                  <View style={styles.value}>
                    <Text style={styles.cellValue}>0</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.parameter}>
                    <Text style={styles.cellValue}>Q margin</Text>
                  </View>
                  <View style={styles.value}>
                    <Text style={styles.cellValue}>{selectedQMarginValue}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.parameter}>
                    <Text style={styles.cellValue}>Meter Health Acceptance Result</Text>
                  </View>
                  <View style={styles.value}>
                    <Text style={styles.cellValue}>
                      {parseFloat(selectedQMarginValue) > 0
                        ? 'Qmargin at acceptable level'
                        : 'Qmargin below acceptable level'}
                    </Text>
                  </View>
                </View>
              </View>

              <View style={styles.commentsSection}>
                <Text>Comments:</Text>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Modal>
  );
};

const styles = StyleSheet.create({
  pdfViewer: {
    height: '100%',
    width: '100%',
  },
  header: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 5,
    height: 80,
    width: '100%',
    backgroundColor: '#0C2340',
  },
  headerSection: {
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  logo: {
    width: 112,
    height: 23,
  },
  headerText: {
    color: 'white',
    textAlign: 'right',
    fontSize: 12,
  },
  mainContent: {
    paddingVertical: 10,
    paddingHorizontal: 20,
  },
  title: {
    width: '100%',
    textAlign: 'center',
  },
  table: {
    width: '100%',
    borderWidth: 1,
    borderColor: 'black',
    marginTop: 20,
  },
  tableHeader: {
    padding: 10,
    backgroundColor: '#F1F1F1',
  },
  tableHeaderTitle: {
    fontSize: 20,
    textAlign: 'center',
  },
  tableRow: {
    flexDirection: 'row',
  },
  parameter: {
    flex: 1,
    padding: 10,
    borderRightColor: 'black',
    borderRightWidth: 1,
    borderTopColor: 'black',
    borderTopWidth: 1,
  },
  value: {
    flex: 2,
    padding: 10,
    borderTopColor: 'black',
    borderTopWidth: 1,
  },
  cellValue: {
    textAlign: 'center',
  },
  commentsSection: {
    marginTop: 20,
    padding: 10,
    backgroundColor: '#F1F1F1',
    borderColor: 'black',
    borderWidth: 1,
  },
});

export default MeterHealthCertificateModal;
