import { memo, useCallback, useMemo, useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { QueryObserverResult } from 'react-query';

import { Device, ListDevicesQuery, TagCategory } from '../../API';
import Can from '../../components/Can/Can';
import { OptionalTitleContentWrapper, TitleContainer } from '../../components/MainLayout/style';
import AssetConfigurationModal from '../../components/Modal/AssetConfigurationModal/AssetConfigurationModal';
import DeviceShadowModal from '../../components/Modal/DeviceShadowModal/DeviceShadowModal';
import ExportAlarmModal from '../../components/Modal/ExportAlarmModal/ExportAlarmModal';
import ExportAssetModal from '../../components/Modal/ExportAssetModal/ExportAssetModal';
import InstallFirmwareModal from '../../components/Modal/InstallFirmwareModal/InstallFirmwareModal';
import PullDataModal from '../../components/Modal/PullDataModal/PullDataModal';
import SetThresholdsModal, {
  ThresholdsFormType,
} from '../../components/Modal/SetThresholdsModal/SetThresholdsModal';
import Spinner from '../../components/Spinner/Spinner';
import { DropdownBtn } from '../Devices/DeviceTable/style';
import { MainTitleWrapper } from '../Notifications/style';
import {
  AlarmsBedge,
  DeviceID,
  DeviceInfo,
  DeviceTitle,
  DevicesLogo,
  DropdownItem,
  TableDropdown,
} from './style';

type Props = {
  device?: Device | null;
  refetch?: () => Promise<QueryObserverResult<ListDevicesQuery, unknown>>;
  predefinedTagCategories: TagCategory[];
  utilityTagCategories: TagCategory[];
  isDevicesLoading: boolean;
  flowUnit?: string | undefined;
  volumeUnit?: string | undefined;
};
export const MeterIdHeader = memo(
  ({
    device,
    refetch,
    predefinedTagCategories,
    utilityTagCategories,
    isDevicesLoading,
    flowUnit,
    volumeUnit,
  }: Props) => {
    const [assetConfigModalOpen, setAssetConfigModalOpen] = useState(false);
    const [thresholdsModalOpen, setThresholdsModalOpen] = useState(false);
    const [installFirmwareModalOpen, setInstallFirmwareModalOpen] = useState(false);
    const [pullDataModalOpen, setPullDataModalOpen] = useState(false);
    const [exportDeviceModalOpen, setExportDeviceModalOpen] = useState(false);
    const [exportAlarmModalOpen, setExportAlarmModalOpen] = useState(false);
    const [deviceShadowModalOpen, setDeviceShadowModalOpen] = useState(false);

    const handleTagsChange = useCallback(
      (changed: boolean) => {
        if (changed) {
          refetch?.();
        }
      },
      [refetch]
    );

    const totalAlarms = useMemo(
      () =>
        device?.messages?.reduce((alarmCount, message) => {
          return alarmCount + (message?.alarms?.length || 0);
        }, 0) ?? 0,
      [device?.messages]
    );

    const defaultThresholdValues = useMemo(() => {
      const allThresholds = device?.thresholds || [];
      const withoutNull = allThresholds.filter((threshold) => threshold?.value !== null);
      const defaultValues = withoutNull.reduce<ThresholdsFormType>((accumulator, threshold) => {
        const thresholdValue = threshold?.value;
        const thresholdKey = threshold?.threshold_key!;

        (accumulator as any)[thresholdKey] = thresholdValue;

        return accumulator;
      }, {});

      return defaultValues;
    }, [device?.thresholds]);

    const handleThresholdsUpdate = useCallback(() => {
      refetch?.();
    }, [refetch]);

    const handleInstallFirmwareForDevice = async () => {
      setInstallFirmwareModalOpen(true);
    };

    const handleExportAlarmsBtn = async () => {
      setExportAlarmModalOpen(true);
    };

    const handleExportDevicesBtn = async () => {
      setExportDeviceModalOpen(true);
    };

    const handleDeviceShadowBtn = async () => {
      setDeviceShadowModalOpen(true);
    };

    const handlePullData = useCallback(() => setPullDataModalOpen(true), []);

    const handleAssetConfig = useCallback(() => {
      setAssetConfigModalOpen(true);
    }, []);

    const handleSetThresholds = useCallback(() => {
      setThresholdsModalOpen(true);
    }, []);

    return (
      <>
        {installFirmwareModalOpen && (
          <InstallFirmwareModal
            open={installFirmwareModalOpen}
            closeModalFunc={setInstallFirmwareModalOpen}
            devicesToInstallFirmwares={[device]}
          />
        )}
        {pullDataModalOpen && (
          <PullDataModal
            open={pullDataModalOpen}
            closeModalFunc={setPullDataModalOpen}
            deviceBeingPulled={device?.id!}
          />
        )}

        {exportAlarmModalOpen && (
          <ExportAlarmModal
            devicesToBeExported={[device]}
            open={exportAlarmModalOpen}
            closeModalFunc={setExportAlarmModalOpen}
          />
        )}

        {exportDeviceModalOpen && (
          <ExportAssetModal
            devicesToBeExported={[device]}
            open={exportDeviceModalOpen}
            flowUnit={flowUnit}
            volumeUnit={volumeUnit}
            closeModalFunc={setExportDeviceModalOpen}
          />
        )}

        {assetConfigModalOpen && (
          <AssetConfigurationModal
            device={device!}
            predefinedTagCategories={predefinedTagCategories}
            utilityTagCategories={utilityTagCategories}
            currentPredefinedTags={device?.tags?.predefinedTags ?? []}
            currentUtilityTags={device?.tags?.utilityTags ?? []}
            open={assetConfigModalOpen}
            closeModalFunc={setAssetConfigModalOpen}
            onTagsChanged={handleTagsChange}
          />
        )}

        {thresholdsModalOpen && (
          <SetThresholdsModal
            defaultValues={defaultThresholdValues}
            open={thresholdsModalOpen}
            closeModalFunc={setThresholdsModalOpen}
            onThresholdsUpdated={handleThresholdsUpdate}
            device={device!}
          />
        )}

        {deviceShadowModalOpen && (
          <DeviceShadowModal
            open={deviceShadowModalOpen}
            closeModalFunc={setDeviceShadowModalOpen}
            deviceBeingShadowed={device}
          />
        )}

        <TitleContainer>
          <MainTitleWrapper>
            <DevicesLogo>
              <img
                alt=""
                src={'/svgs/devices-solid.svg'}
              />
            </DevicesLogo>
            <DeviceInfo>
              <DeviceTitle>Assets</DeviceTitle>
              {!isDevicesLoading ? (
                <DeviceID>Customer ID - {device?.meterId || 'Not found'}</DeviceID>
              ) : (
                <Spinner variant="primary" />
              )}
            </DeviceInfo>
            <AlarmsBedge>{totalAlarms} Alarms</AlarmsBedge>
          </MainTitleWrapper>
          <OptionalTitleContentWrapper>
            <Can role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}>
              <DropdownBtn
                renderContent={
                  <TableDropdown>
                    <Can role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}>
                      <DropdownItem onClick={handleInstallFirmwareForDevice}>
                        Install Firmware
                      </DropdownItem>
                    </Can>
                    <Can role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}>
                      <DropdownItem onClick={handlePullData}>Pull Data</DropdownItem>
                    </Can>
                    <Can role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}>
                      <DropdownItem onClick={handleExportAlarmsBtn}>Export Alarm Data</DropdownItem>
                    </Can>
                    <Can role={['CompanyAdmin', 'UtilityAdmin', 'DeviceGroupAdmin', 'admin']}>
                      <DropdownItem onClick={handleExportDevicesBtn}>
                        Export Asset Data
                      </DropdownItem>
                    </Can>
                    <Can role={['UtilityAdmin', 'admin', 'DeviceGroupAdmin', 'CompanyAdmin']}>
                      <DropdownItem onClick={handleAssetConfig}>Asset Configuration</DropdownItem>
                    </Can>
                    <Can role={['UtilityAdmin', 'admin', 'UtilityPowerUser']}>
                      <DropdownItem onClick={handleSetThresholds}>Set Thresholds</DropdownItem>
                    </Can>
                    <Can role={'admin'}>
                      <DropdownItem onClick={handleDeviceShadowBtn}>Asset Shadow</DropdownItem>
                    </Can>
                  </TableDropdown>
                }
                shape="circle"
                variant="light"
                dropIcon={false}
              >
                <BsThreeDots />
              </DropdownBtn>
            </Can>
          </OptionalTitleContentWrapper>
        </TitleContainer>
      </>
    );
  }
);
