import React, { useState } from 'react';
import { exportBatteryLifeReportQuery } from '../../../hooks/queries';
import Alert from '../../Alert/Alert';
import Modal, { ModalOpeningProps } from '../Modal';

type Props = {};

const ExportBatteryLifeReportModal: React.FC<ModalOpeningProps & Props> = ({
  open,
  closeModalFunc,
}) => {
  const [exporting, setExporting] = useState(false);
  const [exportBatteryLifeReportAlertMsg, setExportBatteryLifeReportAlertMsg] = useState({
    msg: '',
    variant: 'success',
  });

  const handleExportBatteryLifeReportSubmit = async () => {
    setExporting(true);
    try {
      const { data } = await exportBatteryLifeReportQuery({});

      const success = data.exportBatteryLifeReport.success;

      if (success) {
        const msg = data.exportBatteryLifeReport.message || '';
        setExportBatteryLifeReportAlertMsg({
          msg,
          variant: 'success',
        });
      }
    } catch (e) {
      const msg = 'Something went wrong. Please try again.';
      setExportBatteryLifeReportAlertMsg({
        msg,
        variant: 'danger',
      });
    }

    setExporting(false);
  };

  return (
    <Modal
      title="Export battery life report for all devices"
      description="Export battery life report CSV"
      open={open}
      closeModalFunc={closeModalFunc}
      buttonText="Export battery life report"
      onSubmitBtnClick={handleExportBatteryLifeReportSubmit}
      buttonLoading={exporting}
    >
      {exportBatteryLifeReportAlertMsg.msg && (
        <Alert variant={exportBatteryLifeReportAlertMsg.variant}>
          {exportBatteryLifeReportAlertMsg.msg}
        </Alert>
      )}
    </Modal>
  );
};

export default ExportBatteryLifeReportModal;
