import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 5px;
  padding: 20px;
  padding-top: 10px;
  font-size: 12px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const Wrapper = styled.div`
  padding: 0 4px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const InfoItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 5px;
`;

export const Col = styled.div`
  color: ${(props) => props.theme.colors.black};

  &:first-child {
    margin-right: 16px;
  }

  &:last-child {
    flex: 1;
  }
`;

export const Label = styled.label`
  color: #666;
`;

export const BoxTitle = styled.h3`
  font-size: 16px;
  line-height: 32px;
  font-weight: normal;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:empty {
    display: none;
  }
`;

export const MultipleValue = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 411px;

  & span:not(:last-child) {
    &::after {
      content: '';
      position: relative;

      height: 5px;
      width: 1px;
      z-index: 100;
      /* background-color: ${(props) => props.theme.colors.black}66; */
      display: inline-block;
      margin: 0 5px;
      transform: translateY(-25%);
    }
  }
`;

export const Value = styled.span``;

export const BottomContentContainer = styled.div`
  margin-top: 16px;
`;
