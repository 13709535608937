import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Device, RequestExportAlarmLogsQueryVariables } from '../../../API';
import errorMessages from '../../../config/errorMessages';
import { requestExportAlarmLogsQuery } from '../../../hooks/queries';
import Alert from '../../Alert/Alert';
import FormControl from '../../Forms/FormControl/FormControl';
import FormGroup from '../../Forms/FormGroup/FormGroup';
import FormRow from '../../Forms/FormRow/FormRow';
import Radio from '../../Forms/Radio/Radio';
import RadioGroup from '../../Forms/RadioGroup/RadioGroup';
import Modal, { ModalOpeningProps } from '../Modal';

type Props = {
  devicesToBeExported: Array<Device | null | undefined>;
};

const exportDataSchema = yup.object({
  format: yup.string().required(errorMessages.required),
  fromDate: yup
    .string()
    .matches(/\d{4}-\d{2}-\d{2}/g, 'Not a valid date')
    .required(errorMessages.required),
  toDate: yup
    .string()
    .matches(/\d{4}-\d{2}-\d{2}/g, 'Not a valid date')
    .required(errorMessages.required),
});

const start = new Date();
start.setDate(start.getDate() - 30);
const defaultToDate = new Date();
const defaultFromDate = start;

const ExportAlarmModal: React.FC<ModalOpeningProps & Props> = ({
  open,
  closeModalFunc,
  devicesToBeExported,
}) => {
  const [exportingDevice, setExportingDevice] = useState(false);
  const [exportAlarmDataAlertMsg, setExportAlarmDataAlertMsg] = useState({
    msg: '',
    variant: 'success',
  });

  const formats = [
    {
      label: 'CSV',
      value: 'csv',
    },
    {
      label: 'JSON',
      value: 'json',
    },
  ];

  const {
    register,
    formState: { errors },
    trigger,
    getValues,
  } = useForm<
    RequestExportAlarmLogsQueryVariables & {
      filename: string;
    }
  >({
    resolver: yupResolver(exportDataSchema),
  });

  const handleExportAlarmsSubmit = async () => {
    const hasNoErrors = await trigger();

    if (!hasNoErrors) return;

    const { format, fromDate, toDate } = getValues();

    setExportingDevice(true);
    try {
      const { data } = await requestExportAlarmLogsQuery({
        deviceIds: [...devicesToBeExported.map((device) => device!.id)],
        format,
        fromDate: moment(fromDate, 'YYYY-MM-DD').format('MM-DD-YYYY'),
        toDate: moment(toDate, 'YYYY-MM-DD').format('MM-DD-YYYY'),
      });

      const success = data.requestExportAlarmLogs.success;

      if (success) {
        const msg = data.requestExportAlarmLogs.message || '';
        setExportAlarmDataAlertMsg({
          msg,
          variant: 'success',
        });
      }
    } catch (e) {
      const msg = 'Maximum difference between From Date and To Date should be 31 days';
      setExportAlarmDataAlertMsg({
        msg,
        variant: 'danger',
      });
    }

    setExportingDevice(false);
  };

  return (
    <Modal
      title={`Export alarm data for ${
        devicesToBeExported.length === 1 ? devicesToBeExported[0]?.id : 'all assets'
      }`}
      description="Export alarm data for CSV or JSON"
      open={open}
      closeModalFunc={closeModalFunc}
      buttonText="Export asset(s) alarms"
      onSubmitBtnClick={handleExportAlarmsSubmit}
      buttonLoading={exportingDevice}
    >
      <form>
        <FormGroup>
          <FormRow>
            <FormControl
              name="fromDate"
              label="From"
              type={'date'}
              id="from"
              value={moment(defaultFromDate).format('YYYY-MM-DD')}
              register={register}
              error={errors.fromDate?.message?.toString()}
            />
            <FormControl
              register={register}
              error={errors.toDate?.message?.toString()}
              name="toDate"
              label="To"
              value={moment(defaultToDate).format('YYYY-MM-DD')}
              type={'date'}
              id="to"
            />
          </FormRow>
        </FormGroup>
        <FormGroup>
          <RadioGroup label="Format">
            {formats.map(({ label, value }, index) => (
              <Radio
                checked={index === 0}
                register={register}
                key={`${label}_${index}`}
                error={errors.format?.message}
                id={label}
                label={label}
                name="format"
                value={value}
              />
            ))}
          </RadioGroup>
        </FormGroup>
      </form>
      {exportAlarmDataAlertMsg.msg && (
        <Alert variant={exportAlarmDataAlertMsg.variant}>{exportAlarmDataAlertMsg.msg}</Alert>
      )}
    </Modal>
  );
};

export default ExportAlarmModal;
