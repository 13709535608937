import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { Device } from '../../API';
import AlarmUtils from '../../utils/AlarmUtils';
import { getDeviceAlarms, infoWindowContent, mapOptions, markerIcon } from './MapComponentHelpers';

interface MapProps extends google.maps.MapOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  onDragEnd?: (map: google.maps.Map) => void;
  onDragStart?: (map: google.maps.Map) => void;
  onZoomChanged?: (map: google.maps.Map) => void;
  devices?: (Device | null)[];
  dynamicMap?: boolean;
}

const MapComponent = memo(
  ({ onDragEnd, onDragStart, onZoomChanged, onIdle, devices, dynamicMap }: MapProps) => {
    const theme = useTheme();
    const ref = useRef<HTMLDivElement>(null);

    const [map, setMap] = useState<google.maps.Map>();
    const infoWindow = useRef(new window.google.maps.InfoWindow());
    const markers = useRef<google.maps.Marker[]>([]);

    const deleteAllMarkers = () => {
      markers.current.forEach((marker) => {
        marker.setMap(null);
      });
      markers.current.length = 0;
    };

    const fitBounds = useCallback(() => {
      const bounds = new window.google.maps.LatLngBounds();
      markers.current.forEach((marker) => {
        bounds.extend(marker.getPosition() as google.maps.LatLng);
      });
      map?.fitBounds(bounds, 20);
    }, [map]);

    const showMarkers = useCallback(() => {
      deleteAllMarkers();
      infoWindow.current.close();

      devices?.forEach((device) => {
        if (!device?.latitude || !device?.longitude) return;
        const { color, flashing } = AlarmUtils.getDeviceData(device);

        const marker = new window.google.maps.Marker({
          position: {
            lng: device.longitude,
            lat: device.latitude,
          },
          icon: markerIcon(color, theme.colors.white),
          ...(flashing && { animation: google.maps.Animation.BOUNCE }),
        });

        const alarms = getDeviceAlarms(device);

        const handleClickMarker = () => {
          infoWindow.current.setContent(infoWindowContent(device, alarms));
          infoWindow.current.open({
            anchor: marker,
            shouldFocus: false,
          });
        };

        marker.addListener('click', handleClickMarker);
        markers.current.push(marker);
      });

      markers.current.forEach((marker) => {
        marker.setMap(map!);
      });

      if (!dynamicMap) {
        fitBounds();
      }
    }, [devices, dynamicMap, fitBounds, map, theme.colors.white]);

    useEffect(() => {
      if (ref.current && !map) {
        const map = new window.google.maps.Map(ref.current, mapOptions);

        setMap(map);
      }
    }, [map]);

    useEffect(() => {
      if (ref.current && map) {
        infoWindow.current.close();
        showMarkers();
      }
    }, [devices, map, showMarkers]);

    useEffect(() => {
      if (map) {
        showMarkers();
        ['click', 'idle'].forEach((eventName) =>
          window.google.maps.event.clearListeners(map, eventName)
        );

        map.addListener('click', (e: google.maps.MapMouseEvent) => {
          infoWindow.current.close();
        });

        if (onDragEnd) {
          const handleDragger = () => onDragEnd(map);
          map.addListener('dragend', handleDragger);
        }

        if (onDragStart) {
          const handleDragStart = () => onDragStart(map);
          map.addListener('dragstart', handleDragStart);
        }

        if (onIdle) {
          const handleIdle = () => onIdle(map);
          map.addListener('idle', handleIdle);
        }

        if (onZoomChanged) {
          const handleZoom = () => onZoomChanged(map);
          map.addListener('zoom_changed', handleZoom);
        }
      }
    }, [map, onDragEnd, onDragStart, onIdle, onZoomChanged, showMarkers]);

    return (
      <div
        ref={ref}
        style={{ width: '100%', height: '100%', position: 'relative' }}
      ></div>
    );
  }
);

export default MapComponent;
