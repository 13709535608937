import styled from 'styled-components';
import DropdownButton from '../../../components/DropdownButton/DropdownButton';
import { MapStates } from '../DeviceMap/DeviceMap';

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: auto;
`;

export const DropdownBtn = styled(DropdownButton)`
  background-color: red;
`;

export const DropdownItem = styled.span`
  font-size: 12px;
  line-height: 20px;
  padding: 8px 0;
  cursor: pointer;
  &:last-child {
    /* color: ${(props) => props.theme.colors.danger}; */
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }
`;

export const DataContainer = styled.div<MapStates>`
  ${(props) => (props.fullMap ? 'opacity: 0;' : 'opacity: 1;')}
  overflow: hidden;
  transition: all 300ms;

  @media screen and (min-width: ${(props) => props.theme.breakpoints.lg}) {
    ${(props) =>
      props.fullMap ? 'width:  0%;' : !props.showingMap ? 'width: 100%;' : 'width: 50%;'}
  }
`;

export const DataOptions = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  margin-bottom: 1rem;
  & button {
    font-size: 12px;

    & > svg {
      margin-right: 5px;
    }
  }

  & > button {
    white-space: break-spaces;
    margin-left: 24px;
  }

  & > *:first-child {
    margin-right: auto;
  }
`;

export const TableButtons = styled.div`
  position: absolute;
  bottom: 5px;
  left: 5px;
  display: inline-flex;
  gap: 5px;
`;
